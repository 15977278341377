define("backend/mobiledoc-titleize/helper", ["exports", "ember-mobiledoc-editor/helpers/mobiledoc-titleize"], function (_exports, _mobiledocTitleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mobiledocTitleize.default;
    }
  });
  Object.defineProperty(_exports, "mobiledocTitleize", {
    enumerable: true,
    get: function get() {
      return _mobiledocTitleize.mobiledocTitleize;
    }
  });
});