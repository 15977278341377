define("backend/pods/countries/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    model: function model(params) {
      var store = this.store;
      return this.queryModel({
        countries: store.query('country', this.curPage(params))
      });
    }
  });

  _exports.default = _default;
});