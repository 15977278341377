define("backend/prototypes/tables/headers/offers", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'number'
  }, {
    attr: 'date'
  }, {
    attr: 'amount',
    format: 'currency'
  }, {
    attr: 'status.displayName',
    name: 'Status'
  }, {
    attr: 'file'
  }]);

  _exports.default = _default;
});