define("backend/serializers/device", ["exports", "backend/serializers/application", "backend/models/simple/device-component"], function (_exports, _application, _deviceComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function findOwner(hash, key) {
    var loadModelName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : key;

    if (hash[key]) {
      hash.async_ids['owner'] = {
        loadModelName: loadModelName,
        id: hash[key]
      };
    }
  }

  var EMBEDDED_IDS = {
    deserialize: 'ids',
    serialize: 'ids'
  };

  var _default = _application.default.extend({
    attrs: {
      distributor: EMBEDDED_IDS,
      inStore: 'store'
    },
    normalize: function normalize(typeClass, hash) {
      var adapter = this.store.adapterFor('device');
      var links = hash['links'] ? hash.links : {};
      links['computedMovies'] = adapter.urlForLinks('movie', hash.id);
      links['computedCategories'] = adapter.urlForLinks('category', hash.id);
      hash['links'] = links;
      hash.async_ids = {
        app: {
          loadModelName: 'app',
          id: hash.app
        },
        owner: {
          loadModelName: null,
          id: null
        }
      };
      findOwner(hash, 'client');
      findOwner(hash, 'distributor');
      findOwner(hash, 'licensor');
      findOwner(hash, 'lead');
      findOwner(hash, 'tester');
      findOwner(hash, 'store', 'in-store');
      findOwner(hash, 'sponsored_client', 'sponsored-client');
      this.normalizeComponents(hash);
      this.normalizeSettings(hash);
      this.normalieAdditionalDetails(hash);

      var json = this._super(typeClass, hash);

      return json;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      this.serializeComponents(json);
      return json;
    },
    normalizeSettings: function normalizeSettings(json) {
      var settingsJson = json && json['settings'] || {};
      var settings = settingsJson['data'] || [];
      json['settings'] = Object.keys(settings).map(function (label) {
        return _deviceComponent.default.create({
          label: label,
          value: settings[label]
        });
      });
    },
    normalieAdditionalDetails: function normalieAdditionalDetails(json) {
      var settings = json && json['additional_details'] || {};
      json['additional_details'] = Object.keys(settings).map(function (label) {
        return {
          label: label,
          value: settings[label]
        };
      });
    },
    normalizeComponents: function normalizeComponents(json) {
      var components = json && json['components'] || [];
      json['components'] = components.map(function (component) {
        return _deviceComponent.default.create(component);
      });
    },
    serializeComponents: function serializeComponents(json) {
      var _ref = json || [],
          components = _ref.components;

      json['components'] = components.filterBy('isNotEmpty').map(function (component) {
        return component.getProperties('label', 'value');
      });
    }
  });

  _exports.default = _default;
});