define("backend/prototypes/tables/headers/device-starts", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'date',
    name: 'Date'
  }, {
    attr: 'starts',
    name: 'Starts'
  }, {
    attr: 'total_duration',
    name: 'Total Duration'
  }]);

  _exports.default = _default;
});