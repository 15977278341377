define("backend/components/manage-owner-contacts", ["exports", "backend/components/partners-component", "backend/mixins/route-component/reload-counts"], function (_exports, _partnersComponent, _reloadCounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _partnersComponent.default.extend(_reloadCounts.default, {
    keywords: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.edit(this.get('selected'));
    },
    listClientsAction: 'listClients',
    createModel: 'client',
    keepSelected: true,
    forceReloadSelected: true,
    showType: true,
    existingContactTypes: Ember.computed.mapBy('keywords.contactTypes', 'displayName'),
    sortedContactTypes: Ember.computed('existingContactTypes', 'existingContactTypes.length', function () {
      return this.get('existingContactTypes').sort();
    }),
    actions: {
      cancelEdit: function cancelEdit() {
        this._super();

        return this.sendAction('listClientsAction');
      },
      createLinkedEntry: function createLinkedEntry(type, value, model) {
        if (type === 'contact-type') {
          return this.send('addNewContactType', value, model);
        }

        return this._super.apply(this, arguments);
      },
      addNewContactType: function addNewContactType(name, contact) {
        this.get('store').createRecord('contact-type', {
          name: name
        });

        if (!Ember.isNone(contact)) {
          Ember.run.schedule('afterRender', function () {
            return contact.set('type', name);
          });
        }
      }
    }
  });

  _exports.default = _default;
});