define("backend/pods/licensors/overview/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size'],
    page: 1,
    size: 10000,
    licensorsContacted: Ember.computed.filterBy('model.owners', 'status.id', '2'),
    licensorsDiscussion: Ember.computed.filterBy('model.owners', 'status.id', '3'),
    licensorsMovieSelecting: Ember.computed.filterBy('model.owners', 'status.id', '4'),
    licensorsFeeProposition: Ember.computed.filterBy('model.owners', 'status.id', '5'),
    licensorsContractNegotiating: Ember.computed.filterBy('model.owners', 'status.id', '6'),
    actions: {
      setActive: function setActive(licensor) {
        var _this = this;

        return licensor.reload().then(function () {
          return _this.transitionToRoute('licensor.index', licensor);
        });
      },
      updateLicensorStatus: function updateLicensorStatus(licensor, _ref) {
        var _this2 = this;

        var newStatus = _ref.target.newStatus;
        licensor.updateStatus(newStatus).finally(function () {
          _this2.send('licensorChanged');
        });
      }
    }
  });

  _exports.default = _default;
});