define("backend/adapters/category", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    duplicate: function duplicate(id) {
      var url = this.urlForCreateRecord('category');
      return this.ajax(url, 'POST', {
        data: {
          data: {
            template: id
          }
        }
      });
    },
    deleteRecursive: function deleteRecursive(id) {
      var url = this.urlForDeleteRecord(id, 'category') + '/recursive';
      return this.ajax(url, 'DELETE');
    }
  });

  _exports.default = _default;
});