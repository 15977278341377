define("backend/pods/leads/create/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      checkDuplicate: function checkDuplicate(displayName) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            var url = _environment.default.host + "/search_duplicate?display_name=".concat(displayName);

            _this.get('ajax').request(url, {
              headers: headers
            }).then(function (_ref) {
              var data = _ref.data;
              return resolve(data);
            }).catch(function () {
              return reject();
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});