define("backend/pods/device/content/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function testUnique(v, i, self) {
    return self.indexOf(v) === i;
  }

  var _default = Ember.Controller.extend({
    allLanguages: Ember.computed.reads('model.languages'),
    selLanguage: Ember.computed('categories', function () {
      var catLanguages = this.get('categories').mapBy('language');
      var availLanguages = catLanguages.filter(testUnique);

      if (availLanguages.length === 1) {
        return this.get('allLanguages').findBy('id', availLanguages[0]);
      }

      return null;
    }),
    showSimulator: false,
    categories: Ember.computed.reads('model.device.computedCategories'),
    movies: Ember.computed.reads('model.device.computedMovies'),
    filteredMovies: Ember.computed('model.device.computedMovies', 'selTargetGroup', function () {
      var movies = this.get('model.device.computedMovies');
      var selTargetGroup = this.get('selTargetGroup');
      var filters = [];

      if (!Ember.isEmpty(selTargetGroup)) {
        filters.push(function (m) {
          return m.get('targetGroup').findBy('id', selTargetGroup);
        });
      }

      if (!filters.length) {
        return movies;
      }

      return movies.filter(function (movie) {
        return filters.every(function (filter) {
          return filter(movie);
        });
      });
    }),
    languagesFiltered: Ember.computed('allLanguages', 'selLanguage', 'filteredMovies.length', 'categories.length', function () {
      var _this = this;

      var categories = this.get('categories');
      var movies = this.get('filteredMovies');
      var selLanguage = this.get('selLanguage');
      var languages = [];

      if (!selLanguage) {
        languages = this.get('allLanguages');
      } else {
        languages = [selLanguage];
      }

      return languages.reduce(function (acc, language) {
        if (categories.get('length') > 0) {
          var langId = language.get('id');
          var langCategories = categories.filterBy('language', langId);

          if (!Ember.isEmpty(langCategories)) {
            acc.pushObject(Ember.Object.create({
              name: language.get('displayName'),
              categories: _this.buildCategoryTree(langCategories, movies)
            }));
          }
        }

        return acc;
      }, []);
    }),
    languages: Ember.computed('allLanguages', 'movies.length', 'categories.length', function () {
      var _this2 = this;

      var categories = this.get('categories');
      var movies = this.get('movies');
      var languages = this.get('allLanguages');
      return languages.reduce(function (acc, language) {
        if (categories.get('length') > 0) {
          var langId = language.get('id');
          var langCategories = categories.filterBy('language', langId);

          if (!Ember.isEmpty(langCategories)) {
            acc.pushObject(Ember.Object.create({
              name: language.get('displayName'),
              categories: _this2.buildCategoryTree(langCategories, movies)
            }));
          }
        }

        return acc;
      }, []);
    }),
    buildCategoryTree: function buildCategoryTree(categories, movies) {
      var _this3 = this;

      var parentId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
      return categories.filterBy('parent.id', parentId).reduce(function (acc, category) {
        var subCategories = _this3.buildCategoryTree(categories, movies, category.get('id'));

        var catMovies = movies.filterBy('category.id', category.get('id')).sortBy('order', 'displayName');

        if (subCategories.length > 0 || catMovies.length > 0) {
          acc.pushObject(Ember.Object.create({
            id: category.get('id'),
            name: category.get('name'),
            order: category.get('order'),
            categories: subCategories,
            movies: catMovies
          }));
        }

        return acc;
      }, []).sortBy('order', 'displayName');
    },
    actions: {
      closeSimulator: function closeSimulator() {
        this.set('showSimulator', false);
      },
      showSimulator: function showSimulator() {
        this.setProperties({
          showSimulator: true,
          selFilter: true,
          showCategory: null
        });
      },
      startSimulator: function startSimulator() {
        this.set('selFilter', false);
      },
      simulateCategory: function simulateCategory(category) {
        this.set('showCategory', category);
      },
      clearSelCategory: function clearSelCategory() {
        this.set('showCategory', null);
      },
      clearSelLang: function clearSelLang() {
        this.set('selFilter', true); // this.set('selLanguage', null);
      },
      setTargetGroup: function setTargetGroup(id) {
        this.set('selTargetGroup', id);
      }
    }
  });

  _exports.default = _default;
});