define("backend/pods/device/content/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var device = this.modelFor('device');
      return Ember.RSVP.hash({
        device: device,
        languages: this.get('store').findAll('language'),
        targetGroups: this.get('store').peekAll('targetGroup')
      });
    },
    setupController: function setupController(controller, model) {
      model.contentComparison = this.get('store').adapterFor('device').getComparison(model.device).catch(function () {});
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});