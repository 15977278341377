define("backend/pods/logs/backend/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function formatErrorStack(value) {
    if (value) {
      var li = value.split('\n').map(function (v) {
        if (!v) {
          return '&nbsp;';
        }

        if (v.includes('backend')) {
          return "<b>".concat(v, "</b>");
        }

        return v;
      }).join('</li><li>');
      value = "<ul class=\"no-ul-style\"><li>".concat(li, "</li></ul>");
    }

    return Ember.String.htmlSafe(value);
  }

  var MAX_LIMIT = 500;

  var _default = Ember.Route.extend({
    queryParams: {
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var start = _ref.start,
          end = _ref.end;
      var adapter = this.get('store').adapterFor('application');

      var url = adapter._buildURL('clientlogs');

      return adapter.ajax(url, 'GET').then(function (_ref2) {
        var data = _ref2.data;
        var newArray = [];
        var stopAt = (data.length > MAX_LIMIT ? data.length - MAX_LIMIT : 0) - 1;

        for (var idx = data.length - 1; idx > stopAt; idx--) {
          var d = data[idx];
          var date = d.timestamp.slice(0, 10);

          if (date > start) {
            d.essentials = Ember.String.htmlSafe("<ul class=\"no-ul-style\"><li><b>".concat(d.log.error.message, "</b></li><li>").concat(d.log.meta.url, "</li></ul>"));
            d.log.error.stack = formatErrorStack(d.log.error.stack);
            newArray.push(Ember.Object.create(d));
          }
        }

        return newArray;
      });
    }
  });

  _exports.default = _default;
});