define("backend/pods/client-features/movies/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        inprogress: this.getUploadsInProgress(),
        transcodeFiles: this.getTranscodeFiles()
      });
    },
    getUploadsInProgress: function getUploadsInProgress() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/upload/resumable";

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            return resolve(data.findBy('status', 1) ? Ember.Object.create(data.findBy('status', 1)) : null);
          });
        });
      });
    },
    getTranscodeFiles: function getTranscodeFiles() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this2.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;

          _this2.get('ajax').request(_environment.default.host + "/transcode/list_movies", {
            headers: headers
          }).then(function (_ref2) {
            var data = _ref2.data;
            return resolve(data.map(function (d) {
              return Ember.Object.create({
                displayName: d.slice(d.lastIndexOf('/') + 1),
                id: d
              });
            }));
          });
        });
      });
    }
  });

  _exports.default = _default;
});