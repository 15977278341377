define("backend/pods/logs/server/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'type'],
    page: 1,
    size: 100,
    types: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'clients',
        displayName: 'Client'
      }), Ember.Object.create({
        id: 'server',
        displayName: 'Server'
      })];
    })
  });

  _exports.default = _default;
});