define("backend/prototypes/tables/headers/packages", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'displayName',
    name: 'Name'
  }, {
    attr: 'standardPrice',
    name: 'Price',
    format: 'currency',
    style: 'text-right text-nowrap'
  }, {
    attr: 'revenueShare',
    name: 'Revenue Share',
    format: 'currency',
    style: 'text-right text-nowrap'
  }, {
    attr: 'includedPackages',
    name: 'Included Packages',
    format: 'array'
  }, {
    attr: 'categories',
    name: 'Categories',
    format: 'array'
  }, {
    attr: 'public',
    name: 'Public',
    format: 'boolean'
  }]);

  _exports.default = _default;
});