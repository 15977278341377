define("backend/mixins/device-packages-mixin", ["exports", "backend/utils/buffer-proxy"], function (_exports, _bufferProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BUFFER_KEY = 'selected.buffers.packages';

  var _default = Ember.Mixin.create({
    // ------------------------------- ADD, SAVE --------------------------------
    addDevicePackage: function addDevicePackage() {
      var record = this.get('store').createRecord('devicePackage');
      var buffer = (0, _bufferProxy.default)(record);
      this.get(BUFFER_KEY).pushObject(buffer);
    },
    saveDevicePackages: function saveDevicePackages() {
      if (!this.get('selected.canSaveDevicePackages')) {
        return Ember.RSVP.resolve();
      }

      var devicePackages = this.get(BUFFER_KEY);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.isEmpty(devicePackages)) {
          return resolve();
        }

        devicePackages = devicePackages.mapBy('content');
        Ember.RSVP.Promise.all(devicePackages.map(function (dP) {
          return dP.save();
        })).then(function (result) {
          return resolve(result);
        }).catch(reject);
      });
    },
    // --------------------------- REMOVE, DESTROY ------------------------------
    deletedDevicePackages: Ember.computed(function () {
      return [];
    }),
    removeDevicePackage: function removeDevicePackage(buffer) {
      this.get(BUFFER_KEY).removeObject(buffer);
      buffer.discardChanges();
      this.get('deletedDevicePackages').pushObject(buffer.content);
    },
    destroyDevicePackages: function destroyDevicePackages() {
      var _this = this;

      if (!this.get('selected.canDestroyDevicePackages')) {
        return Ember.RSVP.resolve();
      }

      var devicePackages = this.get('deletedDevicePackages');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.isEmpty(devicePackages)) {
          return resolve();
        }

        Ember.RSVP.Promise.all(devicePackages.map(function (dP) {
          return dP.destroyRecord();
        })).then(_this.clearDeletedDevicePackages.bind(_this)).then(resolve).catch(reject);
      });
    },
    clearDeletedDevicePackages: function clearDeletedDevicePackages() {
      this.get('deletedDevicePackages').clear();
    },
    // ------------------------------ HOOKS ------------------------------------
    saveDependencies: function saveDependencies() {
      var defaultDependencies = this._super.apply(this, arguments) || [];
      return [this.saveDevicePackages(), this.destroyDevicePackages()].concat(defaultDependencies);
    },
    createLinkedEntry: function createLinkedEntry(type) {
      if (type === 'devicePackage') {
        return this.addDevicePackage();
      }

      return this._super(type);
    },
    deleteLinkedEntry: function deleteLinkedEntry(linkedEntry) {
      var type = linkedEntry.get('content.constructor.modelName');

      if (type === 'device-package') {
        return this.removeDevicePackage(linkedEntry);
      }

      return this._super(linkedEntry);
    }
  });

  _exports.default = _default;
});