define("backend/templates/components/category-selector-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "17vejQlj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"ember-power-select-group\"],[11,\"aria-disabled\",[28,\"ember-power-select-true-string-if-present\",[[24,[\"disabled\"]]],null]],[10,\"role\",\"option\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"group\",\"isRootOption\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-group-name\"],[8],[1,[24,[\"group\",\"groupName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/category-selector-group.hbs"
    }
  });

  _exports.default = _default;
});