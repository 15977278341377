define("backend/pods/four-oh-four/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qEypNOwn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Page does not exist\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/four-oh-four/template.hbs"
    }
  });

  _exports.default = _default;
});