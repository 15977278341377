define("backend/pods/components/hm-models/list/filter-statistic-billing/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    last2Years: Ember.computed(function () {
      var year = (0, _moment.default)().year();
      return [Ember.Object.create({
        displayName: "" + year,
        id: "" + year
      }), Ember.Object.create({
        displayName: "" + (year - 1),
        id: "" + (year - 1)
      })];
    }),
    allMonths: Ember.computed(function () {
      return _moment.default.months().map(function (name, idx) {
        return Ember.Object.create({
          displayName: name,
          id: ("0" + (idx + 1)).slice(-2)
        });
      });
    })
  });

  _exports.default = _default;
});