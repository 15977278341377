define("backend/pods/components/hm-models/list/contracts/delivery-date/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    refreshModelAction: 'refreshModelAction',
    hasSelectedDate: Ember.computed.notEmpty('date'),
    submitClass: Ember.computed('hasSelectedDate', function () {
      return this.get('hasSelectedDate') ? 'btn-primary' : 'bg-slate-300';
    }),
    cursorClass: Ember.computed('hasSelectedDate', function () {
      return this.get('hasSelectedDate') ? '' : 'cursor--not-allowed';
    }),
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('hasSelectedDate')) {
          return false;
        }

        var date = this.get('date');
        var dateFmt = (0, _moment.default)(date).format('DD.MM.YYYY');

        if (confirm("The contract will be set active.\n\n Please confirm the delivery date for ".concat(dateFmt, "."))) {
          var promise = this.get('model').saveDeliveryDate(date);
          this.set('promise', ObjectPromiseProxy.create({
            promise: promise
          }));
          promise.then(function () {
            _this.sendAction('refreshModelAction');
          });
          promise.catch(function (_ref) {
            var errors = _ref.errors;
            alert("Could not save date: ".concat(errors.get('firstObject.error')));
          });
        }
      }
    }
  });

  _exports.default = _default;
});