define("backend/prototypes/tables/headers/devices-no-owner", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'labels',
    format: 'labels'
  }, {
    attr: 'serialNumber',
    name: 'Serial Number'
  }, {
    attr: 'deviceVersion',
    name: 'Version'
  }, {
    modelName: 'app',
    attr: 'displayName',
    name: 'App',
    component: 'lazy-load'
  }, {
    attr: 'deliveryDate',
    name: 'Assembly Date',
    format: 'date'
  }, {
    attr: 'returnDate',
    name: 'Return Date',
    format: 'date',
    display: false
  }, {
    attr: 'nextInspection',
    name: 'Next Inspection',
    format: 'date'
  }, {
    attr: 'lastUpdate',
    name: 'Last Content Update',
    format: 'datetime'
  }, {
    attr: 'replayDuration',
    name: 'Replay Duration (this month)',
    component: 'format-promise',
    style: 'text-center',
    display: false
  }, {
    attr: 'type.displayName',
    name: 'Device Type'
  }, {
    attr: 'status.displayName',
    name: 'Device Status',
    display: false
  }, {
    attr: 'licenseStatus.displayName',
    name: 'License Status'
  }, {
    modelName: 'owner',
    attr: 'displayName',
    name: 'Owner',
    component: 'lazy-load'
  }, {
    attr: 'ownerTypeName',
    name: 'Owner Type',
    display: false,
    evalLazy: true
  }, {
    attr: 'location',
    name: 'Location',
    display: false
  }, {
    attr: 'batteryInPercent',
    name: 'Battery Level',
    display: false,
    format: 'trend',
    style: 'text-right text-nowrap'
  }, {
    attr: 'lastRequestDate',
    name: 'Last Request',
    format: 'datetime',
    display: false
  }, {
    attr: 'failedUpdateAttempts',
    name: 'Failed Update Attempts',
    display: false
  }, {
    attr: 'storageUsedMB',
    name: 'Storage Used',
    display: false,
    style: 'text-right text-nowrap'
  }, {
    attr: 'storageFreeMB',
    name: 'Storage Free',
    display: false,
    style: 'text-right text-nowrap'
  }, {
    attr: 'ipLocation',
    name: 'IP Location',
    display: false
  }, {
    attr: 'ipAddress',
    name: 'IP Address',
    display: false
  }, {
    attr: 'wifiName',
    name: 'WiFi',
    display: false
  }, {
    attr: 'debugMode',
    name: 'Debug Mode',
    display: false
  }, {
    attr: 'mobileData',
    name: 'Mobile Data',
    display: false
  }, {
    attr: 'movieFileInfos',
    name: 'Movie Files',
    display: false
  }, {
    attr: 'downloadSpeedFormatted',
    name: 'Download Speed',
    display: false
  }, {
    attr: 'maintenanceCheck',
    name: 'Maintenance Check',
    format: 'boolean',
    display: false
  }]);

  _exports.default = _default;
});