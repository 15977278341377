define("backend/pods/licensor/attachments/route", ["exports", "backend/prototypes/attachments/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    ownerType: 'licensor'
  });

  _exports.default = _default;
});