define("backend/instance-initializers/error-logging", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'error-logging',
    initialize: function initialize(container) {
      if (!_environment.default['hm-error-logging'].enabled) {
        return false;
      }

      Ember.onerror = handleError.bind(container, 'Ember.onerror');
      window.onerror = handleError.bind(container, 'window.onerror');
      Ember.RSVP.on('error', handleError.bind(container, 'Ember.RSVP'));

      Ember.Logger.error = function (message, cause, stack, notes) {
        handleError.bind(container, 'Ember.Logger')(generateError(cause, stack, message));

        if (notes) {
          handleError.bind(container, 'Ember.Logger')(generateError(notes));
        }
      };
    }
  };
  _exports.default = _default;

  function handleError(issuer, error) {
    var _this = this;

    if (error && error.name !== 'TransitionAborted') {
      var properties = ['name', 'message', 'stack', 'description', 'number', 'fileName', 'lineNumber', 'columnNumber'];

      try {
        var clientLog = {
          error: {
            summary: JSON.stringify(error)
          },
          meta: {
            url: window.location.href,
            issuer: issuer,
            timestamp: new Date().toJSON(),
            userAgent: navigator.userAgent,
            vendor: navigator.vendor
          }
        };

        if (error) {
          properties.forEach(function (key) {
            var val = error[key];

            if (!Ember.isNone(val)) {
              clientLog.error[key] = typeof val === 'string' ? val : JSON.stringify(val);
            }
          });
        }

        this.lookup('service:session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;

          var url = _this.lookup('adapter:application').urlForClientLog();

          console.error(clientLog); // Disable for now
          // $.ajax({
          //   type: "POST",
          //   beforeSend: function(request) {
          //     request.setRequestHeader(headerName, headerValue);
          //   },
          //   url,
          //   dataType: 'json',
          //   contentType: "application/json; charset=utf-8",
          //   data: JSON.stringify({ data: { log: clientLog } })
          // });
        });
      } catch (e) {// ignore
      }

      logToConsole(error);
    }
  }

  function generateError(cause, stack, message) {
    var error = new Error(cause);
    error.stack = stack;
    error.message = message;
    return error;
  }

  function logToConsole(error) {
    if (error && error.stack) {
      console.error(error.stack);
    } else {
      console.error(error);
    }
  }
});