define("backend/utils/static-data/lead-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Exhibition Dental',
    is_exhibition: true
  }, {
    id: 2,
    name: 'Exhibition Hospital',
    is_exhibition: true
  }, {
    id: 3,
    name: 'Inbound Dental',
    is_inbound: true
  }, {
    id: 4,
    name: 'Inbound Hospital',
    is_inbound: true
  }, {
    id: 5,
    name: 'Inbound Other',
    is_inbound: true
  }, {
    id: 6,
    name: 'Outbound Dental',
    is_outbound: true
  }, {
    id: 7,
    name: 'Outbound Hospital',
    is_outbound: true
  }, {
    id: 8,
    name: 'Referral Dental',
    is_referral: true
  }, {
    id: 9,
    name: 'Referral Hospital',
    is_referral: true
  }];
  _exports.default = _default;
});