define("backend/pods/content/activities/controller", ["exports", "backend/prototypes/tables/headers/events-movie", "moment"], function (_exports, _eventsMovie, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'user', 'startDate', 'endDate', 'resource'],
    page: 1,
    size: 100,
    startDate: (0, _moment.default)().subtract(4, 'weeks').format('YYYY-MM-DD'),
    resource: 'movie',
    tableColumns: _eventsMovie.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    }
  });

  _exports.default = _default;
});