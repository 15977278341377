define("backend/pods/categories/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    model: function model(params) {
      var store = this.store;
      return this.queryModel({
        categories: store.query('category', this.curPage(params))
      });
    },
    preloadDependencies: function preloadDependencies() {
      return this.store.findAll('package');
    },
    loadDependencies: function loadDependencies() {
      var store = this.store;
      return {
        packages: store.peekAll('package'),
        categoryStatusList: store.peekAll('categoryStatus')
      };
    },
    lazyloadDependencies: function lazyloadDependencies() {
      var store = this.store;
      return {
        movies: store.findAll('movie'),
        languages: store.findAll('language')
      };
    }
  });

  _exports.default = _default;
});