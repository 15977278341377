define("backend/pods/sales-cockpit/team/route", ["exports", "moment", "backend/utils/sales/sales-overview"], function (_exports, _moment, _salesOverview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return this._loadDependencies().then(function (dependencies) {
        var overview = (0, _salesOverview.default)(_this.get('store'), dependencies);
        return {
          dependencies: dependencies,
          overview: overview
        };
      });
    },
    _loadDependencies: function _loadDependencies() {
      var store = this.get('store');
      var allPages = {
        number: 1,
        size: 1000
      };
      return Ember.RSVP.hash({
        distributors: store.query('distributor', {
          filter: 'active',
          page: allPages
        }),
        clients: store.query('client', {
          filter: 'active',
          page: allPages
        }),
        leads: store.query('lead', {
          page: allPages
        }),
        users: store.query('user', {
          page: allPages
        })
      });
    },
    setupController: function setupController(controller, model) {
      var from = (0, _moment.default)().subtract(7, 'day');
      var to = (0, _moment.default)();
      controller.setProperties({
        model: model,
        from: from,
        to: to
      });
      controller.send('setFromDate', from);
      controller.send('setToDate', to);
    }
  });

  _exports.default = _default;
});