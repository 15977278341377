define("backend/pods/movie-upload/route", ["exports", "backend/config/environment", "backend/helpers/format-filesize"], function (_exports, _environment, _formatFilesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/upload/resumable";

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            resolve(data.map(function (d) {
              // d.created_at = `${moment(d.created_at).format('DD.MM.YYYY HH:mm')} by ${d.created_by_user}`;
              d.filesize_mb = (0, _formatFilesize.formatFilesize)([d.filesize], {});
              d.filesize_bytes = "".concat(d.filesize, " bytes");
              d.file_info = "".concat(d.filename, " (").concat(d.filesize_mb, ")");
              d.bytes_transferred_fmt = "".concat(d.bytes_transferred || 0, " bytes");
              d.bytes_transferred_fmt_mb = (0, _formatFilesize.formatFilesize)([d.bytes_transferred], {});
              return Ember.Object.create(d);
            }));
          });
        });
      });
    }
  });

  _exports.default = _default;
});