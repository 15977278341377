define("backend/pods/sales/documents/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('document', {
        page: {
          size: 10000
        }
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('selected', null);
    },
    actions: {
      saveComplete: function saveComplete() {
        return this.refresh();
      },
      deleteComplete: function deleteComplete() {
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});