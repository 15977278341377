define("backend/components/billing-shipment-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    refreshModelAction: 'refreshModelAction',
    hasSelectedDate: Ember.computed.notEmpty('dateOfShipment'),
    submitClass: Ember.computed('hasSelectedDate', function () {
      return this.get('hasSelectedDate') ? 'btn-primary' : 'bg-slate-300';
    }),
    cursorClass: Ember.computed('hasSelectedDate', function () {
      return this.get('hasSelectedDate') ? '' : 'cursor--not-allowed';
    }),
    canEnterDate: Ember.computed.not('model.dateOfCancellation'),
    actions: {
      saveShipmentDate: function saveShipmentDate() {
        var _this = this;

        if (this.get('hasSelectedDate')) {
          var billing = this.get('model');
          var promise = billing.saveShipmentDate(this.get('dateOfShipment'));
          this.set('promise', ObjectPromiseProxy.create({
            promise: promise
          }));
          promise.then(function () {
            _this.sendAction('refreshModelAction');
          });
        }
      }
    }
  });

  _exports.default = _default;
});