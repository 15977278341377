define("backend/components/collapsable-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    classNames: ['content-group-lg'],
    classNameBindings: ['isCollapsed:is-collapsed:animation--fade-in'],
    isCollapsed: Ember.computed('userSettings.inactiveBoxes.length', function () {
      return this.get('userSettings').isBoxCollapsed(this.get('boxId'));
    })
  });

  _exports.default = _default;
});