define("backend/prototypes/tables/headers/contracts-all", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Client'
    },
    columns: [{
      attr: 'client.displayName',
      name: 'Client'
    }, {
      attr: 'department.name',
      name: 'Department',
      style: 'text-nowrap text-center'
    }]
  }, {
    group: {
      name: 'Value'
    },
    columns: [{
      attr: 'addedValue',
      name: 'Value',
      style: 'text-nowrap text-center',
      format: 'currency'
    }]
  }, {
    group: {
      name: 'Contract'
    },
    columns: [{
      attr: 'type.displayName',
      name: 'Type',
      style: 'text-nowrap text-center'
    }, {
      attr: 'numDevices',
      name: 'Devices',
      style: 'text-nowrap text-center'
    }, {
      attr: 'duration',
      name: 'Duration',
      style: 'text-nowrap text-center',
      noFormat: true
    }, {
      attr: 'insurance',
      name: 'Insurance',
      style: 'text-nowrap text-center',
      format: 'currency',
      display: false
    }, {
      attr: 'hardwareAcquisition',
      name: 'Hardware Acquisition',
      style: 'text-nowrap text-center',
      canEdit: true,
      format: 'currency',
      display: false
    }, {
      attr: 'setupFee',
      name: 'Setup Fee',
      style: 'text-nowrap text-center',
      format: 'currency',
      display: false
    }, {
      attr: 'noticePeriod',
      name: 'Notice Period',
      style: 'text-nowrap text-center',
      display: false
    }, {
      attr: 'notes',
      name: 'Notes',
      style: 'text-nowrap text-center',
      display: false
    }, {
      attr: 'deliveryDate',
      name: 'Delivery Date',
      style: 'text-nowrap text-center',
      format: 'date'
    }, {
      attr: 'cancellationDate',
      name: 'Cancel Date',
      style: 'text-nowrap text-center',
      format: 'date'
    }, {
      attr: 'lastCancelDate',
      name: 'Last Cancellation Date',
      style: 'text-nowrap text-center',
      format: 'date'
    }, {
      attr: 'ordinaryContractEndDate',
      name: 'Ordinary End Date',
      style: 'text-nowrap text-center',
      format: 'date'
    }, {
      attr: 'cancellationEndDate',
      name: 'Actual Contract End Date',
      style: 'text-nowrap text-center',
      format: 'date'
    }, {
      attr: 'file',
      name: 'PDF',
      style: 'text-nowrap text-center',
      canEdit: false,
      component: 'secure-download',
      display: false
    }, {
      attr: 'cancellationFile',
      name: 'Cancel PDF',
      style: 'text-nowrap text-center',
      canEdit: false,
      component: 'secure-download',
      display: false
    }, {
      attr: 'signingDate',
      name: 'Signing Date',
      style: 'text-nowrap text-center',
      canEdit: false,
      format: 'date'
    }]
  }, {
    group: {
      name: 'Billing'
    },
    columns: [{
      attr: 'billingInterval',
      name: 'Interval',
      style: 'text-nowrap text-center',
      display: false
    }, {
      attr: 'billingDiscount',
      name: 'Discount',
      style: 'text-nowrap text-center',
      format: 'percent',
      display: false
    }, {
      attr: 'otherDiscount',
      name: 'Other Discounts',
      style: 'text-nowrap text-center',
      canEdit: true,
      display: false
    }]
  }, {
    group: {
      name: 'Package'
    },
    columns: [{
      attr: 'package.name',
      name: 'Name',
      style: 'text-center'
    }, {
      attr: 'packagePrice',
      name: 'Price',
      style: 'text-nowrap text-center',
      format: 'currency'
    }, {
      attr: 'packagePriceDiscounted',
      name: 'Discounted Price',
      style: 'text-nowrap text-center',
      canEdit: true,
      format: 'currency'
    }]
  }, {
    group: {
      name: 'Contact'
    },
    columns: [{
      attr: 'contact.displayName',
      name: 'Name'
    }, {
      attr: 'contact.phone',
      name: 'Phone',
      style: 'text-nowrap'
    }, {
      attr: 'contact.email',
      name: 'E-Mail',
      style: 'text-nowrap'
    }]
  }]);

  _exports.default = _default;
});