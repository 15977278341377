define("backend/pods/components/render-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j4kYLOzv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"render-mobiledoc\",null,[[\"mobiledoc\"],[[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/render-notes/template.hbs"
    }
  });

  _exports.default = _default;
});