define("backend/utils/hm-transforms/date-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var TIME_FORMAT = 'HH:mm';

  function deserialize(timestamp) {
    if (timestamp) {
      return (0, _moment.default)(timestamp).format(TIME_FORMAT);
    }

    return null;
  }

  function serialize(timestamp, newTime) {
    if (newTime) {
      var _newTime$match = newTime.match(/(\d+)(:(\d\d))?\s*/i),
          _newTime$match2 = _slicedToArray(_newTime$match, 4),
          _ = _newTime$match2[0],
          hours = _newTime$match2[1],
          __ = _newTime$match2[2],
          minutes = _newTime$match2[3];

      if (timestamp && !Ember.isBlank(hours) && !Ember.isBlank(minutes)) {
        var date = new Date(timestamp);
        date.setHours(hours);
        date.setMinutes(minutes);
        return date.toISOString();
      }
    }

    return null;
  }

  var _default = {
    serialize: serialize,
    deserialize: deserialize
  };
  _exports.default = _default;
});