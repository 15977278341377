define("backend/pods/oauth/authenticate/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var URL_AUTH_CLIENT = '/auth/client';
  var CLIENT_ORIGINS = {
    'agchcagddmefpccecdkcgpmnenpaepog': 'chrome-extension://agchcagddmefpccecdkcgpmnenpaepog' // DEV
    // 'faddmejhbomlkhpnohhojlikobgjcnil': 'chrome-extension://faddmejhbomlkhpnohhojlikobgjcnil'

  };

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: {
      client_id: {
        refreshModel: true
      },
      client_secret: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this.set('currentUser.loggedInAsThirdParty', true);
    },
    model: function model(_ref, transition) {
      var client_id = _ref.client_id,
          client_secret = _ref.client_secret;

      if (client_id && client_secret && CLIENT_ORIGINS[client_id]) {
        return this.authorizeClient({
          client_id: client_id,
          client_secret: client_secret
        }, transition);
      }

      return null;
    },
    authorizeClient: function authorizeClient(data, transition) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        return _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var options = {
            method: 'POST',
            headers: _defineProperty({}, headerName, headerValue),
            data: {
              data: data
            },
            contentType: 'application/json; charset=utf-8'
          };

          var authRequest = _this.get('ajax').request(_environment.default.host + URL_AUTH_CLIENT, options);

          return authRequest.then(function (response) {
            window.top.postMessage({
              action: 'authenticated',
              data: response.data
            }, CLIENT_ORIGINS[data.client_id]);
            resolve({
              success: true,
              isNotLiveSystem: _environment.default.isNotLiveSystem
            });
          }).catch(function () {
            return transition.send('logout', data);
          });
        });
      });
    }
  });

  _exports.default = _default;
});