define("backend/pods/components/hm-model/fieldset-essentials/descendants/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hMjfTfJh",
    "block": "{\"symbols\":[\"model\",\"model\"],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold label-small-gap\"],[8],[7,\"i\",true],[10,\"class\",\"icon-users position-left\"],[8],[9],[0,\" Sub Companies\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"style\",\"display:flex;flex-wrap: wrap; margin-left: -10px; margin-right: -10px;\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"slice\",[0,4,[24,[\"models\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"hm-model/fieldset-essentials/descendants/descendant\",null,[[\"model\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[4,\"if\",[[28,\"gt\",[[24,[\"models\",\"length\"]],4],null]],null,{\"statements\":[[4,\"if\",[[24,[\"showMore\"]]],null,{\"statements\":[[4,\"each\",[[28,\"slice\",[4,100,[24,[\"models\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"hm-model/fieldset-essentials/descendants/descendant\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-default btn-block\"],[12,\"style\",\"margin: 10px\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showMore\"]]],null],false]],[8],[0,\"\\n        Hide More\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-default btn-block\"],[12,\"style\",\"margin: 10px\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showMore\"]]],null],true]],[8],[0,\"\\n        Show More\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-essentials/descendants/template.hbs"
    }
  });

  _exports.default = _default;
});