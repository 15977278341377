define("backend/models/package", ["exports", "ember-data", "backend/models/buffered-model", "ember-cp-validations", "backend/utils/package-overview"], function (_exports, _emberData, _bufferedModel, _emberCpValidations, _packageOverview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = _bufferedModel.default.extend({
    name: attr('string'),
    standardPrice: attr('number'),
    revenueShare: attr('number'),
    public: attr('boolean', {
      defaultValue: false
    }),
    counts: attr(),
    categories: hasMany('categories', {
      inverse: 'package',
      async: true
    }),
    inheritedCategories: hasMany('categories', {
      inverse: 'inheritedPackages',
      async: true
    }),
    // TODO: Implement useBuffer for hasMany selection. The use case is different
    // from hasMany 'contacts', since it's only a selection.
    includedPackages: hasMany('package', {
      inverse: null,
      async: true
    }),
    sortedCategories: Ember.computed.sort('categories', 'sortByOrder'),
    sortByOrder: ['order:asc'],
    displayName: Ember.computed.alias('name'),
    duration: Ember.computed('childCategories.length', function () {
      return this.get('childCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('duration'));
      }, 0);
    }),
    filesize: Ember.computed('childCategories.length', function () {
      return this.get('childCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('counts.filesize'));
      }, 0);
    }),
    items: Ember.computed('childCategories.length', function () {
      var items = this.get('childCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('counts.movies'));
      }, 0);
      return items === 1 ? "1 movie" : "".concat(items, " movies");
    }),
    inheritedDuration: Ember.computed('inheritedChildCategories.length', function () {
      return this.get('inheritedChildCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('duration'));
      }, 0);
    }),
    inheritedFilesize: Ember.computed('inheritedChildCategories.length', function () {
      return this.get('inheritedChildCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('counts.filesize'));
      }, 0);
    }),
    inheritedItems: Ember.computed('inheritedChildCategories.length', function () {
      var items = this.get('inheritedChildCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('counts.movies'));
      }, 0);
      return items === 1 ? "1 movie" : "".concat(items, " movies");
    }),
    isOpen: true,
    cssStyle: Ember.computed('isOpen', function () {
      if (this.get('isOpen')) {
        return null;
      }

      return Ember.String.htmlSafe('display:none;');
    }),
    hasInheritedCategories: Ember.computed.gt('inheritedChildCategories.length', 0),
    childCategories: Ember.computed.filterBy('categories', 'hasParent', false),
    inheritedChildCategories: Ember.computed.filterBy('inheritedCategories', 'hasParent', false),
    allChildCategories: Ember.computed('childCategories', 'inheritedChildCategories', function () {
      return (0, _packageOverview.default)(this, {
        children: this.get('childCategories'),
        inherited: this.get('inheritedChildCategories')
      });
    }),
    sortedChildCategories: Ember.computed.sort('allChildCategories', 'sortByOrder'),
    deleteRecursive: function deleteRecursive() {
      var _this = this;

      var adapter = this.get('store').adapterFor('package');
      return adapter.deleteRecursive(this.get('id')).then(function () {
        return _this.deleteRecord();
      });
    },
    // ----------------------------- VALIDATIONS ---------------------------------
    validators: {
      standardPrice: (0, _emberCpValidations.validator)('presence', true),
      revenueShare: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});