define("backend/utils/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidParam = isValidParam;

  function isValidParam(value) {
    return value && value !== 'null';
  }
});