define("backend/pods/dashboard/route", ["exports", "backend/config/environment", "backend/utils/sales/jour-fixe"], function (_exports, _environment, _jourFixe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CURRENT_ROUTE_URL = '/dashboard';

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        tasks: this.get('store').query('hm-task', {
          due: '7_days'
        }),
        activeLeads: this.loadLeads()
      });
    },
    loadLeads: function loadLeads() {
      var _this = this;

      var distributor = this.get('session.data.authenticated.data.distributor');

      if (!distributor) {
        return Ember.RSVP.resolve(null);
      }

      var store = this.get('store');
      var adapter = store.adapterFor('statistic');
      var devices = store.query('device', {
        page: {
          number: 1,
          size: 10000
        }
      });
      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/sales/jour_fixe?distributor=".concat(distributor);

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (data) {
            return (0, _jourFixe.default)(adapter, data);
          }).then(function (model) {
            model.devices = devices;
            resolve(model);
          });
        });
      });
    },
    actions: {
      reloadTasks: function reloadTasks() {
        var _this2 = this;

        this.controller.set('isRefreshing', true);
        return this.refresh().finally(function () {
          return _this2.controller.set('isRefreshing', false);
        });
      },
      toggleTask: function toggleTask(task) {
        if (task.get('isSavingChecked')) {
          return false;
        }

        task.setProperties({
          isSavingChecked: true,
          isSavingError: false,
          isSavingSlow: false
        });
        window.setTimeout(function () {
          if (!task.isDestroyed && task.get('isSavingChecked')) {
            task.set('isSavingSlow', true);
          }
        }, 250);
        return task.toggleChecked().then(function () {
          return task.reload();
        }).catch(function (xhr) {
          return task.setProperties({
            errorMessage: xhr.errors[0].error,
            isSavingError: true
          });
        }).finally(function () {
          return task.set('isSavingChecked', false);
        });
      },
      createBilling: function createBilling(task) {
        var client = task.get('client.id');
        var info = task.get('info');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'billings'].join('/') + "?create=".concat(info);
        window.open(url, '_blank').focus();
      },
      viewContract: function viewContract(task) {
        var client = task.get('client.id');
        var contract = task.get('contract');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'contracts'].join('/') + "?open=".concat(contract);
        window.open(url, '_blank').focus();
      },
      viewMaterialOrder: function viewMaterialOrder(task) {
        var client = task.get('client.id');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'orders'].join('/');
        window.open(url, '_blank').focus();
      },
      createMaterialOrderBilling: function createMaterialOrderBilling(task) {
        var client = task.get('client.id');
        var info = task.get('info');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'billings'].join('/') + "?create=".concat(info);
        window.open(url, '_blank').focus();
      },
      viewRepairOrder: function viewRepairOrder(task) {
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'support-cases'].join('/');
        window.open(url, '_blank').focus();
      },
      createRepairOrderBilling: function createRepairOrderBilling(task) {
        var client = task.get('client.id');
        var info = task.get('info');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'billings'].join('/') + "?create=".concat(info);
        window.open(url, '_blank').focus();
      }
    }
  });

  _exports.default = _default;
});