define("backend/pods/devices/all/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      ownerType: {
        refreshModel: true
      },
      t: {
        refreshModel: true
      }
    },
    filter: ['device', ['ownerType', 't:type']],
    setQuery: function setQuery(query, key, value) {
      if (key === 'ownerType') {
        query[value] = 'all'; // Loading /:owner-types/all/devices
      } else {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});