define("backend/prototypes/tables/headers/attachments-all", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'uploadTimestamp',
    name: 'Upload Date',
    format: 'datetime'
  }, {
    attr: 'documentDate',
    name: 'Document Date',
    format: 'date'
  }, {
    attr: 'typeWithDetail',
    name: 'Type'
  }, {
    attr: 'file',
    component: 'secure-download'
  }, {
    modelName: 'owner',
    attr: 'displayName',
    name: 'Owner',
    component: 'lazy-load',
    display: false
  }, {
    modelName: 'owner',
    attr: 'typeName',
    name: 'Owner Type',
    component: 'lazy-load',
    display: false
  }, {
    modelName: 'contract',
    attr: 'displayName',
    name: 'Contract',
    component: 'lazy-load',
    display: false
  }]);

  _exports.default = _default;
});