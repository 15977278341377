define("backend/pods/tester/statistics/route", ["exports", "backend/pods/client/statistics/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ownerType: 'tester'
  });

  _exports.default = _default;
});