define("backend/pods/content/movie-expiration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sl9auUcE",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Movie Expiration\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[10,\"style\",\"padding-bottom: 0;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel\"],[10,\"style\",\"margin-bottom: 0;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[10,\"style\",\"margin: 20px; padding: 0;\"],[8],[0,\"\\n      \"],[1,[28,\"hm-chart/movie-expiration\",null,[[\"data\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/content/movie-expiration/template.hbs"
    }
  });

  _exports.default = _default;
});