define("backend/pods/studies-partners/all/controller", ["exports", "backend/prototypes/tables/headers/studies-partners"], function (_exports, _studiesPartners) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    clientSort: {
      sortBy: 'displayName',
      order: 'asc'
    },
    tableColumns: _studiesPartners.default
  });

  _exports.default = _default;
});