define("backend/adapters/package", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    deleteRecursive: function deleteRecursive(id) {
      var url = this.urlForDeleteRecord(id, 'package') + '/recursive';
      return this.ajax(url, 'DELETE');
    }
  });

  _exports.default = _default;
});