define("backend/prototypes/tables/headers/users", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'idAsNumber',
    name: 'Id'
  }, {
    attr: 'firstName',
    name: 'First Name'
  }, {
    attr: 'lastName',
    name: 'Last Name'
  }, {
    attr: 'email',
    name: 'E-Mail'
  }, {
    attr: 'role.displayName',
    name: 'Role'
  }, {
    attr: 'distributor.displayName',
    name: 'Distributor'
  }, {
    attr: 'picture',
    name: 'Picture'
  }, {
    attr: 'status.displayName',
    name: 'Status'
  }]);

  _exports.default = _default;
});