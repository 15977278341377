define("backend/pods/device/battery/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OptionWeek = Ember.Object.extend({
    days: null,
    title: null,
    xAxisDaysTicks: 1
  });

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    selected: Ember.computed.reads('model.owner'),
    batteryLevels: Ember.computed('selected.id', 'selectedWeek.days', function () {
      var device = this.get('selected.id');
      var days = this.get('selectedWeek.days') || 1;
      return this.get('store').queryBatterLevels({
        device: device,
        'page[number]': 1,
        'page[size]': days
      });
    }),
    optionsWeeks: [OptionWeek.create({
      days: 7,
      title: 'Last 7 Days'
    }), OptionWeek.create({
      days: 30,
      title: 'Last 30 Days',
      xAxisDaysTicks: 2
    })],
    selectedWeek: Ember.computed(function () {
      return this.get('optionsWeeks.firstObject');
    }),
    showWeekOptions: false,
    actions: {
      toggleShowWeekOptions: function toggleShowWeekOptions() {
        this.toggleProperty('showWeekOptions');
      },
      selectWeekOption: function selectWeekOption(selectedWeek) {
        this.setProperties({
          selectedWeek: selectedWeek,
          showWeekOptions: false
        });
      }
    }
  });

  _exports.default = _default;
});