define("backend/pods/sales-cockpit/jour-fixe-notes/controller", ["exports", "backend/prototypes/tables/headers/notes-any"], function (_exports, _notesAny) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: _notesAny.default,
    clientSort: {
      sortBy: 'ID',
      order: 'desc'
    }
  });

  _exports.default = _default;
});