define("backend/pods/components/hm-chart/devices-orders-chart/component", ["exports", "d3-time-format", "d3-collection", "d3-selection", "d3-array", "d3-scale", "d3-axis", "d3-time", "moment"], function (_exports, _d3TimeFormat, _d3Collection, _d3Selection, _d3Array, _d3Scale, _d3Axis, _d3Time, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CURRENT_ROUTE_URL = '/devices/chart';

  function openDevice(d) {
    if (!d.device_id) {
      return false;
    }

    var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'devices', d.device_id].join('/');
    window.open(url, '_blank').focus();
  }

  function openOwner(d) {
    if (!d.values[0].owner_id) {
      return false;
    }

    var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL))];

    if (d.values[0].is_distributor) {
      url.pushObjects(['distributors', d.values[0].owner_id]);
    } else {
      url.pushObjects([d.values[0].is_client ? 'clients' : 'leads', d.values[0].owner_id, 'essentials']);
    }

    window.open(url.join('/'), '_blank').focus();
  }

  var _default = Ember.Component.extend({
    height: 300,
    margin: {
      top: 30,
      right: 45,
      bottom: 55,
      left: 55
    },
    classNames: ['devices-offers-chart__wrapper'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.paint();
    },
    paint: function paint() {
      var wrapper = document.querySelector('.devices-offers-chart');
      var width = wrapper.clientWidth;
      var data = this.get('data');
      var minX = (0, _d3Array.min)(data.map(function (d) {
        return d.x1 && (0, _d3TimeFormat.isoParse)(d.x1);
      }));
      var maxX1 = (0, _d3Array.max)(data.map(function (d) {
        return d.x1 && (0, _d3TimeFormat.isoParse)(d.x1);
      }));
      var maxX2 = (0, _d3Array.max)(data.map(function (d) {
        return d.x2 && (0, _d3TimeFormat.isoParse)(d.x2);
      }));
      minX = (0, _moment.default)(minX).subtract(1, 'weeks').toDate();
      var maxX = (0, _moment.default)((0, _d3Array.max)([maxX1, maxX2])).add(1, 'weeks').toDate();
      var x = (0, _d3Scale.scaleTime)().domain([minX, maxX]).rangeRound([0, width]);
      var symbols = (0, _d3Collection.nest)().key(function (d) {
        return "".concat(d.id).concat(d.title).concat(d.idx).concat(d.device_id);
      }).entries(data);

      var spanX = function spanX(d) {
        return d.x1 ? x((0, _d3TimeFormat.isoParse)(d.x1)) : null;
      };

      var spanW = function spanW(d) {
        return d.x2 ? x((0, _d3TimeFormat.isoParse)(d.x2)) - x((0, _d3TimeFormat.isoParse)(d.x1)) : d.x1 ? x(maxX) : null;
      };

      var lastCategory,
          lastPackage = null;
      var grid = (0, _d3Selection.select)(wrapper).append('svg').attr('class', 'devices-offers-chart__grid-lines').attr('width', width).attr('height', wrapper.getBoundingClientRect().bottom);
      (0, _d3Selection.select)(wrapper).selectAll('svg.devices-offers-chart__svg').data(symbols).enter().append('svg').attr('height', 19).attr('class', 'devices-offers-chart__svg').each(function (symbol) {
        var svg = (0, _d3Selection.select)(this);
        var curCategory = symbol.values[0].category;
        var curPackage = symbol.values[0].package;
        var marginTop = 0;

        if (lastPackage !== curPackage) {
          marginTop += 10;
          svg.append('text').attr('x', 0).attr('y', marginTop + 5).attr('class', 'devices-offers-chart__package').text(symbol.values[0].package);
          svg.attr('height', 55);
        }

        if (lastCategory !== curCategory) {
          marginTop += 10;
          svg.append('text').attr('x', 25).attr('y', marginTop + 15).attr('class', 'devices-offers-chart__category').on('click', openOwner).text(symbol.values[0].category);

          if (lastPackage === curPackage) {
            svg.attr('height', 45);
          }
        }

        if (marginTop > 0) {
          svg.attr('style', "margin-top: ".concat(marginTop, "px"));
        }

        var group = svg.selectAll('g').data(symbol.values).enter().append('g');

        if (lastCategory !== curCategory && lastPackage !== curPackage) {
          group.attr('transform', "translate(0, 37)");
        } else if (lastCategory !== curCategory) {
          group.attr('transform', "translate(0, 27)");
        }

        if (symbol.values[0].type === 'offer') {
          group.append('rect').attr('x', function (d) {
            return x((0, _moment.default)(d.x1).subtract(d.days_delivery, 'days').toDate());
          }).attr('y', 5).attr('width', function (d) {
            return x((0, _d3TimeFormat.isoParse)(d.x1)) - x((0, _moment.default)(d.x1).subtract(d.days_delivery, 'days').toDate()) - 1;
          }).attr('height', 12).attr('fill', '#e0e0e0').attr('class', 'devices-offers-chart__rect').on('click', openDevice);
          group.append('text').attr('x', function (d) {
            return x((0, _moment.default)(d.x1).subtract(d.days_delivery, 'days').toDate()) + 10;
          }).attr('y', 15).attr('class', 'devices-offers-chart__rect-text').text(function (d) {
            return "".concat((0, _moment.default)(d.x1).subtract(d.days_delivery, 'days').format('DD.MM'));
          });
          group.append('rect').attr('x', function (d) {
            return spanX(d);
          }).attr('y', 5).attr('width', function (d) {
            return spanW(d);
          }).attr('height', 12).attr('fill', function (d) {
            return d.color;
          }).attr('class', 'devices-offers-chart__rect').on('click', openDevice);
          group.append('text').attr('x', function (d) {
            return spanX(d) + 10;
          }).attr('y', 15).attr('class', 'devices-offers-chart__rect-text').text(function (d) {
            return "".concat((0, _moment.default)(d.x1).format('DD.MM'));
          });
          group.append('text').attr('x', 50).attr('y', 15).attr('class', 'devices-offers-chart__device').attr('fill', function (d) {
            return d.title ? 'black' : '#ff5722';
          }).text(function (d) {
            return d.title ? d.title : 'No device assigned';
          }).on('click', openDevice);
        } else {
          group.append('rect').attr('x', function (d) {
            return spanX(d) - 4;
          }).attr('y', 5).attr('width', function (d) {
            return spanW(d);
          }).attr('height', 12).attr('fill', function (d) {
            return d.color;
          }).attr('class', 'devices-offers-chart__rect').on('click', openDevice);
          group.append('text').attr('x', 50).attr('y', 15).attr('class', 'devices-offers-chart__device').attr('fill', function (d) {
            return d.title ? 'black' : '#ff5722';
          }).text(function (d) {
            return d.title ? d.title : 'No device assigned';
          }).on('click', openDevice);
        }

        lastCategory = curCategory;
        lastPackage = curPackage;
      });
      var xAxis, format;

      if (x.ticks(_d3Time.timeMonday).length > 10) {
        format = _d3Time.timeMonth;
        xAxis = (0, _d3Axis.axisBottom)(x).ticks(_d3Time.timeMonth).tickFormat((0, _d3TimeFormat.timeFormat)("%d.%m.%y"));
      } else {
        format = _d3Time.timeMonday;
        xAxis = (0, _d3Axis.axisBottom)(x).ticks(_d3Time.timeMonday).tickFormat((0, _d3TimeFormat.timeFormat)("Week %V (%d.%m)"));
      }

      (0, _d3Selection.select)(wrapper).append('svg').attr('class', 'axis').call(xAxis);
      this.paintMonthlyLines(grid, xAxis, format);
      this.paintToday(grid, xAxis);
    },
    paintMonthlyLines: function paintMonthlyLines(group, xAxis, format) {
      group.selectAll('line').remove();
      var scale = xAxis.scale();
      scale.ticks(format).forEach(function (date) {
        group.append('line').attr('class', 'devices-offers-chart__hover__line').attr("x1", scale(date)).attr("x2", scale(date)).attr("y1", 50).attr("y2", 'calc(100% - 38px)');
      });
    },
    paintToday: function paintToday(group, xAxis) {
      var scale = xAxis.scale();
      var today = (0, _moment.default)().startOf('day').toDate();
      group.append('line').attr('class', 'devices-offers-chart__today-line').attr("x1", scale(today)).attr("x2", scale(today)).attr("y1", 50).attr("y2", 'calc(100% - 38px)');
      var g = group.append('g').attr('transform', "translate(".concat(scale(today), ", 30)"));
      g.append('text').attr('fill', '#000').attr('text-anchor', 'middle').attr('dy', '0.71em').text((0, _moment.default)().format('DD.MM.YYYY'));
    }
  });

  _exports.default = _default;
});