define("backend/pods/devices/filter-no-connection/controller", ["exports", "backend/prototypes/tables/headers/devices-low-battery"], function (_exports, _devicesLowBattery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size'],
    page: 1,
    size: 100,
    tableColumns: _devicesLowBattery.default,
    clientSort: {
      sortBy: 'serialNumber',
      order: 'asc'
    }
  });

  _exports.default = _default;
});