define("backend/pods/sales/device-order/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['contract', 'recipient', 'type']
  });

  _exports.default = _default;
});