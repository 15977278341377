define("backend/pods/sales/material-order/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        leads: store.query('lead', {
          filter: 'active',
          exclude_clients: true,
          page: {
            number: 1,
            size: 10000
          }
        }),
        clients: store.query('client', {
          filter: 'available',
          page: {
            number: 1,
            size: 10000
          }
        }),
        distributors: store.query('distributor', {
          filter: 'active',
          page: {
            number: 1,
            size: 10000
          }
        })
      }).then(function (_ref) {
        var leads = _ref.leads,
            clients = _ref.clients,
            distributors = _ref.distributors,
            packages = _ref.packages;
        return {
          recipients: [Ember.Object.create({
            groupName: 'Leads',
            options: leads.sortBy('displayName')
          }), Ember.Object.create({
            groupName: 'Clients',
            options: clients.sortBy('displayName')
          }), Ember.Object.create({
            groupName: 'Distributors',
            options: distributors.sortBy('displayName')
          })]
        };
      });
    }
  });

  _exports.default = _default;
});