define("backend/instance-initializers/support-case-status", ["exports", "backend/utils/static-data/support-case-status"], function (_exports, _supportCaseStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.lookup('service:store').pushPayload({
      supportCaseStatus: _supportCaseStatus.default
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});