define("backend/pods/kpi-dashboard/sales/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var SALES_DASHBOARD = [{
    label: 'Happy patiens total',
    suffix: 'happy patients',
    url: 'sessions/number/total'
  }, {
    label: 'Happy patients this month',
    url: 'sessions/number/month'
  }, {
    label: 'Watched movie hours total',
    suffix: 'hours',
    url: 'duration/number/total'
  }, {
    label: 'Watched movie hours this month',
    url: 'duration/number/month'
  }, {
    label: 'Watched movie minutes per patient total',
    suffix: 'minutes',
    url: 'session_duration/number/total'
  }, {
    label: 'Watched movie minutes per patient this month',
    url: 'session_duration/number/month'
  }, {
    label: 'Total devices',
    suffix: 'devices',
    url: 'devices/number/total'
  }];
  var LEADS_LEADERBOARD = 'leads/duration/ranking/month';
  var LEADS_MOVIE_DURATION = 'duration/ranking/month';
  var CLIENTS_LEADERBOARD = 'duration/ranking/total';
  var USAGE_DURATION = 'duration/progress/total';
  var USAGE_DURATION_MONTH = 'duration/progress/month';
  var DEVICE_PROGRESS_URL = 'devices/progress/total';
  var COLORS = ["#1e8cc8", "rgb(229, 200, 82)"];

  var _default = Ember.Route.extend({
    model: function model() {
      var adapter = this.get('store').adapterFor('dashboard');
      var tiles = SALES_DASHBOARD.map(function (_ref, idx) {
        var suffix = _ref.suffix,
            label = _ref.label,
            url = _ref.url;
        return {
          id: idx,
          label: label,
          suffix: suffix,
          query: adapter.ajax(adapter.buildUrl(url), 'GET')
        };
      });
      return {
        tiles: tiles,
        leadsLeaderboard: adapter.ajax(adapter.buildUrl(LEADS_LEADERBOARD), 'GET'),
        leadsMovieDuration: adapter.ajax(adapter.buildUrl(LEADS_MOVIE_DURATION), 'GET'),
        clientsLeaderboard: adapter.ajax(adapter.buildUrl(CLIENTS_LEADERBOARD), 'GET'),
        usageDuration: adapter.ajax(adapter.buildUrl(USAGE_DURATION), 'GET').then(function (response) {
          response.series = response.series.map(function (series, idx) {
            series.data = series.data.map(function (_ref2) {
              var _ref3 = _slicedToArray(_ref2, 2),
                  date = _ref3[0],
                  hours = _ref3[1];

              var dateLocal = new Date(date);
              var dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), dateLocal.getUTCHours(), dateLocal.getUTCMinutes(), dateLocal.getUTCSeconds());
              return [dateUTC, hours];
            });
            series.color = COLORS[idx];
            return series;
          });
          return response;
        }),
        usageDuration30Days: adapter.ajax(adapter.buildUrl(USAGE_DURATION_MONTH), 'GET').then(function (response) {
          response.series = response.series.map(function (series, idx) {
            series.data = series.data.map(function (_ref4) {
              var _ref5 = _slicedToArray(_ref4, 2),
                  date = _ref5[0],
                  hours = _ref5[1];

              var dateLocal = new Date(date);
              var dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), dateLocal.getUTCHours(), dateLocal.getUTCMinutes(), dateLocal.getUTCSeconds());
              return [dateUTC, hours];
            });
            series.color = COLORS[idx];
            return series;
          });
          return response;
        }),
        deviceProgress: adapter.ajax(adapter.buildUrl(DEVICE_PROGRESS_URL), 'GET').then(function (response) {
          response.series = response.series.map(function (series, idx) {
            series.data = series.data.map(function (_ref6) {
              var _ref7 = _slicedToArray(_ref6, 2),
                  date = _ref7[0],
                  hours = _ref7[1];

              var dateLocal = new Date(date);
              var dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), dateLocal.getUTCHours(), dateLocal.getUTCMinutes(), dateLocal.getUTCSeconds());
              return [dateUTC, hours];
            });
            series.color = COLORS[idx];
            return series;
          });
          return response;
        })
      };
    }
  });

  _exports.default = _default;
});