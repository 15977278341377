define("backend/pods/sales-cockpit/activities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rWqf7RoI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Activities\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[1,[28,\"hm-models/list/filter-activities\",null,[[\"model\"],[[23,0,[]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"manage-owner-events\",null,[[\"data\",\"canEdit\",\"page\",\"size\",\"tableColumns\",\"clientSort\"],[[28,\"hash\",null,[[\"events\"],[[24,[\"filteredEvents\"]]]]],false,[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/sales-cockpit/activities/template.hbs"
    }
  });

  _exports.default = _default;
});