define("backend/templates/partials/-list-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9l90297u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showContainer\"]]],null,{\"statements\":[[4,\"panel-container\",null,null,{\"statements\":[[4,\"if\",[[24,[\"showCreate\"]]],null,{\"statements\":[[0,\"      \"],[4,\"panel-header\",null,[[\"createAction\"],[\"createEntry\"]],{\"statements\":[[1,[22,\"labelCreate\"],false]],\"parameters\":[]},null],[0,\"\\n    \"]],\"parameters\":[]},null],[1,[22,\"panel-body\"],false],[1,[28,\"data-table\",null,[[\"data\",\"isLoading\",\"page\",\"size\",\"template\",\"tableColumns\",\"defaultClientSort\",\"filterByField\",\"filterModel\",\"createAction\",\"editPerColumn\",\"clickRoute\"],[[24,[\"table\"]],[24,[\"data\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"templateList\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],[24,[\"filterByField\"]],[24,[\"filterModel\"]],\"createEntry\",[24,[\"editPerColumn\"]],[24,[\"clickRoute\"]]]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showCreate\"]]],null,{\"statements\":[[0,\"    \"],[4,\"panel-header\",null,[[\"createAction\"],[\"createEntry\"]],{\"statements\":[[1,[22,\"labelCreate\"],false]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[1,[22,\"panel-body\"],false],[1,[28,\"data-table\",null,[[\"data\",\"isLoading\",\"page\",\"size\",\"template\",\"tableColumns\",\"defaultClientSort\",\"filterByField\",\"filterModel\",\"createAction\",\"editPerColumn\",\"clickRoute\"],[[24,[\"table\"]],[24,[\"data\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"templateList\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],[24,[\"filterByField\"]],[24,[\"filterModel\"]],\"createEntry\",[24,[\"editPerColumn\"]],[24,[\"clickRoute\"]]]]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/partials/-list-entries.hbs"
    }
  });

  _exports.default = _default;
});