define("backend/templates/components/manage-owner-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZrVg5Amr",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,1,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,1,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"],[15,\"partials/show-error\",[]],[0,\"\\n\\n\"],[1,[28,\"entry-form\",null,[[\"selected\",\"errors\",\"data\",\"template\",\"contactTypes\",\"canDelete\",\"showType\"],[[24,[\"selected\"]],[24,[\"validationErrors\"]],[24,[\"data\"]],\"components/forms/owner-contacts\",[24,[\"sortedContactTypes\"]],false,[24,[\"showType\"]]]]],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/manage-owner-contacts.hbs"
    }
  });

  _exports.default = _default;
});