define("backend/pods/components/hm-uploader/resume/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploader: Ember.inject.service(),
    tagName: 'form',
    file: null,
    filename: Ember.computed('file', function () {
      var file = this.get('file');

      if (!file) {
        return null;
      }

      return "".concat(file.name, " (").concat(file.size, ")");
    }),
    actions: {
      selectFile: function selectFile(evt) {
        this.set('file', evt.target.files[0]);
      },
      upload: function upload() {
        if (this.get('resumeAction')) {
          this.get('resumeAction')(this.get('file'));
        } else {
          this.get('uploader').resume(this.get('file'));
        }
      }
    }
  });

  _exports.default = _default;
});