define("backend/pods/movies/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    queryParams: {
      template: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var template = _ref.template,
          _ref$modelName = _ref.modelName,
          modelName = _ref$modelName === void 0 ? 'movie' : _ref$modelName;
      var store = this.get('store');
      var model = {
        packages: store.findAll('package'),
        categories: store.findAll('category'),
        licensors: store.query('licensor', {
          filter: 'active'
        }),
        statusList: store.peekAll('movieStatus'),
        targetGroups: store.peekAll('targetGroup'),
        files: [],
        thumbnails: []
      };
      model.owner = this.modelFor('movie');
      model.languages = store.findAll('language');
      model.countries = store.findAll('country');
      model.tags = store.query('tag', {
        type: 'movie'
      });

      if (template) {
        model.template = store.find(modelName, template);
      }

      return Ember.RSVP.hash(model);
    }
  });

  _exports.default = _default;
});