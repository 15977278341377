define("backend/adapters/validation", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    validate: function validate(type, value) {
      var url = [this.urlPrefix(), 'validation', type].join('/');
      var data = {};
      data[type] = value;
      return this.ajax(url, 'GET', {
        data: data
      });
    }
  });

  _exports.default = _default;
});