define("backend/components/user-role-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMPTY_ROLE = {
    'client': null,
    'distributor': null,
    'licensor': null,
    'tester': null
  };

  var _default = Ember.Component.extend({
    isClient: Ember.computed('user.role', function () {
      return this.get('user.role.name') === 'Client';
    }),
    isLicensor: Ember.computed('user.role', function () {
      return this.get('user.role.name') === 'Licensor';
    }),
    isDistributor: Ember.computed('user.role', function () {
      return this.get('user.role.name') === 'Distributor';
    }),
    isTester: Ember.computed('user.role', function () {
      return this.get('user.role.name') === 'Tester';
    }),
    isAdmin: Ember.computed('user.role', function () {
      return this.get('user.role.name') === 'Admin';
    }),
    allOwners: Ember.computed('clients.length', 'licensors.length', 'distributors.length', 'testers.length', function () {
      var owners = this.getProperties('distributors', 'clients', 'licensors', 'testers');
      return Object.keys(owners).reduce(function (all, key) {
        all.pushObject(Ember.Object.create({
          label: Ember.String.capitalize(key),
          content: owners[key].sortBy('displayName')
        }));
        return all;
      }, []);
    }),
    hasLoadedUserOwner: Ember.computed.reads('user.distributor.isFulfilled'),
    adminOwner: Ember.computed('isAdmin', 'hasLoadedUserOwner', {
      get: function get() {
        if (!this.get('isAdmin')) {
          return null;
        }

        if (this.get('user.distributor.id')) {
          return this.get('user.distributor.content');
        }

        return null;
      },
      set: function set(key, selected) {
        if (!this.get('isAdmin') || !selected) {
          return null;
        }

        if (this.get('adminOwner') === selected) {
          return null;
        }

        var ownerType = selected.constructor.modelName;
        this.set("user.".concat(ownerType), selected);
      }
    }),
    actions: {
      clearOwners: function clearOwners() {
        this.get('user').setProperties(EMPTY_ROLE);
      }
    }
  });

  _exports.default = _default;
});