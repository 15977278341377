define("backend/authorizers/hm-token", ["exports", "ember-simple-auth/authorizers/base", "moment", "backend/config/environment"], function (_exports, _base, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // Public: Adds authorization header.
    authorize: function authorize(sessionData, block) {
      var token;

      if (_environment.default.environment === 'test') {
        token = sessionData.authenticated.data.token;
      } else {
        token = sessionData.data.token;
      }

      if (!Ember.isEmpty(token)) {
        block('Authorization', token); // this.updateLastRequest();
      }
    },
    updateLastRequest: function updateLastRequest() {
      window.localStorage.setItem('last_request', (0, _moment.default)().toJSON());
    }
  });

  _exports.default = _default;
});