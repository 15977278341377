define("backend/serializers/contract", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      contact: {
        serialize: 'ids',
        deserialize: 'records'
      },
      package: {
        serialize: 'ids',
        deserialize: 'records'
      }
    },
    serialize: function serialize() {
      var _json$package;

      var json = this._super.apply(this, arguments);

      if ((_json$package = json.package) === null || _json$package === void 0 ? void 0 : _json$package.startsWith('deleted-ember')) {
        json.package = null;
      }

      return json;
    }
  });

  _exports.default = _default;
});