define("backend/access-control-list/overview-route", ["exports", "backend/access-control-list/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    canFilter: (0, _util.isCurrentUserRole)('isDistributor'),
    canFilterDistributors: Ember.computed.reads('currentUser.isAdmin'),
    canFilterUPSCustomerCenter: Ember.computed.reads('currentUser.isAdmin')
  });

  _exports.default = _default;
});