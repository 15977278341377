define("backend/utils/dropdown-options/sales-closing-months", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createClosingMonthOptionsForNewOffers = createClosingMonthOptionsForNewOffers;
  _exports.createClosingMonthOptionsToSupportExisting = createClosingMonthOptionsToSupportExisting;
  var END_DATE = '2026-12-01';

  function createOptions() {
    var from = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '2018-07-01';
    var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'MMMM \'YY';
    var today = moment(from).startOf('month');
    var monthCount = moment(END_DATE).startOf('month').diff(today, 'month') + 1;
    var months = [];

    for (var idx = 0; idx < monthCount; idx++) {
      months.push(idx);
    }

    return months.map(function (m) {
      if (m > 0) {
        today.add(1, 'months');
      }

      return Ember.Object.create({
        id: today.format('YYYY-MM-DD'),
        displayName: today.format(dateFormat)
      });
    });
  }

  function createClosingMonthOptionsForNewOffers() {
    return createOptions('2024-10-01', 'MMMM \'YY');
  }

  function createClosingMonthOptionsToSupportExisting() {
    return createOptions('2024-10-01', 'MMM \'YY');
  }
});