define("backend/pods/backoffice/contracts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HIJ4tIg",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Contracts\"]]],false],[0,\"\\n\"],[7,\"a\",true],[10,\"id\",\"csv-export-link\"],[11,\"href\",[29,[[22,\"href\"]]]],[11,\"download\",[29,[[22,\"filename\"]]]],[10,\"style\",\"opacity: 0;\"],[8],[9],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[4,\"if\",[[24,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"headerComponent\",\"acts\",\"title\",\"data\",\"tableColumns\",\"defaultClientSort\",\"showPagination\",\"showFooter\"],[[28,\"component\",[\"hm-models/list/header-contracts\"],null],[28,\"hash\",null,[[\"exportTable\"],[[28,\"route-action\",[\"exportCSV\",[24,[\"status\"]]],null]]]],[24,[\"tableTitle\"]],[24,[\"model\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],false,false]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"     \"],[7,\"div\",true],[10,\"style\",\"margin: 0 40px 30px 40px;\"],[8],[0,\"\\n       \"],[7,\"h4\",true],[8],[7,\"b\",true],[8],[1,[22,\"tableTitle\"],false],[9],[9],[0,\"\\n       Nothing here\\n     \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/backoffice/contracts/template.hbs"
    }
  });

  _exports.default = _default;
});