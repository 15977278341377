define("backend/pods/components/hm-models/list/export-csv/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AGYLZb13",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"secure-download\",null,[[\"url\",\"downloadUrl\",\"fileName\",\"name\",\"icon\",\"mimeType\"],[[24,[\"model\",\"export\"]],[24,[\"model\",\"export\"]],[24,[\"filename\"]],\"Download\",\"file-excel\",\"text/csv\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/export-csv/template.hbs"
    }
  });

  _exports.default = _default;
});