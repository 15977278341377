define("backend/pods/components/hm-form/select-multiple-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rR2xZHkQ",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select-multiple-with-create\",null,[[\"options\",\"selected\",\"searchField\",\"allowClear\",\"searchEnabled\",\"disabled\",\"placeholder\",\"onOpen\",\"onChange\",\"onCreate\"],[[24,[\"data\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null],\"displayName\",true,true,[24,[\"disabled\"]],[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"onOpen\"],null],[28,\"action\",[[23,0,[]],\"setOption\"],null],[28,\"action\",[[23,0,[]],[24,[\"acts\",\"createAction\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/select-multiple-create/template.hbs"
    }
  });

  _exports.default = _default;
});