define("backend/utils/static-data/event-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Device Owners
  var ROLE_OWNER = {
    L: 'lead',
    C: 'client',
    D: 'distributor',
    T: 'tester',
    LI: 'licensor',
    ST: 'study'
  };
  var CONTACT_PERSONS = ['lead', 'client', 'sponsored-client', 'distributor', 'tester', 'licensor', 'investor'];
  var ALL_ROLES = ['movie', 'device', 'study'].concat(CONTACT_PERSONS);
  var _default = [{
    id: 1,
    name: 'Creation',
    can_edit: false,
    order: 1,
    roles: ALL_ROLES
  }, {
    id: 2,
    name: 'Status Change',
    can_edit: false,
    order: 2,
    roles: ALL_ROLES
  }, {
    id: 3,
    name: 'Deletion',
    can_edit: false,
    order: 3,
    roles: ALL_ROLES
  }, {
    id: 11,
    name: 'Call',
    suffix: 'with',
    order: 4,
    roles: CONTACT_PERSONS
  }, {
    id: 12,
    name: 'E-Mail',
    suffix: 'to',
    order: 5,
    roles: CONTACT_PERSONS
  }, {
    id: 13,
    name: 'Visit',
    suffix: 'with',
    order: 6,
    roles: CONTACT_PERSONS
  }, {
    id: 14,
    name: 'Contact Creation',
    can_edit: false,
    order: 7,
    roles: CONTACT_PERSONS
  }, {
    id: 15,
    name: 'Skype',
    suffix: 'with',
    order: 99,
    roles: CONTACT_PERSONS
  }, {
    id: 16,
    name: 'WhatsApp',
    suffix: 'with',
    order: 99,
    roles: CONTACT_PERSONS
  }, {
    id: 17,
    name: 'LinkedIn',
    suffix: 'with',
    order: 10,
    roles: CONTACT_PERSONS
  }, {
    id: 18,
    name: 'Other',
    suffix: null,
    order: 11,
    roles: CONTACT_PERSONS
  }, {
    id: 19,
    name: 'Video Call',
    suffix: 'with',
    order: 12,
    roles: CONTACT_PERSONS
  }, {
    id: 27,
    name: 'Innovation Report',
    suffix: null,
    order: 9,
    roles: CONTACT_PERSONS
  }, {
    id: 21,
    name: 'Offer requested',
    order: 13,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 22,
    name: 'Offer ready',
    order: 14,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 23,
    name: 'Offer sent',
    order: 10,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 24,
    name: 'Offer accepted',
    order: 16,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 25,
    name: 'Billing Sent',
    order: 17,
    can_edit: false,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 26,
    name: 'Billing Paid',
    order: 18,
    can_edit: false,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 31,
    name: 'Owner Change',
    order: 19,
    can_edit: false,
    roles: ['device']
  }, {
    id: 32,
    name: 'Setup',
    order: 20,
    roles: ['device']
  }, {
    id: 33,
    name: 'Acceptance Test',
    order: 21,
    roles: ['device']
  }, {
    id: 34,
    name: 'Service',
    order: 22,
    roles: ['device']
  }, {
    id: 35,
    name: 'Device Added',
    order: 23,
    can_edit: false,
    roles: ['device']
  }, {
    id: 36,
    name: 'Device Removed',
    order: 24,
    can_edit: false,
    roles: ['device']
  }, {
    id: 41,
    name: 'Expiration Change',
    order: 25,
    can_edit: false,
    roles: ['movie']
  }, {
    id: 42,
    name: 'Movie Change',
    order: 26,
    can_edit: false,
    roles: ['movie']
  }, {
    id: 43,
    name: 'Movie Category',
    order: 27,
    can_edit: false,
    roles: ['movie']
  }, // Departments
  {
    id: 51,
    name: 'New Department',
    order: 28,
    can_edit: false,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 52,
    name: 'Department Status',
    order: 29,
    can_edit: false,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 53,
    name: 'Department Deletion',
    order: 30,
    can_edit: false,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, // Contracts
  {
    id: 61,
    name: 'New Contract',
    order: 31,
    can_edit: false,
    roles: [ROLE_OWNER.C]
  }, {
    id: 62,
    name: 'Contract Activated',
    order: 32,
    can_edit: false,
    roles: [ROLE_OWNER.C]
  }, {
    id: 63,
    name: 'Contract Cancelled',
    order: 33,
    can_edit: false,
    roles: [ROLE_OWNER.C]
  }, {
    id: 64,
    name: 'Contract Auto Cancelled',
    order: 33,
    can_edit: false,
    roles: [ROLE_OWNER.C]
  }, // Custom
  {
    id: 71,
    name: 'Support Case',
    order: 34,
    can_edit: false,
    roles: CONTACT_PERSONS
  }, {
    id: 72,
    name: 'WiFi',
    order: 35,
    can_edit: true,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 73,
    name: 'PR',
    order: 36,
    can_edit: true,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 74,
    name: 'Support Case Status',
    order: 37,
    can_edit: false,
    roles: CONTACT_PERSONS
  }, // Studies
  {
    id: 81,
    name: 'New Study',
    order: 38,
    can_edit: false,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C]
  }, {
    id: 82,
    name: 'Study Event',
    order: 39,
    can_edit: true,
    roles: [ROLE_OWNER.ST]
  }, // Orders
  {
    id: 91,
    name: 'Order Created',
    order: 40,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L, ROLE_OWNER.D]
  }, {
    id: 92,
    name: 'Order Delivered',
    order: 41,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L, ROLE_OWNER.D]
  }, {
    id: 93,
    name: 'Order Status',
    order: 42,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L, ROLE_OWNER.D]
  }, {
    id: 94,
    name: 'Order Attachment',
    order: 43,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L]
  }, {
    id: 95,
    name: 'Material Order Created',
    order: 44,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L, ROLE_OWNER.D]
  }, // Billing Emails
  {
    id: 98,
    name: 'Email sent: Invoice',
    order: 50,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L]
  }, {
    id: 99,
    name: 'Email sent: Reminder 1',
    order: 51,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L]
  }, {
    id: 100,
    name: 'Email sent: Reminder 2',
    order: 52,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L]
  }, {
    id: 101,
    name: 'Email sent: Final Reminder',
    order: 53,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L]
  }, {
    id: 102,
    name: 'Email sent: Material Order Invoice',
    order: 54,
    can_edit: false,
    roles: [ROLE_OWNER.C, ROLE_OWNER.L]
  }];
  _exports.default = _default;
});