define("backend/pods/components/hm-form/select-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "No+DkduU",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-select input-select--2-lines\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-select--input\"],[8],[0,\"\\n    \"],[1,[28,\"textarea\",null,[[\"value\",\"class\",\"placeholder\"],[[24,[\"value\"]],\"form-control\",[24,[\"placeholder\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-select--select\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchField\",\"searchEnabled\",\"disabled\",\"onChange\"],[[24,[\"data\"]],[28,\"find-by\",[\"displayName\",[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null],[24,[\"data\"]]],null],\"displayName\",true,[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"setValue\"],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/select-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});