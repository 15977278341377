define("backend/pods/oauth/authenticate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iIWLprYR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"is-pending\",[[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"    Authorizing...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"model\",\"success\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[0,\"Logged in as \"],[1,[24,[\"currentUser\",\"fullName\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/oauth/authenticate/template.hbs"
    }
  });

  _exports.default = _default;
});