define("backend/components/d3-multi-bar-chart", ["exports", "moment", "d3-time-format", "d3-selection", "d3-collection", "d3-array", "d3-scale", "d3-time", "d3-axis"], function (_exports, _moment, _d3TimeFormat, _d3Selection, _d3Collection, _d3Array, _d3Scale, _d3Time, _d3Axis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var formatTime = (0, _d3TimeFormat.timeFormat)("%H:%M");
  var formatTime2 = (0, _d3TimeFormat.timeFormat)("%-H:%M");
  var formatTimeSec = (0, _d3TimeFormat.timeFormat)("%H:%M:%S");

  var formatMinutes = function formatMinutes(d) {
    return formatTime(new Date(2012, 0, 1, 0, d));
  };

  var formatMinutesNoLeadingZero = function formatMinutesNoLeadingZero(d) {
    return formatTime2(new Date(2012, 0, 1, 0, d));
  };

  var formatSeconds = function formatSeconds(d) {
    return formatTimeSec(new Date(2012, 0, 1, 0, d));
  };

  function paintToolTip(divTooltip, d) {
    var hoverElements = document.querySelectorAll(':hover');
    var lastElement = hoverElements[hoverElements.length - 1];
    var cur = (0, _d3Selection.select)(lastElement).datum();

    if (cur) {
      divTooltip.style("left", _d3Selection.event.pageX + 10 + "px");
      divTooltip.style("top", _d3Selection.event.pageY - 25 + "px");
      divTooltip.style("display", "inline-block");
      var date = (0, _d3TimeFormat.timeFormat)("%d.%m")(d.x);
      var id = cur.name;
      var devices = this.get('devices');
      divTooltip.html("<div>Duration: ".concat(formatSeconds(cur.value), "</div><div>Date: ").concat(date, "</div><div>Device: ").concat(deviceLabel(devices, id), "</div>"));
    }
  }

  function hideToolTip(divTooltip) {
    divTooltip.style("display", "none");
  }

  function deviceLabel(devices, deviceId, extendedVersion) {
    var label;
    var device = devices.findBy('id', "" + deviceId);

    if (device) {
      var _device$getProperties = device.getProperties('serialNumber', 'location'),
          serialNumber = _device$getProperties.serialNumber,
          location = _device$getProperties.location;

      var serialShort = serialNumber;

      if (serialShort && serialShort.indexOf('-') > -1) {
        serialShort = serialShort.slice(serialShort.lastIndexOf('-') + 1);
      }

      label = location ? "".concat(location, " (").concat(serialShort, ")") : serialShort;

      if (extendedVersion) {
        var _device$getProperties2 = device.getProperties('lastRequestDate', 'batteryInPercent'),
            lastRequestDate = _device$getProperties2.lastRequestDate,
            batteryInPercent = _device$getProperties2.batteryInPercent;

        if (batteryInPercent) {
          label += " - ".concat(batteryInPercent);
        }

        if (lastRequestDate) {
          var lastRequest = (0, _moment.default)(lastRequestDate);
          label += " - ".concat(lastRequest.format('D.M'));
        }
      }
    } else {
      label = deviceId;
    }

    return label;
  }

  function deviceTextColor(devices, deviceId) {
    var label;
    var device = devices.findBy('id', "" + deviceId);

    if (device) {
      var lastRequestDate = device.get('lastRequestDate');

      if (lastRequestDate) {
        var lastRequest = (0, _moment.default)(lastRequestDate);
        var now = (0, _moment.default)();

        if (_moment.default.duration(now.diff(lastRequest)).asDays() > 3) {
          return 'red';
        }
      }
    }

    return 'black';
  }

  var MARGIN_MOBILE = {
    top: 10,
    right: 5,
    bottom: 40,
    left: 35
  };
  var MARGIN_DESKTOP = {
    top: 10,
    right: 8,
    bottom: 40,
    left: 42
  };

  var _default = Ember.Component.extend({
    classNameBindings: ['hasMultipleMonths:overflow-x-auto'],
    height: 420,
    fixedBarWidth: null,
    seriesNames: Ember.computed('data', function () {
      return this.get('data').reduce(function (names, row) {
        return names.pushObjects((0, _d3Collection.keys)(row).filter(function (k) {
          return k !== 'x';
        }));
      }, []).uniq().toArray();
    }),
    chartData: Ember.computed('data', 'seriesNames', function () {
      var _this$getProperties = this.getProperties('data', 'seriesNames'),
          data = _this$getProperties.data,
          seriesNames = _this$getProperties.seriesNames;

      data.forEach(function (d) {
        var yValues = seriesNames.map(function (name) {
          return {
            name: name,
            value: +d[name] || 0
          };
        });
        yValues = yValues.filter(function (_ref) {
          var value = _ref.value;
          return value !== 0;
        });
        yValues = yValues.sortBy('name').map(function (yValue, idx) {
          yValue.idx = idx;
          return yValue;
        });
        d.yValues = yValues;
      });
      return data;
    }),
    x0DataRange: Ember.computed('chartData', 'hasMultipleMonths', function () {
      if (this.get('hasMultipleMonths')) {
        var _extent = (0, _d3Array.extent)(this.get("chartData").map(function (d) {
          return d.x;
        })),
            _extent2 = _slicedToArray(_extent, 2),
            min = _extent2[0],
            _max = _extent2[1];

        min = (0, _moment.default)(min).startOf('month').toDate();
        _max = (0, _moment.default)(_max).endOf('month').toDate();
        return [min, _max];
      }

      return (0, _d3Array.extent)(this.get('chartData').map(function (d) {
        return d.x;
      }));
    }),
    yDataRange: Ember.computed('chartData', function () {
      return [0, (0, _d3Array.max)(this.get('chartData'), function (d) {
        return (0, _d3Array.max)(d.yValues, function (d2) {
          return d2.value;
        });
      })];
    }),
    x0Scale: Ember.computed('svgWidth', 'x0DataRange', function () {
      var svgWidth = this.get('svgWidth');
      return (0, _d3Scale.scaleTime)().domain(this.get('x0DataRange')).nice(_d3Time.timeDay).range([0, svgWidth]);
    }),
    x0ScaleOrdinal: Ember.computed('svgWidth', 'x0DataRange', function () {
      var svgWidth = this.get('svgWidth');
      return (0, _d3Scale.scaleBand)().rangeRound([0, svgWidth]).padding(0.1).domain(this.get('x0DataRange'));
    }),
    x1Scale: Ember.computed('seriesNames', 'x0ScaleOrdinal', 'barWidth', function () {
      var seriesNames = this.get('seriesNames').sort().reverse();
      return (0, _d3Scale.scaleBand)().domain(seriesNames).rangeRound([0, seriesNames.length * this.get('barWidth')]); // this.get('x0ScaleOrdinal').rangeBand()
    }),
    yScale: Ember.computed('svgHeight', 'yDataRange', function () {
      var svgHeight = this.get('svgHeight');
      return (0, _d3Scale.scaleLinear)().domain(this.get('yDataRange')).range([svgHeight, 0]);
    }),
    xAxis: Ember.computed('x0Scale', 'svgHeight', function () {
      return (0, _d3Axis.axisBottom)().scale(this.get('x0Scale')).tickFormat((0, _d3TimeFormat.timeFormat)('%e')).ticks(_d3Time.timeDay, 1) // .orient('bottom')
      .tickSizeInner(-this.get('svgHeight')).tickSizeOuter(0);
    }),
    xAxisMonthly: Ember.computed('x0Scale', 'svgHeight', function () {
      return (0, _d3Axis.axisBottom)().scale(this.get('x0Scale')).tickFormat((0, _d3TimeFormat.timeFormat)('%B %Y')).ticks(_d3Time.timeMonth, 1) // .orient('bottom')
      .tickSizeInner(-this.get('svgHeight')).tickSizeOuter(0);
    }),
    yAxis: Ember.computed('yScale', function () {
      return (0, _d3Axis.axisLeft)().scale(this.get('yScale')).tickFormat(formatMinutes); // .orient('left');
    }),
    yAxisMonthly: Ember.computed('yScale', function () {
      return (0, _d3Axis.axisLeft)().scale(this.get('yScale')).ticks(4).tickFormat(formatMinutesNoLeadingZero); // .orient('left');
    }),
    color: Ember.computed(function () {
      return (0, _d3Scale.scaleOrdinal)().range(['#ff9900', '#eded1a', '#669ef2', '#34eb49', '#ff0000', '#f543ff', '#B27762', '#777777', '#FFEACA']);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initSvg();
      this.paint();
    },
    initSvg: function initSvg() {
      if (window.innerWidth < 768) {
        this.set('margin', MARGIN_MOBILE);
      } else {
        this.set('margin', MARGIN_DESKTOP);
      }

      var container = this.$();
      var margin = this.get('margin');
      var svgHeight = this.get('height') - margin.bottom - margin.top;
      var svgWidth = container.width();
      var adjustWidth = '100%';

      if (this.get('hasMultipleMonths')) {
        var minWidth = this.get('data.length') * this.get('seriesNames.length') * this.get('fixedBarWidth');

        if (minWidth > svgWidth) {
          svgWidth = minWidth;
          adjustWidth = svgWidth;
        }
      }

      this.setProperties({
        svgWidth: svgWidth - margin.left - margin.right,
        svgHeight: svgHeight,
        svg: (0, _d3Selection.select)(container[0]).append('svg').attr('width', adjustWidth).attr('height', svgHeight).attr('viewBox', "0 0 ".concat(svgWidth, " ").concat(this.get('height'))).attr('preserveAspectRatio', 'none').append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"))
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._installResizeListener();
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      this._uninstallResizeListener();
    },
    repaint: function repaint() {
      (0, _d3Selection.select)(this.element).select('svg').remove();
      this.initSvg();
      this.paint();
    },
    paint: function paint() {
      this.paintAxisX();

      if (this.get('hasMultipleMonths')) {
        this.paintAxisYSupport();
        this.paintAxisXSupport();
      } else {
        this.paintAxisY();
      }

      this.paintBars();
      this.paintLegend();
    },
    paintAxisX: function paintAxisX() {
      var _this$getProperties2 = this.getProperties('xAxis', 'svgHeight'),
          xAxis = _this$getProperties2.xAxis,
          svgHeight = _this$getProperties2.svgHeight;

      this.get('svg').append('g').attr('class', 'x axis').attr('transform', "translate(0, ".concat(svgHeight, ")")).call(xAxis);
    },
    paintAxisXSupport: function paintAxisXSupport() {
      var _this$getProperties3 = this.getProperties('xAxisMonthly', 'svgHeight'),
          xAxisMonthly = _this$getProperties3.xAxisMonthly,
          svgHeight = _this$getProperties3.svgHeight;

      this.get('svg').append('g').attr('class', 'x axis support-axis').attr('transform', "translate(0, ".concat(svgHeight + 15, ")")).call(xAxisMonthly);
    },
    paintAxisY: function paintAxisY() {
      var yAxis = this.get('yAxis');
      this.get('svg').append("g").attr("class", "y axis").attr('x', 20).call(yAxis);
    },
    paintAxisYSupport: function paintAxisYSupport() {
      var yAxis = this.get('yAxisMonthly');
      this.get('svg').append("g").attr("class", "y axis").attr('x', 20).call(yAxis);
    },
    paintBars: function paintBars() {
      var _this$getProperties4 = this.getProperties('x0Scale', 'x0ScaleOrdinal', 'x1Scale', 'yScale', 'color', 'svgHeight'),
          x0Scale = _this$getProperties4.x0Scale,
          yScale = _this$getProperties4.yScale,
          color = _this$getProperties4.color,
          svgHeight = _this$getProperties4.svgHeight;

      var bars = this.get('svg').selectAll('.barGroup').data(this.get('chartData')).enter().append('g').attr('class', 'barGroup').attr('transform', function (d) {
        return "translate(".concat(x0Scale(d.x), ", 0)");
      });
      var barWidth = this.get('barWidth');
      bars.selectAll('rect').data(function (d) {
        return d.yValues;
      }).enter().append('rect').attr('width', barWidth) // x0ScaleOrdinal.rangeBand()
      .attr('x', function (d) {
        return d.idx * barWidth;
      }).attr('y', function (d) {
        return yScale(d.value);
      }).attr('height', function (d) {
        return svgHeight - yScale(d.value);
      }).style('fill', function (d) {
        return color(d.name);
      });
      var divTooltip = (0, _d3Selection.select)("body").append("div").attr("class", "toolTip");
      bars.on("mousemove", paintToolTip.bind(this, divTooltip));
      bars.on("touchmove", paintToolTip.bind(this, divTooltip));
      bars.on("mouseout", hideToolTip.bind(this, divTooltip));
      bars.on("touchend", hideToolTip.bind(this, divTooltip));
      bars.on("touchleave", hideToolTip.bind(this, divTooltip));
    },
    barWidth: Ember.computed('x0DataRange', 'x0Scale', 'seriesNames', 'chartData', 'fixedBarWidth', function () {
      var _this$get = this.get('x0DataRange'),
          _this$get2 = _slicedToArray(_this$get, 2),
          minDate = _this$get2[0],
          maxDate = _this$get2[1];

      var x0Scale = this.get('x0Scale');
      var min = x0Scale(minDate);
      var max = x0Scale(maxDate);
      var numOfSeries = Math.max.apply(this, this.get('chartData').mapBy('yValues.length'));

      var daysDiff = _moment.default.duration((0, _moment.default)(maxDate).diff((0, _moment.default)(minDate))).asDays();

      var tickWidth = (max - min) / daysDiff;
      var barWidth = tickWidth / numOfSeries;
      barWidth = Math.min(barWidth, 24);
      barWidth = Math.max(barWidth, 5);
      return barWidth;
    }),
    additionalLegendDevices: Ember.computed(function () {
      return [];
    }),
    paintLegend: function paintLegend() {
      var _this$getProperties5 = this.getProperties('svg', 'svgWidth', 'seriesNames', 'color'),
          svg = _this$getProperties5.svg,
          svgWidth = _this$getProperties5.svgWidth,
          seriesNames = _this$getProperties5.seriesNames,
          color = _this$getProperties5.color;

      var additionalSeries = this.get('additionalLegendDevices');
      var legendNames = seriesNames.slice();
      legendNames.push.apply(legendNames, _toConsumableArray(additionalSeries));
      var legend = svg.selectAll(".legend").data(legendNames.uniq().reverse()).enter().append("g").attr("class", "legend").attr("transform", function (d, i) {
        return "translate(0," + i * 20 + ")";
      });
      var marginRect = svgWidth - 18;
      var marginText = marginRect - 6;
      legend.append("rect").attr("x", marginRect).attr("width", 18).attr("height", 18).style("fill", function (series) {
        if (seriesNames.includes(series)) {
          return color(series);
        }

        return '#333';
      });
      var devices = this.get('devices') || [];

      if (this.get('hasMultipleMonths')) {
        legend.append("text").attr("x", marginText).attr("y", 9).attr("dy", ".35em").style("text-anchor", "end").style('fill', function (deviceId) {
          return deviceTextColor(devices, deviceId);
        }).text(function (deviceId) {
          return deviceLabel(devices, deviceId, true);
        });
        legend.selectAll("text").append("title").text("Device - Battery - Last Request");
      } else {
        legend.append("text").attr("x", marginText).attr("y", 9).attr("dy", ".35em").style("text-anchor", "end").text(function (deviceId) {
          return deviceLabel(devices, deviceId);
        });
      }
    },
    createFocusElement: function createFocusElement() {
      var focus = this.get('svg').append("g").attr("class", "focus").style("display", "none");
      focus.append("circle").attr("r", 4.5);
      focus.append("text").attr("x", 9).attr("dy", ".35em");
      this.set('focus', focus);
      return focus;
    },
    _installResizeListener: function _installResizeListener() {
      var _this = this;

      this._onResizeHandler = function (evt) {
        Ember.run.debounce(_this, _this.repaint, evt, 100);
      };

      window.addEventListener('resize', this._onResizeHandler);
    },
    _uninstallResizeListener: function _uninstallResizeListener() {
      window.removeEventListener('resize', this._onResizeHandler);
    }
  });

  _exports.default = _default;
});