define("backend/pods/leads/filter-testing/route", ["exports", "backend/pods/mixins/route/paginate-and-filter", "moment"], function (_exports, _paginateAndFilter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      distributor: {
        refreshModel: true
      },
      t: {
        refreshModel: true
      }
    },
    filter: ['lead', 'testing', ['distributor', 't:type']],
    createQuery: function createQuery() {
      var query = this._super.apply(this, arguments);

      query.exclude_territories = true;
      return query;
    },
    setupController: function setupController(controller, model) {
      var store = this.get('store');
      var adapter = store.adapterFor('statistic');
      var today = (0, _moment.default)().format('YYYY-MM-DD');
      controller.setProperties({
        devices: store.query('device', {
          page: {
            number: 1,
            size: 10000
          }
        }),
        models: model.map(function (owner) {
          return {
            owner: owner,
            statistics: adapter.queryOverview({
              'date[start]': (0, _moment.default)(owner.get('meta.created')).format('YYYY-MM-DD'),
              'date[end]': today,
              ownerId: owner.get('id'),
              ownerType: 'lead'
            })
          };
        })
      });
    }
  });

  _exports.default = _default;
});