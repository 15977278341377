define("backend/models/contract", ["exports", "ember-data", "backend/models/buffered-model", "ember-cp-validations", "moment", "backend/utils/contracts"], function (_exports, _emberData, _bufferedModel, _emberCpValidations, _moment, _contracts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _bufferedModel.default.extend({
    // Required
    numDevices: attr('number'),
    duration: attr('number'),
    // Months
    noticePeriod: attr('number'),
    // Months
    billingInterval: attr('number'),
    // Months
    packagePrice: attr('number'),
    // Euro
    type: belongsTo('contract-type', {
      async: false
    }),
    cancelReason: belongsTo('contract-cancel-reason', {
      async: false
    }),
    // Optional
    pillows: attr('string'),
    headphoneCovers: attr('string'),
    file: attr('string'),
    // Attachment
    cancellationFile: attr('string'),
    // Attachment
    deliveryDate: attr('string'),
    cancellationDate: attr('string'),
    cancellationEndDate: attr('string'),
    signingDate: attr('string'),
    billingDiscount: attr('number'),
    // Percent
    otherDiscount: attr('string'),
    hardwareAcquisition: attr('number'),
    // Euro
    billingInformation: attr('string'),
    setupFee: attr('number'),
    // Euro
    insurance: attr('number'),
    // Euro
    notes: attr('string'),
    cancellationNotes: attr('string'),
    autoCancellation: attr('boolean-null'),
    irregularBillingInterval: attr('boolean-null'),
    // Relationships
    client: belongsTo('client', {
      async: true
    }),
    lead: belongsTo('lead', {
      async: true
    }),
    department: belongsTo('department', {
      async: true
    }),
    // Loaded via client
    contact: belongsTo('contact', {
      async: false
    }),
    // Include
    package: belongsTo('package', {
      async: false
    }),
    // Include
    // Readonly
    isSigned: attr('boolean'),
    isActive: attr('boolean'),
    isCancelled: attr('boolean'),
    orderStatus: belongsTo('order-status', {
      async: false
    }),
    displayName: Ember.computed('id', 'type', 'department.id', 'department.isLoading', 'department.displayName', 'isLoading', function () {
      if (this.isLoading || !this.belongsTo('department').value()) {
        return 'Loading...';
      }

      var deliveryDate = this.deliveryDate,
          numDevices = this.numDevices;
      var date = deliveryDate ? (0, _moment.default)(deliveryDate).format('MM/YYYY') : 'Not delivered';
      var type = this.type.displayName;
      return "".concat(this.get('department.displayName'), " (").concat(type, ", ").concat(date, ", ").concat(numDevices, " ").concat(numDevices === 1 ? 'device' : 'devices', ")");
    }),
    addedValue: Ember.computed('numDevices', 'packagePrice', 'duration', 'insurance', 'setupFee', 'billingDiscount', 'hardwareAcquisition', function () {
      var data = this.getProperties('numDevices', 'packagePrice', 'duration', 'insurance', 'setupFee', 'billingDiscount', 'hardwareAcquisition');
      var numDevices = data.numDevices,
          packagePrice = data.packagePrice,
          duration = data.duration,
          insurance = data.insurance,
          setupFee = data.setupFee,
          hardwareAcquisition = data.hardwareAcquisition,
          billingDiscount = data.billingDiscount;

      if (!insurance) {
        insurance = 0;
      }

      var packageSum = numDevices * packagePrice * duration;
      var insuranceSum = numDevices * insurance * duration;
      var hardwareAcquisitionSum = numDevices * hardwareAcquisition;
      var hardwareAcquisitionDiscount = hardwareAcquisitionSum * ((100 - billingDiscount) / 100);
      console.log("contract packageSum: " + packageSum);
      console.log("contract insuranceSum: " + insuranceSum);
      console.log("contract setupFee: " + setupFee);
      console.log("contract hardwareAcquisitionSum: " + hardwareAcquisitionSum);
      console.log("contract hardwareAcquisitionDiscount: " + hardwareAcquisitionDiscount);
      return packageSum + insuranceSum + setupFee + hardwareAcquisitionDiscount;
    }),
    packagePriceDiscounted: Ember.computed('packagePrice', 'billingDiscount', function () {
      var data = this.getProperties('packagePrice', 'billingDiscount');
      var packagePrice = data.packagePrice,
          billingDiscount = data.billingDiscount;

      if (!billingDiscount) {
        billingDiscount = 0;
      }

      return packagePrice * ((100 - billingDiscount) / 100);
    }),
    ordinaryContractEndDate: Ember.computed('deliveryDate', 'duration', function () {
      return (0, _contracts.calcOrdinaryEndDate)(this.get('deliveryDate'), this.get('duration'));
    }),
    lastCancelDate: Ember.computed('deliveryDate', 'duration', 'noticePeriod', function () {
      return (0, _contracts.calcLastCancelDate)(this.get('deliveryDate'), this.get('duration'), this.get('noticePeriod'));
    }),
    billingInformationLines: Ember.computed('billingInformation', function () {
      var _this$get;

      return ((_this$get = this.get('billingInformation')) !== null && _this$get !== void 0 ? _this$get : '').split('\n');
    }),
    daysTilLastCancelDate: Ember.computed('lastCancelDate', function () {
      var now = (0, _moment.default)();
      var lastCancelDate = (0, _moment.default)(this.lastCancelDate);

      var days = _moment.default.duration(now.diff(lastCancelDate)).asDays();

      if (days > 0) {
        return 0;
      }

      return Math.ceil(days * -1);
    }),
    saveDeliveryDate: function saveDeliveryDate(date) {
      var adapter = this.get('store').adapterFor('contract');
      return adapter.updateDeliveryDate(this.get('id'), date);
    },
    saveCancellation: function saveCancellation(date, endDate, file, notes, cancelReason) {
      var adapter = this.get('store').adapterFor('contract');
      return adapter.updateCancellation(this.get('id'), date, endDate, file, notes, cancelReason);
    },
    validators: {
      type: (0, _emberCpValidations.validator)('presence', true),
      department: (0, _emberCpValidations.validator)('presence', true),
      contact: (0, _emberCpValidations.validator)('presence', true),
      numDevices: (0, _emberCpValidations.validator)('presence', true),
      duration: (0, _emberCpValidations.validator)('presence', true),
      noticePeriod: (0, _emberCpValidations.validator)('presence', true),
      billingInterval: (0, _emberCpValidations.validator)('presence', true),
      packagePrice: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: -1,
        allowString: true
      })]
    }
  });

  _exports.default = _default;
});