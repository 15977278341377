define("backend/adapters/statistics-summary", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'statistics/summary';
    },
    findQueryUrl: function findQueryUrl(type, query) {
      var url = [];
      var prefix = this.urlPrefix();

      if (typeof query['deviceTypes'] !== 'undefined') {
        if (query.deviceTypes && !Ember.isEmpty(query.deviceTypes)) {
          query.device_types = query.deviceTypes.mapBy('id').join(',');
        }

        delete query.deviceTypes;
      }

      if (typeof query['clientTypes'] !== 'undefined') {
        if (query.clientTypes && !Ember.isEmpty(query.clientTypes)) {
          query.client_types = query.clientTypes.mapBy('id').join(',');
        }

        delete query.clientTypes;
      }

      if (type) {
        url.push(this.pathForType(type));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      return url.join('/');
    },
    query: function query(store, type, _query) {
      var findUrl = this.findQueryUrl(type.modelName, _query);
      _query = this.clearEmptyQueryParams(_query);

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      if (_query.start_date) {
        _query['date[start]'] = _query.start_date;
        delete _query.start_date;
      }

      if (_query.end_date) {
        _query['date[end]'] = _query.end_date;
        delete _query.end_date;
      }

      return this.ajax(findUrl, 'GET', {
        data: _query
      });
    },
    clearEmptyQueryParams: function clearEmptyQueryParams(params) {
      return Object.keys(params).reduce(function (acc, key) {
        if (!Ember.isBlank(params[key]) && params[key] !== 'null') {
          acc[Ember.String.underscore(key)] = params[key];
        }

        return acc;
      }, {});
    }
  });

  _exports.default = _default;
});