define("backend/pods/statistics/summary/table/controller", ["exports", "backend/helpers/calc-share", "backend/helpers/format-boolean", "backend/helpers/fix-decimal", "backend/utils/export-csv", "backend/utils/format-csv", "backend/prototypes/tables/headers/statistics-summary"], function (_exports, _calcShare, _formatBoolean, _fixDecimal, _exportCsv, _formatCsv, _statisticsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CSV_HEAD = ['Movie ID', 'Replays', 'Duration', 'Percentage', 'Movie Title', 'Licensor Name', 'Movie Duration', 'Revenue Share', '% of Revenue Share'].join(';');

  var _default = Ember.Controller.extend({
    tableAsCSV: Ember.computed('model.table', function () {
      var totalRevShare = this.get('model.query.totalRevenueShare');
      return this.get('model.table').map(function (sel) {
        var revShare = "";

        if (totalRevShare) {
          revShare = (0, _fixDecimal.fixDecimal)([(0, _calcShare.calcShare)([totalRevShare, sel.get('percentage')]), 2]);
        }

        return [sel.get('movie.id'), sel.get('replays'), sel.get('duration'), (0, _fixDecimal.fixDecimal)([sel.get('percentage'), 2]), sel.get('movie.title'), sel.get('movie.licensor.displayName'), sel.get('movie.formattedDurationLong'), (0, _formatBoolean.formatBoolean)([sel.get('movie.revenueShare')]), revShare].map(_formatCsv.default).join(';');
      });
    }),
    tableColumns: _statisticsSummary.default,
    clientSort: {
      sortBy: 'percentage',
      order: 'desc'
    },
    createCSV: function createCSV() {
      return [CSV_HEAD].concat(this.get('tableAsCSV')).join('\n');
    },
    actions: {
      exportAsCSV: function exportAsCSV() {
        (0, _exportCsv.default)(this.createCSV(), 'statistics-summary.csv');
      }
    }
  });

  _exports.default = _default;
});