define("backend/utils/array-buffer-to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(arrayBuffer) {
    var intArr = new Uint8Array(arrayBuffer);
    var chunk = 5000;
    var str = '';
    var subArray;

    for (var idx = 0, len = intArr.length; idx < len; idx += chunk) {
      subArray = intArr.subarray(idx, idx + chunk);
      str += String.fromCharCode.apply(null, subArray);
    }

    return str;
  }
});