define("backend/pods/components/hm-form/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2tedPCi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isUploading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[22,\"loading-indicator\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"uploader \",[28,\"if\",[[24,[\"filename\"]],\"has-filename\"],null],\" \",[22,\"uploaderClass\"]]]],[8],[0,\"\\n    \"],[1,[28,\"file-input\",null,[[\"fileChanged\",\"name\",\"size\",\"class\",\"accept\"],[\"fileChanged\",\"uploaded_files\",\"40\",\"file-styled\",[24,[\"accept\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"uiButtonOnly\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"filename\"],[10,\"style\",\"-webkit-user-select: none;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isError\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[10,\"class\",\"text-danger\"],[8],[0,\"Couldn't upload file\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"filename\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,\"filename\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[4,\"if\",[[24,[\"placeholder\"]]],null,{\"statements\":[[1,[22,\"placeholder\"],false]],\"parameters\":[]},{\"statements\":[[0,\"No file selected\"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\",true],[11,\"class\",[29,[\"action btn \",[22,\"btnClass\"]]]],[10,\"type\",\"submit\"],[8],[7,\"i\",true],[10,\"class\",\"icon-square-up\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});