define("backend/pods/statistics/summary/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    queryParams: [{
      year: {
        scope: 'controller'
      }
    }, {
      month: {
        scope: 'controller'
      }
    }, {
      movie: {
        scope: 'controller'
      }
    }, {
      licensor: {
        scope: 'controller'
      }
    }, {
      revensureShare: {
        scope: 'controller'
      }
    }, {
      device: {
        scope: 'controller'
      }
    }, {
      deviceTypes: {
        scope: 'controller'
      }
    }, {
      clientTypes: {
        scope: 'controller'
      }
    }, {
      ownerType: {
        scope: 'controller'
      }
    }, {
      ownerId: {
        scope: 'controller'
      }
    }, {
      startDate: {
        scope: 'controller'
      }
    }, {
      endDate: {
        scope: 'controller'
      }
    }, {
      totalRevenueShare: {
        scope: 'controller'
      }
    }],
    client: Ember.computed.alias('ownerId'),
    lead: Ember.computed.alias('ownerId'),
    distributor: Ember.computed.alias('ownerId'),
    // licensor: alias('ownerId'),
    tester: Ember.computed.alias('ownerId'),
    year: Ember.computed(function () {
      return "" + new Date().getFullYear();
    }),
    month: Ember.computed(function () {
      return ("0" + (new Date().getMonth() + 1)).slice(-2);
    })
  });

  _exports.default = _default;
});