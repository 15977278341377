define("backend/components/one-way-number-mask", ["exports", "ember-inputmask/components/one-way-number-mask"], function (_exports, _oneWayNumberMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _oneWayNumberMask.default;
    }
  });
});