define("backend/templates/partials/-nested-sorted-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MTmUF42O",
    "block": "{\"symbols\":[\"movie\",\"category\"],\"statements\":[[7,\"ol\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"category\",\"categories\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"fancytree-node fancytree-expanded fancytree-has-children fancytree-exp-e fancytree-ico-e\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon-price-tag3\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"fancytree-title\"],[8],[1,[23,2,[\"id\"]],false],[0,\" - \"],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[15,\"partials/nested-sorted-categories\",[2]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\\n\"],[7,\"ol\",true],[10,\"class\",\"sortable-item\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"category\",\"movies\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"fancytree-node fancytree-exp-nl fancytree-ico-c\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon-film\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"fancytree-title\"],[8],[0,\"\\n          \"],[1,[23,1,[\"id\"]],false],[0,\" - \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/partials/-nested-sorted-categories.hbs"
    }
  });

  _exports.default = _default;
});