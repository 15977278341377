define("backend/pods/components/hm-form/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cn4+4k7q",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select\",null,[[\"options\",\"selected\",\"searchField\",\"allowClear\",\"searchEnabled\",\"disabled\",\"groupComponent\",\"triggerClass\",\"placeholder\",\"onOpen\",\"onChange\"],[[24,[\"data\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null],[24,[\"attrLabel\"]],[24,[\"allowClear\"]],true,[24,[\"disabled\"]],[24,[\"groupComponent\"]],[28,\"unless\",[[28,\"eq\",[[24,[\"inputClass\"]],\"form-control\"],null],[24,[\"inputClass\"]]],null],[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"onOpen\"],null],[28,\"action\",[[23,0,[]],\"setOption\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"get\",[[23,1,[]],[24,[\"attrLabel\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/select/template.hbs"
    }
  });

  _exports.default = _default;
});