define("backend/components/movies-component", ["exports", "backend/components/route-component", "backend/models/language", "backend/models/category", "backend/models/licensor"], function (_exports, _routeComponent, _language, _category, _licensor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LEAVE_ON = ['delete', 'cancel', 'create'];

  var _default = _routeComponent.default.extend({
    // ------------------------------ HOOKS --------------------------------------
    init: function init() {
      this._super.apply(this, arguments);

      var movie = this.get('editMovie');

      if (!Ember.isEmpty(movie)) {
        this.edit(movie);
      }

      if (this.get('createMovie') && !this.get('createTemplate')) {
        this.create();
      }
    },
    listOwnersAction: 'listOwners',
    keepSelected: true,
    saveComplete: function saveComplete() {
      this.set('createTemplate', undefined);

      if (this.get('isNewMovie')) {
        this.cleanupUnsavedMovieTags();
        var controller = Ember.getOwner(this).lookup('controller:application');
        return controller.transitionToRoute('movie', this.get('selected'));
      }

      return this.listMovies('save');
    },
    deleteComplete: function deleteComplete() {
      this._super();

      return this.listMovies('delete');
    },
    listMovies: function listMovies(action) {
      if (LEAVE_ON.includes(action)) {
        this.cleanupUnsavedMovieTags();
        return this.sendAction('listOwnersAction');
      }
    },
    create: function create() {
      this.set('isNewMovie', true);
      return this._super.apply(this, arguments);
    },
    googleStorage: Ember.inject.service(),
    createModel: 'movie',
    categories: Ember.computed.alias('data.categories'),
    packages: Ember.computed.alias('data.packages'),

    /**
      Override validationKeys method to skip validation for relationships.
        @method validationKeys
     */
    validationKeys: function validationKeys() {
      return [];
    },
    reloadOptions: Ember.observer('selected.buffers.selected', function () {
      var buffer = this.get('selected.buffers.selected');

      if (buffer) {
        var licensor = buffer.get('licensor.id');
        this.updateFileOptions(licensor);
        this.updateThumbOptions(licensor);
      }
    }),
    updateFileOptions: function updateFileOptions(licensor) {
      var _this = this;

      if (licensor) {
        var promise = this.get('googleStorage').loadMovies(licensor);
        this.set('data.loadingFiles', promise);
        promise.then(function (_ref) {
          var data = _ref.data;

          _this.set('data.files', data.map(function (val) {
            return Ember.Object.create({
              displayName: val.slice(val.lastIndexOf('/') + 1),
              id: val
            });
          }));
        });
      } else {
        this.set('data.files', []);
      }
    },
    updateThumbOptions: function updateThumbOptions(licensor) {
      var _this2 = this;

      if (licensor) {
        var promise = this.get('googleStorage').loadThumbs(licensor);
        this.set('data.loadingThumbs', promise);
        return promise.then(function (_ref2) {
          var data = _ref2.data;
          var original = data.filter(function (f) {
            return f.indexOf('_540x540') === -1 && f.indexOf('_240x160') === -1;
          });

          _this2.set('data.thumbnails', original.map(function (val) {
            return Ember.Object.create({
              displayName: val.slice(val.lastIndexOf('/') + 1),
              id: val
            });
          }));
        });
      } else {
        this.set('data.thumbnails', []);
      }
    },

    /**
      Clear movie's category when the language is changed.
        @method clearMovieCategory
     */
    clearMovieCategory: function clearMovieCategory() {
      this.get('selected').set('buffers.selected.category', null);
    },
    clearOrder: function clearOrder() {
      this.get('selected').set('buffers.selected.order', null);
    },
    cleanupUnsavedMovieTags: function cleanupUnsavedMovieTags() {
      var selected = this.get('selected');

      if (!selected) {
        return false;
      }

      selected.get('tags').filterBy('isNew').forEach(function (t) {
        return t.rollbackAttributes();
      });
    },
    actions: {
      cancelEdit: function cancelEdit() {
        this._super();

        return this.listMovies('cancel');
      },
      selectItem: function selectItem(item) {
        if (item instanceof _language.default) {
          this.clearMovieCategory();
          this.clearOrder();
        } else if (item instanceof _category.default) {
          this.clearOrder();
        } else if (item instanceof _licensor.default) {
          var licensor = item.get('id');
          this.updateFileOptions(licensor);
          this.updateThumbOptions(licensor);
        }
      },
      createUploadUrl: function createUploadUrl() {
        var title = this.get('selected.buffers.selected.title');
        var licensor = this.get('selected.buffers.selected.licensor.id');

        if (title && licensor) {
          var params = "&owner_type=licensor&owner_id=".concat(licensor, "&title=").concat(title);
          return this.createUploadUrl('thumbnail', params);
        }

        alert('Title and licensor must be specified before uploading a thumbnail');
      },
      completeUpload: function completeUpload(file) {
        var _this3 = this;

        var licensor = this.get('selected.buffers.selected.licensor.id');
        var promise = this.updateThumbOptions(licensor);

        if (promise) {
          promise.then(function () {
            _this3.set('selected.buffers.selected.thumbnail', null);

            Ember.run.later(function () {
              return _this3.set('selected.buffers.selected.thumbnail', file);
            });
          });
        }
      },
      openThumbnail: function openThumbnail() {},
      createTag: function createTag(name) {
        var newTag = this.get('store').createRecord('tag', {
          name: name,
          type: 1
        });
        this.get('selected.buffers.selected.tags').pushObject(newTag);
      }
    }
  });

  _exports.default = _default;
});