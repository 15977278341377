define("backend/utils/static-data/tester-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Active'
  }, {
    id: 2,
    name: 'Inactive'
  }];
  _exports.default = _default;
});