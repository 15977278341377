define("backend/prototypes/tables/headers/logs-client", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'timestamp',
    name: 'Timestamp'
  }, // { attr: 'log.meta.url', name: 'URL' },
  // { attr: 'log.error.message', name: 'Error Message' },
  {
    attr: 'essentials',
    name: 'Essentials'
  }, {
    attr: 'uid',
    name: 'User',
    display: false
  }, {
    attr: 'log.meta.userAgent',
    name: 'UA',
    display: false
  }, {
    attr: 'log.error.name',
    name: 'Error Name',
    display: false
  }, {
    attr: 'log.error.stack',
    name: 'Error Stack',
    display: false,
    style: 'text-nowrap'
  }, {
    attr: 'log.error.summary',
    name: 'Error Summary',
    display: false
  }]);

  _exports.default = _default;
});