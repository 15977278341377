define("backend/helpers/calc-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcPercentage = calcPercentage;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function calcPercentage(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        durationInSec = _ref2[0],
        formattedDuration = _ref2[1];

    if (Ember.isEmpty(durationInSec) || Ember.isEmpty(formattedDuration)) {
      return '0%';
    }

    var _formattedDuration$sp = formattedDuration.split(':'),
        _formattedDuration$sp2 = _slicedToArray(_formattedDuration$sp, 3),
        hour = _formattedDuration$sp2[0],
        minute = _formattedDuration$sp2[1],
        sec = _formattedDuration$sp2[2];

    var cmpLeft = durationInSec;
    var cmpRight = Number(sec) + minute * 60 + hour * 60 * 60;
    return "".concat((cmpRight / cmpLeft * 100).toFixed(2), "%");
  }

  var _default = Ember.Helper.helper(calcPercentage);

  _exports.default = _default;
});