define("backend/components/category-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var filterByCategories = function filterByCategories(p) {
    return p.get('categories.length');
  };

  var filterBySubcategories = function filterBySubcategories(p) {
    return p.get('options.length');
  };

  var _default = Ember.Component.extend({
    disabled: Ember.computed.empty('language'),
    hasCategories: Ember.computed.notEmpty('availableCategories'),
    hasPackages: Ember.computed.notEmpty('availablePackages'),
    availableCategories: Ember.computed('language', function () {
      var available = this.get('categories');
      return available.filterBy('language', this.get('language'));
    }),
    availablePackages: Ember.computed('packages.length', function () {
      return this.get('packages').filter(filterByCategories);
    }),
    options: Ember.computed('hasCategories', 'hasPackages', function () {
      if (this.get('hasCategories') && this.get('hasPackages')) {
        var packages = this.get('availablePackages');
        var options = packages.map(this.buildNestedOptions.bind(this));
        return options.filter(filterBySubcategories);
      }

      return [];
    }),
    buildNestedOptions: function buildNestedOptions(currentPackage) {
      var topCategories = this.filterTopCategories(currentPackage.get('id'));
      var subCategories = this.buildSubCategories(topCategories, null);
      var rootOption = this.buildOption(currentPackage.get('displayName'), null, true, subCategories);
      rootOption.set('isRootOption', true);
      return rootOption;
    },
    buildSubCategories: function buildSubCategories(categories, parentId) {
      var _this = this;

      var parents = this.filterSubCategories(categories, parentId);
      return parents.reduce(function (options, category) {
        var subCategories = _this.buildSubCategories(categories, category.get('id'));

        var isGroup = !!subCategories.length;
        var label = category.get('displayName');

        if (isGroup) {
          // Add selectable fake category
          options.push(_this.buildOption(label, category, false, null));
        }

        options.push(_this.buildOption(label, category, isGroup, subCategories));
        return options;
      }, []);
    },
    buildOption: function buildOption(label, value, isGroup, subCategories) {
      if (isGroup) {
        return Ember.Object.create({
          groupName: label,
          options: subCategories
        });
      }

      return value;
    },
    filterTopCategories: function filterTopCategories(packageId) {
      return this.get('availableCategories').filterBy('package.id', packageId);
    },
    filterSubCategories: function filterSubCategories(categories, parentId) {
      if (parentId != null) {
        return categories.filterBy('parent.id', parentId);
      } else {
        return categories.filter(function (category) {
          return !category.get('parent.content');
        });
      }
    },
    actions: {
      selectItem: function selectItem(item, id) {
        this.sendAction('selectAction', item, id);
      }
    }
  });

  _exports.default = _default;
});