define("backend/pods/components/hm-uploader/table-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploader: Ember.inject.service(),
    model: null,
    actions: {
      resume: function resume() {
        this.get('uploader').resume(this.get('model'));
      },
      cancel: function cancel() {
        this.get('uploader').cancel(this.get('model'));
      }
    }
  });

  _exports.default = _default;
});