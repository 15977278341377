define("backend/components/billing-cancellation-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    refreshModelAction: 'refreshModelAction',
    submitClass: 'bg-slate-300 hover--bg-red',
    cursorClass: '',
    actions: {
      confirmCancellation: function confirmCancellation() {
        var _this = this;

        if (confirm("Do you really want to cancel the billing #".concat(this.get('model.number'), "?"))) {
          var billing = this.get('model');
          var promise = billing.saveCancellation();
          this.set('promise', ObjectPromiseProxy.create({
            promise: promise
          }));
          promise.then(function () {
            _this.sendAction('refreshModelAction');
          });
        }
      }
    }
  });

  _exports.default = _default;
});