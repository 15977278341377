define("backend/pods/leads/import/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadImport = Ember.Object.extend({
    leadType: null,
    source: null,
    file: null,
    save: function save(store) {
      return store.adapterFor('application').importLeads({
        leadType: this.get('leadType.id'),
        source: this.get('source'),
        file: this.get('file')
      });
    }
  });

  var _default = Ember.Route.extend({
    model: function model() {
      return LeadImport.create();
    },
    actions: {
      listLeads: function listLeads() {
        this.transitionTo('leads.filter-active', {
          queryParams: {}
        });
      }
    }
  });

  _exports.default = _default;
});