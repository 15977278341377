define("backend/pods/components/hm-model/form/contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AakIcbs2",
    "block": "{\"symbols\":[\"flash\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,1,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,1,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"serverError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[24,[\"serverError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"entry-form\",null,[[\"selected\",\"supportsVATValidation\",\"canDelete\"],[[24,[\"selected\"]],false,false]],{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/form/contacts/template.hbs"
    }
  });

  _exports.default = _default;
});