define("backend/prototypes/tables/headers/system-images", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'version',
    name: 'Version'
  }, {
    attr: 'deviceModel.displayName',
    name: 'Device Model',
    style: 'text-nowrap'
  }, {
    attr: 'file',
    noFormat: true
  }, {
    attr: 'debug',
    format: 'boolean'
  }, {
    attr: 'updatePercentage',
    name: 'Update Percentage',
    format: 'percent'
  }, {
    attr: 'changelog'
  }]);

  _exports.default = _default;
});