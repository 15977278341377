define("backend/pods/leads/filter-demo-done/controller", ["exports", "backend/prototypes/tables/headers/leads"], function (_exports, _leads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userSettings: Ember.inject.service(),
    queryParams: ['page', 'size', 'distributor', 'status', 'source', 't'],
    page: 1,
    size: 100,
    t: 'hospital',
    tName: Ember.computed('t', function () {
      return Ember.String.capitalize(this.get('t'));
    }),
    clientSort: {
      sortBy: 'displayName',
      order: 'asc'
    },
    tableColumns: _leads.default,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('userSettings').on('evtSalesType', this, function (t) {
        return _this.set('t', t);
      });
    }
  });

  _exports.default = _default;
});