define("backend/instance-initializers/billing-template-type", ["exports", "backend/utils/static-data/billing-template-type"], function (_exports, _billingTemplateType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.lookup('service:store').pushPayload({
      billingTemplateType: _billingTemplateType.default
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});