define("backend/pods/sales-cockpit/team/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      setFromDate: function setFromDate(from) {
        this.get('model.overview').filterByFrom((0, _moment.default)(from));
      },
      setToDate: function setToDate(to) {
        this.get('model.overview').filterByTo((0, _moment.default)(to));
      }
    }
  });

  _exports.default = _default;
});