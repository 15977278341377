define("backend/utils/static-data/attachment-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Setup Report'
  }, {
    id: 2,
    name: 'Service Report'
  }, {
    id: 3,
    name: 'Delivery Note',
    has_contract_field: true
  }, {
    id: 4,
    name: 'Acceptance Report'
  }, {
    id: 5,
    name: 'Lending Agreement'
  }, {
    id: 7,
    name: 'Non-Disclosure Agreement'
  }, {
    id: 8,
    name: 'Signed Contract',
    has_type_detail: true,
    has_contract_field: true
  }, {
    id: 9,
    name: 'Cancellation',
    has_contract_field: true
  }, {
    id: 10,
    name: 'Study Document',
    has_type_detail: true
  }, {
    id: 11,
    name: 'Unsigned Offer',
    has_contract_field: true
  }, {
    id: 12,
    name: 'Protocols'
  }, {
    id: 13,
    name: 'Other',
    has_contract_field: true,
    has_type_detail: true
  }, {
    id: 14,
    name: 'Device Client Certificate',
    has_document_date: false,
    accept: '*'
  }, {
    id: 15,
    name: 'Device CA Certificate',
    has_document_date: false,
    accept: '*'
  }, {
    id: 16,
    name: 'Intro Video',
    has_document_date: false,
    accept: '*'
  }, {
    id: 17,
    name: 'Return Label'
  }, {
    id: 18,
    name: 'Confirmed Order',
    has_contract_field: true
  }, {
    id: 19,
    name: 'Support Order',
    has_contract_field: true
  }, {
    id: 20,
    name: 'Repair Order',
    has_contract_field: true
  }, {
    id: 21,
    name: 'Repair Communication',
    has_contract_field: true
  }, {
    id: 22,
    name: 'Material Order',
    has_contract_field: true
  }, {
    id: 23,
    name: 'STK'
  }, {
    id: 24,
    name: 'VAT Check'
  }, {
    id: 25,
    name: 'Repair Report'
  }];
  _exports.default = _default;
});