define("backend/pods/logs/server/table/controller", ["exports", "backend/prototypes/tables/headers/logs-server"], function (_exports, _logsServer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    size: Ember.computed.reads('model.size'),
    page: Ember.computed.reads('model.page'),
    tableColumns: _logsServer.default,
    changedPageSize: Ember.observer('size', 'page', function () {
      this.transitionToRoute({
        queryParams: {
          size: this.get('size'),
          page: this.get('page')
        }
      });
    })
  });

  _exports.default = _default;
});