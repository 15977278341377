define("backend/pods/components/hm-models/list/row-w-departments/probability/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    status: Ember.computed('filterBy', function () {
      if (this.get('filterBy')) {
        return this.get('model').get('departments').filterBy(this.get('filterBy')).map(function (d) {
          return "".concat(d.get('probability') || '?', "%, ").concat(d.get('expectedDate') ? (0, _moment.default)(d.get('expectedDate')).format('D.M.YYYY') : '?', ", ").concat(d.get('expectedDevices') || '?', " device(s)");
        }).join(', ');
      }

      return this.get('model').get('departments').map(function (d) {
        return "".concat(d.get('probability') || '?', "%, ").concat(d.get('expectedDate') ? (0, _moment.default)(d.get('expectedDate')).format('D.M.YYYY') : '?', ", ").concat(d.get('expectedDevices') || '?', " device(s)");
      }).join(', ');
    })
  });

  _exports.default = _default;
});