define("backend/pods/components/hm-model/fieldset-create/-essentials-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    keywords: Ember.inject.service(),
    statusModel: null,
    statusList: Ember.computed('statusModel', function () {
      return this.get('store').peekAll(this.get('statusModel'));
    }),
    buffers: Ember.computed.alias('model.buffers'),
    firstContact: Ember.computed.alias('model.buffers.contacts.firstObject'),
    firstDepartment: Ember.computed.alias('model.buffers.departments.firstObject'),
    gender: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'M',
        displayName: 'Male'
      }), Ember.Object.create({
        id: 'F',
        displayName: 'Female'
      }), Ember.Object.create({
        id: 'D',
        displayName: 'Diverse'
      })];
    }),
    countries: Ember.computed(function () {
      return this.get('store').findAll('country');
    }),
    actions: {
      createContactType: function createContactType(name) {
        var _this = this;

        this.get('store').createRecord('contact-type', {
          name: name
        });
        Ember.run.schedule('afterRender', function () {
          return _this.set('firstContact.type', name);
        });
      },
      createContact: function createContact() {
        this.get('model').addContact();
      }
    }
  });

  _exports.default = _default;
});