define("backend/components/device-owner-select", ["exports", "ember-data", "backend/pods/mixins/model/owner-type-by-role"], function (_exports, _emberData, _ownerTypeByRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  var EMPTY_OWNER = {
    'client': null,
    'distributor': null,
    'licensor': null,
    'lead': null,
    'tester': null,
    'inStore': null,
    'sponsoredClient': null
  };

  function loadCollection(modelName, qp) {
    return Ember.computed(function () {
      var promise;

      if (qp) {
        promise = this.get('store').query(modelName, qp);
      } else {
        promise = this.get('store').findByCache(modelName);
      }

      return PromiseArray.create({
        promise: promise
      });
    });
  }

  var _default = Ember.Component.extend(_ownerTypeByRole.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    clients: loadCollection('client', {
      page: {
        number: 1,
        size: 10000
      },
      filter: 'available'
    }),
    distributors: Ember.computed('currentUser.isDistributor', function () {
      if (this.get('currentUser.isDistributor')) {
        return [this.get('currentUser.model')];
      }

      return PromiseArray.create({
        promise: this.get('store').query('distributor', {
          page: {
            number: 1,
            size: 10000
          },
          filter: 'active'
        })
      });
    }),
    licensors: loadCollection('licensor', {
      page: {
        number: 1,
        size: 10000
      },
      filter: 'active'
    }),
    leads: loadCollection('lead', {
      page: {
        number: 1,
        size: 10000
      },
      filter: 'active',
      exclude_clients: true
    }),
    testers: loadCollection('tester', {
      page: {
        number: 1,
        size: 10000
      },
      filter: 'active'
    }),
    sponsoredClients: loadCollection('sponsored-client', {
      page: {
        number: 1,
        size: 10000
      },
      filter: 'active'
    }),
    inStore: Ember.computed(function () {
      return this.get('store').peekAll('in-store').objectAt(0);
    }),
    opts: 'lg-3-9',
    selectId: false,
    actions: {
      clearOwners: function clearOwners(option) {
        var device = this.get('device');

        if (!device) {
          return false;
        }

        this.get('device').setProperties(EMPTY_OWNER);

        if (option && option.id === 'inStore') {
          this.get('device').set('inStore', this.get('inStore'));
        }
      }
    }
  });

  _exports.default = _default;
});