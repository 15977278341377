define("backend/pods/components/hm-models/list/orders/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['cell--editable'],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('orderStatuses', this.get('store').peekAll('order-status').filterBy('isSelectable'));
    },
    click: function click(evt) {
      this.edit(evt);
    },
    edit: function edit(evt) {
      evt.stopPropagation();

      if (!this.get('isEditing')) {
        var deliveryNote = this.get('model.deliveryNote');
        this.setProperties({
          isEditing: true,
          newStatus: this.get('model.status'),
          newTrackingNo: this.get('model.tracking'),
          returnTracking: this.get('model.returnTracking'),
          newFile: deliveryNote ? deliveryNote.replace('files/', '') : null
        });

        if (!deliveryNote) {
          this.setUploadUrl();
        }
      }
    },
    cancel: function cancel(evt) {
      if (evt) {
        evt.stopPropagation();
      }

      this.setProperties({
        isEditing: false,
        newStatus: null,
        newTrackingNo: null,
        returnTracking: null
      });
    },
    save: function save(evt) {
      var _this = this;

      if (evt) {
        evt.stopPropagation();
      }

      this.get('model').updateStatus(this.get('newStatus'), this.get('newFile'), this.get('newTrackingNo'), this.get('returnTracking')).then(function () {
        return _this.cancel();
      });
    },
    setUploadUrl: function setUploadUrl() {
      var _this2 = this;

      return this.get('store').adapterFor('attachment').generateUploadUrl('attachment').then(function (_ref) {
        var upload_url = _ref.data.upload_url;

        _this2.setProperties({
          newFile: null,
          uploadUrl: upload_url
        });
      });
    },
    actions: {
      save: function save(evt) {
        this.save(evt);
      },
      cancel: function cancel(evt) {
        this.cancel(evt);
      },
      deleteFile: function deleteFile() {
        this.setUploadUrl();
      }
    }
  });

  _exports.default = _default;
});