define("backend/pods/movie-upload/controller", ["exports", "backend/prototypes/tables/headers/uploader"], function (_exports, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    uploader: Ember.inject.service(),
    tableColumns: _uploader.default,
    clientSort: {
      sortBy: 'changed_at',
      order: 'desc'
    }
  });

  _exports.default = _default;
});