define("backend/pods/components/hm-models/list/filter-statistic-summary/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ALL_PAGES = {
    page: {
      number: 1,
      size: 10000
    }
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('model.device')) {
        this.send('loadDevices');
      }

      if (this.get('model.movie')) {
        this.send('loadMovies');
      }

      if (this.get('model.licensor')) {
        this.send('loadLicensors');
      }
    },
    deviceTypeList: Ember.computed(function () {
      return this.get('store').peekAll('device-type');
    }),
    last2Years: Ember.computed(function () {
      var year = (0, _moment.default)().year();
      return [Ember.Object.create({
        displayName: "" + year,
        id: "" + year
      }), Ember.Object.create({
        displayName: "" + (year - 1),
        id: "" + (year - 1)
      })];
    }),
    allMonths: Ember.computed(function () {
      return _moment.default.months().map(function (name, idx) {
        return Ember.Object.create({
          displayName: name,
          id: ("0" + (idx + 1)).slice(-2)
        });
      });
    }),
    actions: {
      loadDevices: function loadDevices() {
        if (!this.get('devices')) {
          this.set('devices', this.get('store').query('device', ALL_PAGES));
        }
      },
      loadMovies: function loadMovies() {
        var _this = this;

        if (!this.get('movies')) {
          Ember.RSVP.all([this.get('store').query('package', ALL_PAGES), this.get('store').query('category', ALL_PAGES)]).then(function () {
            _this.set('movies', _this.get('store').query('movie', {
              page: ALL_PAGES
            }));
          });
        }
      },
      loadLicensors: function loadLicensors() {
        if (!this.get('licensors')) {
          this.set('licensors', this.get('store').query('licensor', ALL_PAGES));
        }
      }
    }
  });

  _exports.default = _default;
});