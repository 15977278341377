define("backend/pods/movie/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var store = this.get('store');
      var model;

      if (this.get('currentUser.isClient')) {
        model = {
          statusList: store.peekAll('movieStatus'),
          targetGroups: store.peekAll('targetGroup'),
          files: [],
          thumbnails: []
        };
      } else {
        model = {
          packages: store.findAll('package'),
          categories: store.findAll('category'),
          licensors: store.findAll('licensor'),
          statusList: store.peekAll('movieStatus'),
          targetGroups: store.peekAll('targetGroup'),
          files: [],
          thumbnails: []
        };
      }

      model.owner = this.modelFor('movie');
      model.languages = store.findAll('language');
      model.countries = store.findAll('country');
      model.tags = store.query('tag', {
        type: 'movie'
      });
      return Ember.RSVP.hash(model);
    }
  });

  _exports.default = _default;
});