define("backend/pods/sales-cockpit/jour-fixe/route", ["exports", "backend/config/environment", "backend/utils/sales/jour-fixe"], function (_exports, _environment, _jourFixe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      distributor: {
        refreshModel: true
      },
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (!params.distributor) {
        return null;
      }

      var store = this.get('store');
      var adapter = store.adapterFor('statistic');
      var devices = store.query('device', {
        page: {
          number: 1,
          size: 10000
        }
      });
      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          Ember.RSVP.hash({
            jour_fixe: _this.queryJourFixe(params, adapter, headers),
            notes: _this.queryNotes(params, headers)
          }).then(function (_ref) {
            var jour_fixe = _ref.jour_fixe,
                notes = _ref.notes;
            jour_fixe.devices = devices;
            jour_fixe.notes = notes;
            resolve(jour_fixe);
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      if (!controller.get('distributors')) {
        controller.set('distributors', this.get('store').query('distributor', {
          filter: 'active'
        }));
      }
    },
    queryJourFixe: function queryJourFixe(_ref2, adapter, headers) {
      var distributor = _ref2.distributor,
          start = _ref2.start,
          end = _ref2.end;
      var url = _environment.default.host + "/sales/jour_fixe?distributor=".concat(distributor, "&start=").concat(start, "&end=").concat(end);
      return this.get('ajax').request(url, {
        headers: headers
      }).then(function (data) {
        return (0, _jourFixe.default)(adapter, data);
      });
    },
    queryNotes: function queryNotes(_ref3, headers) {
      var distributor = _ref3.distributor,
          start = _ref3.start,
          end = _ref3.end;
      var url = _environment.default.host + "/notes/jour_fixe/".concat(distributor, "/").concat(start, "/").concat(end);
      return this.get('ajax').request(url, {
        headers: headers,
        contentType: 'application/json; charset=utf-8'
      }).then(function (_ref4) {
        var data = _ref4.data;
        return data ? JSON.parse(data) : null;
      });
    },
    saveNotes: function saveNotes(_ref5) {
      var _this2 = this;

      var payload = _ref5.payload,
          _ref5$key = _ref5.key,
          distributor = _ref5$key.distributor,
          start = _ref5$key.start,
          end = _ref5$key.end;
      this.controller.set('isDebouncing', false);
      return this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;
        var url = _environment.default.host + "/notes/jour_fixe/".concat(distributor, "/").concat(start, "/").concat(end);

        var promise = _this2.get('ajax').request(url, {
          method: 'PUT',
          headers: headers,
          contentType: 'application/json; charset=utf-8',
          data: {
            data: payload
          }
        });

        promise.catch(function (error) {
          throw error;
        });

        _this2.controller.set('notesPromise', promise);
      });
    },
    actions: {
      saveNotes: function saveNotes(doc) {
        this.doc = doc;
        this.controller.set('isDebouncing', true);
        var params = this.controller.getProperties('distributor', 'start', 'end');
        this.set('debounceNotes', Ember.run.debounce(this, this.saveNotes, {
          payload: JSON.stringify(doc),
          key: params
        }, 3000));
      },
      saveNotesImmediately: function saveNotesImmediately() {
        var debounce = this.get('debounceNotes');

        if (debounce) {
          this.controller.set('isDebouncing', false);
          Ember.run.cancel(debounce);
        }

        var params = this.controller.getProperties('distributor', 'start', 'end');
        this.saveNotes({
          payload: JSON.stringify(this.doc),
          key: params
        });
      }
    }
  });

  _exports.default = _default;
});