define("backend/instance-initializers/device-status", ["exports", "backend/utils/static-data/device-status"], function (_exports, _deviceStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.lookup('service:store').pushPayload({
      deviceStatus: _deviceStatus.default
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});