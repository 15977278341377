define("backend/prototypes/tables/headers/logs", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: '',
    name: 'Delete',
    isDelete: true
  }, {
    attr: 'device.serialNumber',
    name: 'Device',
    style: 'text-nowrap'
  }, {
    attr: 'timestamp',
    name: 'Date',
    format: 'datetime',
    style: 'text-nowrap'
  }, {
    attr: 'log'
  }]);

  _exports.default = _default;
});