define("backend/pods/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['redirect'],
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    authenticator: 'authenticator:hm-token',
    showLoginError: function showLoginError(error) {
      return this.set('loginError', error);
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        this.setProperties({
          isLoading: true,
          loginError: null,
          password: null
        });
        this.get('session').authenticate(this.get('authenticator'), identification, password).then(function () {// success
        }, function (_ref) {
          var error = _ref.error;

          _this.showLoginError(error);

          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});