define("backend/services/keywords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LIST_OF_INACTIVE_REASONS = ['Contract duration', 'Budget constraint', 'Price too high', 'Payment terms unfavorable', 'Rental refusal', 'Competing solution', 'Product - technical problems', 'Product - patient refusal', 'Product - internal processes', 'Distribution within institution', 'No reaction', 'Other'];

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sources: [],
    tipsters: [],
    organizations: [],
    contactTypes: [],
    reasons: [],
    isLoadingSources: true,
    isLoadingTipsters: true,
    isLoadingOrganizations: true,
    isLoadingContactTypes: true,
    isLoadingReasons: true,
    init: function init() {
      this._super.apply(this, arguments);

      var store = this.get('store');
      this.setProperties({
        sources: store.peekAll('source'),
        tipsters: store.peekAll('tipster'),
        contactTypes: store.peekAll('contactType'),
        organizations: store.peekAll('organization'),
        reasons: store.peekAll('reason')
      });
      this.loadSources();
      this.loadTipsters();
      this.loadOrganizations();
      this.loadContactTypes();
      this.loadReasons();
    },
    loadSources: function loadSources() {
      var _this = this;

      var store = this.get('store');
      var adapter = store.adapterFor('lead');
      adapter.findAllSources().then(function (_ref) {
        var data = _ref.data;
        data.forEach(function (id) {
          if (id && !store.peekRecord('source', id)) {
            store.createRecord('source', {
              id: id,
              displayName: id
            });
          }
        });
        Ember.run.later(function () {
          return _this.set('isLoadingSources', false);
        }, 100);
      });
    },
    loadTipsters: function loadTipsters() {
      var _this2 = this;

      var store = this.get('store');
      var adapter = store.adapterFor('lead');
      var tipsterNames = [];
      adapter.findAllTipsters().then(function (_ref2) {
        var data = _ref2.data;
        data.forEach(function (name) {
          if (name && !tipsterNames.includes(name)) {
            tipsterNames.pushObject(name);
            store.createRecord('tipster', {
              name: name
            });
          }
        });
        Ember.run.later(function () {
          return _this2.set('isLoadingTipsters', false);
        }, 100);
      });
    },
    loadOrganizations: function loadOrganizations() {
      var _this3 = this;

      var store = this.get('store');
      var adapter = store.adapterFor('lead');
      var names = [];
      adapter.findAllOrganizations().then(function (_ref3) {
        var data = _ref3.data;
        data.forEach(function (name) {
          if (name && !names.includes(name)) {
            names.pushObject(name);
            store.createRecord('organization', {
              name: name
            });
          }
        });
        Ember.run.later(function () {
          return _this3.set('isLoadingOrganizations', false);
        }, 100);
      });
    },
    loadContactTypes: function loadContactTypes() {
      var _this4 = this;

      var store = this.get('store');
      var adapter = store.adapterFor('contact');
      var names = [];
      adapter.findAllTypes().then(function (_ref4) {
        var data = _ref4.data;
        data.forEach(function (name) {
          if (name && !names.includes(name)) {
            names.pushObject(name);
            store.createRecord('contact-type', {
              name: name
            });
          }
        });
        Ember.run.later(function () {
          return _this4.set('isLoadingContactTypes', false);
        }, 100);
      });
    },
    loadReasons: function loadReasons() {
      var _this5 = this;

      var store = this.get('store');
      var names = [];
      LIST_OF_INACTIVE_REASONS.forEach(function (name) {
        if (name && !names.includes(name)) {
          names.pushObject(name);
          store.createRecord('reason', {
            id: name,
            name: name
          });
        }
      });
      Ember.run.later(function () {
        return _this5.set('isLoadingReasons', false);
      }, 100);
    },
    pushReason: function pushReason(name) {
      if (name && !this.get('reasons').includes(name)) {
        this.get('store').createRecord('reason', {
          id: name,
          name: name
        });
      }
    }
  });

  _exports.default = _default;
});