define("backend/models/investor", ["exports", "ember-cp-validations", "backend/models/buffered-model", "backend/utils/cp/buffer-changes", "ember-data"], function (_exports, _emberCpValidations, _bufferedModel, _bufferChanges, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Buffer
  var BUFFER_BELONGS_TO = ['address', 'bank'];
  var BUFFER_HAS_MANY = ['contacts']; // Ember-Data

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _bufferedModel.default.extend({
    currentUser: Ember.inject.service(),
    displayName: attr('string'),
    notes: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    picture: attr('string'),
    website: attr('string'),
    // Person
    gender: attr('string'),
    titlePrefix: attr('string'),
    titleSuffix: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    // Company
    companyName: attr('string'),
    companyDepartment: attr('string'),
    organization: attr('string'),
    hasCompanyName: Ember.computed.notEmpty('companyName'),
    status: belongsTo('investor-status', {
      async: false
    }),
    address: belongsTo('address', {
      async: false,
      useBuffer: true
    }),
    bank: belongsTo('bank', {
      async: false,
      useBuffer: true
    }),
    contacts: hasMany('contact', {
      async: true,
      useBuffer: true
    }),
    sources: hasMany('source', {
      async: false
    }),
    events: hasMany('event', {
      async: true
    }),
    attachments: hasMany('attachment', {
      async: true
    }),
    counts: attr(),
    validators: {
      status: (0, _emberCpValidations.validator)('presence', true)
    },
    // Events
    sortedEvents: Ember.computed.sort('events', 'sortDateDesc'),
    sortDateDesc: ['timestamp:desc'],
    // Buffer
    supportsDirtyBufferTracking: true,
    hasBufferedChangesContacts: (0, _bufferChanges.watchBufferedHasMany)('contacts'),
    hasBufferedChanges: (0, _bufferChanges.watchBufferChanges)(BUFFER_BELONGS_TO, BUFFER_HAS_MANY),
    // Static
    typeName: 'Investor',
    ownerType: 'investor'
  });

  _exports.default = _default;
});