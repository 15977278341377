define("backend/services/scroll-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    visibilityChanged: function visibilityChanged() {
      this.trigger('evtVisibilityChange');
    }
  });

  _exports.default = _default;
});