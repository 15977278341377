define("backend/pods/device/starts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8c42bwM",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"panel-body\"],false],[0,\"\\n\"],[1,[28,\"data-table\",null,[[\"filteredEntries\",\"data\",\"meta\",\"isLoading\",\"page\",\"size\",\"dateFormat\",\"tableColumns\",\"canEdit\"],[[24,[\"deviceStarts\"]],[24,[\"deviceStarts\"]],[24,[\"deviceStartsMeta\"]],[24,[\"deviceStarts\",\"isLoading\"]],[24,[\"deviceStartsPage\"]],[24,[\"deviceStartsSize\"]],\"DD.MM.YYYY\",[24,[\"deviceStartsTableColumns\"]],false]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/device/starts/template.hbs"
    }
  });

  _exports.default = _default;
});