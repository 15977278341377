define("backend/pods/distributor/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gG6W4qg+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"hm-model/form/profile\",null,[[\"selected\",\"ownerType\"],[[24,[\"model\"]],\"distributor\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-profile/company\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-profile/contact\",null,[[\"model\",\"modelHasDistributor\"],[[24,[\"model\"]],false]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-gap--full-row\"],[8],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-profile/detail\",null,[[\"model\",\"statusModel\"],[[24,[\"model\"]],\"distributor-status\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-profile/address\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-profile/bank\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-profile/notes\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/distributor/profile/template.hbs"
    }
  });

  _exports.default = _default;
});