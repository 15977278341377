define("backend/pods/components/hm-model/fieldset-billings/information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'fieldset',
    billingInformation: Ember.computed.reads('model.billingInformation'),
    actions: {
      saveBillingInformation: function saveBillingInformation() {
        var billingInformation = this.get('billingInformation');
        var promise = this.get('model').saveBillingInformation(billingInformation);
        this.set('promise', promise);
      }
    }
  });

  _exports.default = _default;
});