define("backend/services/uploader", ["exports", "backend/config/environment", "backend/helpers/format-filesize"], function (_exports, _environment, _formatFilesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_HTTP_RESUME_INCOMPLETE = 308;

  function parseHeaderRange(xhr) {
    var header = xhr.getResponseHeader('Range');
    return parseInt(header.split('-')[1], 10);
  }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // -------------------------------- PUBLIC -----------------------------------
    filename: Ember.computed('_file', function () {
      var file = this.get('_file');

      if (!file) {
        return null;
      }

      var size = (0, _formatFilesize.formatFilesize)([file.size], {});
      return "".concat(file.name, " (").concat(size, ")");
    }),
    filesize: Ember.computed('_file', function () {
      var file = this.get('_file');

      if (!file) {
        return null;
      }

      return file.size;
    }),
    transferredBytes: Ember.computed('_chunkIdx', '_chunkProgress', function () {
      return this.get('_chunkIdx') + this.get('_chunkProgress');
    }),
    progress: Ember.computed('transferredBytes', 'filesize', function () {
      return (this.get('transferredBytes') / this.get('filesize') * 100).toFixed(2);
    }),
    isPending: false,
    _isResumableUpload: false,
    _upload: null,
    _file: null,
    cancel: function cancel(upload) {},
    upload: function upload(file) {
      var _this = this;

      var idx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var upload = this.get('_upload');
      this.resetState();
      this.setProperties({
        isPending: true,
        _file: file
      });

      if (idx > 0 && upload) {
        this.setProperties({
          _chunkIdx: idx
        });

        this._uploadNextChunk(upload.get('id'), file, file.size, file.type, upload.get('upload_url'));
      } else {
        this._uploadInit(file).then(function (_ref) {
          var id = _ref.id,
              url = _ref.url;

          _this.setProperties({
            _chunkIdx: 0
          });

          _this._uploadNextChunk(id, file, file.size, file.type, url);
        });
      }
    },
    resume: function resume(param) {
      var _this2 = this;

      var upload = this.get('_upload');

      if (!upload) {
        this.resetState();
        this.setProperties({
          _isResumableUpload: true,
          _upload: param
        });
      } else {
        var file = param;

        if (!upload || !file) {
          throw new Error("File and upload required for resuming");
        }

        this._getResumeIdx(upload).then(function (nextIdx) {
          _this2._uploadProgress(upload.get('id'), nextIdx);

          _this2.upload(file, nextIdx);
        });
      }
    },
    resetState: function resetState() {
      this.setProperties({
        isPending: false,
        _isResumableUpload: false,
        _upload: null,
        _file: null,
        _chunkStep: 1000000,
        _chunkCounter: 0,
        _chunkProgress: 0
      });
    },
    // -------------------------------- PRIVATE ----------------------------------
    _makeRequest: function _makeRequest(url, options) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this3.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          options.headers = headers;
          options.contentType = 'application/json; charset=utf-8';
          options.data = {
            data: options.data
          };

          _this3.get('ajax').request(_environment.default.host + url, options).then(function (response) {
            return resolve(response);
          });
        });
      });
    },
    _chunkIdx: null,
    _chunkStep: 1000000,
    // start with 1 MB then increase w/ every count
    _chunkCounter: 0,
    _chunkProgress: 0,
    _uploadInit: function _uploadInit(file) {
      var meta = {
        filename: file.name,
        filesize: '' + file.size,
        mime_type: file.type,
        hash: '???'
      };
      return this._makeRequest("/upload/resumable", {
        method: 'POST',
        data: meta
      }).then(function (_ref2) {
        var data = _ref2.data;
        return data;
      });
    },
    _uploadNextChunk: function _uploadNextChunk(id, file, filesize, mimetype, url) {
      var _this4 = this;

      var chunkIdx = this.get('_chunkIdx');
      var chunkCounter = this.get('_chunkCounter') + 1;
      this.set('_chunkCounter', chunkCounter);
      var chunkStep = this.get('_chunkStep') * chunkCounter;
      chunkStep = Math.min(chunkStep, 200000000); // max. 200 MB chunk step

      var isLastRequest = chunkIdx + chunkStep > filesize;
      var endIdx = isLastRequest ? filesize : chunkIdx + chunkStep - 1;
      console.log('_uploadNextChunk: ' + chunkIdx + ' to ' + endIdx + ' of ' + filesize);
      var reader = new FileReader();

      reader.onloadend = function () {
        var xmlHttpRequest = new XMLHttpRequest();
        console.log('attach upload listener');

        xmlHttpRequest.upload.onprogress = function (event) {
          _this4.set('_chunkProgress', event.loaded);
        };

        xmlHttpRequest.onreadystatechange = function () {
          console.log('  state', xmlHttpRequest.readyState);

          if (xmlHttpRequest.readyState == 4) {
            console.log('  status', xmlHttpRequest.status);
            console.log('  headers', xmlHttpRequest.getAllResponseHeaders());
            console.log("   ... done", xmlHttpRequest.responseText);

            if (xmlHttpRequest.status >= 400) {
              throw new Error("Upload failed:" + xmlHttpRequest.responseText);
              console.log("   error occured, stopping", xmlHttpRequest.responseText);
            } else {
              if (isLastRequest) {
                _this4.resetState();

                _this4._uploadFinalize(id, filesize);
              } else if (xmlHttpRequest.status === STATUS_HTTP_RESUME_INCOMPLETE) {
                var bytesTransferred = parseHeaderRange(xmlHttpRequest) + 1;

                _this4.setProperties({
                  _chunkIdx: bytesTransferred,
                  _chunkProgress: 0
                });

                _this4._uploadProgress(id, bytesTransferred);

                _this4._uploadNextChunk(id, file, filesize, mimetype, url);
              }
            }
          }
        };

        console.log('open');
        xmlHttpRequest.open('PUT', url);
        xmlHttpRequest.setRequestHeader('Content-Type', mimetype);
        xmlHttpRequest.setRequestHeader('Content-Range', "bytes ".concat(chunkIdx, "-").concat(isLastRequest ? endIdx - 1 : endIdx, "/").concat(filesize));
        console.log("...uploading bytes ".concat(chunkIdx, "-").concat(isLastRequest ? endIdx - 1 : endIdx, "/").concat(filesize));
        xmlHttpRequest.send(reader.result);
      };

      reader.readAsArrayBuffer(file.slice(chunkIdx, endIdx + 1));
    },
    _uploadProgress: function _uploadProgress(id, bytesTransferred) {
      var isComplete = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return this._makeRequest(isComplete ? "/upload/resumable/".concat(id, "/complete") : "/upload/resumable/".concat(id), {
        method: 'PUT',
        data: {
          bytes_transferred: '' + bytesTransferred
        }
      });
    },
    _uploadFinalize: function _uploadFinalize(id, filesize) {
      this._uploadProgress(id, filesize, true).then(function () {
        return alert("Upload complete");
      });
    },
    _getResumeIdx: function _getResumeIdx(upload) {
      var _this5 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this5.get('ajax').raw(upload.get('upload_url'), {
          method: 'PUT',
          headers: {
            'Content-Range': "bytes */".concat(upload.get('filesize'))
          }
        }).then(function (raw) {
          if (raw && raw.payload.size) {
            _this5._uploadProgress(upload.get('id'), raw.payload.size, true);
          }
        }).catch(function (raw) {
          if (raw.jqXHR.status === STATUS_HTTP_RESUME_INCOMPLETE) {
            resolve(parseHeaderRange(raw.jqXHR) + 1);
          } else {
            throw raw.response;
          }
        });
      });
    }
  });

  _exports.default = _default;
});