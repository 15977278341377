define("backend/prototypes/owners/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params, transition) {
      var store = this.store;
      var deviceOwner = transition.data.deviceOwner;
      return Ember.RSVP.hash({
        deviceOwner: deviceOwner,
        countries: store.findAll('country'),
        statusList: store.peekAll(this.get('statusType'))
      });
    }
  });

  _exports.default = _default;
});