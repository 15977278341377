define("backend/pods/studies-partner/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YqID4rqI",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"hm-model/form/essentials\",null,[[\"selected\",\"ownerType\"],[[24,[\"model\"]],[24,[\"model\",\"ownerType\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/company-w-departments\",null,[[\"model\",\"ownerType\",\"disabled\",\"form\"],[[24,[\"model\"]],[24,[\"model\",\"ownerType\"]],true,[23,1,[]]]]],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/contacts\",null,[[\"model\"],[[24,[\"model\",\"contacts\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs nav-sub-tabs nav-tabs-bottom\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"active text-semibold\"],[8],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"text-uppercase\"],[8],[0,\"Add Event\"],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/quick-add/event\",null,[[\"model\",\"modelName\",\"showLabel\"],[[24,[\"model\"]],[24,[\"model\",\"ownerType\"]],false]]],false],[0,\"\\n\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs nav-sub-tabs nav-tabs-bottom\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"active text-semibold\"],[8],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"text-uppercase\"],[8],[0,\"Events\"],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/list-add/events\",null,[[\"model\",\"showLabel\"],[[24,[\"model\",\"events\"]],false]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/studies-partner/index/template.hbs"
    }
  });

  _exports.default = _default;
});