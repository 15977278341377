define("backend/helpers/format-changelog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatChangelog = formatChangelog;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatChangelog(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        value = _ref3[0];

    var _ref2$showBullets = _ref2.showBullets,
        showBullets = _ref2$showBullets === void 0 ? true : _ref2$showBullets;

    if (!Ember.isEmpty(value)) {
      if (showBullets) {
        var li = value.split('\n').join('</li><li>');
        value = "<ul><li>".concat(li, "</li></ul>");
      } else {
        var _li = value.split('\n').map(function (v) {
          if (!v) {
            return '&nbsp;';
          }

          return v;
        }).join('</li><li>');

        value = "<ul class=\"no-ul-style\"><li>".concat(_li, "</li></ul>");
      }
    }

    return Ember.String.htmlSafe(value);
  }

  var _default = Ember.Helper.helper(formatChangelog);

  _exports.default = _default;
});