define("backend/adapters/serverlog", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    findQueryUrl: function findQueryUrl(type, query) {
      var url = [];
      var prefix = this.urlPrefix();

      if (type) {
        url.push(this.pathForType(type));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      if (typeof query['type'] !== 'undefined') {
        if (query.type) {
          url.push('errors/' + query.type);
        }

        delete query.type;
      }

      return url.join('/');
    }
  });

  _exports.default = _default;
});