define("backend/pods/components/hm-models/list/row-w-departments/component", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: '',
    rowsGroupByClientGroup: Ember.computed('rows.length', function () {
      var rows = this.get('rows');
      var groupIds = rows.filterBy('selected.isClientGroup').mapBy('selected.id');
      return rows.filter(function (row) {
        return !groupIds.includes(row.selected.get('clientGroup.id'));
      });
    }),
    _urlForRoute: function _urlForRoute(route, id) {
      var url;

      if (id) {
        url = (0, _emberInflector.pluralize)(route) + '/' + id;
      }

      debugger;

      switch (route) {
        case 'client':
        case 'sponsored-client':
        case 'lead':
          url += '/essentials';
          break;
      }

      return url;
    },
    actions: {
      clickRow: function clickRow(owner, evt) {
        // TODO: get event from handler
        var route = this.get('clickRoute');

        if (!route) {
          if (owner.get('departments').isAny('status.isClientStatus')) {
            route = 'client';
          } else if (owner.get('departments').isAny('status.isSponsoredClientStatus')) {
            route = 'sponsored-client';
          } else {
            route = 'lead';
          }
        }

        var event = evt || (_typeof(window.event) === 'object' ? window.event : null);

        if (event && (event.ctrlKey || event.metaKey) && owner) {
          var newURL = [window.location.protocol + '//' + window.location.host, this._urlForRoute(route, owner.get('id'))];
          var win = window.open(newURL.join('/'), '_blank');
          win.focus();
        } else {
          return this.get('router').transitionTo(route, owner.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});