define("backend/pods/mixins/model/owner-type-by-role", ["exports", "backend/utils/static-data/owner-type"], function (_exports, _ownerType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DISTR_TYPES = ['lead', 'client', 'distributor'];

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    filteredOwnerTypes: Ember.computed('currentUser.isAdmin', 'currentUser.isDistributor', function () {
      if (this.get('currentUser.isAdmin')) {
        return _ownerType.default;
      }

      if (this.get('currentUser.isDistributor')) {
        return _ownerType.default.filter(function (type) {
          return DISTR_TYPES.includes(type.id);
        });
      }

      return [];
    }),
    ownerTypes: Ember.computed('filteredOwnerTypes', function () {
      return this.get('filteredOwnerTypes').map(function (_ref) {
        var id = _ref.id,
            display_name = _ref.display_name;
        return Ember.Object.create({
          id: id,
          displayName: display_name
        });
      });
    })
  });

  _exports.default = _default;
});