define("backend/templates/components/forms/-localized-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xm39Av4P",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"hm-form/wrapper\",null,[[\"model\"],[[24,[\"buffers\",\"selected\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/templates/components/forms/-localized-model.hbs' @ L4:C8) \"],null]],[[\"field\",\"label\",\"disabled\"],[\"id\",\"Code\",[24,[\"isNotNew\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"options\",\"showIsoCodeInfo\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"p-2 rounded bg-grey-light -mt-4 mb-4 text-black text-bold\"],[8],[0,\"IMPORTANT: Use country codes from ISO 3166 Alpha-2 \"],[7,\"a\",true],[10,\"href\",\"https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes\"],[8],[0,\"https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/templates/components/forms/-localized-model.hbs' @ L8:C8) \"],null]],[[\"field\",\"label\"],[\"name\",\"Name\"]]],false],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/templates/components/forms/-localized-model.hbs' @ L9:C8) \"],null]],[[\"field\",\"label\"],[\"nameEng\",\"Name (English)\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/forms/-localized-model.hbs"
    }
  });

  _exports.default = _default;
});