define("backend/pods/components/hm-models/list/table-base/component", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var PromiseArray = _emberData.default.PromiseArray;

  function calcVisibleCols(cols) {
    return cols.reduce(function (obj, column) {
      if (column.get('display')) {
        obj.set(Ember.String.camelize(column.get('attr') || 'empty'), true);
      }

      return obj;
    }, Ember.Object.create({}));
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    scrollService: Ember.inject.service(),
    classNames: ['no-footer'],
    classNameBindings: ['showWrapper:dataTables_wrapper'],
    attributeBindings: ['style'],
    action: 'editSelected',
    filterText: 'Filter:',
    refreshModelAction: 'refreshModelAction',
    pageSizes: [Ember.Object.create({
      id: 10000,
      displayName: 'All'
    }), Ember.Object.create({
      id: 10,
      displayName: '10'
    }), Ember.Object.create({
      id: 25,
      displayName: '25'
    }), Ember.Object.create({
      id: 50,
      displayName: '50'
    }), Ember.Object.create({
      id: 100,
      displayName: '100'
    })],
    incrementalRender: true,
    showHeader: true,
    showFooter: true,
    showPagination: true,
    showWrapper: true,
    canEdit: true,
    meta: Ember.computed.reads('data.meta'),
    dataLoading: Ember.computed.or('isLoading', 'filteredEntries.isPending'),
    dateFormat: 'DD.MM.YYYY',
    filterData: Ember.computed('filterModel', function () {
      var model = this.get('filterModel');

      if (model) {
        return this.get('store').query(model, {
          page: {
            size: 10000
          }
        });
      }

      return [];
    }),
    savedEntries: Ember.computed.reads('data'),
    sortedEntries: Ember.computed.sort('savedEntries', 'sortProperties'),
    filteredEntries: Ember.computed('sortedEntries', 'savedEntries', 'filterValue', 'filterByField', 'filterByFields.@each.input', 'filterGt', function () {
      var _this$getProperties = this.getProperties('filterByField', 'filterValue', 'filterByFields', 'filterGt'),
          filterByField = _this$getProperties.filterByField,
          filterValue = _this$getProperties.filterValue,
          filterByFields = _this$getProperties.filterByFields,
          filterGt = _this$getProperties.filterGt;

      if (filterByField && filterValue || filterByFields && filterByFields.any(function (f) {
        return !!f.get('input');
      })) {
        if (filterGt) {
          return this.get('sortedEntries').filter(function (entry) {
            var value = entry.get(filterByField);
            return value > filterValue;
          });
        } else if (filterByFields) {
          return PromiseArray.create({
            promise: this.get('filterData').then(function (entries) {
              return entries.filter(function (entry) {
                return filterByFields.any(function (field) {
                  var value = entry.get(field.get('attribute'));
                  var searchFor = (field.get('input') || '').toLowerCase();
                  return value && searchFor && value.indexOf && value.toLowerCase().indexOf(searchFor) > -1;
                });
              });
            })
          });
        } else {
          var searchPhrase = filterValue.toLowerCase();
          return PromiseArray.create({
            promise: this.get('filterData').then(function (entries) {
              return entries.filter(function (entry) {
                var value = entry.get(filterByField);
                return value && value.indexOf && value.toLowerCase().indexOf(searchPhrase) > -1;
              });
            })
          });
        }
      }

      return this.get('sortedEntries');
    }),
    dataTableRows: Ember.computed('filteredEntries.length', 'tableColumnsSorted', function () {
      var _this$getProperties2 = this.getProperties('filteredEntries', 'tableColumnsSorted'),
          filteredEntries = _this$getProperties2.filteredEntries,
          tableColumnsSorted = _this$getProperties2.tableColumnsSorted;

      return filteredEntries.map(function (row) {
        return {
          selected: row,
          columns: tableColumnsSorted.map(function (column) {
            var _column$getProperties = column.getProperties('display', 'style', 'title'),
                display = _column$getProperties.display,
                style = _column$getProperties.style,
                title = _column$getProperties.title;

            var col = Ember.Object.create({
              style: style,
              title: title
            });
            col.set('display', display);
            col.set('obj', column);

            if (column.get('isValue')) {
              col.set('value', row.get(column.get('attr')));
              col.set('isValue', true);
            } else if (column.get('isFormatter')) {
              col.set('value', column.get('formatter')([row.get(column.get('attr'))], {
                model: row
              }));
              col.set('isValue', true);
            } else if (column.get('isComponent')) {
              col.set('isComponent', true);
              col.set('component', column.get('component'));
              col.set('attr', column.get('attr'));
              col.set('modelName', column.get('modelName'));
            }

            return col;
          })
        };
      });
    }),
    dataTableRowsAboveFold: Ember.computed('dataTableRows.length', 'page', function () {
      return this.get('dataTableRows').slice(0, 20);
    }),
    dataTableRowsBelowFold: Ember.computed('dataTableRows.length', 'page', function () {
      var _this = this;

      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise(function (resolve) {
          Ember.run.schedule('afterRender', function () {
            Ember.run.later(function () {
              return resolve(_this.get('dataTableRows').slice(20));
            }, 100);
          });
        })
      });
    }),
    // Filter
    isFiltering: Ember.computed('filterByField', 'filterValue', 'filterByFields.@each.input', function () {
      var filterFields = this.get('filterByFields');
      return !!this.get('filterByField') && !!this.get('filterValue') || filterFields && filterFields.any(function (f) {
        return !!f.get('input');
      });
    }),
    isNotFiltering: Ember.computed.not('isFiltering'),
    // Pagination
    prevPage: Ember.computed('page', function () {
      return this.get('page') - 1;
    }),
    nextPage: Ember.computed('page', function () {
      return this.get('page') + 1;
    }),
    isFirstPage: Ember.computed('page', function () {
      return this.get('page') === 1;
    }),
    isLastPage: Ember.computed('page', function () {
      return this.get('page') >= this.get('meta.total-pages');
    }),
    rangeHasMore: Ember.computed.gt('pageRange.length', 19),
    pageRange: Ember.computed('meta.total-pages', 'meta.total-count', 'size', function () {
      var totalPages = this.get('meta.total-pages');

      if (!totalPages) {
        totalPages = this.get('meta.total-count') || 0 / this.get('size');
      }

      totalPages = Math.min(totalPages, 20);
      var result = Ember.A();

      for (var idx = 1; idx <= totalPages; idx++) {
        result.push(idx);
      }

      return result;
    }),
    startCount: Ember.computed('pageMinCount', 'totalCount', function () {
      var _this$getProperties3 = this.getProperties('pageMinCount', 'totalCount'),
          pageMinCount = _this$getProperties3.pageMinCount,
          totalCount = _this$getProperties3.totalCount;

      if (pageMinCount == 0 && totalCount > 0) {
        return 1;
      }

      return pageMinCount;
    }),
    pageMinCount: Ember.computed('page', 'size', function () {
      return (this.get('page') - 1) * this.get('size');
    }),
    pageMaxCount: Ember.computed('pageMinCount', 'size', 'totalCount', function () {
      return Math.min(this.get('pageMinCount') + this.get('size'), this.get('totalCount') || 0);
    }),
    totalCount: Ember.computed.reads('meta.total-count'),
    resetPage: Ember.observer('size', function () {
      this.set('page', 1);
    }),
    // Sorting
    clientSort: Ember.computed('defaultClientSort', function () {
      var sort = this.get('defaultClientSort');

      if (sort) {
        return sort;
      }

      return {
        sortBy: 'id',
        order: 'asc'
      };
    }),
    sortProperties: Ember.computed('clientSort', function () {
      return [this.get('clientSort').sortBy + ':' + this.get('clientSort').order];
    }),
    tableColumnsSorted: Ember.computed('sortProperties', function () {
      var _this2 = this;

      var columns = this.get('tableColumns');
      columns.forEach(function (column) {
        if ((column.get('sortAttr') || column.get('attr')) === _this2.get('clientSort').sortBy) {
          column.set('isSorted', true);
          column.set('sortClass', 'sorting_' + (_this2.get('clientSort').order === 'desc' ? 'desc' : 'asc'));
        } else {
          column.set('isSorted', false);
        }
      });
      return columns;
    }),
    showColumnOptions: false,
    tableId: '',
    hasLoadedColumns: false,
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var visibleCols;

      if (!this.get('userSettings.hasLoaded') && this.get('userSettings.hasLocalSettings')) {
        visibleCols = this.get('userSettings').loadTableColumns(this.get('tableId'), true);
        this.initTableColumns(visibleCols);
      } else {
        this.get('currentUser.user').then(function () {
          visibleCols = _this3.get('userSettings').loadTableColumns(_this3.get('tableId'));

          _this3.initTableColumns(visibleCols);
        });
      }

      var scrollService = this.get('scrollService');

      this._onVisibilityChangeHandler = function (evt) {
        Ember.run.debounce(scrollService, scrollService.visibilityChanged, evt, 100);
      };
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._installVisibilityListeners();
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      this._uninstallVisibilityListeners();
    },
    _installVisibilityListeners: function _installVisibilityListeners() {
      window.addEventListener('load', this._onVisibilityChangeHandler);
      window.addEventListener('DOMContentLoaded', this._onVisibilityChangeHandler);
      window.addEventListener('scroll', this._onVisibilityChangeHandler);
      window.addEventListener('resize', this._onVisibilityChangeHandler);
    },
    _uninstallVisibilityListeners: function _uninstallVisibilityListeners() {
      window.removeEventListener('load', this._onVisibilityChangeHandler);
      window.removeEventListener('DOMContentLoaded', this._onVisibilityChangeHandler);
      window.removeEventListener('scroll', this._onVisibilityChangeHandler);
      window.removeEventListener('resize', this._onVisibilityChangeHandler);
    },
    visibilityChange: function visibilityChange() {
      console.log('visibilityChange');
    },
    initTableColumns: function initTableColumns(visibleCols) {
      if (!Ember.isBlank(visibleCols)) {
        this.get('tableColumns').forEach(function (col) {
          col.set('display', visibleCols.includes(col.get('attr')));
        });
      }

      this.set('activeColumns', calcVisibleCols(this.get('tableColumns')));
      this.set('hasLoadedColumns', true);
    },
    columnToggled: function columnToggled() {
      var cols = this.get('tableColumns');
      this.get('userSettings').updateTableColumns(cols, this.get('tableId'));
      this.set('activeColumns', calcVisibleCols(cols));
    },
    _urlForRoute: function _urlForRoute(route, id) {
      var url;

      if (id) {
        url = (0, _emberInflector.pluralize)(route) + '/' + id;
      }

      debugger;

      switch (route) {
        case 'client':
        case 'sponsored-client':
        case 'lead':
        case 'investor':
        case 'licensor':
          url += '/essentials';
          break;
      }

      return url;
    },
    actions: {
      clickRow: function clickRow(route, model, evt) {
        var event = evt || (_typeof(window.event) === 'object' ? window.event : null);

        if (event && (event.ctrlKey || event.metaKey) && route && model) {
          var newURL = [window.location.protocol + '//' + window.location.host, this._urlForRoute(route, model.get('id'))];
          var win = window.open(newURL.join('/'), '_blank');
          win.focus();
        } else {
          var controller = Ember.getOwner(this).lookup('controller:application');
          return controller.transitionToRoute(route, model);
        }
      },
      editSelected: function editSelected(entry) {
        if (this.get('editAction')) {
          return this.get('editAction')(entry);
        }

        return this.sendAction('action', entry);
      },
      create: function create(template) {
        return this.sendAction('createAction', template);
      },
      clientSortBy: function clientSortBy(sortProperty, addSortProperty) {
        this.set('clientSort', {
          sortBy: addSortProperty || sortProperty,
          order: this.get('clientSort').order === 'desc' ? 'asc' : 'desc'
        });
      },
      toggleColumnOptions: function toggleColumnOptions() {
        this.toggleProperty('showColumnOptions');
      },
      toggleColumn: function toggleColumn(column, isVisible) {
        column.toggleProperty('display');

        if (this.get('savedEntries.length') > 200) {
          Ember.run.debounce(this, this.columnToggled, 1000);
        } else {
          this.columnToggled();
        }
      },
      refreshModelAction: function refreshModelAction() {
        this.sendAction('refreshModelAction');
      }
    }
  });

  _exports.default = _default;
});