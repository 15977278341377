define("backend/adapters/contract", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateDeliveryDate: function updateDeliveryDate(id, date) {
      var url = this.urlForUpdateRecord(id, 'contract') + "/delivery_date/" + date;
      return this.ajax(url, 'PUT');
    },
    updateCancellation: function updateCancellation(id, date, endDate, file, notes, cancelReason) {
      var url = this.urlForUpdateRecord(id, 'contract') + "/cancellation";
      var data = {
        data: {
          cancellation_date: date,
          cancellation_end_date: endDate,
          cancellation_file: file,
          cancellation_notes: notes,
          cancel_reason: cancelReason
        }
      };
      return this.ajax(url, 'PUT', {
        data: data
      });
    }
  });

  _exports.default = _default;
});