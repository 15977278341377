define("backend/pods/components/hm-model/fieldset-create/essentials-sponsored-client/component", ["exports", "backend/pods/components/hm-model/mixins/department-status", "backend/pods/components/hm-model/fieldset-create/-essentials-base"], function (_exports, _departmentStatus, _essentialsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_essentialsBase.default, _departmentStatus.default, {
    currentUser: Ember.inject.service(),
    distributors: Ember.computed(function () {
      return this.get('store').query('distributor', {
        filter: 'active'
      });
    }),
    clientTypes: Ember.computed(function () {
      return this.get('store').peekAll('client-type');
    })
  });

  _exports.default = _default;
});