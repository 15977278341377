define("backend/mixins/form-attachments-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    createUploadUrl: function createUploadUrl(type, query) {
      return this.get('store').adapterFor('attachment').generateUploadUrl(type, query);
    },
    addAttachment: function addAttachment() {
      var _this = this;

      this.createUploadUrl('attachment').then(function (_ref) {
        var upload_url = _ref.data.upload_url;

        _this.get('selected').addAttachment(upload_url);
      });
    },
    removeAttachment: function removeAttachment(attachment) {
      this.get('selected').removeAttachment(attachment);
    },

    /**
      Retrieve attachments from form buffer because they may be overwritten
      after saving the device, as the api returns empty attachments.
     */
    _getAttachments: function _getAttachments() {
      var attachments = this.get('selected.buffers.attachments');

      if (!attachments) {
        return null;
      }

      return attachments.map(function (buffer) {
        return buffer.get('content');
      });
    },
    saveAttachments: function saveAttachments() {
      if (!this.get('currentUser.canSaveAttachments')) {
        return Ember.RSVP.resolve();
      }

      var attachments = this._getAttachments();

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!attachments || attachments.get('length') === 0) {
          return resolve();
        }

        var promises = [];
        attachments.forEach(function (record) {
          return promises.pushObject(record.save());
        }); // TODO: Add Flash

        Ember.RSVP.Promise.all(promises).then(function (attachments) {
          return resolve(attachments);
        }).catch(reject);
      });
    },
    destroyAttachments: function destroyAttachments() {
      if (!this.get('currentUser.canDestroyAttachments')) {
        return Ember.RSVP.resolve();
      }

      var attachments = this.get('selected.deletedAttachments');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!attachments || attachments.get('length') === 0) {
          return resolve();
        }

        var promises = [];
        attachments.forEach(function (record) {
          return promises.pushObject(record.destroyRecord());
        }); // TODO: Add Flash

        Ember.RSVP.Promise.all(promises).then(function () {
          attachments.clear();
          return resolve();
        }).catch(reject);
      });
    }
  });

  _exports.default = _default;
});