define("backend/pods/device/battery/route", ["exports", "backend/prototypes/owner/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    ownerType: 'device'
  });

  _exports.default = _default;
});