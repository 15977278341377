define("backend/templates/components/load-unpaid-billings-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmdu1vZP",
    "block": "{\"symbols\":[\"async\",\"&default\"],\"statements\":[[5,\"async\",[],[[\"@do\"],[[23,0,[\"loadSummary\"]]]],{\"statements\":[[0,\"\\n  \"],[14,2,[[23,1,[]]]],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"hidden\"],[3,\"did-insert\",[[23,1,[\"actions\",\"do\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/load-unpaid-billings-summary.hbs"
    }
  });

  _exports.default = _default;
});