define("backend/utils/static-data/material-order-products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SHIPPING_PRODUCTS = _exports.MARKETING_PRODUCTS = _exports.INDIVIDUAL_PRODUCTS = _exports.HEADPHONE_COVERS = void 0;
  var HEADPHONE_COVERS = [{
    articleNo: "1H-05",
    amount: 5,
    unit: "Boxen",
    description: "250 Anwendungen",
    price: "125.00"
  }, {
    articleNo: "1H-06",
    amount: 10,
    unit: "Boxen",
    description: "500 Anwendungen",
    price: "238.00"
  }, {
    articleNo: "1H-07",
    amount: 15,
    unit: "Boxen",
    description: "750 Anwendungen",
    price: "338.00"
  }, {
    articleNo: "1H-08",
    amount: 20,
    unit: "Boxen",
    description: "1000 Anwendungen",
    price: "425.00"
  }];
  _exports.HEADPHONE_COVERS = HEADPHONE_COVERS;
  var INDIVIDUAL_PRODUCTS = [{
    articleNo: '1M-10',
    amount: 1,
    unit: 'Stk.',
    description: 'HappyMed Videobrille Standard',
    price: '6900.00'
  }, {
    articleNo: '1M-09',
    amount: 1,
    unit: 'Stk.',
    description: 'HappyMed Videobrille Hardware',
    price: '3900.00'
  }, {
    articleNo: '1Z-04',
    amount: 1,
    unit: 'Stk.',
    description: 'Zusätzliches Eyeshield zur Abschirmung',
    price: '49.00'
  }, {
    articleNo: '1Z-05',
    amount: 1,
    unit: 'Stk.',
    description: 'Fernbedienung',
    price: '79.00'
  }, {
    articleNo: '1Z-06',
    amount: 1,
    unit: 'Stk.',
    description: 'HappyMed adaptierte Kopfhörer',
    price: '130.00'
  }, {
    articleNo: '1P-01',
    amount: 1,
    unit: 'Stk.',
    description: 'Lagerungskissen für Bauchlage',
    price: '149.00'
  }, {
    articleNo: '1Z-02',
    amount: 2,
    unit: 'Stk.',
    description: 'Batterien für Fernbedienung',
    price: '5.00'
  }, {
    articleNo: '1Z-03',
    amount: 1,
    unit: 'Stk.',
    description: 'Ladenetzteil',
    price: '69.00'
  }, {
    articleNo: '1Z-07',
    amount: 1,
    unit: 'Stk.',
    description: 'Starterpaket',
    price: '0.00'
  }];
  _exports.INDIVIDUAL_PRODUCTS = INDIVIDUAL_PRODUCTS;
  var MARKETING_PRODUCTS = [{
    articleNo: '1M-01',
    amount: 100,
    unit: 'Stk.',
    description: 'Patienten-Information',
    price: '50.00'
  }, {
    articleNo: '1M-02',
    amount: 500,
    unit: 'Stk.',
    description: 'Patienten-Information',
    price: '200.00'
  }, {
    articleNo: '1M-13',
    amount: 5,
    unit: 'Stk.',
    description: 'Info Poster mit HappyMed-Logo',
    price: 0
  }];
  _exports.MARKETING_PRODUCTS = MARKETING_PRODUCTS;
  var SHIPPING_PRODUCTS = [{
    articleNo: 'SHIPPING-0',
    amount: 1,
    unit: '',
    description: 'Versand Kostenlos',
    price: '0.00'
  }, {
    articleNo: 'SHIPPING-15',
    amount: 1,
    unit: '',
    description: 'Versand Kleinpaket',
    price: '20.00'
  }, {
    articleNo: 'SHIPPING-30',
    amount: 1,
    unit: '',
    description: 'Versand Großpaket (ab 10 Boxen Hygienische Kopfhöhrer-Überzieher)',
    price: '35.00'
  }];
  _exports.SHIPPING_PRODUCTS = SHIPPING_PRODUCTS;
});