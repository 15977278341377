define("backend/pods/licensor/movies/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    filter: ['movie'],
    createQuery: function createQuery(params) {
      var query = this.curPage(params);
      query.licensor = this.modelFor('licensor').get('id');
      return query;
    }
  });

  _exports.default = _default;
});