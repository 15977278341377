define("backend/components/d3-line-chart", ["exports", "d3-time-format", "d3-selection", "d3-format", "d3-array", "d3-scale", "d3-time", "d3-axis", "d3-shape"], function (_exports, _d3TimeFormat, _d3Selection, _d3Format, _d3Array, _d3Scale, _d3Time, _d3Axis, _d3Shape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var bisectDate = (0, _d3Array.bisector)(function (d) {
    return d.x;
  }).left;
  var formatPercent = (0, _d3Format.format)('.0%');

  var _default = Ember.Component.extend({
    height: 320,
    margin: {
      top: 10,
      right: 20,
      bottom: 30,
      left: 40
    },
    horizontalLines: [0.2, 0.4, 0.6, 0.8, 1],
    xDataRange: Ember.computed('data', function () {
      return (0, _d3Array.extent)(this.get('data').toArray(), function (d) {
        return d.x;
      });
    }),
    yDataRange: [0, 1],
    xScale: Ember.computed('svgWidth', 'xDataRange', function () {
      var svgWidth = this.get('svgWidth');
      return (0, _d3Scale.scaleTime)().domain(this.get('xDataRange')).nice(_d3Time.timeDay).range([0, svgWidth]);
    }),
    yScale: Ember.computed('svgHeight', 'yDataRange', function () {
      var svgHeight = this.get('svgHeight');
      return (0, _d3Scale.scaleLinear)().domain(this.get('yDataRange')).range([svgHeight, 0]);
    }),
    xAxisDaysTicks: 1,
    xAxis: Ember.computed('xScale', 'xAxisDaysTicks', function () {
      return (0, _d3Axis.axisBottom)().scale(this.get('xScale')).tickFormat((0, _d3TimeFormat.timeFormat)("%d.%m")).ticks(_d3Time.timeDay, this.get('xAxisDaysTicks'));
    }),
    yAxis: Ember.computed('yScale', function () {
      return (0, _d3Axis.axisLeft)().scale(this.get('yScale')).tickFormat((0, _d3Format.format)(".0%"));
    }),
    calcLine: Ember.computed('xScale', 'yScale', function () {
      var _this$getProperties = this.getProperties('xScale', 'yScale'),
          xScale = _this$getProperties.xScale,
          yScale = _this$getProperties.yScale;

      return (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).x(function (d) {
        return xScale(d.x);
      }).y(function (d) {
        return yScale(d.y);
      });
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initSvg();
      this.paint();
    },
    dataDidChange: Ember.observer('data.@each', function () {
      this.repaint();
    }),
    initSvg: function initSvg() {
      var container = this.$();
      var margin = this.get('margin');
      var svgWidth = container.width();
      var svgHeight = this.get('height') - margin.bottom - margin.top;
      this.setProperties({
        svgWidth: svgWidth,
        svgHeight: svgHeight,
        svg: (0, _d3Selection.select)(container[0]).append('svg').attr('width', '100%').attr('height', svgHeight).attr('viewBox', "0 0 ".concat(svgWidth, " ").concat(this.get('height'))).attr('preserveAspectRatio', 'none').append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"))
      });
    },
    paint: function paint() {
      this.paintAxisX();
      this.paintAxisY();
      this.paintPath();
      this.paintHorizontalLines();
      this.addMouseEvents();
    },
    repaint: function repaint() {},
    paintAxisX: function paintAxisX() {
      var _this$getProperties2 = this.getProperties('xAxis', 'svgHeight'),
          xAxis = _this$getProperties2.xAxis,
          svgHeight = _this$getProperties2.svgHeight;

      this.get('svg').append('g').attr('class', 'x axis').attr('transform', "translate(0, ".concat(svgHeight, ")")).call(xAxis);
    },
    paintAxisY: function paintAxisY() {
      var yAxis = this.get('yAxis');
      this.get('svg').append("g").attr("class", "y axis").attr('x', 20).call(yAxis);
    },
    paintPath: function paintPath() {
      this.get('svg').append("path").datum(this.get('data').toArray()).attr("class", "line").attr("d", this.get('calcLine'));
    },
    paintHorizontalLines: function paintHorizontalLines() {
      var _this$getProperties3 = this.getProperties('svg', 'svgWidth', 'yScale', 'horizontalLines'),
          svg = _this$getProperties3.svg,
          svgWidth = _this$getProperties3.svgWidth,
          yScale = _this$getProperties3.yScale,
          horizontalLines = _this$getProperties3.horizontalLines;

      horizontalLines.forEach(function (yValue) {
        svg.append("svg:line").attr("x1", 0).attr("x2", svgWidth).attr("y1", yScale(yValue)).attr("y2", yScale(yValue)).style("stroke", "#e5e5e5");
      });
    },
    addMouseEvents: function addMouseEvents() {
      var _this$getProperties4 = this.getProperties('svgWidth', 'svgHeight', 'xScale', 'yScale', 'data'),
          svgWidth = _this$getProperties4.svgWidth,
          svgHeight = _this$getProperties4.svgHeight,
          xScale = _this$getProperties4.xScale,
          yScale = _this$getProperties4.yScale,
          data = _this$getProperties4.data;

      var focus = this.createFocusElement();
      this.get('svg').append('rect');
      var a1 = this.get('svg').select('rect');
      a1.style('fill', 'none');
      a1.style('pointer-events', 'all');
      a1.attr('width', svgWidth);
      a1.attr('height', svgHeight);
      a1.on('mouseover', function () {
        focus.style("display", null);
      });
      a1.on('mouseout', function () {
        focus.style("display", "none");
      });
      a1.on('mousemove', function () {
        var x0 = xScale.invert((0, _d3Selection.mouse)(this)[0]);
        var i = bisectDate(data.toArray(), x0, 1);
        var d0 = data.objectAt(i - 1);
        var d1 = data.objectAt(i);
        var x, y;

        if (d0 && !d1) {
          x = d0.x;
          y = d0.y;
        } else if (!d0 && d1) {
          x = d1.x;
          y = d1.y;
        } else {
          if (x0 - d0.x > d1.x - x0) {
            x = d1.x;
            y = d1.y;
          } else {
            x = d0.x;
            y = d0.y;
          }
        }

        focus.attr("transform", "translate(" + xScale(x) + "," + yScale(y) + ")");
        focus.select("text").text(formatPercent(y));
      });
    },
    createFocusElement: function createFocusElement() {
      var focus = this.get('svg').append("g").attr("class", "focus").style("display", "none");
      focus.append("circle").attr("r", 4.5);
      focus.append("text").attr("x", 9).attr("dy", ".35em");
      this.set('focus', focus);
      return focus;
    }
  });

  _exports.default = _default;
});