define("backend/pods/components/hm-model/fieldset-profile/payment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'fieldset',
    buffers: Ember.computed.alias('model.buffers'),
    paymentIntervals: [Ember.Object.create({
      id: 'M',
      displayName: 'Monthly'
    }), Ember.Object.create({
      id: 'Q',
      displayName: 'Quarter'
    }), Ember.Object.create({
      id: 'Y',
      displayName: 'Yearly'
    })],
    paymentMethods: [Ember.Object.create({
      id: 'B',
      displayName: 'Billing'
    }), Ember.Object.create({
      id: 'D',
      displayName: 'Debit'
    })]
  });

  _exports.default = _default;
});