define("backend/templates/components/async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WjTPYjtm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"state\",\"actions\"],[[28,\"hash\",null,[[\"isIdle\",\"isBusy\",\"didSucceed\",\"didError\",\"result\",\"error\"],[[23,0,[\"isIdle\"]],[23,0,[\"isBusy\"]],[23,0,[\"didSucceed\"]],[23,0,[\"didError\"]],[23,0,[\"result\"]],[23,0,[\"error\"]]]]],[28,\"hash\",null,[[\"do\",\"reset\"],[[23,0,[\"_do\"]],[23,0,[\"reset\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/async.hbs"
    }
  });

  _exports.default = _default;
});