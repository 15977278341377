define("backend/prototypes/tables/headers/logs-server", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'date',
    name: 'Date',
    format: 'datetime'
  }, {
    attr: 'method',
    name: 'Method'
  }, {
    attr: 'resource',
    name: 'Resource'
  }, {
    attr: 'status',
    name: 'Status'
  }, {
    attr: 'ip',
    name: 'IP'
  }]);

  _exports.default = _default;
});