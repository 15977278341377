define("backend/adapters/study", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateStatus: function updateStatus(id, newStatus) {
      var url = this.urlForUpdateRecord(id, 'study') + "/status/" + newStatus;
      return this.ajax(url, 'PUT');
    }
  });

  _exports.default = _default;
});