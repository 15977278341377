define("backend/components/etw/module-style-example", ["exports", "ember-cli-tailwind/utils/classes-for-module-style"], function (_exports, _classesForModuleStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    etwTailwindStyleguide: Ember.inject.service(),
    // Passed in
    moduleStyle: null,
    classesForModuleStyle: Ember.computed('moduleStyle', function () {
      return (0, _classesForModuleStyle.default)(this.moduleStyle);
    }),
    actions: {
      selectModuleStyle: function selectModuleStyle() {
        this.etwTailwindStyleguide.set('selectedModuleStyle', this.moduleStyle);
      }
    }
  });

  _exports.default = _default;
});