define("backend/pods/movie-convert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Du9pJpG8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Movie Conversion\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"converter\",\"isPending\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"hm-converter/progress\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"hm-converter/new\",null,[[\"files\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/movie-convert/template.hbs"
    }
  });

  _exports.default = _default;
});