define("backend/pods/components/hm-model/fieldset-essentials/list-add/tasks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ao9JIRXf",
    "block": "{\"symbols\":[\"task\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-height-400\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table table-tasks table-task--inline\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"style\",\"width: 100%\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"hm-models/list/task/row-small\",null,[[\"task\",\"showDate\",\"showFullDate\",\"toggleAction\"],[[23,1,[]],true,true,[28,\"action\",[[23,0,[]],\"toggleTask\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[10,\"colspan\",\"4\"],[10,\"class\",\"text-center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"isPending\"]]],null,{\"statements\":[[0,\"              loading\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              No tasks available.\\n\"]],\"parameters\":[]}],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-essentials/list-add/tasks/template.hbs"
    }
  });

  _exports.default = _default;
});