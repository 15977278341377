define("backend/pods/components/hm-models/list/filter-activities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESOURCE_TYPES = [{
    id: 'client',
    displayName: 'Client/Lead'
  }, {
    id: 'distributor',
    displayName: 'Distributor'
  }, {
    id: 'licensor',
    displayName: 'Licensor'
  }, {
    id: 'tester',
    displayName: 'Tester'
  }, {
    id: 'device',
    displayName: 'Device'
  }, // { id: 'lead', displayName: 'Lead' },
  {
    id: 'movie',
    displayName: 'Movie'
  }];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    can: {
      selUser: true,
      selResource: true,
      selTypes: true
    },
    eventTypes: Ember.computed(function () {
      return this.get('store').peekAll('event-type');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('model.user')) {
        this.send('loadUsers');
      }

      this.set('resourceTypes', RESOURCE_TYPES.map(function (d) {
        return Ember.Object.create(d);
      }));
    },
    actions: {
      loadUsers: function loadUsers() {
        if (!this.get('users')) {
          this.set('users', this.get('store').findAll('user'));
        }
      }
    }
  });

  _exports.default = _default;
});