define("backend/utils/format-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  function escapeStr(v) {
    return "\"".concat(v, "\"");
  }

  function _default(v) {
    if (!v) {
      return '';
    }

    if (typeof v === 'string') {
      if (v.indexOf(';') > -1) {
        return escapeStr(v);
      }

      if (v.indexOf('.') > -1) {
        return "=".concat(escapeStr(v));
      }

      if (v.indexOf(',') > -1 && v.indexOf('"') === -1) {
        return escapeStr(v);
      }
    }

    if (isFloat(v)) {
      return "=".concat(escapeStr(v));
    }

    return v;
  }
});