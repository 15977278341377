define("backend/pods/sales-cockpit/jour-fixe-notes/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function deserialize(d) {
    var key = d.key.split(';');
    d.start_date = key[2] === 'null' ? null : key[2];
    d.end_date = key[3] === 'null' ? null : key[3];
    d.mobiledoc = JSON.parse(d.mobiledoc);
    return Ember.Object.create(d);
  }

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/notes";

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            resolve(data.map(function (d) {
              return deserialize(d);
            }));
          });
        });
      });
    }
  });

  _exports.default = _default;
});