define("backend/pods/components/hm-models/list/filter-clients/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    organization: Ember.computed.reads('model.organization'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('organization')) {
        this.send('loadOrganizations');
      }
    },
    actions: {
      setQueryParam: function setQueryParam(fieldAndAttr, option) {
        var _this = this;

        var _fieldAndAttr$split = fieldAndAttr.split(':'),
            _fieldAndAttr$split2 = _slicedToArray(_fieldAndAttr$split, 2),
            field = _fieldAndAttr$split2[0],
            _fieldAndAttr$split2$ = _fieldAndAttr$split2[1],
            attr = _fieldAndAttr$split2$ === void 0 ? 'id' : _fieldAndAttr$split2$;

        var value = !Ember.isNone(option) ? option.get(attr) : null;
        window.requestAnimationFrame(function () {
          return _this.get('model').set(field, value);
        });
      },
      loadOrganizations: function loadOrganizations() {
        if (!this.get('organizations')) {
          this.set('organizations', this.get('store').findAllOrganizations());
        }
      }
    }
  });

  _exports.default = _default;
});