define("backend/serializers/lead", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMBEDDED_ALWAYS = {
    embedded: 'always'
  };
  var EMBEDDED_NEVER = {
    serialize: false
  };
  var EMBEDDED_IDS = {
    deserialize: 'ids',
    serialize: 'ids'
  };

  var _default = _application.default.extend({
    attrs: {
      address: EMBEDDED_ALWAYS,
      departments: EMBEDDED_ALWAYS,
      sources: EMBEDDED_ALWAYS,
      contacts: EMBEDDED_NEVER,
      billings: EMBEDDED_NEVER,
      distributor: EMBEDDED_IDS,
      descendants: EMBEDDED_IDS
    },
    keywords: Ember.inject.service(),
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.address) {
        this._removeEmptyAddress(json);
      }

      return json;
    },
    _removeEmptyAddress: function _removeEmptyAddress(json) {
      var _json$address = json.address,
          address_1 = _json$address.address_1,
          zip = _json$address.zip,
          city = _json$address.city,
          country = _json$address.country;

      if (!address_1 && !zip && !city && !country) {
        json.address = null;
      }
    }
  });

  _exports.default = _default;
});