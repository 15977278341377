define("backend/services/expires", ["exports", "moment", "backend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isExpiredAfter(time) {
    return (0, _moment.default)(time).add(1, 'hour').isBefore(new Date());
  }

  function tokenExpired() {
    var lastRequest = window.localStorage.getItem('last_request');
    return lastRequest && isExpiredAfter(lastRequest);
  }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    isPolling: false,
    startPolling: function startPolling() {
      var _this = this;

      if (this.get('isPolling')) {
        return;
      }

      this.set('isPolling', true);
      Ember.run.later(this, function () {
        return _this.checkTokenExpired();
      }, 10000);
    },
    checkTokenExpired: function checkTokenExpired() {
      var _this2 = this;

      if (this.isTokenExpired()) {
        this.set('isPolling', false);
        location.reload();
      } else {
        Ember.run.later(this, function () {
          return _this2.checkTokenExpired();
        }, 60000);
      }
    },
    isTokenExpired: function isTokenExpired() {
      return tokenExpired();
    },
    currentVersion: null,
    displayNewVersionDialog: function displayNewVersionDialog(forceReload) {
      this.set('hasDialog', true);
      this.set('forceReload', forceReload);
    },
    startPollingNewVersion: function startPollingNewVersion() {
      if (_environment.default.environment === 'test') {
        return;
      }

      if (!this.get('hasDialog')) {
        this.set('pollingVersion', Ember.run.later(this, this.checkNewVersion, 5 * 60000));
      }
    },
    checkNewVersion: function checkNewVersion() {
      var _this3 = this;

      this.get('ajax').request('/assets/version.json?date=' + Date.now()).then(function (data) {
        if (!data) {
          return false;
        }

        if (!_this3.get('currentVersion')) {
          _this3.set('currentVersion', data.version);
        }

        if (data['display-dialog'] === true && _this3.get('currentVersion') !== data.version) {
          var timer = _this3.get('pollingVersion');

          if (timer) {
            Ember.run.cancel(timer);
          }

          _this3.set('currentVersion', data.version);

          _this3.displayNewVersionDialog(data['force-reload'] === true);
        }
      }).finally(function () {
        return _this3.startPollingNewVersion();
      });
    },
    cancelNewVersion: function cancelNewVersion() {
      var _this4 = this;

      this.set('hasDialog', false);

      if (this.get('forceReload')) {
        Ember.run.later(function () {
          return _this4.set('hasDialog', true);
        }, 5 * 60000);
      }

      this.startPollingNewVersion();
    }
  });

  _exports.default = _default;
});