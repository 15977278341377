define("backend/pods/components/hm-models/list/task/row-small/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['task-item'],
    classNameBindings: ['task.isChecked:task-item--checked']
  });

  _exports.default = _default;
});