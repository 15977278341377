define("backend/pods/device-owners/overview/controller", ["exports", "ember-data", "backend/access-control-list/overview-route"], function (_exports, _emberData, _overviewRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function createMarker(prefix, suffix) {
    return new google.maps.MarkerImage("/assets/hm_marker.png", new google.maps.Size(40, 40), new google.maps.Point(suffix, prefix));
  }

  function filterStatus(collection, filterBy) {
    return Ember.computed("".concat(collection, ".length"), function () {
      return this.get(collection).filter(function (item) {
        return !!item.get('departments').findBy("status.".concat(filterBy));
      }).map(function (owner) {
        if (owner.get('address.isCountryOnly')) {
          return owner;
        }

        var prefix = owner.get('departments').findBy("status.".concat(filterBy)).get('status.markerPrefix') || 0;
        var suffix = owner.get('priority.markerSuffix') || 0;
        return Ember.ObjectProxy.create({
          content: owner,
          markerIcon: createMarker(prefix, suffix)
        });
      });
    });
  }

  var _default = Ember.Controller.extend(_overviewRoute.default, {
    userSettings: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    keywords: Ember.inject.service(),
    queryParams: ['t'],
    t: 'hospital',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('userSettings').on('evtSalesType', this, function (t) {
        return _this.set('t', t);
      });
    },
    hideFilterBox: true,
    showOpenContacted: true,
    showInterestExpressed: true,
    showPreordered: true,
    showDemoDone: true,
    showOfferPhaseDone: true,
    showActiveClients: true,
    showInPrepClients: false,
    showDistributors: false,
    showLogisticCenters: false,
    distributors: Ember.computed.reads('model.distributors'),
    logisticCenters: Ember.computed(function () {
      var store = this.get('store');
      var adapter = store.adapterFor('application');
      return _emberData.default.PromiseArray.create({
        promise: adapter.findAll(store, {
          modelName: 'logistic-center'
        }).then(function (_ref) {
          var data = _ref.data;
          return data.map(function (obj) {
            obj.markerInfo = Ember.String.htmlSafe(['<p class="text-bold">', obj.display_name, '</p>', '<p>', obj.notes, '</p>'].join(''));
            obj.markerIcon = new google.maps.MarkerImage("/assets/hm_marker.png", new google.maps.Size(40, 40), new google.maps.Point(0, 5 * 40));
            return Ember.Object.create(obj);
          });
        })
      });
    }),
    filteredLeads: Ember.computed('model.leads.length', 'selectedSources.length', 'selectedDistributor', function () {
      var filter = this.getProperties('selectedSources', 'selectedDistributor');
      var leads = this.get('model.leads');

      if (!Ember.isEmpty(filter.selectedSources)) {
        var sources = filter.selectedSources.mapBy('id');
        leads = leads.filter(function (lead) {
          var leadSources = lead.get('sources').mapBy('id');
          return sources.any(function (source) {
            return leadSources.includes(source);
          });
        });
      }

      if (!Ember.isBlank(filter.selectedDistributor)) {
        leads = leads.filterBy('distributor.id', filter.selectedDistributor);
      }

      return leads;
    }),
    allClients: Ember.computed('model.activeClients.length', 'showInPrepClients', 'inPrepClients.length', function () {
      var all = this.get('model.activeClients').toArray();

      if (this.get('showInPrepClients') && this.get('inPrepClients.length') > 0) {
        all.pushObjects(this.get('inPrepClients').toArray());
      }

      return all;
    }),
    inPrepClients: Ember.computed(function () {
      return this.get('store').query('client', {
        page: {
          size: 10000
        },
        filter: 'in_preparation'
      });
    }),
    filteredClients: Ember.computed('allClients.length', 'selectedDistributor', function () {
      var filter = this.getProperties('selectedDistributor');
      var clients = this.get('allClients');

      if (!Ember.isBlank(filter.selectedDistributor)) {
        clients = clients.filterBy('distributor.id', filter.selectedDistributor);
      }

      return clients;
    }),
    clientsActive: filterStatus('allClients', 'isClientActive'),
    clientsInPrep: filterStatus('allClients', 'isClientInPreparation'),
    leadsOpen: filterStatus('filteredLeads', 'isOpen'),
    leadsContacted: filterStatus('filteredLeads', 'isContacted'),
    leadsInterestExpressed: filterStatus('filteredLeads', 'isInterestExpressed'),
    leadsPreordered: filterStatus('filteredLeads', 'isWon'),
    leadsDemoDone: filterStatus('filteredLeads', 'isDemoDone'),
    leadsOfferPhaseDone: filterStatus('filteredLeads', 'isOfferPhaseDone'),
    leadsOpenContacted: Ember.computed('leadsOpen.length', 'leadsContacted.length', function () {
      return this.get('leadsOpen.length') + this.get('leadsContacted.length');
    }),
    selectedSources: Ember.computed(function () {
      return [];
    }),
    existingSources: Ember.computed.mapBy('keywords.sources', 'displayName'),
    sortedSources: Ember.computed('existingSources', 'existingSources.length', function () {
      return this.get('existingSources').sort().map(function (s) {
        return Ember.Object.create({
          id: s,
          displayName: s
        });
      });
    }),
    selectedDistributor: null,
    markers: Ember.computed('filterLeads.length', 'leadsOpen.length', 'leadsContacted.length', 'leadsInterestExpressed.length', 'leadsPreordered.length', 'leadsDemoDone.length', 'leadsOfferPhaseDone.length', 'filteredClients.length', 'distributors.length', 'logisticCenters.length', 'showOpenContacted', 'showInterestExpressed', 'showPreordered', 'showDemoDone', 'showOfferPhaseDone', 'showActiveClients', 'showInPrepClients', 'showDistributors', 'showLogisticCenters', function () {
      var clients = this.filterClients();
      var leads = this.filterLeads();
      var distributors = this.filterDistributors();
      var logisticCenters = this.filterLogisticCenters();
      return {
        leads: leads,
        clients: clients,
        distributors: distributors,
        logisticCenters: logisticCenters
      };
    }),
    filterLeads: function filterLeads() {
      var filter = this.getProperties('showOpenContacted', 'showInterestExpressed', 'showPreordered', 'showDemoDone', 'showOfferPhaseDone');
      var activeLeads = [];

      if (filter.showOpenContacted === true) {
        activeLeads.pushObjects(this.get('leadsOpen'));
        activeLeads.pushObjects(this.get('leadsContacted'));
      }

      if (filter.showInterestExpressed === true) {
        activeLeads.pushObjects(this.get('leadsInterestExpressed'));
      }

      if (filter.showPreordered === true) {
        activeLeads.pushObjects(this.get('leadsPreordered'));
      }

      if (filter.showDemoDone === true) {
        activeLeads.pushObjects(this.get('leadsDemoDone'));
      }

      if (filter.showOfferPhaseDone === true) {
        activeLeads.pushObjects(this.get('leadsOfferPhaseDone'));
      }

      return activeLeads;
    },
    filterClients: function filterClients() {
      var filter = this.getProperties('showActiveClients', 'showInPrepClients');
      var activeClients = [];

      if (filter.showActiveClients === true) {
        activeClients.pushObjects(this.get('clientsActive'));
      }

      if (filter.showInPrepClients === true) {
        activeClients.pushObjects(this.get('clientsInPrep'));
      }

      return activeClients;
    },
    filterDistributors: function filterDistributors() {
      if (this.get('showDistributors') === false) {
        return [];
      }

      return this.get('distributors');
    },
    filterLogisticCenters: function filterLogisticCenters() {
      if (this.get('showLogisticCenters') === false) {
        return [];
      }

      return this.get('logisticCenters');
    },
    actions: {
      toggleFilterBox: function toggleFilterBox() {
        this.toggleProperty('hideFilterBox');
      },
      export: function _export() {
        var _this2 = this;

        var markers = this.get('markers');
        var exportMarkers = {
          leads: markers.leads.mapBy('id'),
          clients: markers.clients.mapBy('id'),
          distributors: markers.distributors.mapBy('id'),
          logistic_centers: this.get('showLogisticCenters')
        };
        var adapter = this.get('store').adapterFor('application');

        var promise = _emberData.default.PromiseObject.create({
          promise: adapter.getMapExport(exportMarkers).then(function (_ref2) {
            var file = _ref2.data.file;

            _this2.set('exportFile', file);
          })
        });

        this.set('exportPromise', promise);
      }
    }
  });

  _exports.default = _default;
});