define("backend/utils/cp/buffer-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.watchBufferedHasMany = watchBufferedHasMany;
  _exports.watchBufferChanges = watchBufferChanges;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function testEqualLen(a, b) {
    return a.get('length') === b.get('length');
  }

  function testEqualRecord(realHasMany, bufferHasMany) {
    return realHasMany.any(function (entry) {
      return !bufferHasMany.find(function (bufferEntry) {
        return bufferEntry.get('content') === entry;
      });
    });
  }

  function testBufferDirty(bufferHasMany) {
    return bufferHasMany.isAny('hasChanges');
  }

  function computeWatchHasMany(hasManyAttr) {
    var watchKeys = [];
    watchKeys.push("buffers.".concat(hasManyAttr, ".length"));
    watchKeys.push("".concat(hasManyAttr, ".length"));
    watchKeys.push("buffers.".concat(hasManyAttr, ".@each.hasChanges"));
    return watchKeys;
  }

  function watchBufferedHasMany(hasManyAttr) {
    var keys = computeWatchHasMany(hasManyAttr);
    return Ember.computed.apply(void 0, _toConsumableArray(keys).concat([function () {
      var bufferHasMany = this.get("buffers.".concat(hasManyAttr));

      if (!bufferHasMany) {
        return false;
      }

      var realHasMany = this.get(hasManyAttr);
      return !testEqualLen(realHasMany, bufferHasMany) || testEqualRecord(realHasMany, bufferHasMany) || testBufferDirty(bufferHasMany);
    }]));
  }

  function computeWatchKeys(belongsTo, hasMany) {
    var watchKeys = [];
    watchKeys.pushObjects(belongsTo.map(function (key) {
      return "buffers.".concat(key, ".hasChanges");
    }));
    watchKeys.pushObjects(hasMany.map(function (key) {
      return "hasBufferedChanges".concat(Ember.String.capitalize(key));
    }));
    return watchKeys;
  }

  function watchBufferChanges(belongsTo, hasMany) {
    belongsTo.pushObject('selected');
    var keys = computeWatchKeys(belongsTo, hasMany);
    return Ember.computed.apply(void 0, _toConsumableArray(keys).concat([function () {
      var _this = this;

      var buffers = this.get('buffers');

      if (!buffers) {
        return false;
      }

      var hasManyChanged = hasMany.some(function (key) {
        return _this.get("hasBufferedChanges".concat(Ember.String.capitalize(key)));
      });
      return hasManyChanged || belongsTo.some(function (key) {
        return _this.get("buffers.".concat(key, ".hasChanges"));
      });
    }]));
  }

  var _default = {
    watchBufferedHasMany: watchBufferedHasMany,
    watchBufferChanges: watchBufferChanges
  };
  _exports.default = _default;
});