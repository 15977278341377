define("backend/pods/components/hm-model/mixins/department-won-lost", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    keywords: Ember.inject.service(),
    reloadAfterSave: function reloadAfterSave(isWon) {
      this.clearData();

      if (isWon && this.get('model.ownerType') === 'lead') {
        this.get('router').transitionTo('client.index', this.get('model.id'));
      } else {
        this.get('model').reload();
      }
    },
    cancelOverlay: function cancelOverlay() {
      var department = this.get('lostDepartment');

      if (department && department.buffer) {
        if (department.buffer['reason']) {
          department.set('reason', department.get('content.reason'));
        }

        if (department.buffer['reasonAdditional']) {
          department.set('reasonAdditional', department.get('content.reasonAdditional'));
        }

        if (department.buffer['followupDate']) {
          department.set('followupDate', department.get('content.followupDate'));
        }
      }

      this.clearData();
    },
    clearData: function clearData() {
      this.setProperties({
        errorMessage: null,
        saveLostPromise: null,
        lostDepartment: null,
        showLostOverlay: false,
        wonDepartment: null,
        showWonOverlay: false,
        saveWonPromise: null,
        overlayData: Ember.Object.create()
      });
    },
    actions: {
      displayLostOverlay: function displayLostOverlay(lostDepartment) {
        this.clearData();
        this.setProperties({
          lostDepartment: lostDepartment,
          showLostOverlay: true
        });
      },
      displayWonOverlay: function displayWonOverlay(wonDepartment) {
        this.clearData();
        this.setProperties({
          wonDepartment: wonDepartment,
          showWonOverlay: true
        });
      },
      cancelOverlay: function cancelOverlay() {
        this.cancelOverlay();
      },
      saveLost: function saveLost() {
        var _this = this;

        var lostDepartment = this.get('lostDepartment');
        var overlayData = this.get('overlayData');
        var id = lostDepartment.get('id');

        if (!id || !overlayData) {
          throw Error('No id or data for department');
        }

        this.set('saveLostPromise', new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            var url = _environment.default.host + "/departments/".concat(id, "/lost");

            _this.get('ajax').request(url, {
              method: 'POST',
              data: {
                data: {
                  client: lostDepartment.get('lead.id') || lostDepartment.get('client.id'),
                  reason: overlayData.get('reason'),
                  reason_additional: overlayData.get('reasonAdditional'),
                  followup_date: overlayData.get('followupDate')
                }
              },
              contentType: 'application/json',
              dataType: 'json',
              headers: headers
            }).then(function () {
              _this.reloadAfterSave();

              resolve();
            }).catch(function (_ref) {
              var error = _ref.payload.error;

              _this.set('errorMessage', error);

              reject(error);
            });
          });
        }));
      },
      saveWon: function saveWon() {
        var _this2 = this;

        var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var wonDepartment = this.get('wonDepartment');
        var overlayData = this.get('overlayData');
        var id = wonDepartment.get('id');

        if (!id || !overlayData) {
          throw Error('No id or data for department');
        }

        this.set('saveWonPromise', new Ember.RSVP.Promise(function (resolve, reject) {
          _this2.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            var url = _environment.default.host + "/departments/".concat(id, "/won");

            _this2.get('ajax').request(url, {
              method: 'POST',
              data: {
                data: {
                  client: wonDepartment.get('lead.id') || wonDepartment.get('client.id'),
                  attachment: overlayData.get('attachment'),
                  force: force
                }
              },
              contentType: 'application/json',
              dataType: 'json',
              headers: headers
            }).then(function () {
              _this2.reloadAfterSave(true);

              resolve();
            }).catch(function (_ref2) {
              var error = _ref2.payload.error;

              _this2.set('errorMessage', error);

              reject(error);
            });
          });
        }));
      }
    }
  });

  _exports.default = _default;
});