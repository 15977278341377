define("backend/prototypes/tables/headers/orders", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Order'
    },
    columns: [{
      attr: 'id',
      name: '#',
      style: 'text-center text-nowrap',
      component: 'hm-models/list/orders/id-and-toggle'
    }, {
      attr: 'timestamp',
      name: 'Created At',
      format: 'date',
      style: 'text-center'
    }, {
      attr: 'user',
      name: 'Created By',
      style: 'text-nowrap',
      display: false
    }, {
      attr: 'recipient',
      name: 'Recipient',
      component: 'link-owner',
      style: 'text-nowrap'
    }, {
      attr: 'contact',
      name: 'Contact',
      style: 'text-nowrap'
    }, {
      attr: 'hardwareType',
      name: 'Device',
      style: 'text-nowrap'
    }, {
      attr: 'deliveryType',
      name: 'Delivery Type',
      style: 'text-center text-nowrap'
    }, {
      attr: 'deliveryDate',
      name: 'Delivery Date',
      component: 'hm-models/list/orders/date',
      style: 'text-center text-nowrap'
    }, {
      attr: 'status.id',
      name: 'Status',
      component: 'hm-models/list/orders/status',
      style: 'text-center'
    }, {
      attr: 'inTransitSinceDays',
      name: 'Days Since Transit',
      style: 'text-center'
    }, {
      attr: 'attachmentsAsCsl',
      name: 'Attachments',
      format: 'comma-seperated-list',
      style: 'text-nowrap',
      display: false
    }, {
      attr: 'documentsAsCsl',
      name: 'Documents',
      format: 'comma-seperated-list',
      style: 'text-nowrap',
      display: false
    }]
  }, {
    group: {
      name: 'Devices'
    },
    columns: [{
      attr: 'deviceAmount',
      name: 'Amount',
      style: 'text-center'
    }, {
      attr: 'deviceType',
      name: 'Purpose',
      style: 'text-center text-nowrap'
    }, {
      attr: 'contentPackages',
      name: 'Content',
      format: 'comma-seperated-list',
      style: 'text-nowrap',
      display: false
    }]
  }, {
    group: {
      name: 'Notes'
    },
    columns: [{
      attr: 'notes',
      name: 'Notes',
      format: 'log',
      display: false
    }]
  }, {
    group: {
      name: 'Sort'
    },
    columns: [{
      attr: 'sortByStatusAndDeliveryDate',
      name: 'Sort',
      display: true
    }]
  }]);

  _exports.default = _default;
});