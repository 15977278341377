define("backend/pods/investor/attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7D15nOkP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"attachments-component\",null,[[\"data\",\"page\",\"size\",\"tableColumns\",\"clientSort\",\"showContainer\"],[[24,[\"model\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],false]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/investor/attachments/template.hbs"
    }
  });

  _exports.default = _default;
});