define("backend/adapters/billing-statistic", ["exports", "backend/adapters/statistics-summary"], function (_exports, _statisticsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _statisticsSummary.default.extend({
    pathForType: function pathForType() {
      return 'statistics/billing';
    }
  });

  _exports.default = _default;
});