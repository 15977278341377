define("backend/utils/sales/jour-fixe", ["exports", "backend/utils/static-data/department-status", "backend/utils/static-data/event-type", "backend/utils/static-data/lead-type", "backend/utils/static-data/client-type", "moment"], function (_exports, _departmentStatus, _eventType, _leadType, _clientType, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareData;
  var STATUS_DEMO_DONE = 14;
  var STATUS_TRIAL_DONE_OFFER = [17];
  var DATE_FMT = 'DD.MM.YYYY';

  function serialize(_ref, adapter) {
    var ID = _ref.ID,
        display_name = _ref.display_name,
        name = _ref.name,
        status = _ref.status,
        is_testing = _ref.is_testing,
        demo_date = _ref.demo_date,
        trial_start_date = _ref.trial_start_date,
        trial_end_date = _ref.trial_end_date,
        created = _ref.created,
        is_client = _ref.is_client;
    var note, testingStartDate, testingEndDate, statistics;

    if (is_testing) {
      note = "".concat((0, _moment.default)(trial_start_date).format(DATE_FMT), " to ").concat(trial_end_date ? (0, _moment.default)(trial_end_date).format(DATE_FMT) : '?');
    } else if (demo_date) {
      note = demo_date;
    }

    if (is_testing && adapter.queryOverview) {
      var today = (0, _moment.default)().format('YYYY-MM-DD');
      statistics = adapter.queryOverview({
        'date[start]': (0, _moment.default)(created).format('YYYY-MM-DD'),
        'date[end]': today,
        ownerId: ID,
        ownerType: 'lead'
      });
    }

    return {
      id: ID,
      display_name: display_name,
      name: name,
      status: _departmentStatus.default.findBy('id', status).name,
      status_note: note,
      route: is_client ? 'client.index' : 'lead.index',
      ownerType: is_client ? 'client' : 'lead',
      testingStartDate: trial_start_date,
      testingEndDate: trial_end_date,
      statistics: statistics
    };
  }

  function serializeEvent(evt) {
    if (evt.type > 0) {
      evt.type_id = evt.type;
      evt.type = _eventType.default.findBy('id', evt.type).name;
    }

    if (evt.lead_type > 0) {
      var obj = _leadType.default.findBy('id', evt.lead_type);

      evt.lead_type = obj ? obj.name : evt.lead_type;
    }

    if (evt.client_type > 0) {
      var _obj = _clientType.default.findBy('id', evt.client_type);

      evt.client_type = _obj ? _obj.name : evt.client_Type;
    }

    if (evt.is_client === 1) {
      evt.route = 'client.index';
    } else if (evt.is_client === 0) {
      evt.route = 'lead.index';
    }

    if (evt.departments_status) {
      evt.departments_status = evt.departments_status.split(',').map(function (id) {
        var obj = _departmentStatus.default.findBy('id', parseInt(id, 10));

        if (obj) {
          return obj.name;
        }

        return id;
      }).join(',');
    }

    return Ember.Object.create(evt);
  }

  function prepareData(adapter, _ref2) {
    var _ref2$data = _ref2.data,
        leads = _ref2$data.leads,
        events = _ref2$data.events;
    return {
      statusDemoDone: leads.filter(function (_ref3) {
        var status = _ref3.status,
            demo_date = _ref3.demo_date;
        return STATUS_DEMO_DONE === status || !!demo_date;
      }).map(serialize),
      statusTesting: leads.filter(function (_ref4) {
        var is_testing = _ref4.is_testing;
        return !!is_testing;
      }).map(function (d) {
        return serialize(d, adapter);
      }),
      statusTrialDoneOffer: leads.filter(function (_ref5) {
        var status = _ref5.status,
            is_testing = _ref5.is_testing;
        return STATUS_TRIAL_DONE_OFFER.includes(status) || !!is_testing;
      }).map(serialize),
      activities: events.map(serializeEvent)
    };
  }
});