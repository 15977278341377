define("backend/helpers/tables/table-header", ["exports", "backend/helpers/formatters/date", "backend/helpers/formatters/datetime", "backend/helpers/formatters/datetime-ago", "backend/helpers/formatters/percent", "backend/helpers/formatters/language", "backend/helpers/formatters/trend", "backend/helpers/formatters/inline-label", "backend/helpers/formatters/comma-seperated-list", "backend/helpers/format-currency", "backend/helpers/format-duration", "backend/helpers/format-boolean", "backend/helpers/format-array", "backend/helpers/format-log", "backend/helpers/format-changelog"], function (_exports, _date, _datetime, _datetimeAgo, _percent, _language, _trend, _inlineLabel, _commaSeperatedList, _formatCurrency, _formatDuration, _formatBoolean, _formatArray, _formatLog, _formatChangelog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var FORMATTERS = {
    date: _date.default,
    datetime: _datetime.default,
    datetimeago: _datetimeAgo.default,
    boolean: _formatBoolean.formatBoolean,
    currency: _formatCurrency.formatCurrency,
    array: _formatArray.formatArray,
    percent: _percent.default,
    log: _formatLog.formatLog,
    duration: _formatDuration.formatDuration,
    trend: _trend.default,
    labels: _inlineLabel.default,
    changelog: _formatChangelog.formatChangelog,
    language: _language.default,
    'comma-seperated-list': _commaSeperatedList.default
  };

  function findFormatter(attr, format) {
    return format ? FORMATTERS[format] : FORMATTERS[attr];
  }

  function _default(tableHeaders) {
    return tableHeaders.map(function (_ref) {
      var attr = _ref.attr,
          attrs = _ref.attrs,
          sortAttr = _ref.sortAttr,
          name = _ref.name,
          component = _ref.component,
          format = _ref.format,
          style = _ref.style,
          isDelete = _ref.isDelete,
          isCreate = _ref.isCreate,
          canEdit = _ref.canEdit,
          noFormat = _ref.noFormat,
          title = _ref.title,
          modelName = _ref.modelName,
          evalLazy = _ref.evalLazy,
          _ref$display = _ref.display,
          display = _ref$display === void 0 ? true : _ref$display;

      if (!name) {
        name = Ember.String.capitalize(attr);
      }

      var formatter = !noFormat ? findFormatter(attr, format) : null;
      var isFormatter, isComponent, isValue;

      if (!isDelete && !isCreate) {
        if (formatter) {
          isFormatter = true;
        } else if (component) {
          isComponent = true;
        } else if (!evalLazy) {
          isValue = true;
        }
      }

      if (modelName && !sortAttr) {
        sortAttr = "".concat(modelName, ".").concat(attr);
      }

      return Ember.Object.create({
        isValue: isValue,
        isFormatter: isFormatter,
        isComponent: isComponent,
        attr: attr,
        sortAttr: sortAttr,
        attrs: attrs,
        name: name,
        component: component,
        formatter: formatter,
        display: display,
        style: style,
        title: title,
        canEdit: canEdit,
        isDelete: isDelete,
        isCreate: isCreate,
        evalLazy: evalLazy,
        modelName: modelName
      });
    });
  }
});