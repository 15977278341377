define("backend/templates/components/secure-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iETMh/M0",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[4,\"if\",[[24,[\"isDownloading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[22,\"loading-indicator\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"noFile\"]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"icon-\",[22,\"icon\"]]]],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[11,\"title\",[29,[[22,\"name\"]]]],[8],[1,[28,\"or\",[[23,1,[]],\"Download\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/secure-download.hbs"
    }
  });

  _exports.default = _default;
});