define("backend/pods/licensors/filter-movie-selecting/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    filter: ['licensor', 'movie_selecting']
  });

  _exports.default = _default;
});