define("backend/components/users-component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_NEW = '1';
  var STATUS_ACTIVE = '2';

  var _default = _routeComponent.default.extend({
    store: Ember.inject.service(),
    createModel: 'user',
    prefill: function prefill(entry) {
      if (entry.get('isNew')) {
        this._setStatusNew(entry);
      } else if (entry.get('status.id') === '1') {
        this._setStatusActive(entry);
      }
    },
    _setStatusNew: function _setStatusNew(entry) {
      entry.set('buffers.selected.status', this.get('store').peekRecord('user-status', STATUS_NEW));
    },
    _setStatusActive: function _setStatusActive(entry) {
      entry.set('buffers.selected.status', this.get('store').peekRecord('user-status', STATUS_ACTIVE));
    }
  });

  _exports.default = _default;
});