define("backend/components/offers-component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    createModel: 'offer',
    createPath: 'data.lead.offers',
    offers: Ember.computed.alias('data.offers')
  });

  _exports.default = _default;
});