define("backend/pods/lead/billings/controller", ["exports", "backend/prototypes/tables/headers/billings-waiting", "backend/prototypes/tables/headers/billings-unpaid", "backend/prototypes/tables/headers/billings-paid", "backend/prototypes/tables/headers/billings-cancelled"], function (_exports, _billingsWaiting, _billingsUnpaid, _billingsPaid, _billingsCancelled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function removeLeadColumn(tableColumns) {
    return tableColumns.filter(function (col) {
      var attr = col.get('attr');
      return attr !== 'client.displayName' && attr !== 'lead.displayName';
    });
  }

  var _default = Ember.Controller.extend({
    tableColumnsWaiting: removeLeadColumn(_billingsWaiting.default),
    tableColumnsPaid: removeLeadColumn(_billingsPaid.default),
    tableColumnsUnpaid: removeLeadColumn(_billingsUnpaid.default),
    tableColumnsCancelled: removeLeadColumn(_billingsCancelled.default),
    clientSort: {
      sortBy: 'number',
      order: 'desc'
    }
  });

  _exports.default = _default;
});