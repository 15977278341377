define("backend/pods/sales-cockpit/monthly-activities/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentMonth: Ember.computed(function () {
      return (0, _moment.default)().format('MMMM YYYY');
    }),
    currentMonthShort: Ember.computed(function () {
      return (0, _moment.default)().format('M.YY');
    })
  });

  _exports.default = _default;
});