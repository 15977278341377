define("backend/pods/distributor/leads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8LfeKB2",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"hm-models/create/leads\"],false],[0,\"\\n\"],[1,[28,\"hm-models/list/filter-leads\",null,[[\"model\",\"hideDistributorSelect\"],[[23,0,[]],true]]],false],[0,\"\\n\"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"data\",\"isLoading\",\"rowComponent\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"lead\",[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],[[\"filterBy\"],[\"status.isLeadStatus\"]]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/distributor/leads/template.hbs"
    }
  });

  _exports.default = _default;
});