define("backend/adapters/distributor", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    currentUser: Ember.inject.service(),
    findAll: function findAll() {
      if (this.get('currentUser.isAdmin')) {
        return this._super.apply(this, arguments);
      }

      return Ember.RSVP.resolve({
        data: []
      });
    }
  });

  _exports.default = _default;
});