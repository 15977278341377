define("backend/pods/leads/filter-active/route", ["exports", "backend/pods/mixins/route/paginate-and-filter", "backend/config/environment", "backend/utils/ajax-file", "moment"], function (_exports, _paginateAndFilter, _environment, _ajaxFile, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    session: Ember.inject.service(),
    queryParams: {
      distributor: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      },
      organization: {
        refreshModel: true
      },
      department: {
        refreshModel: true
      },
      leadType: {
        refreshModel: true
      },
      t: {
        refreshModel: true
      }
    },
    filter: ['lead', 'active', ['distributor', 'status', 'source', 'organization', 'department', 'leadType', 't:type']],
    createQuery: function createQuery() {
      var query = this._super.apply(this, arguments);

      query.exclude_territories = true;
      return query;
    },
    actions: {
      exportCSV: function exportCSV() {
        var _this = this;

        var filename = "acquisition-costs-".concat((0, _moment.default)().format('YYYY-MM-DD'), ".csv");
        this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          (0, _ajaxFile.default)(_environment.default.host + "/lead_export_acquisition_costs", headers, 'text/csv').then(function (href) {
            _this.controller.setProperties({
              href: href,
              filename: filename
            });

            Ember.run.scheduleOnce('afterRender', _this, function () {
              var link = Ember.$('#csv-export-link')[0];

              try {
                link.click();
              } catch (ex) {
                console.log(ex);
              }
            });
            Ember.run.later(_this, function () {
              var URL = window.URL || window.webkitURL;

              if (URL && typeof URL.revokeObjectURL === 'function') {
                URL.revokeObjectURL(href);
                this.controller.setProperties({
                  href: '#',
                  filename: null
                });
              }
            }, 1000);
          });
        });
      }
    }
  });

  _exports.default = _default;
});