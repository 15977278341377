define("backend/pods/components/hm-models/list/row-w-departments/name/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    countCurDepartments: Ember.computed('filterBy', function () {
      if (this.get('filterBy')) {
        return this.get('model').get('departments').filterBy(this.get('filterBy')).get('length');
      }

      return this.get('model').get('departments').get('length');
    }),
    countAllDepartments: Ember.computed.reads('model.departments.length'),
    countCurDescendants: Ember.computed('filterBy', function () {
      var _this = this;

      if (this.get('filterBy')) {
        return this.get('model').get('descendants').filter(function (client) {
          return client.get('departments').isAny(_this.get('filterBy'));
        }).get('length');
      }

      return this.get('model').get('descendants').get('length');
    }),
    countAllDescendants: Ember.computed.reads('model.descendants.length'),
    click: function click(evt) {
      if (this.get('model.hasMultipleDepartments') || this.get('model.isClientGroup')) {
        evt.stopPropagation();
        this.get('model').toggleProperty('uiToggleDeps');
      }
    }
  });

  _exports.default = _default;
});