define("backend/models/system-image", ["exports", "ember-data", "backend/models/buffered-model", "ember-cp-validations"], function (_exports, _emberData, _bufferedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _bufferedModel.default.extend({
    version: attr('number'),
    deviceModel: belongsTo('device-model', {
      async: false
    }),
    changelog: attr('string'),
    file: attr('string'),
    updatePercentage: attr('number'),
    debug: attr('boolean'),
    displayName: Ember.computed.reads('version'),
    validators: {
      version: (0, _emberCpValidations.validator)('presence', true),
      file: (0, _emberCpValidations.validator)('presence', true),
      updatePercentage: (0, _emberCpValidations.validator)('number', {
        gte: 0,
        lte: 100,
        allowString: true,
        allowBlank: true
      })
    }
  });

  _exports.default = _default;
});