define("backend/prototypes/tables/headers/support-cases", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Support Case'
    },
    columns: [{
      attr: 'id',
      name: '#'
    }, {
      attr: 'client',
      name: 'Client',
      component: 'link-owner2'
    }, {
      attr: 'device',
      name: 'Device',
      component: 'link-device',
      style: 'text-nowrap'
    }, {
      attr: 'notes',
      name: 'Exact Problem',
      format: 'log',
      style: 'min-w-200'
    }, {
      attr: 'replacementDevice',
      name: 'Replacement Device?',
      format: 'boolean'
    }, {
      attr: 'stk',
      name: 'STK?',
      format: 'boolean'
    }, {
      attr: 'billingTask',
      name: 'Billing Task?',
      component: 'hm-models/list/support-cases/billing-task',
      style: 'text-center'
    }, {
      attr: 'replacedDevice',
      name: 'Replaced Device',
      component: 'link-device',
      style: 'text-nowrap'
    }, {
      attr: 'createdAt',
      name: 'Created At',
      format: 'datetime',
      style: 'text-nowrap text-center'
    }, {
      modelName: 'user',
      attr: 'displayName',
      name: 'Created By',
      component: 'lazy-load',
      style: 'text-nowrap'
    }, {
      attr: 'status.id',
      name: 'Status',
      component: 'hm-models/list/support-cases/status',
      style: 'text-center'
    }]
  }, {
    group: {
      name: 'Analysis'
    },
    columns: [{
      attr: 'problemSource',
      name: 'Problem Source'
    }, {
      attr: 'problemDetails',
      name: 'Problem Details'
    }]
  }, {
    group: {
      name: 'Solution'
    },
    columns: [{
      attr: 'solution',
      name: 'Solution'
    }, {
      attr: 'workLocation.name',
      name: 'HQ/on site'
    }, {
      attr: 'workTimeFmt',
      name: 'Work Time',
      style: 'text-nowrap text-right'
    }, {
      attr: 'labourCost',
      name: 'Labour Cost',
      format: 'currency',
      style: 'text-nowrap text-right'
    }]
  }, {
    group: {
      name: 'Contact'
    },
    columns: [{
      attr: 'contactName',
      name: 'Name'
    }, {
      attr: 'contactPhone',
      name: 'Phone'
    }, {
      attr: 'contactEmail',
      name: 'Email'
    }]
  }]);

  _exports.default = _default;
});