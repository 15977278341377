define("backend/pods/client/contracts/controller", ["exports", "backend/prototypes/tables/headers/contracts-signed", "backend/prototypes/tables/headers/contracts-active", "backend/prototypes/tables/headers/contracts-cancelled"], function (_exports, _contractsSigned, _contractsActive, _contractsCancelled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'open'],
    page: 1,
    size: 100,
    tableColumnsSigned: _contractsSigned.default,
    tableColumnsActive: _contractsActive.default,
    tableColumnsCancelled: _contractsCancelled.default,
    clientSort: {
      sortBy: 'addedValue',
      order: 'desc'
    },
    contractTypes: Ember.computed(function () {
      return this.store.peekAll('contract-type');
    }),
    actions: {
      viewContract: function viewContract(contract) {
        var _this = this;

        this.setProperties({
          selected: null,
          viewContract: null
        });
        Ember.run.schedule('afterRender', function () {
          return _this.set('viewContract', contract);
        });
      },
      editContract: function editContract(contract) {
        var _this2 = this;

        this.setProperties({
          selected: null,
          viewContract: null
        });
        Ember.run.schedule('afterRender', function () {
          return _this2.set('selected', contract);
        });
      }
    }
  });

  _exports.default = _default;
});