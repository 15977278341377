define("backend/pods/client-features/movies/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    uploader: Ember.inject.service(),
    converter: Ember.inject.service(),
    actions: {
      newUpload: function newUpload() {
        this.set('forceNewUpload', true);
      },
      resume: function resume(file) {
        this.get('uploader').resume(this.get('model.inprogress'));
        this.get('uploader').resume(file);
      }
    }
  });

  _exports.default = _default;
});