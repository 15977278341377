define("backend/templates/partials/-nested-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "odqc9D95",
    "block": "{\"symbols\":[\"movie\",\"category\"],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"category\",\"categories\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[23,2,[\"name\"]],false],[9],[15,\"partials/nested-categories\",[2]],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"movie-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"category\",\"movies\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row movie-preview\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[1,[28,\"secure-image\",null,[[\"url\",\"fadeIn\"],[[23,1,[\"thumbnail\"]],true]]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-9\"],[8],[0,\"\\n          \"],[7,\"h2\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-4\"],[8],[1,[23,1,[\"formattedDuration\"]],false],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-8\"],[8],[1,[23,1,[\"category\",\"displayName\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[1,[23,1,[\"description\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/partials/-nested-categories.hbs"
    }
  });

  _exports.default = _default;
});