define("backend/pods/leads/filter-testing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2zZIgVaf",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[1,[28,\"page-header\",null,[[\"title\",\"additional\"],[\"Testing Leads\",[24,[\"tName\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-white\"],[8],[0,\"\\n    \"],[1,[28,\"hm-models/list/filter-leads-testing\",null,[[\"model\"],[[23,0,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"models\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"hm-models/list/card-lead\",null,[[\"model\",\"statistics\",\"devices\",\"ownerType\"],[[23,1,[\"owner\"]],[23,1,[\"statistics\"]],[24,[\"devices\"]],\"lead\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"panel\"],[10,\"style\",\"padding: 15px;\"],[8],[0,\"\\n      No leads found\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/leads/filter-testing/template.hbs"
    }
  });

  _exports.default = _default;
});