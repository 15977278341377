define("backend/pods/logs/error/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'type', 'device', 'start', 'end'],
    type: 'errors',
    page: 1,
    size: 100,
    actions: {
      loadDevices: function loadDevices() {
        this.set('devices', this.get('store').query('device', {
          page: {
            size: 10000
          }
        }));
      }
    }
  });

  _exports.default = _default;
});