define("backend/utils/static-data/support-case-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: "Open",
    label: "label-default"
  }, {
    id: 2,
    name: "Incoming",
    label: "label-info",
    is_in_transit: true
  }, {
    id: 3,
    name: "In Processing",
    label: "label-primary"
  }, {
    id: 4,
    name: "Back to Client",
    label: "label-primary",
    is_in_return_transit: true
  }, {
    id: 5,
    name: "Solved",
    label: "label-success",
    is_selectable: true
  }];
  _exports.default = _default;
});