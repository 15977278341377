define("backend/adapters/event", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      var urlParts = ['client', 'distributor', 'licensor', 'lead', 'device', 'movie', 'tester', 'study', 'investor', 'sponsoredClient'];
      return this.urlForQueryEx(query, modelName, urlParts);
    }
  });

  _exports.default = _default;
});