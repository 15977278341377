define("backend/pods/sales/analysis/route", ["exports", "moment", "backend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      funnel_start: {
        refreshModel: true
      },
      funnel_end: {
        refreshModel: true
      },
      funnel_source: {
        refreshModel: true
      },
      funnel_distributor: {
        refreshModel: true
      },
      activitiy_start: {
        refreshModel: true
      },
      activitiy_end: {
        refreshModel: true
      },
      activity_distributor: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      return this.get('currentUser.model');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        distributors: this.get('store').query('distributor', {
          filter: 'active'
        }),
        won: this.loadWon(),
        funnel: this.loadFunnel(params),
        lost: this.loadLost(params),
        active: this.loadActive(params),
        activities: this.loadActivities(params)
      });
    },
    loadWon: function loadWon() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;

          _this.get('ajax').request(_environment.default.host + "/sales/analysis_won", {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            var currentYear = (0, _moment.default)().year();
            var lastYear = currentYear - 1;
            resolve(data.reduce(function (tables, _ref2) {
              var won_date = _ref2.won_date,
                  creation_date = _ref2.creation_date,
                  devices = _ref2.devices,
                  mrr = _ref2.mrr,
                  country = _ref2.country,
                  distributor = _ref2.distributor,
                  distributor_id = _ref2.distributor_id;
              var effectiveWon = won_date || creation_date;

              if (!effectiveWon) {
                return tables;
              }

              var effectiveYear = Number(effectiveWon.slice(0, 4));

              switch (effectiveYear) {
                case lastYear:
                  tables.total.lastYear.push({
                    effectiveWon: effectiveWon,
                    devices: Number(devices),
                    mrr: mrr,
                    distributor: distributor,
                    distributor_id: distributor_id,
                    country: country
                  });
                  break;

                case currentYear:
                  var curMonth = Number(effectiveWon.slice(5, 7));
                  var tertiary = curMonth < 5 ? '1' : curMonth < 9 ? '2' : '3';
                  tables.total["currentT".concat(tertiary)].push({
                    effectiveWon: effectiveWon,
                    devices: Number(devices),
                    mrr: mrr,
                    distributor: distributor,
                    distributor_id: distributor_id,
                    country: country
                  });
                  tables.total.currentYear.push({
                    effectiveWon: effectiveWon,
                    devices: Number(devices),
                    mrr: mrr,
                    distributor: distributor,
                    distributor_id: distributor_id,
                    country: country
                  });
                  break;
              }

              tables.total.all.push({
                effectiveWon: effectiveWon,
                devices: Number(devices),
                mrr: mrr,
                distributor: distributor,
                distributor_id: distributor_id,
                country: country
              });
              return tables;
            }, {
              total: {
                lastYear: [],
                currentYear: [],
                currentT1: [],
                currentT2: [],
                currentT3: [],
                all: []
              }
            }));
          });
        });
      });
    },
    loadLost: function loadLost(_ref3) {
      var _this2 = this;

      var _ref3$funnel_start = _ref3.funnel_start,
          funnel_start = _ref3$funnel_start === void 0 ? null : _ref3$funnel_start,
          _ref3$funnel_end = _ref3.funnel_end,
          funnel_end = _ref3$funnel_end === void 0 ? null : _ref3$funnel_end,
          _ref3$funnel_source = _ref3.funnel_source,
          funnel_source = _ref3$funnel_source === void 0 ? null : _ref3$funnel_source,
          _ref3$funnel_distribu = _ref3.funnel_distributor,
          funnel_distributor = _ref3$funnel_distribu === void 0 ? null : _ref3$funnel_distribu;
      return new Ember.RSVP.Promise(function (resolve) {
        _this2.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var qp = '';

          if (funnel_start || funnel_end || funnel_source || funnel_distributor) {
            qp = '?';

            if (funnel_start) {
              qp += "&start=".concat(funnel_start);
            }

            if (funnel_end) {
              qp += "&end=".concat(funnel_end);
            }

            if (funnel_source) {
              qp += "&source=".concat(funnel_source);
            }

            if (funnel_distributor) {
              qp += "&distributor=".concat(funnel_distributor);
            }
          }

          _this2.get('ajax').request(_environment.default.host + "/sales/analysis_lost".concat(qp), {
            headers: headers
          }).then(function (_ref4) {
            var data = _ref4.data;
            return resolve(data);
          });
        });
      });
    },
    loadActive: function loadActive(_ref5) {
      var _this3 = this;

      var _ref5$funnel_start = _ref5.funnel_start,
          funnel_start = _ref5$funnel_start === void 0 ? null : _ref5$funnel_start,
          _ref5$funnel_end = _ref5.funnel_end,
          funnel_end = _ref5$funnel_end === void 0 ? null : _ref5$funnel_end,
          _ref5$funnel_source = _ref5.funnel_source,
          funnel_source = _ref5$funnel_source === void 0 ? null : _ref5$funnel_source,
          _ref5$funnel_distribu = _ref5.funnel_distributor,
          funnel_distributor = _ref5$funnel_distribu === void 0 ? null : _ref5$funnel_distribu;
      return new Ember.RSVP.Promise(function (resolve) {
        _this3.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var qp = '';

          if (funnel_start || funnel_end || funnel_source || funnel_distributor) {
            qp = '?';

            if (funnel_start) {
              qp += "&start=".concat(funnel_start);
            }

            if (funnel_end) {
              qp += "&end=".concat(funnel_end);
            }

            if (funnel_source) {
              qp += "&source=".concat(funnel_source);
            }

            if (funnel_distributor) {
              qp += "&distributor=".concat(funnel_distributor);
            }
          }

          _this3.get('ajax').request(_environment.default.host + "/sales/analysis_active".concat(qp), {
            headers: headers
          }).then(function (_ref6) {
            var data = _ref6.data;
            return resolve(data);
          });
        });
      });
    },
    loadFunnel: function loadFunnel(_ref7) {
      var _this4 = this;

      var _ref7$funnel_start = _ref7.funnel_start,
          funnel_start = _ref7$funnel_start === void 0 ? null : _ref7$funnel_start,
          _ref7$funnel_end = _ref7.funnel_end,
          funnel_end = _ref7$funnel_end === void 0 ? null : _ref7$funnel_end,
          _ref7$funnel_source = _ref7.funnel_source,
          funnel_source = _ref7$funnel_source === void 0 ? null : _ref7$funnel_source,
          _ref7$funnel_distribu = _ref7.funnel_distributor,
          funnel_distributor = _ref7$funnel_distribu === void 0 ? null : _ref7$funnel_distribu;
      return new Ember.RSVP.Promise(function (resolve) {
        _this4.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var qp = '';

          if (funnel_start || funnel_end || funnel_source || funnel_distributor) {
            qp = '?';

            if (funnel_start) {
              qp += "&start=".concat(funnel_start);
            }

            if (funnel_end) {
              qp += "&end=".concat(funnel_end);
            }

            if (funnel_source) {
              qp += "&source=".concat(funnel_source);
            }

            if (funnel_distributor) {
              qp += "&distributor=".concat(funnel_distributor);
            }
          }

          _this4.get('ajax').request(_environment.default.host + "/sales/analysis_funnel".concat(qp), {
            headers: headers
          }).then(function (_ref8) {
            var data = _ref8.data;
            resolve(data);
          });
        });
      });
    },
    loadActivities: function loadActivities(_ref9) {
      var _this5 = this;

      var _ref9$activitiy_start = _ref9.activitiy_start,
          activitiy_start = _ref9$activitiy_start === void 0 ? null : _ref9$activitiy_start,
          _ref9$activitiy_end = _ref9.activitiy_end,
          activitiy_end = _ref9$activitiy_end === void 0 ? null : _ref9$activitiy_end,
          _ref9$activity_distri = _ref9.activity_distributor,
          activity_distributor = _ref9$activity_distri === void 0 ? null : _ref9$activity_distri;
      return new Ember.RSVP.Promise(function (resolve) {
        _this5.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var qp = '';

          if (activitiy_start || activitiy_end || activity_distributor) {
            qp = '?';

            if (activitiy_start) {
              qp += "&start=".concat(activitiy_start);
            }

            if (activitiy_end) {
              qp += "&end=".concat(activitiy_end);
            }

            if (activity_distributor) {
              qp += "&distributor=".concat(activity_distributor);
            }
          }

          _this5.get('ajax').request(_environment.default.host + "/sales/analysis_activities".concat(qp), {
            headers: headers
          }).then(function (_ref10) {
            var data = _ref10.data;
            resolve(data);
          });
        });
      });
    },
    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});