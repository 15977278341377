define("backend/pods/backoffice/contracts/route", ["exports", "backend/config/environment", "backend/utils/ajax-file", "moment"], function (_exports, _environment, _ajaxFile, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      status: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.controller) {
        return this.get('store').query('client', {
          page: {
            size: 10000
          }
        });
      }
    },
    model: function model(params) {
      if (params.status) {
        return this.get('store').query('contract', {
          status: params.status
        });
      }

      return this.get('store').findAll('contract');
    },
    actions: {
      exportCSV: function exportCSV(status) {
        var _this = this;

        if (!status) {
          status = 'all';
        }

        var filename = "contracts-".concat(status, "-").concat((0, _moment.default)().format('YYYY-MM-DD'), ".csv");
        this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          (0, _ajaxFile.default)(_environment.default.host + "/contracts/export/".concat(status), headers, 'text/csv').then(function (href) {
            _this.controller.setProperties({
              href: href,
              filename: filename
            });

            Ember.run.scheduleOnce('afterRender', _this, function () {
              var link = Ember.$('#csv-export-link')[0];

              try {
                link.click();
              } catch (ex) {
                console.log(ex);
              }
            });
            Ember.run.later(_this, function () {
              var URL = window.URL || window.webkitURL;

              if (URL && typeof URL.revokeObjectURL === 'function') {
                URL.revokeObjectURL(href);
                this.controller.setProperties({
                  href: '#',
                  filename: null
                });
              }
            }, 1000);
          });
        });
      }
    }
  });

  _exports.default = _default;
});