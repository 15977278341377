define("backend/pods/billings/tasks/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TaskDay = Ember.Object.extend({
    hideMore: true,
    sortBy: null
  });

  var _default = Ember.Controller.extend({
    showChecked: false,
    hasNoUncheckedTasks: Ember.computed('model.@each.isChecked', function () {
      return !this.get('model').isAny('isChecked', false);
    }),
    showTaskList: Ember.computed('hasNoUncheckedTasks', 'showChecked', function () {
      return !this.get('hasNoUncheckedTasks') || this.get('showChecked');
    }),
    showOverdueHeadline: Ember.computed('showChecked', 'model.@each.isChecked', function () {
      if (this.get('showChecked')) {
        return true;
      }

      return this.get('model').any(function (task) {
        return task.get('isOverdue') && !task.get('isChecked');
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var today = (0, _moment.default)();
      var days = [today, (0, _moment.default)().add(1, 'day'), (0, _moment.default)().add(2, 'day'), (0, _moment.default)().add(3, 'day'), (0, _moment.default)().add(4, 'day'), (0, _moment.default)().add(5, 'day'), (0, _moment.default)().add(6, 'day'), (0, _moment.default)().add(7, 'day')];
      this.set('nextSevenDays', [TaskDay.create({
        filterBy: 'isOverdue',
        sortBy: 'dueDate',
        showDate: true
      }), TaskDay.create({
        title: 'Today',
        small: days[0].format('ddd D MMM'),
        filterBy: 'isToday'
      }), TaskDay.create({
        title: 'Tomorrow',
        small: days[1].format('ddd D MMM'),
        filterBy: 'isTomorrow'
      }), TaskDay.create({
        title: days[2].format('dddd'),
        small: days[2].format('D MMM'),
        filterBy: 'isIn2Days'
      }), TaskDay.create({
        title: days[3].format('dddd'),
        small: days[3].format('D MMM'),
        filterBy: 'isIn3Days'
      }), TaskDay.create({
        title: days[4].format('dddd'),
        small: days[4].format('D MMM'),
        filterBy: 'isIn4Days'
      }), TaskDay.create({
        title: days[5].format('dddd'),
        small: days[5].format('D MMM'),
        filterBy: 'isIn5Days'
      }), TaskDay.create({
        title: days[6].format('dddd'),
        small: days[6].format('D MMM'),
        filterBy: 'isIn6Days'
      }), TaskDay.create({
        title: days[7].format('dddd'),
        small: days[7].format('D MMM'),
        filterBy: 'isIn7Days'
      })]);
    }
  });

  _exports.default = _default;
});