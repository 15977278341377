define("backend/mixins/redirect-find-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Overwrite adapter's find method to call findAll instead. The serializer
    does the rest to extract the specific record requested by id.
  
    @module RedirectFindMixin
   */
  var _default = Ember.Mixin.create({
    find: function find(store, type, id) {
      if (!Ember.isEmpty(id)) {
        return this.findAll(store, type);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});