define("backend/pods/components/hm-converter/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mCG5y0Fz",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"div\",true],[10,\"style\",\"padding-left:20px;\"],[8],[0,\"\\n  \"],[4,\"unless\",[[24,[\"inline\"]]],null,{\"statements\":[[7,\"h4\",true],[8],[7,\"b\",true],[8],[0,\"New Conversion\"],[9],[9]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"hm-form/wrapper\",null,[[\"model\",\"opts\",\"class\"],[[23,0,[]],\"fullsize\",\"form-horizontal\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n        \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"select-value\"]],\"expected `f.select-value` to be a contextual component but found a string. Did you mean `(component f.select-value)`? ('backend/pods/components/hm-converter/new/template.hbs' @ L6:C10) \"],null]],[[\"field\",\"data\",\"placeholder\"],[\"file\",[24,[\"files\"]],\"No file selected\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-1\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"convert\"]],[8],[4,\"if\",[[24,[\"inline\"]]],null,{\"statements\":[[0,\"Konvertieren\"]],\"parameters\":[]},{\"statements\":[[0,\"Convert\"]],\"parameters\":[]}],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-converter/new/template.hbs"
    }
  });

  _exports.default = _default;
});