define("backend/models/simple/device-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = Ember.Object.extend({
    label: null,
    value: null,
    hasLabel: Ember.computed.notEmpty('label'),
    hasValue: Ember.computed.notEmpty('value'),
    isNotEmpty: Ember.computed.or('hasLabel', 'hasValue')
  });
  Model.reopenClass({
    modelName: 'component'
  });
  var _default = Model;
  _exports.default = _default;
});