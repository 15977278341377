define("backend/pods/components/hm-form/mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PH1dToQj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"mask\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"one-way-input-mask\",[[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null]],[[\"mask\",\"class\",\"placeholder\",\"update\"],[[24,[\"mask\"]],[24,[\"inputClass\"]],[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"setValue\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/mask/template.hbs"
    }
  });

  _exports.default = _default;
});