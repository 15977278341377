define("backend/components/left-sidebar", ["exports", "ember-perfect-scrollbar"], function (_exports, _emberPerfectScrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberPerfectScrollbar.PerfectScrollbarMixin, {
    perfectScrollbarOptions: {
      suppressScrollX: true
    },
    classNames: 'sidebar-content'
  });

  _exports.default = _default;
});