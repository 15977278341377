define("backend/pods/components/hm-form/control2/component", ["exports", "backend/helpers/translate"], function (_exports, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DEFAULT_OPTIONS = {
    layout: {
      inputCol: 'col-lg-9',
      labelCol: 'col-lg-3',
      displayLabel: true // inputAlign: 'left'
      // inputOnly: false

    } // control: {
    //   disabled: false
    // }

  };

  var _default = Ember.Component.extend({
    classNameBindings: ['displayLabel:form-group', 'showError:validation-error'],
    init: function init() {
      this.parseOptions();

      this._super.apply(this, arguments);

      this.initValidations();
      this.initSort();
    },
    allowClear: true,
    accept: 'application/pdf',
    // -------------------------- OPTIONS ----------------------------------------

    /*
     * Examples: 'lg-3-9;center'
     *           'fullsize;right-left'
     *           'fullsize-placeholder'
     */
    opts: null,
    parseOptions: function parseOptions() {
      var opts = this.get('opts');
      var _DEFAULT_OPTIONS$layo = DEFAULT_OPTIONS.layout,
          inputCol = _DEFAULT_OPTIONS$layo.inputCol,
          labelCol = _DEFAULT_OPTIONS$layo.labelCol,
          displayLabel = _DEFAULT_OPTIONS$layo.displayLabel,
          _DEFAULT_OPTIONS$layo2 = _DEFAULT_OPTIONS$layo.labelClass,
          labelClass = _DEFAULT_OPTIONS$layo2 === void 0 ? [] : _DEFAULT_OPTIONS$layo2,
          _DEFAULT_OPTIONS$layo3 = _DEFAULT_OPTIONS$layo.inputClass,
          inputClass = _DEFAULT_OPTIONS$layo3 === void 0 ? ['form-control'] : _DEFAULT_OPTIONS$layo3;

      if (opts) {
        var _opts$split = opts.split(';'),
            _opts$split2 = _slicedToArray(_opts$split, 3),
            layout = _opts$split2[0],
            align = _opts$split2[1],
            style = _opts$split2[2];

        if (layout === 'fullsize') {
          displayLabel = false;
        } else if (layout === 'fullsize-placeholder') {
          displayLabel = false;

          if (!this.get('placeholder')) {
            Ember.defineProperty(this, 'placeholder', Ember.computed('field', function () {
              return (0, _translate.translate)(this.get('field'));
            }));
          }
        } else if (layout.includes('-')) {
          var _layout$split = layout.split('-'),
              _layout$split2 = _slicedToArray(_layout$split, 3),
              colSize = _layout$split2[0],
              labelWidth = _layout$split2[1],
              inputWidth = _layout$split2[2];

          labelCol = "col-".concat(colSize, "-").concat(labelWidth);
          inputCol = "col-".concat(colSize, "-").concat(inputWidth);
        }

        if (typeof align === 'string') {
          if (align.includes('-')) {
            var _align$split = align.split('-'),
                _align$split2 = _slicedToArray(_align$split, 2),
                labelAlign = _align$split2[0],
                inputAlign = _align$split2[1];

            labelClass.push("text-".concat(labelAlign));
            inputClass.push("text-".concat(inputAlign));
          } else {
            inputClass.push("text-".concat(align));
          }
        }

        if (typeof style === 'string') {
          inputClass = [style];
        }
      }

      this.setProperties({
        inputCol: inputCol,
        labelCol: labelCol,
        displayLabel: displayLabel,
        labelClass: labelClass.join(' '),
        inputClass: inputClass.join(' ')
      });
    },
    // ------------------------ VALIDATIONS --------------------------------------
    initValidations: function initValidations() {
      var validatePath = this.get('field');

      if (validatePath) {
        Ember.defineProperty(this, 'validation', Ember.computed.reads("model.validations.attrs.".concat(validatePath)));
        Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(validatePath)));
      }
    },
    didValidate: Ember.computed.reads('model.didValidate'),
    isInvalid: Ember.computed.reads('validation.isInvalid'),
    isDirty: Ember.computed('value', function () {
      var value = this.get('value');

      if (value instanceof Ember.ObjectProxy || value instanceof Ember.ArrayProxy) {
        return !Ember.isNone(value.get('content'));
      }

      return !Ember.isNone(value);
    }),
    showError: Ember.computed('isDirty', 'isInvalid', 'didValidate', function () {
      return this.get('didValidate') && this.get('isInvalid');
    }),
    // -------------------------- SORTING ----------------------------------------
    initSort: function initSort() {
      var defaultSort = this.get('sort') || 'displayName';
      this.setProperties({
        sortBy: ["".concat(defaultSort, ":asc")]
      });
    },
    sortedData: Ember.computed.sort('data', 'sortBy') // ---------------------------------------------------------------------------

  });

  _exports.default = _default;
});