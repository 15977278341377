define("backend/templates/components/link-owner2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dfzfrwRf",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",false],[3,\"on\",[\"click\",[23,0,[\"preventPropagation\"]]]],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"href\",[29,[[23,1,[\"ownerMeta\",\"self\"]]]]],[10,\"target\",\"_blank\"],[8],[1,[23,1,[\"ownerMeta\",\"name\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/link-owner2.hbs"
    }
  });

  _exports.default = _default;
});