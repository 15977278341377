define("backend/pods/client/events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "syJx6ml8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"manage-owner-events\",null,[[\"data\",\"page\",\"size\",\"tableColumns\",\"clientSort\"],[[24,[\"model\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/client/events/template.hbs"
    }
  });

  _exports.default = _default;
});