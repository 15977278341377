define("backend/pods/components/hm-uploader/table-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3DfEI01/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dropdown\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"dropdown-toggle\"],[10,\"data-toggle\",\"dropdown\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon-cog color-subtle\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu dropdown-menu-left\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"title\",\"Continue a former upload by selecting the exact same file\"],[3,\"action\",[[23,0,[]],\"resume\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon-play4 text-success\"],[8],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"text-semibold\"],[8],[0,\"Resume\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"title\",\"Remove from list\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon-cancel-circle2 text-default\"],[8],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"text-semibold\"],[8],[0,\"Cancel\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-uploader/table-actions/template.hbs"
    }
  });

  _exports.default = _default;
});