define("backend/prototypes/tables/headers/studies", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Study'
    },
    columns: [{
      attr: 'id',
      name: 'Id'
    }, {
      attr: 'title',
      name: 'Title'
    }, {
      attr: 'type.displayName',
      name: 'Type',
      style: 'text-nowrap'
    }, {
      attr: 'status.displayName',
      name: 'Status',
      style: 'text-nowrap'
    }, {
      attr: 'outcome.displayName',
      name: 'Outcome',
      style: 'text-nowrap'
    }, {
      attr: 'results',
      name: 'Results'
    }, {
      attr: 'lastContact',
      name: 'Last Contact',
      format: 'date'
    }, {
      attr: 'nextStep',
      name: 'Next Step'
    }, {
      attr: 'notes',
      name: 'Notes'
    }]
  }, {
    group: {
      name: 'Contact'
    },
    columns: [{
      attr: 'contact.displayName',
      name: 'Name'
    }, {
      attr: 'contact.phone',
      name: 'Phone',
      style: 'text-nowrap'
    }, {
      attr: 'contact.email',
      name: 'E-Mail',
      style: 'text-nowrap'
    }]
  }]);

  _exports.default = _default;
});