define("backend/prototypes/tables/headers/statistics-details-client", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Gerät'
    },
    columns: [{
      attr: 'serial_number',
      name: 'Seriennummer'
    }]
  }, {
    group: {
      name: 'Film'
    },
    columns: [{
      attr: 'movie_title',
      name: 'Film'
    }, {
      attr: 'movie_duration',
      name: 'Filmzeit gesamt'
    }, {
      attr: 'movie_viewed',
      name: 'Filmzeit gesehen'
    }]
  }, {
    group: {
      name: 'Session'
    },
    columns: [{
      attr: 'start_timestamp',
      name: 'Start'
    }, {
      attr: 'stop_timestamp',
      name: 'Ende'
    }, {
      attr: 'total_duration',
      name: 'Dauer'
    }]
  }, {
    group: {
      name: 'Kategorie & Pakete'
    },
    columns: [{
      attr: 'movie_category',
      name: 'Kategorie'
    }, {
      attr: 'movie_package',
      name: 'Paket'
    }]
  }]);

  _exports.default = _default;
});