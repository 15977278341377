define("backend/pods/components/hm-form/file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    attributeBindings: ['enctype'],
    enctype: 'multipart/form-data',
    autoUpload: true,
    btnClass: 'bg-info',
    accept: 'application/pdf',
    label: 'File',
    inputCol: 'col-lg-9',
    labelCol: 'col-lg-3',
    didInsertElement: function didInsertElement() {
      this.registerHandler();
    },
    registerHandler: function registerHandler() {
      var handler = this.submit.bind(this);
      this.set('filename', null);
      this.set('handler', handler);
      this.$().on('submit', handler);
    },
    willDestroyElement: function willDestroyElement() {
      this.clearHandler();
    },
    clearHandler: function clearHandler() {
      this.$().off('submit', this.get('handler'));
    },
    submit: function submit(jqEvent) {
      var _this = this;

      if (jqEvent) {
        jqEvent.preventDefault();
      }

      if (this.get('createUploadUrl')) {
        console.log("submit createUploadUrl:" + this.get('createUploadUrl'));
        this.get('createUploadUrl')().then(function (_ref) {
          var upload_url = _ref.data.upload_url;
          console.log("uploadFile upload_url:" + upload_url);

          _this.uploadFile(upload_url, _this.handleSuccess.bind(_this), _this.handleError.bind(_this));
        });
      } else {
        console.log("submit url:" + this.get('url'));
        console.log("submit filename:" + this.get('filename'));
        this.uploadFile(this.get('url'), this.handleSuccess.bind(this), this.handleError.bind(this));
      }
    },
    uploadFile: function uploadFile(url, success, error) {
      this.set('isUploading', true);
      console.log(url);
      console.log(this.$()[0]);
      Ember.$.ajax({
        url: url,
        type: 'POST',
        //headers: { 'x-goog-resumable': 'start' },
        data: new FormData(this.$()[0]),
        success: success,
        error: error,
        cache: false,
        contentType: false,
        processData: false
      });
    },
    handleSuccess: function handleSuccess(_ref2) {
      var file = _ref2.data.file;
      this.setProperties({
        isUploading: false,
        isError: false,
        file: file
      });

      if (this.get('completeAction')) {
        this.get('completeAction')(file);
      }
    },
    handleError: function handleError() {
      var _this2 = this;

      this.setProperties({
        isUploading: false,
        isError: true,
        file: null
      });
      Ember.run.later(this, function () {
        _this2.set('isError', false);
      }, 3000);
    },
    actions: {
      fileChanged: function fileChanged(file) {
        var filename = "";

        if (file) {
          if (file.name) {
            filename = file.name;
          }

          if (file.size) {
            filename += " (".concat((file.size / 1000000).toFixed(2), " MB)");
          }
        }

        this.set('filename', filename);
        console.log("filename:" + filename);
        console.log("autoUpload:" + this.get('autoUpload'));

        if (this.get('autoUpload')) {
          this.submit();
        }
      }
    }
  });

  _exports.default = _default;
});