define("backend/pods/components/hm-model/fieldset-essentials/devices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oNfARd7F",
    "block": "{\"symbols\":[\"device\"],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold label-small-gap\"],[8],[7,\"i\",true],[10,\"class\",\"icon-mobile2 position-left\"],[8],[9],[0,\" Devices\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"devices\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n      \"],[1,[22,\"loading-indicator\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"model\",\"devices\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n        \"],[1,[28,\"hm-state-components/list-device\",null,[[\"device\",\"canRemove\",\"onRemove\",\"class\"],[[23,1,[]],[24,[\"isDistributorOfOwner\"]],[28,\"action\",[[23,0,[]],\"removeDevice\"],null],\"business-card text-center\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"No devices available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isDistributorOfOwner\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"hm-state-components/assign-device\",null,[[\"class\",\"onSave\"],[\"business-card text-center\",[28,\"action\",[[23,0,[]],\"assignDevice\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-essentials/devices/template.hbs"
    }
  });

  _exports.default = _default;
});