define("backend/components/edit-entry", ["exports", "backend/components/route-component", "backend/models/language", "backend/models/category"], function (_exports, _routeComponent, _language, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    createModel: 'movie',
    categories: Ember.computed.alias('data.categories'),
    packages: Ember.computed.alias('data.packages'),

    /**
      Override validationKeys method to skip validation for relationships.
        @method validationKeys
     */
    validationKeys: function validationKeys() {
      return [];
    },

    /**
      Clear movie's category when the language is changed.
        @method clearMovieCategory
     */
    clearMovieCategory: function clearMovieCategory() {
      this.get('selected').set('category', null);
    },
    clearOrder: function clearOrder() {
      this.get('selected').set('order', null);
    },
    actions: {
      selectItem: function selectItem(item) {
        if (item instanceof _language.default) {
          this.clearMovieCategory();
          this.clearOrder();
        } else if (item instanceof _category.default) {
          this.clearOrder();
        }
      }
    }
  });

  _exports.default = _default;
});