define("backend/components/panel-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    action: 'createEntry',
    canCreate: true,
    showCreateLink: Ember.computed.notEmpty('createLink'),
    showCreateButton: Ember.computed.notEmpty('createAction'),
    actions: {
      createEntry: function createEntry() {
        return this.sendAction('action');
      }
    }
  });

  _exports.default = _default;
});