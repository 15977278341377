define("backend/pods/billings/all/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    model: function model(params) {
      return this.get('store').query('billing', this.curPage(params));
    }
  });

  _exports.default = _default;
});