define("backend/mixins/buffer-states-mixin", ["exports", "backend/config/environment", "backend/utils/buffer-proxy"], function (_exports, _environment, _bufferProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    indirectPromises: function indirectPromises() {
      return null;
    },
    keyToPath: function keyToPath(key) {
      return key;
    },
    clearStates: function clearStates() {
      this.clearBuffers();
      this.clearContacts();
    },
    createBuffers: function createBuffers() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var relatedPromises = [];

        var indirectPromise = _this.indirectPromises();

        var keys = [];

        _this.eachRelationship(function (name, descr) {
          if (descr.options.useBuffer) {
            keys.push(name);
            relatedPromises.pushObject(_this.get(name));
          }
        });

        if (indirectPromise != null) {
          keys.pushObject(indirectPromise.key);
          relatedPromises.pushObject(indirectPromise.promise);
        }

        return Ember.RSVP.Promise.all(relatedPromises).then(function (relatedObjects) {
          var buffers = {};
          relatedObjects.forEach(function (related, idx) {
            _this.createBuffer(buffers, related, keys[idx]);
          });

          _this.createBuffer(buffers, _this, 'selected');

          if (_environment.default.isTesting) {
            return Ember.run(function () {
              _this.set('buffers', buffers);

              resolve(buffers);
            });
          } else {
            _this.set('buffers', buffers);

            return resolve(buffers);
          }
        });
      });
    },
    createBuffer: function createBuffer(buffers, content, name) {
      var _this2 = this;

      if (Ember.isArray(content)) {
        var relBuffers = [];
        content.forEach(function (value) {
          return _this2.createBuffer(relBuffers, value);
        });
        buffers[name] = relBuffers;
      } else {
        var buffer = (0, _bufferProxy.default)(content);

        if (Ember.isArray(buffers)) {
          return buffers.pushObject(buffer);
        } else {
          return buffers[name] = buffer;
        }
      }
    },
    copyBufferState: function copyBufferState() {
      var buffers = this.get('buffers');
      var changes = {};
      Object.keys(buffers).forEach(function (key) {
        var buffer = buffers[key];

        if (Ember.isArray(buffer)) {
          var manyChanges = [];
          buffer.forEach(function (item) {
            return manyChanges.pushObject(item.buffer);
          });
          changes[key] = manyChanges;
        } else {
          changes[key] = buffer.buffer;
        }
      });
      return changes;
    },
    resetBuffers: function resetBuffers(states) {
      var _this3 = this;

      var buffers = this.get('buffers');
      Object.keys(states).forEach(function (key) {
        var buffer = buffers[key];

        if (Ember.isArray(buffer)) {
          buffer.forEach(function (itemBuffer, idx) {
            _this3.resetBuffer(itemBuffer, states[key].objectAt(idx));
          });
        } else {
          _this3.resetBuffer(buffer, states[key]);
        }
      });
    },
    resetBuffer: function resetBuffer(buffer, backup) {
      var record = buffer.content;
      var isNew = record.get('isNew');
      var results = [];
      var key;

      if (!isNew) {
        record.rollbackAttributes();
      }

      for (key in backup) {
        if (isNew) {
          if (backup[key] && Array.isArray(backup[key])) {
            record.get(key).clear();
          } else {
            record.set(key, null);
          }
        }

        results.push(buffer.set(key, backup[key]));
      }

      return results;
    },
    clearBuffers: function clearBuffers() {
      var buffers = this.get('buffers');
      var buffer, key;

      for (key in buffers) {
        buffer = buffers[key];

        if (Ember.isArray(buffer)) {
          buffer.forEach(function (itemBuffer) {
            return itemBuffer.discardChanges();
          });
        } else {
          buffers[key].discardChanges();
        }
      }

      this.set('buffers', null);
    },
    applyBuffers: function applyBuffers() {
      var _this4 = this;

      var buffers = this.get('buffers');
      Object.keys(buffers).forEach(function (key) {
        var buffer = buffers[key];

        if (Ember.isArray(buffer)) {
          var newHasMany = [];

          var hasManyPath = _this4.keyToPath(key);

          buffer.forEach(function (item) {
            _this4.applyBuffer(item);

            newHasMany.pushObject(item.content || item);
          });

          _this4.get(hasManyPath).setObjects(newHasMany);
        } else {
          _this4.applyBuffer(buffer);
        }
      });
    },
    applyBuffer: function applyBuffer(buffer) {
      if (buffer.get('hasChanges')) {
        buffer.applyChanges();
      }
    }
  });

  _exports.default = _default;
});