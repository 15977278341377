define("backend/models/statistics-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var Model = _emberData.default.Model.extend({
    duration: attr('string'),
    movie: belongsTo('movie', {
      async: true
    }),
    percentage: attr('number'),
    replays: attr('number')
  });

  Model.reopenClass({
    lastId: 0,
    getId: function getId() {
      return this.lastId += 1;
    }
  });
  var _default = Model;
  _exports.default = _default;
});