define("backend/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L09XD8oz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"errors\",\"firstObject\",\"error\"]],\"template.hbs Resource not found\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"Error: Not found\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"Error\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});