define("backend/pods/device-owners/overview/route", ["exports", "ember-inflector", "backend/utils/route"], function (_exports, _emberInflector, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CURRENT_ROUTE_URL = '/device-owners/overview';
  var TAIL_ROUTE = {
    'lead': 'essentials',
    'client': 'essentials'
  };

  var _default = Ember.Route.extend({
    queryParams: {
      t: {
        refreshModel: true
      }
    },
    activate: function activate() {
      document.title = 'Sales Map - HappyMed';
    },
    model: function model(params) {
      var store = this.get('store');
      var t = params.t;
      var query = {
        page: {
          size: 10000
        },
        filter: 'active'
      };

      if ((0, _route.isValidParam)(t)) {
        query.type = t;
      } // Load initial models only


      return Ember.RSVP.hash({
        leads: store.query('lead', query),
        activeClients: store.query('client', query),
        distributors: store.query('distributor', query)
      });
    },
    actions: {
      redirectTo: function redirectTo(model) {
        if (model instanceof Ember.ObjectProxy) {
          model = model.get('content');
        }

        var modelName = model.constructor.modelName;

        if (modelName === 'lead') {
          modelName = model.get('indexRoute');
        }

        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), Ember.String.dasherize((0, _emberInflector.pluralize)(modelName)), model.get('id')];

        if (TAIL_ROUTE[modelName]) {
          url.push(TAIL_ROUTE[modelName]);
        }

        window.open(url.join('/'), '_blank').focus();
      }
    }
  });

  _exports.default = _default;
});