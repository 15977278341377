define("backend/prototypes/tables/headers/countries", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'id',
    name: 'Code'
  }, {
    attr: 'name',
    name: 'Name'
  }, {
    attr: 'nameEng',
    name: 'Name in English'
  }, {
    attr: 'inEurope',
    name: 'Europe?',
    format: 'boolean'
  }]);

  _exports.default = _default;
});