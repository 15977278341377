define("backend/pods/components/hm-model/fieldset-movies/thumbnail-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mILQKcp4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"secure-image\",null,[[\"url\"],[[24,[\"option\",\"id\"]]]]],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"thumbnail-option__label\"],[8],[1,[24,[\"option\",\"displayName\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-movies/thumbnail-option/template.hbs"
    }
  });

  _exports.default = _default;
});