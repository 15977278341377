define("backend/pods/components/hm-model/form/contacts/component", ["exports", "backend/components/route-component", "backend/mixins/route-component/reload-counts"], function (_exports, _routeComponent, _reloadCounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend(_reloadCounts.default, {
    ownerType: null,
    // lead, client, distributor etc.
    keepSelected: true,
    forceReloadSelected: true,
    afterCancelAction: 'listOwners',
    afterDeleteAction: 'listOwners',
    init: function init() {
      this._super.apply(this, arguments);

      this.edit(this.get('selected'));
    },
    notifyCancel: function notifyCancel() {
      this._super.apply(this, arguments);

      this.sendAction('afterCancelAction', this.get('ownerType'));
    }
  });

  _exports.default = _default;
});