define("backend/pods/distributor/leads/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      distributor: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      }
    },
    filter: ['lead', 'active', ['distributor', 'status', 'source']],
    setQuery: function setQuery(query, key) {
      if (key === 'distributor') {
        query.distributor = this.modelFor('distributor').get('id');
        query.exclude_territories = true;
      } else {
        this._super.apply(this, arguments);
      }
    },
    isValidParam: function isValidParam(key) {
      if (key === 'distributor') {
        return true;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});