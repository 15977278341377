define("backend/pods/components/hm-model/fieldset-profile/company-lead/component", ["exports", "backend/pods/components/hm-model/fieldset-profile/-company-base"], function (_exports, _companyBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_companyBase.default, {
    modelTypes: Ember.computed(function () {
      return this.get('store').peekAll('lead-type');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('model.clientGroup.id')) {
        this.set('allGroups', [this.get('model.clientGroup')]);
      }
    },
    actions: {
      loadGroups: function loadGroups() {
        if (this.get('allGroups.length') > 2) {
          return false;
        }

        this.set('allGroups', Ember.RSVP.hash({
          leads: this.get('store').query('lead', {
            filter: 'active',
            exclude_clients: true,
            page: {
              number: 1,
              size: 10000
            }
          }),
          clients: this.get('store').query('client', {
            filter: 'available',
            page: {
              number: 1,
              size: 10000
            }
          })
        }).then(function (_ref) {
          var leads = _ref.leads,
              clients = _ref.clients;
          return [Ember.Object.create({
            groupName: 'Leads',
            options: leads.sortBy('displayName')
          }), Ember.Object.create({
            groupName: 'Clients',
            options: clients.sortBy('displayName')
          })];
        }));
      }
    }
  });

  _exports.default = _default;
});