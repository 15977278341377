define("backend/prototypes/owner/index", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ownerTypePluralized: Ember.computed('ownerType', function () {
      return (0, _emberInflector.pluralize)(this.get('ownerType'));
    }),
    model: function model(params, transition) {
      var store = this.store;
      var deviceOwner = transition.data.deviceOwner;
      return Ember.RSVP.hash({
        owner: this.modelFor(this.get('ownerType')),
        deviceOwner: deviceOwner,
        countries: store.findAll('country'),
        statusList: store.peekAll(Ember.String.dasherize(this.get('ownerType') + 'Status')),
        ratingsList: store.peekAll('owner-rating')
      });
    }
  });

  _exports.default = _default;
});