define("backend/templates/components/devices-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6OzF1Dd2",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,1,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,1,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"],[15,\"partials/show-error\",[]],[0,\"\\n\"],[1,[28,\"entry-form\",null,[[\"selected\",\"errors\",\"data\",\"template\",\"canDelete\",\"disableLicenseStatus\",\"showLicenseWarning\",\"labelsList\",\"acts\",\"canSave\"],[[24,[\"selected\"]],[24,[\"validationErrors\"]],[24,[\"data\"]],[24,[\"templateEdit\"]],[28,\"not\",[[28,\"feature-flag\",[\"restricted-lead-access\"],null]],null],[24,[\"disableLicenseStatus\"]],[24,[\"showLicenseWarning\"]],[24,[\"labelsList\"]],[28,\"hash\",null,[[\"updateDevicePackage\",\"addComponent\",\"addDevicePackage\",\"addLabel\",\"removeComponent\",\"removeDevicePackage\",\"showNextInspection\"],[[28,\"action\",[[23,0,[]],\"updateDevicePackage\"],null],[28,\"action\",[[23,0,[]],\"addComponent\"],null],[28,\"action\",[[23,0,[]],\"addDevicePackage\"],null],[28,\"action\",[[23,0,[]],\"addLabel\"],null],[28,\"action\",[[23,0,[]],\"removeComponent\"],null],[28,\"action\",[[23,0,[]],\"removeDevicePackage\"],null],[28,\"action\",[[23,0,[]],\"showNextInspection\"],null]]]],[24,[\"selected\",\"canSave\"]]]]],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/devices-component.hbs"
    }
  });

  _exports.default = _default;
});