define("backend/pods/studies-partner/statistics/table/route", ["exports", "backend/pods/client/statistics/table/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ownerParams: function ownerParams() {
      var ownerType = this.modelFor('studies-partner').get('ownerType');
      return {
        ownerType: ownerType,
        ownerId: this.paramsFor('studies-partner').id,
        ownerRoute: 'studies-partner'
      };
    }
  });

  _exports.default = _default;
});