define("backend/db-cache/languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": "deu",
    "displayName": "German"
  }, {
    "id": "eng",
    "displayName": "English"
  }, {
    "id": "fra",
    "displayName": "French"
  }, {
    "id": "ita",
    "displayName": "Italian"
  }, {
    "id": "nld",
    "displayName": "Dutch"
  }, {
    "id": "rus",
    "displayName": "Russian"
  }, {
    "id": "spa",
    "displayName": "Spanish"
  }];
  _exports.default = _default;
});