define("backend/prototypes/tables/headers/uploader", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: ''
    },
    columns: [{
      attr: 'id',
      style: 'text-center',
      display: false
    }]
  }, {
    group: {
      name: ''
    },
    columns: [{
      attr: 'actions',
      component: 'hm-uploader/table-actions',
      style: 'text-center'
    }]
  }, {
    group: {
      name: 'Overview'
    },
    columns: [{
      attr: 'file_info',
      name: 'File',
      style: 'text-nowrap'
    }, {
      attr: 'status_name',
      name: 'Status',
      style: 'text-nowrap'
    }, {
      attr: 'created_at',
      name: 'Created At',
      format: 'datetime',
      style: 'text-nowrap'
    }, {
      attr: 'changed_at',
      name: 'Last Update',
      format: 'datetime',
      style: 'text-nowrap'
    }]
  }, {
    group: {
      name: 'Details'
    },
    columns: [{
      attr: 'created_by_user',
      name: 'Created By'
    }, {
      attr: 'filesize_mb',
      name: 'Total Size (MB)'
    }, {
      attr: 'bytes_transferred_fmt_mb',
      name: 'Transferred (MB)'
    }, {
      attr: 'filesize_bytes',
      name: 'Total Size (bytes)',
      display: false
    }, {
      attr: 'bytes_transferred_fmt',
      name: 'Transferred (bytes)',
      display: false
    }, {
      attr: 'mime_type',
      name: 'Mime',
      display: false
    }, {
      attr: 'upload_folder',
      name: 'Destination Folder',
      display: false
    }]
  }]);

  _exports.default = _default;
});