define("backend/templates/components/public-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8aWFNZ/a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[29,[[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null]]]],[10,\"target\",\"_blank\"],[10,\"title\",\"Public Link\"],[8],[1,[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/public-link.hbs"
    }
  });

  _exports.default = _default;
});