define("backend/pods/components/hm-form/textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attrClass: Ember.computed('size', function () {
      var size = this.get('size');

      if (size === 'small') {
        return 'form-control text-area--small';
      }

      if (size === 'tall') {
        return 'form-control textarea--tall';
      }

      return 'form-control';
    })
  });

  _exports.default = _default;
});