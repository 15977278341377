define("backend/pods/logistics/material-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8CcZuO8j",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Material Orders\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"data\",\"isLoading\",\"headerComponent\",\"rowComponent\",\"tableColumns\",\"defaultClientSort\",\"showPagination\",\"showFooter\"],[[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/header-contracts\"],null],[28,\"component\",[\"hm-models/list/orders/material-order-row\"],null],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],false,false]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/logistics/material-orders/template.hbs"
    }
  });

  _exports.default = _default;
});