define("backend/pods/components/hm-form/select-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oo83A7/C",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-select\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-select--input\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"placeholder\"],[[24,[\"value\"]],[24,[\"inputClass\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-select--select\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchField\",\"searchEnabled\",\"disabled\",\"dropdownClass\",\"onOpen\",\"onChange\"],[[24,[\"data\"]],[28,\"find-by\",[[24,[\"attrValue\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null],[24,[\"dataFlattened\"]]],null],[24,[\"attrValue\"]],true,[24,[\"disabled\"]],[24,[\"dropdownClass\"]],[28,\"action\",[[23,0,[]],\"onOpen\"],null],[28,\"action\",[[23,0,[]],\"setValue\"],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/select-input/template.hbs"
    }
  });

  _exports.default = _default;
});