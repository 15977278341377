define("backend/utils/static-data/support-case-work-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: "HQ"
  }, {
    id: 2,
    name: "On site"
  }];
  _exports.default = _default;
});