define("backend/helpers/format-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatArray = formatArray;
  _exports.default = void 0;
  var DEFAULT_DISPLAY_ATTRIBUTE = 'displayName';

  function formatArray(args) {
    if (!Ember.isArray(args)) {
      return '';
    }

    var values = args[0],
        formatted = [],
        displayAttr = args[1] || DEFAULT_DISPLAY_ATTRIBUTE;
    values.forEach(function (item) {
      if (typeof item === 'string') {
        formatted.pushObject(item);
      } else {
        formatted.pushObject(item.get(displayAttr));
      }
    });
    return formatted.join(', ');
  }

  var _default = Ember.Helper.helper(formatArray);

  _exports.default = _default;
});