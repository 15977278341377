define("backend/prototypes/tables/headers/material-orders", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Material Order'
    },
    columns: [{
      attr: 'id',
      name: '#',
      style: 'text-center text-nowrap',
      component: 'hm-models/list/orders/id-and-toggle'
    }, {
      attr: 'timestamp',
      name: 'Created At',
      format: 'date',
      style: 'text-center'
    }, {
      attr: 'user',
      name: 'Created By',
      style: 'text-nowrap',
      display: false
    }, {
      attr: 'recipient',
      name: 'Recipient',
      component: 'link-owner',
      style: 'text-nowrap'
    }, {
      attr: 'deliveryType',
      name: 'Delivery Type',
      style: 'text-center text-nowrap'
    }, {
      attr: 'deliveryDate',
      name: 'Delivery Date',
      format: 'date',
      style: 'text-center'
    }, {
      attr: 'status.id',
      name: 'Status',
      component: 'hm-models/list/orders/status',
      style: 'text-center'
    }]
  }]);

  _exports.default = _default;
});