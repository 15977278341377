define("backend/pods/billings/paid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5IB3mP1A",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Paid Billings\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"data\",\"isLoading\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/billings/paid/template.hbs"
    }
  });

  _exports.default = _default;
});