define("backend/pods/countries/controller", ["exports", "backend/prototypes/tables/headers/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size'],
    page: 1,
    size: 100,
    tableColumns: _countries.default
  });

  _exports.default = _default;
});