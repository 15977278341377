define("backend/pods/components/hm-chart/multi-line/component", ["exports", "d3-time-format", "d3-selection", "d3-array", "d3-scale", "d3-time", "d3-axis", "d3-shape", "d3-zoom", "backend/helpers/format-currency"], function (_exports, _d3TimeFormat, _d3Selection, _d3Array, _d3Scale, _d3Time, _d3Axis, _d3Shape, _d3Zoom, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var bisectDate = (0, _d3Array.bisector)(function (d) {
    return d[0];
  }).left;
  var SVG_BARWIDTH = 30;

  var _default = Ember.Component.extend({
    height: 320,
    margin: {
      top: 30,
      right: 45,
      bottom: 55,
      left: 55
    },
    lineEvery: 50,
    ticksEvery: 4,
    horizontalLines: Ember.computed('yDataRange', 'lineEvery', function () {
      var _this$get = this.get('yDataRange'),
          _this$get2 = _slicedToArray(_this$get, 2),
          minY = _this$get2[0],
          maxY = _this$get2[1];

      var lineEvery = this.get('lineEvery');
      var tickSize =
      /* this.get('ticksEvery');*/
      Math.ceil(maxY / lineEvery);
      var lines = [];

      for (var idx = 0; idx < tickSize; idx++) {
        lines.push(minY + idx * lineEvery); // Every 100
      }

      return lines;
    }),
    xInterval: 'month',
    xTickInterval: Ember.computed('xInterval', function () {
      switch (this.get('xInterval')) {
        case 'month':
          return _d3Time.timeMonth;

        case 'week':
          return _d3Time.timeDay.every(7);

        case 'month-year-today':
          return _d3Time.timeMonth;
      }
    }),
    xTickFormat: Ember.computed('xInterval', function () {
      switch (this.get('xInterval')) {
        case 'month':
          return (0, _d3TimeFormat.timeFormat)("%b");

        case 'week':
          return (0, _d3TimeFormat.timeFormat)("%e.%b");

        case 'month-year-today':
          return function (date) {
            return (0, _d3Time.timeYear)(date) < date ? (0, _d3TimeFormat.timeFormat)('%b')(date) : (0, _d3TimeFormat.timeFormat)('%Y')(date);
          };
      }
    }),
    xDataRange: Ember.computed('data.series', function () {
      var values = this.get('data.series').reduce(function (acc, _ref) {
        var data = _ref.data;
        return acc.concat(data.map(function (d) {
          return d[0];
        }));
      }, []);
      return (0, _d3Array.extent)(values);
    }),
    yDataRange: Ember.computed('data.series', function () {
      var values = this.get('data.series').reduce(function (acc, _ref2) {
        var data = _ref2.data;
        return acc.concat(data.map(function (d) {
          return d[1];
        }));
      }, []);
      return (0, _d3Array.extent)(values);
    }),
    xScaleMultiplier: 1,
    xScale: Ember.computed('svgWidth', 'xDataRange', function () {
      var svgWidth = this.get('svgWidth');
      return (0, _d3Scale.scaleTime)().domain(this.get('xDataRange')).nice(this.get('xTickInterval')).range([0, svgWidth * this.get('xScaleMultiplier')]);
    }),
    yScale: Ember.computed('svgHeight', 'yDataRange', function () {
      var svgHeight = this.get('svgHeight');
      return (0, _d3Scale.scaleLinear)().domain(this.get('yDataRange')).range([svgHeight, 0]);
    }),
    // xAxisDaysTicks: 1,
    xAxis: Ember.computed('xScale', 'xAxisDaysTicks', function () {
      return (0, _d3Axis.axisBottom)().scale(this.get('xScale')).tickFormat(this.get('xTickFormat')).ticks(this.get('xTickInterval')
      /*, this.get('xAxisDaysTicks')*/
      );
    }),
    yAxis: Ember.computed('yScale', function () {
      return (0, _d3Axis.axisLeft)().scale(this.get('yScale')).ticks(this.get('ticksEvery'));
    }),
    calcLine: Ember.computed('xScale', 'yScale', function () {
      var _this$getProperties = this.getProperties('xScale', 'yScale'),
          xScale = _this$getProperties.xScale,
          yScale = _this$getProperties.yScale;

      return (0, _d3Shape.line)().x(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 1),
            date = _ref4[0];

        return xScale(date);
      }).y(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
            date = _ref6[0],
            hours = _ref6[1];

        return yScale(hours);
      });
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initSvg();
      this.paint();
    },
    initSvg: function initSvg() {
      var container = this.$('.chart-container', this.element);
      var margin = this.get('margin');
      var svgWidth = container.width();
      var svgHeight = this.get('height');
      this.setProperties({
        svgWidth: svgWidth - margin.left - margin.right,
        svgHeight: svgHeight - margin.bottom - margin.top,
        svg: (0, _d3Selection.select)(container[0]).append('svg').attr('width', svgWidth).attr('height', svgHeight).attr('viewBox', "0 0 ".concat(svgWidth, " ").concat(this.get('height'))).attr('preserveAspectRatio', 'none').append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"))
      });
    },
    paint: function paint() {
      if (this.get('lineEvery') > 0) {
        this.paintHorizontalLines();
      }

      this.paintLegend();
      this.paintAxisX();
      this.paintAxisY();

      if (this.get('useBars')) {
        this.paintBars();
      } else {
        this.paintPath();
      }

      if (this.get('addTodayLine')) {
        this.paintToday();
      }

      if (this.get('addPanning')) {
        this.addZoom();
      }

      this.addMouseEvents();
    },
    paintAxisX: function paintAxisX() {
      var _this$getProperties2 = this.getProperties('xAxis', 'svgHeight'),
          xAxis = _this$getProperties2.xAxis,
          svgHeight = _this$getProperties2.svgHeight;

      this.set('gXAxis', this.get('svg').append('g').attr('class', this.get('useBars') ? 'x-left axis dashboard-axis' : 'x axis dashboard-axis').attr('transform', "translate(0, ".concat(svgHeight, ")")).call(xAxis));

      switch (this.get('xInterval')) {
        case 'month-year-today':
          this.get('svg').selectAll('.tick > text').style("font-weight", function (d) {
            return d.getMonth() === 0 && d.getDate() === 1 ? 'bold' : 'normal';
          });
          break;
      }
    },
    repaintAxisX: function repaintAxisX(transform) {
      var _this$getProperties3 = this.getProperties('xAxis', 'xScale'),
          xAxis = _this$getProperties3.xAxis,
          xScale = _this$getProperties3.xScale;

      var newScale = transform.rescaleX(xScale);
      this.set('xScaleZoom', newScale);
      this.get('gXAxis').call(xAxis.scale(newScale));
      var thisMonth = new Date();
      thisMonth.setDate(1);
      thisMonth.setHours(0, 0, 0, 0);

      switch (this.get('xInterval')) {
        case 'month-year-today':
          this.get('svg').selectAll('.tick > text').style("font-weight", function (d) {
            if (d && d instanceof Date) {
              if (d.getMonth() === 0 && d.getDate() === 1 || d.toString() === thisMonth.toString()) {
                return 'bold';
              }
            }

            return null;
          });
          break;
      }
    },
    paintAxisY: function paintAxisY() {
      var yAxis = this.get('yAxis');
      this.get('svg').append("g").attr("class", "y axis dashboard-axis").attr('transform', "translate(0, 0)").call(yAxis);
    },
    paintPath: function paintPath() {
      var chart = this.get('svg');
      this.set('gLine', chart.append('g').selectAll().data(this.get('data.series')).enter().append('path').attr('fill', 'none').attr('stroke', function (series) {
        return series.color;
      }).attr('stroke-width', 2).datum(function (series) {
        return series.data;
      }).attr('d', this.get('calcLine')));
    },
    repaintBars: function repaintBars(transform) {
      this.get('gLine').attr("transform", "translate(".concat(transform.x, ",0) scale(").concat(transform.k, ",1)"));
    },
    paintBars: function paintBars() {
      var chart = this.get('svg');
      var x = this.get('xScale');
      var y = this.get('yScale');
      var svgHeight = this.get('svgHeight');
      var firstSerie = this.get('data.series.firstObject');
      this.set('gLine', chart.append('g').selectAll().data(firstSerie.data).enter().append('rect').attr('class', 'dashboard-bar').attr('fill', firstSerie.color).attr('x', function (d) {
        return x(d[0]);
      }).attr('y', function (d) {
        return y(d[1]);
      }).attr('width', SVG_BARWIDTH).attr('height', function (d) {
        return svgHeight - y(d[1]);
      }));
    },
    repaintPath: function repaintPath(transform) {
      this.get('gLine').attr("transform", "translate(".concat(transform.x, ",0) scale(").concat(transform.k, ",1)"));
    },
    paintHorizontalLines: function paintHorizontalLines() {
      var _this$getProperties4 = this.getProperties('svg', 'svgWidth', 'yScale', 'horizontalLines'),
          svg = _this$getProperties4.svg,
          svgWidth = _this$getProperties4.svgWidth,
          yScale = _this$getProperties4.yScale,
          horizontalLines = _this$getProperties4.horizontalLines;

      horizontalLines.forEach(function (yValue) {
        svg.append("svg:line").attr("x1", 2).attr("x2", svgWidth + 2).attr("y1", yScale(yValue) + 3).attr("y2", yScale(yValue) + 3).style("stroke", "rgba(0, 0, 0, 0.08)");
      });
    },
    paintToday: function paintToday() {
      var scale = this.get('xScale');
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      this.set('lineToday', this.get('svg').append('line').attr("stroke", '#555').attr("stroke-width", '1px').attr("x1", scale(today)).attr("x2", scale(today)).attr("y1", 0).attr("y2", 'calc(100% - 82px)').style('shape-rendering', 'crispEdges'));
    },
    repaintToday: function repaintToday(transform) {
      this.get('lineToday').attr("transform", "translate(".concat(transform.x, ",0) scale(").concat(transform.k, ",1)"));
    },
    addMouseEvents: function addMouseEvents() {
      var _this = this;

      var _this$getProperties5 = this.getProperties('svgWidth', 'svgHeight', 'yScale', 'addTooltip'),
          svgWidth = _this$getProperties5.svgWidth,
          svgHeight = _this$getProperties5.svgHeight,
          yScale = _this$getProperties5.yScale,
          addTooltip = _this$getProperties5.addTooltip;

      var allSeries = this.get('data');
      var ctx = this;
      var tooltip = addTooltip ? (0, _d3Selection.select)(this.element).append('div').attr('class', 'tooltip-dashboard') : null;
      var allFocus = [];
      allSeries.series.forEach(function (series, idx) {
        var seriesFocus = _this.createFocusElement("focus".concat(idx));

        series.focus = seriesFocus;
        allFocus.push(seriesFocus);
      });
      var a1 = this.get('svg').select('rect.chart-catch-all');
      a1.style('fill', 'none');
      a1.style('pointer-events', 'all');
      a1.attr('width', svgWidth);
      a1.attr('height', svgHeight);
      a1.on('mouseover', function () {
        allFocus.forEach(function (f) {
          return f.style("display", null);
        });
      });
      a1.on('mouseout', function () {
        allFocus.forEach(function (f) {
          return f.style("display", "none");
        });
        tooltip && tooltip.style('display', 'none');
      });
      a1.on('mousemove', function () {
        var xScale = ctx.getXScale();
        var x0 = xScale.invert((0, _d3Selection.mouse)(this)[0]);
        allSeries.series.forEach(function (_ref7) {
          var data = _ref7.data,
              focus = _ref7.focus;
          var i = bisectDate(data, x0, 1);
          var d0 = data.objectAt(i - 1);
          var d1 = data.objectAt(i);
          var x, y, tooltipElements;

          if (d0 && !d1) {
            x = d0[0];
            y = d0[1];

            if (addTooltip) {
              tooltipElements = d0[2];
            }
          } else if (!d0 && d1) {
            x = d1[0];
            y = d1[1];

            if (addTooltip) {
              tooltipElements = d1[2];
            }
          } else {
            if (x0 - d0[0] > d1[0] - x0) {
              x = d1[0];
              y = d1[1];

              if (addTooltip) {
                tooltipElements = d1[2];
              }
            } else {
              x = d0[0];
              y = d0[1];

              if (addTooltip) {
                tooltipElements = d0[2];
              }
            }
          }

          if (tooltipElements && tooltip) {
            focus.attr("transform", "translate(" + xScale(x) + "," + yScale(y) + ")");
            tooltip.attr('style', "display:block;left:".concat(xScale(x) + 20, "px;top:").concat(yScale(y) + 110, "px;")).html('');
            var content = ['<h3>' + moment(x).format('MMMM YYYY') + '</h3>', '<table>'];
            tooltipElements.forEach(function (_ref8) {
              var name = _ref8.name,
                  amount = _ref8.amount,
                  link = _ref8.link;
              content.push(['<tr><td><div class="col-truncate"><a href="', link, '" target="_blank" title="', name, '">', name, '</a></div></td><td class="col-right">', (0, _formatCurrency.formatCurrency)([amount]), '</td></tr>'].join(''));
            });
            content.push('<tr><td></td><td class="col-right col-bold">', (0, _formatCurrency.formatCurrency)([y]), '</td></table>');
            tooltip.html(content.join(''));
          } else {
            focus.attr("transform", "translate(" + xScale(x) + "," + yScale(y) + ")");
            focus.select("text").text(Math.ceil(y));
          }
        });
      });
    },
    addZoom: function addZoom() {
      var _this2 = this;

      var catchAll = this.get('svg').append('rect').attr('class', 'chart-catch-all').attr('fill', 'none').attr('width', this.get('svgWidth')).attr('height', this.get('svgHeight')).attr('style', 'cursor:move;position:absolute;top:0;left:0;fill:none;pointer-events:all');

      var initPan = _d3Zoom.zoomIdentity.translate(-1500, 0);

      var MIN_SCALE = 0.44;
      var MAX_SCALE = 1.54;
      var horizontalPanning = (0, _d3Zoom.zoom)().on('zoom', function () {
        var transform = _d3Selection.event.transform;
        transform.k = Math.min(Math.max(transform.k, MIN_SCALE), MAX_SCALE);

        _this2.set('xScaleZoom');

        _this2.repaintAxisX(transform);

        if (_this2.get('useBars')) {
          _this2.repaintBars(transform);
        } else {
          _this2.repaintPath(transform);
        }

        _this2.repaintToday(transform);
      });
      catchAll.call(horizontalPanning).call(horizontalPanning.transform, initPan);
    },
    getXScale: function getXScale() {
      return this.get('xScaleZoom') || this.get('xScale');
    },
    createFocusElement: function createFocusElement(attr) {
      var focus = this.get('svg').append("g").attr("class", "focus dashboard-focus").style("display", "none");

      if (!this.get('useBars')) {
        focus.append("circle").attr("r", 4.5);
      }

      focus.append("text").attr("x", 5).attr("dy", 20);
      this.set(attr, focus);
      return focus;
    },
    paintLegend: function paintLegend() {
      var _this$getProperties6 = this.getProperties('svgWidth', 'data', 'margin'),
          svgWidth = _this$getProperties6.svgWidth,
          data = _this$getProperties6.data,
          margin = _this$getProperties6.margin;

      var svg = (0, _d3Selection.select)(this.element).select('svg');
      var legend = svg.append("g");
      var centerX = svgWidth + margin.left - 240;
      data.series.forEach(function (_ref9, idx) {
        var name = _ref9.name,
            color = _ref9.color;
        var x = centerX + 150 * idx;
        legend.append("circle").attr("r", 4.5).attr("stroke-width", 0).attr("fill", color).attr("transform", "translate(".concat(x, ", 8)"));
        legend.append('text').html(name).attr('stroke-width', 0).attr('fill', '#666').attr("transform", "translate(".concat(x + 10, ", 11.5)"));
      });
    }
  });

  _exports.default = _default;
});