define("backend/models/offer", ["exports", "ember-data", "backend/models/buffered-model", "ember-cp-validations"], function (_exports, _emberData, _bufferedModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _bufferedModel.default.extend({
    number: attr('string'),
    date: attr('string'),
    amount: attr('number'),
    file: attr('string'),
    status: belongsTo('billingStatus', {
      async: false
    }),
    lead: belongsTo('lead', {
      async: true
    }),
    displayName: Ember.computed.alias('number'),
    validators: {
      number: (0, _emberCpValidations.validator)('presence', true),
      date: (0, _emberCpValidations.validator)('presence', true),
      amount: (0, _emberCpValidations.validator)('number', {
        gt: -1,
        allowString: true
      }),
      status: (0, _emberCpValidations.validator)('presence', true),
      file: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});