define("backend/pods/logs/error/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zfMh1Us5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"data-table\",null,[[\"data\",\"isLoading\",\"page\",\"size\",\"canEdit\",\"tableColumns\",\"target\"],[[24,[\"model\",\"logs\"]],[24,[\"model\",\"logs\",\"isLoading\"]],[24,[\"model\",\"page\"]],[24,[\"model\",\"size\"]],false,[24,[\"tableColumns\"]],[23,0,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/logs/error/table/template.hbs"
    }
  });

  _exports.default = _default;
});