define("backend/models/client", ["exports", "ember-data", "backend/utils/cp/buffer-changes", "backend/helpers/formatters/date", "backend/models/client-like"], function (_exports, _emberData, _bufferChanges, _date, _clientLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var BUFFER_BELONGS_TO = ['address', 'shippingAddress', 'billingAddress', 'materialOrderAddress', 'repairOrderAddress', 'bank'];
  var BUFFER_HAS_MANY = ['contacts', 'departments'];

  var _default = _clientLike.default.extend({
    bank: belongsTo('bank', {
      async: false,
      useBuffer: true
    }),
    asyncIds: attr(),
    rating: belongsTo('owner-rating', {
      async: false
    }),
    shippingAddress: belongsTo('address', {
      async: false,
      useBuffer: true
    }),
    billingAddress: belongsTo('address', {
      async: false,
      useBuffer: true
    }),
    materialOrderAddress: belongsTo('address', {
      async: false,
      useBuffer: true
    }),
    repairOrderAddress: belongsTo('address', {
      async: false,
      useBuffer: true
    }),
    clientNumber: attr('number'),
    paymentMethod: attr('string'),
    paymentInterval: attr('string'),
    debitProcedure: attr('boolean', {
      defaultValue: false
    }),
    syncedToClose: attr('number', {
      defaultValue: true
    }),
    sponsorship: attr('string'),
    contracts: hasMany('contract', {
      async: true
    }),
    showShippingAddress: Ember.computed('shippingAddress', function () {
      var shippingAddress = this.get('shippingAddress');
      return shippingAddress && !shippingAddress.get('isNew');
    }),
    showBillingAddress: Ember.computed('billingAddress', function () {
      var billingAddress = this.get('billingAddress');
      return billingAddress && !billingAddress.get('isNew');
    }),
    hasBufferedChangesContacts: (0, _bufferChanges.watchBufferedHasMany)('contacts'),
    hasBufferedChanges: (0, _bufferChanges.watchBufferChanges)(BUFFER_BELONGS_TO, BUFFER_HAS_MANY),
    hasBufferedChangesDepartments: (0, _bufferChanges.watchBufferedHasMany)('departments'),
    typeName: Ember.computed('isClientGroup', function () {
      return this.get('isClientGroup') ? 'Group Client' : 'Client';
    }),
    ownerType: 'client',
    billingCount: Ember.computed('counts.paid_billings', 'counts.unpaid_billings', function () {
      if (this.counts) {
        var _this$counts = this.counts,
            paid_billings = _this$counts.paid_billings,
            unpaid_billings = _this$counts.unpaid_billings;

        if (paid_billings || unpaid_billings) {
          return "".concat(unpaid_billings, "/").concat(paid_billings);
        }

        return null;
      }

      return null;
    }),
    markerIcon: Ember.computed('address.isCountryOnly', 'rating.markerSuffix', function () {
      if (this.get('address.isCountryOnly')) {
        return new google.maps.MarkerImage("/assets/hm_marker.png", new google.maps.Size(40, 40), new google.maps.Point(0, 0));
      }

      var suffix = this.get('rating.markerSuffix') || 0;
      return new google.maps.MarkerImage("/assets/hm_marker.png", new google.maps.Size(40, 40), new google.maps.Point(suffix, 80));
    }),
    markerInfo: Ember.computed('displayName', 'rating', function () {
      var info = [];
      info.push(['<p class="text-bold">', this.get('displayName'), '</p>'].join(''));
      info.push(['<ul>'].join(''));
      info.push(this.get('departments').reduce(function (html, department) {
        html.push(['<li><p>', department.get('name'), ': ', '<span class="text-bold">', department.get('status.displayName'), '</span>', '</p>'].join(''));
        var note = department.get('statusNote');

        if (note) {
          if (department.get('status.needsDate')) {
            html.push(['<p>Note: ', (0, _date.default)([note]), '</p>'].join(''));
          } else if (department.get('status.needsDateRangeAndDevices')) {
            if (note.indexOf(';') > -1) {
              var _note$split = note.split(';'),
                  _note$split2 = _slicedToArray(_note$split, 2),
                  startDate = _note$split2[0],
                  endDate = _note$split2[1];

              html.push(['<p>Note: ', (0, _date.default)([startDate]), ' to ', (0, _date.default)([endDate]), '</p>'].join(''));
            }

            var numDevices = department.get('numDevices');

            if (numDevices) {
              html.push(['<p>Devices: ', numDevices, '</p>'].join(''));
            }
          } else if (department.get('status.needsReason') || department.get('status.needsNumOfDevices')) {
            html.push(['<p>Note: ', note, '</p>'].join(''));
          }
        }

        html.push(['</li>']);
        return html;
      }, []).join(''));
      info.push(['</ul><br />'].join(''));

      if (this.get('rating.id')) {
        info.push(['<p>Rating: ', '<span class="text-bold">', this.get('rating.displayName'), '</span>', '</p>'].join(''));
      }

      info.push(['<p>Distributor: ', this.get('distributor.displayName'), '</p>'].join(''));
      return Ember.String.htmlSafe(info.join(''));
    })
  });

  _exports.default = _default;
});