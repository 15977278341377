define("backend/adapters/battery-level", ["exports", "backend/adapters/statistics-summary"], function (_exports, _statisticsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _statisticsSummary.default.extend({
    query: function query(modelName, _query) {
      var url = this.urlForQuery(_query, modelName);
      delete _query.device;
      return this.ajax(url, 'GET', {
        data: _query
      });
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var parentModelName = 'device';
      var host = this.get('host');
      var prefix = this.urlPrefix();
      var url = [];

      if (query[parentModelName]) {
        url.push(this.pathForType(parentModelName));
        url.push(query[parentModelName]);
      }

      if (modelName) {
        var path = this.pathForType(modelName);

        if (path) {
          url.push(path);
        }
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    }
  });

  _exports.default = _default;
});