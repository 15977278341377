define("backend/pods/components/hm-device/toggle-license-structure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TG0S8vS2",
    "block": "{\"symbols\":[\"language\",\"category\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n  License Structure\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"displayLicenseStructure\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"close\",\"translucentOverlay\",\"containerClass\"],[\"closeModal\",true,\"modal-dialog--max-height\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-body no-list-bullets\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"is-pending\",[[24,[\"languages\"]]],null]],null,{\"statements\":[[0,\"            Loading...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"await\",[[24,[\"languages\"]]],null]],null,{\"statements\":[[0,\"                \"],[7,\"li\",true],[8],[0,\"\\n                  \"],[7,\"h2\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n                  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"categories\"]]],null,{\"statements\":[[0,\"                      \"],[7,\"li\",true],[8],[0,\"\\n                        \"],[7,\"h2\",true],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n                        \"],[15,\"partials/nested-categories\",[1,2]],[0,\"\\n                      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"                  \"],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/pods/components/hm-device/toggle-license-structure/template.hbs"
    }
  });

  _exports.default = _default;
});