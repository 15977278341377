define("backend/pods/impersonate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CURRENT_ROUTE_URL = '/login-as';

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    features: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('currentUser.isImpersonateUser')) {
        return this.transitionTo('index', {
          queryParams: {
            ott: null
          }
        });
      }

      if (!this.get('features').isEnabled('impersonate-user')) {
        throw new Error('Permission denied');
      }
    },
    model: function model() {
      return this.get('store').findAll('user').then(function (users) {
        var groups = users.reduce(function (options, user) {
          var role = user.get('role.displayName');
          var group = options.findBy('groupName', role);

          if (!group) {
            group = Ember.Object.create({
              groupName: role,
              options: []
            });
            options.pushObject(group);
          }

          group.get('options').pushObject(user);
          return options;
        }, []);
        return groups.map(function (group) {
          group.set('options', group.get('options').sortBy('displayName'));
          return group;
        });
      });
    },
    actions: {
      loginAs: function loginAs(id) {
        this.get('currentUser').impersonate(CURRENT_ROUTE_URL, id);
      }
    }
  });

  _exports.default = _default;
});