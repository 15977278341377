define("backend/components/navigation-offboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SalesType = Ember.Object.extend({
    id: null,
    name: null,
    color: null
  });

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var salesTypeHospital = SalesType.create({
        id: 'hospital',
        name: 'Sales Hospital'
      });
      var salesTypeDental = SalesType.create({
        id: 'dental',
        name: 'Sales Dental'
      });
      var salesTypes = [salesTypeHospital, salesTypeDental];
      this.setProperties({
        salesTypeHospital: salesTypeHospital,
        salesTypeDental: salesTypeDental
      });
      this.get('userSettings.salesType').then(function (salesType) {
        _this.set('curSalesType', salesTypes.findBy('id', salesType));
      });
    },
    actions: {
      toggleSalesType: function toggleSalesType() {
        var salesType = this.get('curSalesType') === this.get('salesTypeHospital') ? this.get('salesTypeDental') : this.get('salesTypeHospital');
        this.set('curSalesType', salesType);
        this.get('userSettings').setSalesType(salesType.get('id'));
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});