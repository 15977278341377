define("backend/pods/usage/iterate-years/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6qpjnOPR",
    "block": "{\"symbols\":[\"yearAndMonths\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"allYearAndMonths\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[23,1,[\"year\"]],[23,1,[\"months\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/usage/iterate-years/template.hbs"
    }
  });

  _exports.default = _default;
});