define("backend/pods/components/hm-model/form/in-place/component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    fileType: null,
    fileAttr: 'file',
    supportsFile: Ember.computed.notEmpty('fileType'),
    didUpdateAttrs: function didUpdateAttrs() {
      var selected = this.get('selected');
      var isAlreadyInEditMode = !selected || selected.get('isNew');

      if (!isAlreadyInEditMode) {
        this.edit(selected);
      }
    },
    prefill: function prefill(model) {
      if (this.get('supportsFile')) {
        if (model.get('isNew')) {
          this._updateUploadUrl(model);
        } else if (!model.get(this.get('fileAttr'))) {
          this._updateUploadUrl(model);
        }
      }
    },
    _updateUploadUrl: function _updateUploadUrl() {
      var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('selected');
      var buffer = model.get('buffers.selected');
      var fileType = this.get('fileType');
      this.createUploadUrl(fileType).then(function (_ref) {
        var upload_url = _ref.data.upload_url;
        buffer.setProperties({
          file: null,
          uploadUrl: upload_url
        });
      });
    },
    actions: {
      deleteLinkedEntry: function deleteLinkedEntry() {
        this._updateUploadUrl();
      }
    }
  });

  _exports.default = _default;
});