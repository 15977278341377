define("backend/pods/components/mobiledoc-editor/component", ["exports", "ember-mobiledoc-editor/components/mobiledoc-editor/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('_upstreamMobiledoc', null);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});