define("backend/templates/partials/-show-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gdPY461h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"serverError\"]]],null,{\"statements\":[[1,[28,\"alert-flash\",null,[[\"error\"],[[24,[\"serverError\"]]]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/partials/-show-error.hbs"
    }
  });

  _exports.default = _default;
});