define("backend/models/tester", ["exports", "ember-data", "backend/models/partner-model", "ember-cp-validations", "backend/utils/cp/buffer-changes", "@ember-data/model"], function (_exports, _emberData, _partnerModel, _emberCpValidations, _bufferChanges, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var BUFFER_BELONGS_TO = ['address', 'bank'];
  var BUFFER_HAS_MANY = ['contacts'];

  var _default = _partnerModel.default.extend({
    displayName: (0, _model.attr)('string'),
    status: belongsTo('tester-status', {
      async: false
    }),
    typeName: 'Tester',
    // --------------------------- BUFFER TRACKING -------------------------------
    supportsDirtyBufferTracking: true,
    hasBufferedChangesContacts: (0, _bufferChanges.watchBufferedHasMany)('contacts'),
    hasBufferedChanges: (0, _bufferChanges.watchBufferChanges)(BUFFER_BELONGS_TO, BUFFER_HAS_MANY),
    // ----------------------------- VALIDATIONS ---------------------------------
    validators: {
      email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })],
      phone: (0, _emberCpValidations.validator)('presence', true),
      status: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});