define("backend/helpers/format-trend", ["exports", "backend/helpers/formatters/trend"], function (_exports, _trend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(_trend.default);

  _exports.default = _default;
});