define("backend/pods/content/movie-expiration/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/movies/expiration_chart";

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            return resolve(data);
          });
        });
      });
    }
  });

  _exports.default = _default;
});