define("backend/pods/application/route", ["exports", "ember-data", "ember-simple-auth/mixins/application-route-mixin", "ember-inflector"], function (_exports, _emberData, _applicationRouteMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WARNING_DIALOG = 'Changes you made may not be saved.';
  var REDIRECT_BLACKLIST = ['/', '/login'];

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    breadCrumbs: Ember.inject.service(),
    session: Ember.inject.service(),
    expires: Ember.inject.service(),
    uploader: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      var queryParams = this.paramsFor('application');
      var url = window.location.href;
      var isImpersonateRequest = url.indexOf('/login-as?ott=') > -1 && queryParams.ott;

      if (!REDIRECT_BLACKLIST.includes(url.slice(url.lastIndexOf('/')))) {
        this.set('pageRedirect', location.href);
      }

      this._super();

      if (isImpersonateRequest) {
        return this.get('session').authenticate('authenticator:hm-token', null, null, queryParams.ott).catch(function () {
          return _this.transitionTo('login');
        });
      }

      return this.get('session.session').restore().catch(this.transitionToLogin.bind(this));
    },
    transitionToLogin: function transitionToLogin() {
      this.transitionTo('login');
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var redirect = this.get('pageRedirect');

      if (!Ember.isEmpty(redirect)) {
        var isImpersonateRequest = redirect.indexOf('/login-as?ott=') > -1;

        if (!isImpersonateRequest) {
          this.set('pageRedirect', null);
          location.href = redirect;
        }
      } else {
        return this._super.apply(this, arguments);
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      var logoutUrl = this.get('currentUser.logoutUrl');

      if (logoutUrl) {
        location.href = logoutUrl;
      } else {
        window.location.replace('/');
      }
    },
    hideMobileMenu: function hideMobileMenu() {
      Ember.$('body').removeClass('sidebar-mobile-main');
    },
    activate: function activate() {
      var _this2 = this;

      window.addEventListener('beforeunload', function (e) {
        if (_this2.showWarningDialog()) {
          e.returnValue = WARNING_DIALOG;
          return WARNING_DIALOG;
        }

        return true;
      });
      this.get('expires').startPollingNewVersion();
    },
    showWarningDialog: function showWarningDialog() {
      return this.get('currentUser').hasEnteredData();
    },
    actions: {
      listClients: function listClients() {
        return this.transitionTo('clients.filter-active');
      },
      listLeads: function listLeads() {
        return this.transitionTo('leads.filter-active');
      },
      listLicensors: function listLicensors() {
        return this.transitionTo('licensors.filter-active');
      },
      listLicensor: function listLicensor(licensor) {
        return this.transitionTo('licensor.index', licensor);
      },
      listStudiesPartners: function listStudiesPartners() {
        return this.transitionTo('studies-partners.all');
      },
      listStudiesPartner: function listStudiesPartner(studiesPartner) {
        return this.transitionTo('studies-partner.index', studiesPartner);
      },
      listLead: function listLead(lead) {
        return this.transitionTo('lead.index', lead);
      },
      listOwners: function listOwners(type, owner) {
        if (this.get('breadCrumbs').transitionUp(this)) {
          return true;
        }

        if (this.get('currentUser.isDistributor') && type === 'device' && !owner) {
          return this.transitionTo('clients.filter-active');
        }

        if (owner && owner instanceof _emberData.default.Model) {
          var modelName = owner.constructor.modelName;
          return this.transitionTo("".concat(modelName, ".").concat((0, _emberInflector.pluralize)(type)), owner);
        }

        return this.transitionTo("".concat((0, _emberInflector.pluralize)(type), ".all"));
      },
      showOwner: function showOwner(modelName, model) {
        return this.transitionTo("".concat(modelName, ".index"), model);
      },
      // Placeholder actions that can be ignored when they bubble through
      saveComplete: function saveComplete() {},
      deleteComplete: function deleteComplete() {},
      cancelComplete: function cancelComplete() {},
      willTransition: function willTransition(t) {
        if (this.showWarningDialog() && !confirm(WARNING_DIALOG)) {
          return t.abort();
        }

        this.set('timingStart', performance.now()); // const scheduledLoading = run.later(this, function() {
        //   // $('.page-loading-indicator').css('display', 'block');
        //   $(".content-wrapper:first").addClass('content-wrapper--loading');
        // }, 250);
        // this.set('scheduledLoading', scheduledLoading);

        this.hideMobileMenu();
      },
      didTransition: function didTransition() {
        var scheduledLoading = this.get('scheduledLoading');

        if (scheduledLoading) {
          Ember.run.cancel(scheduledLoading);
        }

        this.set('currentUser.renderTransitionTime', Math.round(performance.now() - this.get('timingStart'))); // $('.page-loading-indicator').css('display', 'none');
        // $(".content-wrapper:first").removeClass('content-wrapper--loading');
      },
      resumeUpload: function resumeUpload(file) {
        return this.get('uploader').resume(file);
      }
    }
  });

  _exports.default = _default;
});