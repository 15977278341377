define("backend/helpers/not-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notEmpty = notEmpty;
  _exports.default = void 0;

  function notEmpty(params
  /*, hash*/
  ) {
    return params[0] && Object.keys(params[0]).length !== 0;
  }

  var _default = Ember.Helper.helper(notEmpty);

  _exports.default = _default;
});