define("backend/pods/movies/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKoGJFTW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Create Movie\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"tabbable\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"tab-pane active\"],[8],[0,\"\\n            \"],[1,[28,\"movies-component\",null,[[\"data\",\"createTemplate\",\"createMovie\",\"showContainer\",\"showEntries\"],[[24,[\"model\"]],[24,[\"model\",\"template\"]],true,false,false]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/movies/create/template.hbs"
    }
  });

  _exports.default = _default;
});