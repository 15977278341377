define("backend/pods/components/hm-models/list/contracts/place-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cy15FOET",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[24,[\"model\",\"orderStatus\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"model\",\"isSigned\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"target\",\"tilte\",\"query\",\"route\"],[\"_blank\",\"Prefill device order form\",[28,\"hash\",null,[[\"contract\"],[[24,[\"model\",\"id\"]]]]],\"sales.device-order\"]],{\"statements\":[[0,\"Place Order\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    No status available\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/contracts/place-order/template.hbs"
    }
  });

  _exports.default = _default;
});