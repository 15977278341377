define("backend/pods/components/hm-model/fieldset-profile/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgdY0x7X",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"icon-phone2 position-left\"],[8],[9],[0,\" Contact\\n\"],[9],[0,\"\\n\\n\"],[4,\"hm-form/wrapper\",null,[[\"model\"],[[24,[\"model\",\"buffers\",\"selected\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/pods/components/hm-model/fieldset-profile/contact/template.hbs' @ L6:C4) \"],null]],[[\"field\",\"type\"],[\"phone\",\"phone\"]]],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/pods/components/hm-model/fieldset-profile/contact/template.hbs' @ L7:C4) \"],null]],[[\"field\",\"type\"],[\"email\",\"email\"]]],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/pods/components/hm-model/fieldset-profile/contact/template.hbs' @ L8:C4) \"],null]],[[\"field\",\"type\"],[\"website\",\"website\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showDistributor\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"select\"]],\"expected `f.select` to be a contextual component but found a string. Did you mean `(component f.select)`? ('backend/pods/components/hm-model/fieldset-profile/contact/template.hbs' @ L11:C6) \"],null]],[[\"field\",\"data\"],[\"distributor\",[24,[\"distributors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-profile/contact/template.hbs"
    }
  });

  _exports.default = _default;
});