define("backend/models/hm-task", ["exports", "ember-data", "ember-cp-validations", "backend/models/buffered-model", "moment"], function (_exports, _emberData, _emberCpValidations, _bufferedModel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  function isInXDays(days) {
    return Ember.computed('dueDate', function () {
      return (0, _moment.default)(this.get('dueDate')).isSame((0, _moment.default)().add(days, 'day'), 'day');
    });
  }

  var _default = _bufferedModel.default.extend({
    description: attr('string'),
    status: attr('number'),
    dueDate: attr('string', {
      defaultValue: function defaultValue() {
        return new Date().toISOString().slice(0, 'YYYY-MM-DD'.length);
      }
    }),
    type: attr('string'),
    info: attr('string'),
    priority: attr('number'),
    client: attr(),
    lead: attr(),
    department: attr(),
    contract: attr(),
    descriptionBeforeRef: Ember.computed('description', function () {
      var description = this.get('description');

      if (description && description.includes('<reference>')) {
        return description.split('<reference>')[0];
      }

      return description;
    }),
    descriptionAfterRef: Ember.computed('description', function () {
      var description = this.get('description');

      if (description && description.includes('<reference>')) {
        return description.split('<reference>')[1];
      }

      return description;
    }),
    isPriority: Ember.computed.gt('priority', 0),
    isChecked: Ember.computed.equal('status', 2),
    isUnchecked: Ember.computed.not('isChecked'),
    isToday: Ember.computed('dueDate', 'isOverdue', function () {
      return !this.get('isOverdue') && (0, _moment.default)(this.get('dueDate')).isSame((0, _moment.default)(), 'day');
    }),
    isTomorrow: isInXDays(1),
    isIn2Days: isInXDays(2),
    isIn3Days: isInXDays(3),
    isIn4Days: isInXDays(4),
    isIn5Days: isInXDays(5),
    isIn6Days: isInXDays(6),
    isIn7Days: isInXDays(7),
    isOverdue: Ember.computed('dueDate', function () {
      return (0, _moment.default)(this.get('dueDate')).isBefore((0, _moment.default)().startOf('day'));
    }),
    toggleChecked: function toggleChecked() {
      var adapter = this.get('store').adapterFor('hm-task');
      return adapter.updateChecked(this.get('id'), !this.get('isChecked'));
    },
    taskComponent: Ember.computed('type', function () {
      switch (this.get('type')) {
        case '40':
          return 'hm-models/list/task/row-billings-contract';

        case '51':
          return 'hm-models/list/task/row-material-order';

        case '52':
          return 'hm-models/list/task/row-repair-order';
      }

      return null;
    }),
    typeHasComponent: Ember.computed.notEmpty('taskComponent'),
    validators: {
      dueDate: (0, _emberCpValidations.validator)('presence', true),
      type: (0, _emberCpValidations.validator)('presence', true),
      department: (0, _emberCpValidations.validator)('presence', true),
      description: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});