define("backend/pods/components/hm-model/fieldset-profile/detail-investor/component", ["exports", "backend/pods/components/hm-model/fieldset-profile/-detail-base"], function (_exports, _detailBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_detailBase.default, {
    keywords: Ember.inject.service(),
    statusModel: 'investor-status'
  });

  _exports.default = _default;
});