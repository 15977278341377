define("backend/pods/devices/overview/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      t: {
        refreshModel: true
      }
    },
    filter: ['device', 'not_delivered', ['t:type']],
    actions: {
      deviceChanged: function deviceChanged() {
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});