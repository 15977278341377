define("backend/utils/static-data/device-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: '1',
    name: 'Assembled'
  }, {
    id: '2',
    name: 'Check -> Health Check'
  }, {
    id: '3',
    name: 'Ready to ship'
  }, {
    id: '5',
    name: 'Delivered'
  }, {
    id: '6',
    name: 'Disassembled'
  }];
  _exports.default = _default;
});