define("backend/pods/components/hm-model/fieldset-profile/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O8uL7ZAj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold\"],[8],[7,\"i\",true],[10,\"class\",\"icon-notebook position-left\"],[8],[9],[0,\" Notes\"],[9],[0,\"\\n\"],[1,[28,\"hm-form/control2\",null,[[\"model\",\"field\",\"ctrl\",\"opts\",\"placeholder\"],[[24,[\"buffers\",\"selected\"]],\"notes\",\"hm-form/textarea\",\"fullsize\",\"Enter notes...\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-profile/notes/template.hbs"
    }
  });

  _exports.default = _default;
});