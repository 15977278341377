define("backend/authenticators/hm-token", ["exports", "ember-simple-auth/authenticators/base", "backend/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import $ from 'jquery';
  var _default = _base.default.extend({
    features: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    serverTokenEndpoint: _environment.default.host + '/auth/user',
    serverTokenEndpointOneTime: _environment.default.host + '/auth/token',
    serverTokenRevocationEndpoint: _environment.default.host + '/auth/logout',
    serverTokenRevocationEndpointOneTime: _environment.default.host + '/auth/logout',
    makeRequest: function makeRequest(url, data) {
      return this.get('ajax').request(url, {
        method: 'POST',
        data: data,
        contentType: 'application/json',
        dataType: 'json'
      });
    },
    resetExpireDate: function resetExpireDate() {
      return window.localStorage.removeItem('last_request');
    },
    // Private: Restore Session Promise (e.g. check token expiry date).
    restore: function restore(response) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(response.data.token)
        /* && !this.get('expires').isTokenExpired()*/
        ) {
            // this.get('expires').startPolling();
            if (response && response.data) {
              (response.data.features || []).forEach(function (featureName) {
                _this.get('features').enable(featureName);
              });
            }

            return resolve(response);
          } else {
          return reject();
        }
      });
    },
    // Private: Authenticate User Promise.
    authenticate: function authenticate(username, password, oneTimeToken) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data, url;

        if (oneTimeToken) {
          data = {
            token: oneTimeToken
          };
          url = _this2.get('serverTokenEndpointOneTime');
        } else {
          data = {
            username: username,
            password: password
          };
          url = _this2.get('serverTokenEndpoint');
        }

        return _this2.makeRequest(url, {
          data: data
        }).then(function (response) {
          // this.get('expires').startPolling();
          if (response && response.data) {
            (response.data.features || []).forEach(function (featureName) {
              _this2.get('features').enable(featureName);
            });
          }

          return Ember.run(function () {
            return resolve(response);
          });
        }, function (xhr) {
          return Ember.run(function () {
            return reject(xhr.responseJSON || xhr.responseText || xhr.payload);
          });
        });
      });
    },
    // Private: Invalidate Session Promise (e.g. on logout).
    invalidate: function invalidate(data, options) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var url, payload;

        if (options && !Ember.isEmpty(options.impersonateUser)) {
          url = _this3.get('serverTokenRevocationEndpointOneTime');
          payload = {
            impersonate: true,
            impersonate_for: options.impersonateUser,
            token: data.data.token
          };
        } else {
          url = _this3.get('serverTokenRevocationEndpoint');
          payload = {
            token: data.data.token
          };
        }

        if (!Ember.isEmpty(url) && !Ember.isEmpty(payload)) {
          _this3.makeRequest(url, {
            data: payload
          }).then(function (response) {
            _this3.resetExpireDate();

            if (options && !Ember.isEmpty(options.impersonateUser) && response.data.token) {
              var _url = [location.href.slice(0, location.href.indexOf(options.currentUrl)), 'login-as'];

              _this3.set('currentUser.logoutUrl', "".concat(_url.join('/'), "?ott=").concat(response.data.token));
            }

            resolve();
          });
        } else {
          reject();
        }
      });
    }
  });

  _exports.default = _default;
});