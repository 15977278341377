define("backend/templates/components/link-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OsowVnGT",
    "block": "{\"symbols\":[\"device\",\"@model\"],\"statements\":[[7,\"div\",false],[3,\"on\",[\"click\",[23,0,[\"preventPropagation\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"deviceException\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[29,[\"/devices/\",[23,1,[\"id\"]]]]],[10,\"target\",\"_blank\"],[8],[1,[23,1,[\"displayName\"]],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/link-devices.hbs"
    }
  });

  _exports.default = _default;
});