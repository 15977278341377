define("backend/pods/components/hm-model/form/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/oi+SC3D",
    "block": "{\"symbols\":[\"dup\",\"flash\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,2,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,2,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[4,\"if\",[[24,[\"serverError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[24,[\"serverError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"entry-form\",null,[[\"selected\",\"supportsVATValidation\"],[[24,[\"selected\"]],false]],{\"statements\":[[0,\"  \"],[14,3,[[24,[\"selected\"]],[24,[\"displayName\"]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"duplicates\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"alert alert-warning alert-styled-left\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-semibold\"],[8],[0,\"Warning: Possible duplicates found!\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"style\",\"margin-top:6px\"],[8],[7,\"small\",true],[8],[0,\"Showing \"],[1,[24,[\"duplicates\",\"length\"]],false],[0,\" similar clients or leads with name \"],[7,\"em\",true],[8],[1,[22,\"displayName\"],false],[9],[9],[9],[0,\"\\n        \"],[7,\"ul\",true],[10,\"style\",\"margin-left:25px\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"duplicates\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[1,[23,1,[\"display_name\"]],false],[0,\" (\"],[1,[23,1,[\"ID\"]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/form/create/template.hbs"
    }
  });

  _exports.default = _default;
});