define("backend/utils/contracts", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcOrdinaryEndDate = calcOrdinaryEndDate;
  _exports.calcActualEndDate = calcActualEndDate;
  _exports.calcLastCancelDate = calcLastCancelDate;

  function calcOrdinaryEndDate(deliveryDate, duration) {
    if (!duration) {
      return deliveryDate;
    }

    var ordinaryEndDate = (0, _moment.default)(deliveryDate).add(duration, 'months');

    while (ordinaryEndDate.isBefore()) {
      ordinaryEndDate.add(duration, 'months');
    }

    return ordinaryEndDate.format('YYYY-MM-DD');
  }

  function calcLastCancelDate(deliveryDate, duration, noticePeriod) {
    if (!duration) {
      return deliveryDate;
    }

    return (0, _moment.default)(calcOrdinaryEndDate(deliveryDate, duration)).subtract(noticePeriod, 'months').format('YYYY-MM-DD');
  }

  function calcActualEndDate(deliveryDate, duration, noticePeriod, cancellationDate) {
    if (!duration) {
      return deliveryDate;
    }

    if (cancellationDate) {
      var actualCancellation = (0, _moment.default)(cancellationDate).add(noticePeriod, 'months');
      var currentPeriodEnd = (0, _moment.default)(deliveryDate).add(duration, 'months');

      while (!(actualCancellation.diff(currentPeriodEnd) <= 0)) {
        // isBefore or same day
        currentPeriodEnd.add(duration, 'months');
      }

      return currentPeriodEnd.format('YYYY-MM-DD');
    }

    return calcOrdinaryEndDate(deliveryDate, duration);
  }
});