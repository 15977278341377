define("backend/pods/client-groups/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function flatten(groupedCollection) {
    return groupedCollection.reduce(function (acc, collection) {
      acc.pushObjects(collection);
      return acc;
    }, []);
  }

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.all([this.get('store').query('lead', {
        page: {
          number: 1,
          size: 10000
        },
        type: 'client_group',
        exclude_clients: true
      }), this.get('store').query('client', {
        page: {
          number: 1,
          size: 10000
        },
        type: 'client_group'
      })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            leads = _ref2[0],
            clients = _ref2[1];

        var groups = leads.toArray().concat(clients.toArray());
        return Ember.RSVP.all(flatten(groups.map(function (clientLike) {
          // FIXME: Ember Update -> Ember-Data Hack
          var descendants = clientLike.hasMany('descendants').hasManyRelationship.members.toArray();
          return descendants.map(function (descendant) {
            return new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').findRecord(descendant.modelName, descendant.id).then(function (record) {
                return resolve(record);
              }).catch(function () {
                return resolve(_this.createForbiddenClient(descendant.modelName, descendant.id, clientLike));
              });
            });
          });
        }))).then(function (descendants) {
          return groups.concat(descendants);
        });
      });
    },
    createForbiddenClient: function createForbiddenClient(modelName, id, clientGroup) {
      return this.get('store').peekRecord(modelName, id) || this.get('store').createRecord(modelName, {
        id: id,
        clientGroup: clientGroup,
        displayName: "Could not load ".concat(modelName, " (Forbidden)")
      });
    }
  });

  _exports.default = _default;
});