define("backend/pods/components/hm-model/fieldset-profile/detail-lead/component", ["exports", "backend/pods/components/hm-model/fieldset-profile/-detail-base"], function (_exports, _detailBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_detailBase.default, {
    keywords: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    priorityList: Ember.computed(function () {
      return this.get('store').peekAll('priority');
    }),
    clientTypes: Ember.computed(function () {
      return this.get('store').peekAll('client-type');
    })
  });

  _exports.default = _default;
});