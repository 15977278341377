define("backend/mixins/buffer-dependencies-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    createDependencies: function createDependencies() {
      var _this = this;

      return this.eachRelationship(function (name, _ref) {
        var useBuffer = _ref.options.useBuffer,
            kind = _ref.kind,
            type = _ref.type;

        if (useBuffer && kind === 'belongsTo' && !_this.get(name)) {
          _this.set(name, _this.get('store').createRecord(type));
        }
      });
    },
    copyBelongsToState: function copyBelongsToState(curBuffer) {
      var _this2 = this;

      var belongsStates = {};
      this.eachRelationship(function (name, _ref2) {
        var useBuffer = _ref2.options.useBuffer,
            kind = _ref2.kind;

        if (!useBuffer && kind === 'belongsTo') {
          var hasChange = name in curBuffer.selected;

          if (hasChange) {
            belongsStates[name] = _this2.get(name);
          }
        }
      });
      return belongsStates;
    },
    copyHasManyState: function copyHasManyState() {
      var _this3 = this;

      var manyStates = {};
      this.eachRelationship(function (name, _ref3) {
        var useBuffer = _ref3.options.useBuffer,
            kind = _ref3.kind;

        if (useBuffer && kind === 'hasMany') {
          var manyItems = [];

          _this3.get(name).forEach(function (manyItem) {
            return manyItems.pushObject(manyItem);
          });

          manyStates[name] = manyItems;
        }
      });
      var indirectRel = this.indirectPromises();

      if (indirectRel != null) {
        var manyItems = [];
        indirectRel.promise.forEach(function (manyItem) {
          return manyItems.pushObject(manyItem);
        });
        manyStates[indirectRel.key] = manyItems;
      }

      return manyStates;
    },
    resetDependencies: function resetDependencies(belongsStates, manyStates) {
      var _this4 = this;

      Object.keys(belongsStates).forEach(function (name) {
        return _this4.set(name, belongsStates[name]);
      });
      Object.keys(manyStates).forEach(function (name) {
        return _this4.get(_this4.keyToPath(name)).setObjects(manyStates[name]);
      });
    },
    clearNewDependencies: function clearNewDependencies() {
      var _this5 = this;

      this.eachRelationship(function (name, _ref4) {
        var useBuffer = _ref4.options.useBuffer,
            kind = _ref4.kind;

        if (useBuffer && kind === 'hasMany') {
          var newDependencies = _this5.get(name).filterBy('isNew', true);

          Ember.run(function () {
            return newDependencies.forEach(function (item) {
              return item.rollbackAttributes();
            });
          });
        }
      });
    }
  });

  _exports.default = _default;
});