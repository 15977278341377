define("backend/pods/components/hm-model/form/essentials/component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    router: Ember.inject.service(),
    ownerType: null,
    // lead, client, distributor etc.
    keepSelected: true,
    afterCancelAction: 'listOwners',
    afterDeleteAction: 'listOwners',
    saveBtnClass: Ember.computed('selected.hasJustSaved', 'selected.hasBufferedChanges', function () {
      if (this.get('selected.hasJustSaved')) {
        return 'btn-primary btn-success';
      } else if (this.get('selected.hasBufferedChanges')) {
        return 'btn-primary';
      }

      return 'btn-primary style-primary-disabled';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.edit(this.get('selected'));
    },
    reInitModelChanges: Ember.observer('selected', function () {
      this.edit(this.get('selected'));
    }),
    notifyCancel: function notifyCancel() {
      this._super.apply(this, arguments);

      this.sendAction('afterCancelAction', this.get('ownerType'));
    },
    notifySaveComplete: function notifySaveComplete(model) {
      var _this = this;

      this._super.apply(this, arguments);

      var ownerType = this.get('ownerType');

      if (ownerType === 'lead' && model.get('departments').isAny('status.isWon')) {
        this.get('router').transitionTo('client.index', model.get('id'));
      } else {
        this._reloadEvents(model);

        this._reloadTasks(model);

        this._reloadCounters(model).then(function () {
          if (ownerType === 'lead' || ownerType === 'client' || ownerType === 'sponsored-client') {
            _this.edit(model);
          }
        });
      }
    },
    notifyDeleteComplete: function notifyDeleteComplete() {
      this._super.apply(this, arguments);

      this.sendAction('afterDeleteAction', this.get('ownerType'));
    },
    _reloadCounters: function _reloadCounters(model) {
      if (model) {
        if (model.then) {
          return model.then(function (m) {
            return m.reload();
          });
        } else {
          return model.reload();
        }
      }
    },
    _reloadEvents: function _reloadEvents(model) {
      model.get('events').reload();
    },
    _reloadTasks: function _reloadTasks(model) {
      var tasks = model.get('tasks');

      if (tasks) {
        tasks.reload();
      }
    },
    actions: {
      createUploadUrl: function createUploadUrl() {
        return this.createUploadUrl();
      }
    }
  });

  _exports.default = _default;
});