define("backend/pods/device/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zvsxVpib",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"devices-component\",null,[[\"editDevice\",\"selected\",\"data\",\"ownerType\",\"showTable\"],[[24,[\"model\",\"owner\"]],[24,[\"model\",\"owner\"]],[24,[\"model\"]],\"device\",false]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/device/index/template.hbs"
    }
  });

  _exports.default = _default;
});