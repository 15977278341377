define("backend/serializers/event", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function findOwner(hash, key) {
    var loadModelName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : key;

    if (hash[key]) {
      hash.async_ids['owner'] = {
        loadModelName: loadModelName,
        id: hash[key]
      };
    }
  }

  var _default = _application.default.extend({
    attrs: {
      inStore: 'store'
    },
    normalize: function normalize(typeClass, hash) {
      hash.async_ids = {
        owner: {
          loadModelName: null,
          id: null
        }
      };
      findOwner(hash, 'client');
      findOwner(hash, 'distributor');
      findOwner(hash, 'licensor');
      findOwner(hash, 'lead');
      findOwner(hash, 'tester');
      findOwner(hash, 'movie');
      findOwner(hash, 'device');
      findOwner(hash, 'study');
      findOwner(hash, 'sponsored_client', 'sponsored-client');
      findOwner(hash, 'store', 'in-store');

      if (hash['user']) {
        hash.async_ids.user = {
          loadModelName: 'user',
          id: hash.user
        };
      }

      var json = this._super(typeClass, hash);

      return json;
    }
  });

  _exports.default = _default;
});