define("backend/pods/components/hm-models/list/header-contracts/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'thead',
    classNames: ['thead--grouped'],
    groups: Ember.computed('columns', function () {
      return this.get('columns').mapBy('group').uniq();
    }),
    firstVisibleGroup: Ember.computed('groups.@each.isVisible', function () {
      return this.get('groups').findBy('isVisible');
    })
  });

  _exports.default = _default;
});