define("backend/pods/sales/devices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TjMW/U8W",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Sales Devices\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[10,\"style\",\"margin: 20px; padding: 0;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"isAdmin\"]]],null,{\"statements\":[[4,\"hm-form/wrapper\",null,[[\"model\",\"opts\"],[[23,0,[]],\"fullsize\"]],{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"col-sm-4\"],[8],[0,\"\\n              \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"select-value\"]],\"expected `f.select-value` to be a contextual component but found a string. Did you mean `(component f.select-value)`? ('backend/pods/sales/devices/template.hbs' @ L10:C16) \"],null]],[[\"field\",\"data\",\"placeholder\",\"acts\"],[\"distributor\",[24,[\"distributors\"]],\"Select Distributor\",[28,\"hash\",null,[[\"setAction\",\"openAction\"],[[28,\"action\",[[23,0,[]],\"setDistributor\"],null],[28,\"action\",[[23,0,[]],\"loadDistributors\"],null]]]]]]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"model\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"hm-chart/sales-devices\",null,[[\"data\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/sales/devices/template.hbs"
    }
  });

  _exports.default = _default;
});