define("backend/pods/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      return this.get('userSettings.salesType').then(function (t) {
        var route = _this.get('currentUser.initRoute');

        if (route) {
          if (route.match('client') || route.match('lead') || route.match('sponsored-client')) {
            return _this.transitionTo(route, {
              queryParams: {
                t: t
              }
            });
          }

          return _this.transitionTo(route);
        }
      });
    }
  });

  _exports.default = _default;
});