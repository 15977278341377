define("backend/pods/components/hm-models/list/support-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VCyIR1Cp",
    "block": "{\"symbols\":[\"SupportAction\",\"@model\"],\"statements\":[[4,\"if\",[[23,2,[\"id\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"concat\",[\"hm-models/list/support-actions/\",[23,2,[\"id\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@model\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  Not found\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/support-actions/template.hbs"
    }
  });

  _exports.default = _default;
});