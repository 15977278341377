define("backend/models/simple/billing-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clonePosition = clonePosition;
  _exports.default = void 0;
  var UNITS = {
    STK: 'STK',
    PKG: 'PKG',
    HOR: 'HOR',
    MONTHLY: 'MONTHLY',
    F5_BOXES: 'F5_BOXES',
    F10_BOXES: 'F10_BOXES',
    F15_BOXES: 'F15_BOXES',
    F20_BOXES: 'F20_BOXES'
  };
  var INTERVALS = {
    THREE_YEARS: 'THREE_YEARS',
    ONE_YEAR: 'ONE_YEAR',
    N_A: 'N_A',
    DEFAULT: '12'
  };
  var PREFILL_ART_NO = {
    '---': {},
    '1A-01-DE': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '149.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1A-01-EN': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '149.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1A-02-DE': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '199.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1A-02-EN': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '199.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1A-02-FR': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '199.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1B-01-DE': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '69.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1C-00': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '0.00'
    },
    '1S-01': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '29.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1S-02': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '299.00'
    },
    '1S-03': {
      amount: 1,
      unitIntlKey: UNITS.MONTHLY,
      netUnitPrice: '9.00',
      intervals: INTERVALS.THREE_YEARS
    },
    '1S-04': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '99.00'
    },
    '1P-01': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '149.00'
    },
    '1H-05': {
      amount: 1,
      unitIntlKey: UNITS.F5_BOXES,
      netUnitPrice: '125.00'
    },
    '1H-06': {
      amount: 1,
      unitIntlKey: UNITS.F10_BOXES,
      netUnitPrice: '238.00'
    },
    '1H-07': {
      amount: 1,
      unitIntlKey: UNITS.F15_BOXES,
      netUnitPrice: '338.00'
    },
    '1H-08': {
      amount: 1,
      unitIntlKey: UNITS.F20_BOXES,
      netUnitPrice: '425.00'
    },
    '1M-01': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '50.00'
    },
    '1M-02': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '200.00'
    },
    '1M-05': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '50.00'
    },
    '1M-06': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '200.00'
    },
    '1M-09': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '3900.00'
    },
    '1M-10': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '6900.00'
    },
    '1Z-02': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '5.00'
    },
    '1Z-03': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '69.00'
    },
    '1Z-04': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '49.00'
    },
    '1Z-05': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '84'
    },
    '1Z-06': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '130'
    },
    '1Z-07': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '0'
    },
    '1PP-01': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '15'
    },
    '1PP-02': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '30'
    },
    '4N-D1': {
      amount: 1,
      unitIntlKey: UNITS.STK,
      netUnitPrice: '0.00'
    }
  };
  var T_DESCR = {
    'deu': {
      '1A-01-DE': 'monatliche Miete: Basis-Filmpaket',
      '1A-01-EN': 'monthly fee: base package',
      '1A-02-DE': 'monatliche Miete: Standard-Filmpaket',
      '1A-02-EN': 'monthly fee: standard package',
      '1A-02-FR': 'monthly fee: standard package',
      '1B-01-DE': 'monatliche Miete EMT',
      '1C-00': 'Gebühr Filme & Updates',
      '1S-01': 'Erweiterter Reparatur- und Ersatzservice',
      '1S-02': 'Aufwand für Setup und Einweisung',
      '1S-03': 'Erweiterter Reparatur- und Ersatzservice',
      '1S-04': 'Pauschale für Setup',
      '1P-01': 'Kissen für Nutzung der Videobrille in Bauchlage',
      '1H-05': '250 Anwendungen',
      '1H-06': '500 Anwendungen',
      '1H-07': '750 Anwendungen',
      '1H-08': '1000 Anwendungen',
      '1M-01': 'Info-Flyer für PatientInnen DE (Pkg. zu 100 ST.)',
      '1M-02': 'Info-Flyer für PatientInnen DE (Pkg. zu 500 ST.)',
      '1M-05': '',
      '1M-06': '',
      '1M-09': 'Ankauf HappyMed Videobrille',
      '1M-10': 'Ankauf HappyMed Videobrille inkl. voller gewerblicher Nutzungsrechte für Filminhalte im Lizenzgebiet DE / AT / CH (80+ Filme, 5-120 Minuten-Beiträge) für 3 Jahre. Ab dem 4. Jahr wird das Angebot auf die Kategorie Natur und Entspannung reduziert.',
      '1Z-02': 'Flachbatterien für Fernbedienung (Pkg. zu 2 ST)',
      '1Z-03': 'Ladenetzteile zum Aufladen des Akkus ',
      '1Z-04': 'Zusätzliches Eyeshield zur Abschirmung ',
      '1Z-05': 'Fernbedienung inkl. Schutzhülle',
      '1Z-06': 'HappyMed adaptierte Kopfhörer',
      '1Z-07': '',
      '1PP-01': '',
      '1PP-02': '',
      '4N-D1': ''
    },
    'eng': {
      '1A-01-DE': '',
      '1A-01-EN': 'monthly fee: base configuration',
      '1A-02-DE': '',
      '1A-02-EN': 'monthly fee: standard configuration',
      '1A-02-FR': 'monthly fee: standard configuration',
      '1B-01-DE': '',
      '1C-00': '',
      '1S-01': 'Comprehensive Repair & Replacement Service',
      '1S-02': 'Setting up infrastructure',
      '1S-03': '',
      '1S-04': '',
      '1P-01': 'usage in prone position',
      '1H-05': 'white disposable covers, 250 applications',
      '1H-06': 'white disposable covers, 500 applications',
      '1H-07': 'white disposable covers, 750 applications',
      '1H-08': 'white disposable covers, 1000 applications',
      '1M-01': '',
      '1M-02': '',
      '1M-05': 'Patient Information explaining HappyMed (100 pieces)',
      '1M-06': 'Patient Information explaining HappyMed (500 pieces)',
      '1M-09': 'HappyMed Videobrille Hardware',
      '1M-10': 'HappyMed Videobrille Standard',
      '1Z-02': 'flat batteries for remote control (2 pieces)',
      '1Z-03': 'to charge the HappyMed device',
      '1Z-04': 'addtional shield to protect patients eyes',
      '1Z-05': 'plus cover',
      '1Z-06': 'Happymed special headphones',
      '1Z-07': '',
      '1PP-01': '',
      '1PP-02': '',
      '4N-D1': ''
    },
    'fra': {
      '1A-01-DE': '',
      '1A-01-EN': 'monthly fee: base configuration',
      '1A-02-DE': '',
      '1A-02-EN': 'monthly fee: standard configuration',
      '1A-02-FR': 'monthly fee: standard configuration',
      '1B-01-DE': '',
      '1C-00': '',
      '1S-01': 'Comprehensive Repair & Replacement Service',
      '1S-02': 'Setting up infrastructure',
      '1S-03': '',
      '1S-04': '',
      '1P-01': 'usage in prone position',
      '1H-05': 'white disposable covers, 250 applications',
      '1H-06': 'white disposable covers, 500 applications',
      '1H-07': 'white disposable covers, 750 applications',
      '1H-08': 'white disposable covers, 1000 applications',
      '1M-01': '',
      '1M-02': '',
      '1M-05': 'Patient Information explaining HappyMed (100 pieces)',
      '1M-06': 'Patient Information explaining HappyMed (500 pieces)',
      '1M-09': 'HappyMed Videobrille Hardware',
      '1M-10': 'HappyMed Videobrille Standard',
      '1Z-02': 'flat batteries for remote control (2 pieces)',
      '1Z-03': 'to charge the HappyMed device',
      '1Z-04': 'addtional shield to protect patients eyes',
      '1Z-05': 'plus cover',
      '1Z-06': 'Happymed special headphones',
      '1Z-07': '',
      '1PP-01': '',
      '1PP-02': '',
      '4N-D1': ''
    }
  };
  var T_TITLE = {
    'deu': {
      '1A-01-DE': 'HappyMed Videobrille Basis',
      '1A-01-EN': 'HappyMed Videobrille Basis',
      '1A-02-DE': 'HappyMed Videobrille Standard',
      '1A-02-EN': 'HappyMed Videobrille Standard',
      '1A-02-FR': 'HappyMed Videobrille Standard',
      '1B-01-DE': 'EasyMovie Tablet',
      '1C-00': 'Content-Paket',
      '1S-01': 'HappyMed Videobrille Versicherung',
      '1S-02': 'Setup Gebühr',
      '1S-03': 'EasyMovie Tablet Versicherung',
      '1S-04': 'EasyMovie Setup Gebühr',
      '1P-01': 'HappyMed Kissen',
      '1H-05': '5 Boxen Hygiene-Schutz für Kopfhörer',
      '1H-06': '10 Boxen Hygiene-Schutz für Kopfhörer',
      '1H-07': '15 Boxen Hygiene-Schutz für Kopfhörer',
      '1H-08': '20 Boxen Hygiene-Schutz für Kopfhörer',
      '1M-01': 'Patienten-Info',
      '1M-02': 'Patienten-Info',
      '1M-05': '',
      '1M-06': '',
      '1M-09': 'HappyMed Videobrille Hardware',
      '1M-10': 'HappyMed Videobrille Standard',
      '1Z-02': 'Flachbatterien',
      '1Z-03': 'HappyMed Ladenetzteil',
      '1Z-04': 'Augenabdeckung',
      '1Z-05': 'Fernbedienung',
      '1Z-06': 'Kopfhörer',
      '1Z-07': 'Starterpaket',
      '1PP-01': 'Versandkostenpauschale',
      '1PP-02': 'Versandkostenpauschale',
      '4N-D1': 'Reparatur-Service'
    },
    'eng': {
      '1A-01-DE': '',
      '1A-01-EN': 'HappyMed Videoglasses Base',
      '1A-02-DE': '',
      '1A-02-EN': 'HappyMed Videoglasses Standard',
      '1A-02-FR': 'HappyMed Videoglasses Standard',
      '1B-01-DE': '',
      '1C-00': '',
      '1S-01': 'HappyMed Videoglasses Insurance',
      '1S-02': 'Setup Fee',
      '1S-03': '',
      '1S-04': '',
      '1P-01': 'Happy Med Pillow',
      '1H-05': 'Ear pad covers',
      '1H-06': 'Ear pad covers',
      '1H-07': 'Ear pad covers',
      '1H-08': 'Ear pad covers',
      '1M-01': '',
      '1M-02': '',
      '1M-05': 'Patient Leaflet EN',
      '1M-06': 'Patient Leaflet EN',
      '1M-09': 'HappyMed Videobrille Hardware',
      '1M-10': 'HappyMed Videobrille Standard',
      '1Z-02': 'flat batteries',
      '1Z-03': 'Charger',
      '1Z-04': 'eye shield',
      '1Z-05': 'remote control',
      '1Z-06': 'headphones',
      '1Z-07': 'Starter package',
      '1PP-01': 'Shipping fee',
      '1PP-02': 'Shipping fee',
      '4N-D1': 'Repair service'
    },
    'fra': {
      '1A-01-DE': '',
      '1A-01-EN': 'HappyMed Videoglasses Base',
      '1A-02-DE': '',
      '1A-02-EN': 'HappyMed Videoglasses Standard',
      '1A-02-FR': 'HappyMed Videoglasses Standard',
      '1B-01-DE': '',
      '1C-00': '',
      '1S-01': 'HappyMed Videoglasses Insurance',
      '1S-02': 'Setup Fee',
      '1S-03': '',
      '1S-04': '',
      '1P-01': 'Happy Med Pillow',
      '1H-05': 'Ear pad covers',
      '1H-06': 'Ear pad covers',
      '1H-07': 'Ear pad covers',
      '1H-08': 'Ear pad covers',
      '1M-01': '',
      '1M-02': '',
      '1M-05': 'Patient Leaflet EN',
      '1M-06': 'Patient Leaflet EN',
      '1M-09': 'HappyMed Videobrille Hardware',
      '1M-10': 'HappyMed Videobrille Standard',
      '1Z-02': 'flat batteries',
      '1Z-03': 'Charger',
      '1Z-04': 'eye shield',
      '1Z-05': 'remote control',
      '1Z-06': 'headphones',
      '1Z-07': 'Starter package',
      '1PP-01': 'Shipping fee',
      '1PP-02': 'Shipping fee',
      '4N-D1': 'Repair service'
    }
  };
  var T_UNIT = {
    'deu': {
      'STK': 'ST',
      'PKG': 'Pkg.',
      'HOR': 'h',
      'MONTHLY': 'Gebühr/Monat',
      'F5_BOXES': '5 Boxen',
      'F10_BOXES': '10 Boxen',
      'F15_BOXES': '15 Boxen',
      'F20_BOXES': '20 Boxen'
    },
    'eng': {
      'STK': 'pcs.',
      'PKG': 'pkg.',
      'HOR': 'h',
      'MONTHLY': 'Fee/Month',
      'F5_BOXES': '5 Boxes',
      'F10_BOXES': '10 Boxes',
      'F15_BOXES': '15 Boxes',
      'F20_BOXES': '20 Boxes'
    },
    'fra': {
      'STK': 'pce.',
      'PKG': 'pqt.',
      'HOR': 'h',
      'MONTHLY': 'Fee/Month',
      'F5_BOXES': '5 Boxes',
      'F10_BOXES': '10 Boxes',
      'F15_BOXES': '15 Boxes',
      'F20_BOXES': '20 Boxes'
    }
  };
  var T_INTERVALS = {
    'deu': {
      'THREE_YEARS': [{
        id: '1',
        displayName: '1M'
      }, {
        id: '2',
        displayName: '2M'
      }, {
        id: '3',
        displayName: '3M'
      }, {
        id: '6',
        displayName: '6M'
      }, {
        id: '7',
        displayName: '7M'
      }, {
        id: '8',
        displayName: '8M'
      }, {
        id: '9',
        displayName: '9M'
      }, {
        id: '10',
        displayName: '10M'
      }, {
        id: '11',
        displayName: '11M'
      }, {
        id: '12',
        displayName: '1J'
      }, {
        id: '24',
        displayName: '2J'
      }, {
        id: '36',
        displayName: '3J'
      }],
      'ONE_YEAR': [{
        id: '1',
        displayName: '1M'
      }, {
        id: '2',
        displayName: '2M'
      }, {
        id: '3',
        displayName: '3M'
      }, {
        id: '6',
        displayName: '6M'
      }, {
        id: '7',
        displayName: '7M'
      }, {
        id: '8',
        displayName: '8M'
      }, {
        id: '9',
        displayName: '9M'
      }, {
        id: '10',
        displayName: '10M'
      }, {
        id: '11',
        displayName: '11M'
      }, {
        id: '12',
        displayName: '1J'
      }],
      'N_A': [{
        id: '0',
        displayName: 'n/a'
      }]
    },
    'eng': {
      'THREE_YEARS': [{
        id: '1',
        displayName: '1M'
      }, {
        id: '2',
        displayName: '2M'
      }, {
        id: '3',
        displayName: '3M'
      }, {
        id: '6',
        displayName: '6M'
      }, {
        id: '7',
        displayName: '7M'
      }, {
        id: '8',
        displayName: '8M'
      }, {
        id: '9',
        displayName: '9M'
      }, {
        id: '10',
        displayName: '10M'
      }, {
        id: '11',
        displayName: '11M'
      }, {
        id: '12',
        displayName: '1Y'
      }, {
        id: '24',
        displayName: '2Y'
      }, {
        id: '36',
        displayName: '3Y'
      }],
      'ONE_YEAR': [{
        id: '1',
        displayName: '1M'
      }, {
        id: '2',
        displayName: '2M'
      }, {
        id: '3',
        displayName: '3M'
      }, {
        id: '6',
        displayName: '6M'
      }, {
        id: '7',
        displayName: '7M'
      }, {
        id: '8',
        displayName: '8M'
      }, {
        id: '9',
        displayName: '9M'
      }, {
        id: '10',
        displayName: '10M'
      }, {
        id: '11',
        displayName: '11M'
      }, {
        id: '12',
        displayName: '1Y'
      }],
      'N_A': [{
        id: '0',
        displayName: 'n/a'
      }]
    },
    'fra': {
      'THREE_YEARS': [{
        id: '1',
        displayName: '1M'
      }, {
        id: '2',
        displayName: '2M'
      }, {
        id: '3',
        displayName: '3M'
      }, {
        id: '6',
        displayName: '6M'
      }, {
        id: '7',
        displayName: '7M'
      }, {
        id: '8',
        displayName: '8M'
      }, {
        id: '9',
        displayName: '9M'
      }, {
        id: '10',
        displayName: '10M'
      }, {
        id: '11',
        displayName: '11M'
      }, {
        id: '12',
        displayName: '1A'
      }, {
        id: '24',
        displayName: '2A'
      }, {
        id: '36',
        displayName: '3A'
      }],
      'ONE_YEAR': [{
        id: '1',
        displayName: '1M'
      }, {
        id: '2',
        displayName: '2M'
      }, {
        id: '3',
        displayName: '3M'
      }, {
        id: '6',
        displayName: '6M'
      }, {
        id: '7',
        displayName: '7M'
      }, {
        id: '8',
        displayName: '8M'
      }, {
        id: '9',
        displayName: '9M'
      }, {
        id: '10',
        displayName: '10M'
      }, {
        id: '11',
        displayName: '11M'
      }, {
        id: '12',
        displayName: '1A'
      }],
      'N_A': [{
        id: '0',
        displayName: 'n/a'
      }]
    }
  };
  var BillingPosition = Ember.Object.extend({
    posNo: null,
    artNo: null,
    description: null,
    title: null,
    amount: null,
    unit: null,
    netUnitPrice: null,
    interval: null,
    netTotalPrice: null,
    // ------------------------------ LANGUAGE -----------------------------------
    _language: 'deu',
    setLanguage: function setLanguage(langCode) {
      this._language = langCode;
    },
    // ---------------------------------------------------------------------------
    clear: function clear(language) {
      this.setProperties({
        posNo: 1,
        artNo: null,
        description: null,
        amount: null,
        unit: null,
        netUnitPrice: null,
        interval: null,
        netTotalPrice: null
      });
      this.setLanguage(language);
    },
    isEmpty: function isEmpty() {
      return this.artNo == null && this.amount == null;
    },
    fillNetTotalPrice: Ember.observer('amount', 'netUnitPrice', 'interval', 'isFixedPrice', function () {
      var _this$getProperties = this.getProperties('amount', 'netUnitPrice', 'interval', 'isFixedPrice'),
          amount = _this$getProperties.amount,
          netUnitPrice = _this$getProperties.netUnitPrice,
          interval = _this$getProperties.interval,
          isFixedPrice = _this$getProperties.isFixedPrice;

      var netTotalPrice;

      if (isFixedPrice && netUnitPrice) {
        netTotalPrice = Number(netUnitPrice);
      } else if (amount && netUnitPrice) {
        netTotalPrice = interval && interval > 1 ? amount * netUnitPrice * interval : amount * netUnitPrice;
      }

      if (netTotalPrice && !isNaN(netTotalPrice)) {
        this.set('netTotalPrice', netTotalPrice.toFixed(2));
      }
    }),
    observeArticleNo: Ember.observer('artNo', function () {
      this.fillByArticleNo(this.get('artNo'));
    }),
    fillByArticleNo: function fillByArticleNo(artNo) {
      var attrs = artNo && Ember.copy(PREFILL_ART_NO[artNo]);

      if (attrs) {
        var amount = attrs.amount,
            netUnitPrice = attrs.netUnitPrice;
        attrs['netTotalPrice'] = (amount * netUnitPrice).toFixed(2);
        attrs['description'] = T_DESCR[this._language][artNo];
        attrs['title'] = T_TITLE[this._language][artNo];
        attrs['unit'] = T_UNIT[this._language][attrs['unitIntlKey']];
        delete attrs['intervals'];

        if (this.get('intervals').findBy('id', INTERVALS.DEFAULT)) {
          attrs['interval'] = INTERVALS.DEFAULT;
        } else {
          attrs['interval'] = INTERVALS.N_A[0].id;
        }

        this.setProperties(attrs);
      }
    },
    intervals: Ember.computed('artNo', function () {
      var artNo = this.get('artNo');
      var attrs = artNo && PREFILL_ART_NO[artNo];
      return T_INTERVALS[this._language][attrs && attrs.intervals || INTERVALS.N_A].map(function (d) {
        return Ember.Object.create(d);
      });
    })
  });

  function clonePosition(position) {
    return BillingPosition.create(position.getProperties('posNo', 'artNo', 'description', 'title', 'amount', 'unit', 'netUnitPrice', 'interval', 'netTotalPrice', '_language'));
  }

  var _default = BillingPosition;
  _exports.default = _default;
});