define("backend/prototypes/tables/headers/statistics-details", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'ID'
    },
    columns: [{
      attr: 'id',
      name: 'ID'
    }]
  }, {
    group: {
      name: 'Session Number'
    },
    columns: [{
      attr: 'session_id',
      name: 'Session Number'
    }]
  }, {
    group: {
      name: 'Session Settings'
    },
    columns: [{
      attr: 'session_details',
      name: 'Session Details'
    }, {
      attr: 'session_language',
      name: 'Session Language'
    }, {
      attr: 'session_gender',
      name: 'Session Gender'
    }, {
      attr: 'session_age',
      name: 'Session Age'
    }, {
      attr: 'session_duration',
      name: 'Session Duration'
    }, {
      attr: 'session_application',
      name: 'Session Application'
    }]
  }, {
    group: {
      name: 'Session Playback'
    },
    columns: [{
      attr: 'session_timestamp',
      name: 'Session Timestamp'
    }, {
      attr: 'start_time',
      name: 'Start Time'
    }, {
      attr: 'stop_time',
      name: 'Stop Time'
    }, {
      attr: 'start_timestamp',
      name: 'Start Timestamp'
    }, {
      attr: 'stop_timestamp',
      name: 'Stop Timestamp'
    }, {
      attr: 'total_duration',
      name: 'Total Duration'
    }, {
      attr: 'replay_duration',
      name: 'Replay Duration'
    }, {
      attr: 'pause_duration',
      name: 'Pause Duration'
    }, {
      attr: 'pauses',
      name: 'Pauses'
    }]
  }, {
    group: {
      name: 'Owner'
    },
    columns: [{
      attr: 'owner_name',
      name: 'Owner Name'
    }, {
      attr: 'owner_type',
      name: 'Owner Type'
    }]
  }, {
    group: {
      name: 'Device'
    },
    columns: [{
      attr: 'device',
      name: 'Device ID'
    }, {
      attr: 'serial_number',
      name: 'Device Serial Number'
    }]
  }, {
    group: {
      name: 'Category & Package'
    },
    columns: [{
      attr: 'movie_category',
      name: 'Category'
    }, {
      attr: 'movie_package',
      name: 'Package'
    }]
  }, {
    group: {
      name: 'Movie'
    },
    columns: [{
      attr: 'movie_id',
      name: 'Movie ID'
    }, {
      attr: 'movie_title',
      name: 'Movie Title'
    }, {
      attr: 'licensor_name',
      name: 'Licensor Name'
    }, {
      attr: 'movie_duration',
      name: 'Movie Duration'
    }, {
      attr: 'movie_viewed',
      name: 'Movie Viewed'
    }, {
      attr: 'revenue_share',
      name: 'Revenue Share'
    }]
  }]);

  _exports.default = _default;
});