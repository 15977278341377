define("backend/models/distributor", ["exports", "ember-data", "backend/models/partner-model", "backend/utils/cp/buffer-changes", "@ember-data/model"], function (_exports, _emberData, _partnerModel, _bufferChanges, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var BUFFER_BELONGS_TO = ['address', 'bank'];
  var BUFFER_HAS_MANY = ['contacts'];

  var _default = _partnerModel.default.extend({
    displayName: (0, _model.attr)('string'),
    status: belongsTo('distributor-status', {
      async: false
    }),
    typeName: 'Distributor',
    // ------------------------ GOOGLE MAP MARKERS  ------------------------------
    markerInfo: Ember.computed('displayName', function () {
      var info = [];
      info.push(['<p class="text-bold">', this.get('displayName'), '</p>'].join(''));
      return Ember.String.htmlSafe(info.join(''));
    }),
    markerIcon: Ember.computed(function () {
      return new google.maps.MarkerImage("/assets/hm_marker.png", new google.maps.Size(40, 40), new google.maps.Point(0, 40));
    }),
    getTax: function getTax() {
      if (Ember.isBlank(this.vat)) {
        return 20;
      }

      return 0;
    },
    // --------------------------- BUFFER TRACKING -------------------------------
    supportsDirtyBufferTracking: true,
    hasBufferedChangesContacts: (0, _bufferChanges.watchBufferedHasMany)('contacts'),
    hasBufferedChanges: (0, _bufferChanges.watchBufferChanges)(BUFFER_BELONGS_TO, BUFFER_HAS_MANY)
  });

  _exports.default = _default;
});