define("backend/pods/client/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model(params) {
      this.get('currentUser').trackSession('visit: client.route: ' + JSON.stringify(params));
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});