define("backend/models/department-status", ["exports", "ember-data", "backend/mixins/localize-mixin"], function (_exports, _emberData, _localizeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend(_localizeMixin.default, {
    markerPrefix: attr('number'),
    noteLabel: attr('string'),
    isClientStatus: attr('boolean', {
      defaultValue: false
    }),
    isLeadStatus: attr('boolean', {
      defaultValue: false
    }),
    isSponsoredClientStatus: attr('boolean', {
      defaultValue: false
    }),
    // Client Departments
    isClientInPreparation: attr('boolean'),
    isClientActive: attr('boolean'),
    isClientCancelled: attr('boolean'),
    // Lead Departments
    needsReason: attr('boolean'),
    needsDate: attr('boolean'),
    needsDateRangeAndDevices: attr('boolean'),
    needsNumOfDevices: attr('boolean'),
    // Sponsored Client Departments
    isSponsoredClientInPreparation: attr('boolean'),
    isSponsoredClientActive: attr('boolean'),
    isSponsoredClientCancelled: attr('boolean'),
    isActive: attr('boolean'),
    isOpen: attr('boolean'),
    isColdLead: attr('boolean'),
    isContacted: attr('boolean'),
    isDemoPlanned: attr('boolean'),
    isDemoDone: attr('boolean'),
    isInterestExpressed: attr('boolean'),
    isTesting: attr('boolean'),
    isOfferPhaseDone: attr('boolean'),
    isWon: attr('boolean'),
    isLost: attr('boolean'),
    isSelectable: attr('boolean', {
      defaultValue: true
    }),
    allowProbability: attr('boolean', {
      defaultValue: false
    }),
    requiresNextStep: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});