define("backend/components/g-map-marker", ["exports", "ember-g-map/components/g-map-marker"], function (_exports, _gMapMarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _gMapMarker.default.extend({
    sendOnClick: function sendOnClick() {
      this.sendAction('onClick', this.get('onClickTarget'));
    }
  });

  _exports.default = _default;
});