define("backend/templates/components/draggable/device-order-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVtXui54",
    "block": "{\"symbols\":[\"order\",\"@displayCreatedAt\",\"@displayCreatedBy\",\"@highlightOverdue\",\"@onOpen\",\"@hint\",\"@label\",\"@displayLoading\",\"@orders\",\"@onDragEnd\"],\"statements\":[[7,\"div\",true],[10,\"style\",\"min-width: 255px;\\n  max-width: 255px;\\n  margin: 0 4px;\\n  border-radius: 8px;\\n  background-color: #f7f8f9;\"],[8],[0,\"\\n\"],[4,\"draggable-object-target\",null,[[\"action\",\"overrideClass\"],[[23,10,[]],\"h-full\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"style\",\"\\n      padding:14px 8px\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[10,\"style\",\"\\n        font-size: 14px;\\n        font-weight: bold;\\n        padding: 4px 8px 4px 12px;\\n        gap: 7px;\"],[8],[0,\"\\n        \"],[1,[23,7,[]],false],[0,\"\\n\"],[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"          \"],[7,\"i\",true],[10,\"class\",\"icon-info22 color-subtle text-small\"],[11,\"title\",[23,6,[]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,8,[]]],null,{\"statements\":[[0,\"          \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"style\",\"\\n      padding: 1px 4px 0;\\n      margin: 0 4px 10px 4px;\\n      background-color: ##F7F8F9;\\n    \"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex flex-col\"],[10,\"style\",\"gap: 7px\"],[8],[0,\"\\n\"],[4,\"each\",[[23,9,[]]],null,{\"statements\":[[4,\"draggable-object\",null,[[\"content\"],[[23,1,[]]]],{\"statements\":[[0,\"            \"],[5,\"draggable/device-order\",[],[[\"@order\",\"@displayCreatedAt\",\"@displayCreatedBy\",\"@highlightOverdue\",\"@onOpen\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/draggable/device-order-list.hbs"
    }
  });

  _exports.default = _default;
});