define("backend/models/study-outcome", ["exports", "ember-data", "backend/mixins/localize-mixin"], function (_exports, _emberData, _localizeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_localizeMixin.default, {});

  _exports.default = _default;
});