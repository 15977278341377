define("backend/instance-initializers/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.inject('controller', 'currentUser', 'service:current-user');
  }

  var _default = {
    name: "current-user",
    initialize: initialize
  };
  _exports.default = _default;
});