define("backend/pods/distributor/statistics/controller", ["exports", "backend/pods/client/statistics/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: [{
      year: {
        scope: 'controller'
      }
    }, {
      month: {
        scope: 'controller'
      }
    }, {
      device: {
        scope: 'controller'
      }
    }, {
      deviceTypes: {
        scope: 'controller'
      }
    }, {
      include: {
        scope: 'controller'
      }
    }, {
      startDate: {
        scope: 'controller'
      }
    }, {
      endDate: {
        scope: 'controller'
      }
    }],
    include: null,
    includeOptions: Ember.computed(function () {
      return [Ember.Object.create({
        id: 'client',
        displayName: 'Client'
      }), Ember.Object.create({
        id: 'lead',
        displayName: 'Lead'
      })];
    })
  });

  _exports.default = _default;
});