define("backend/serializers/order", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function findOwner(hash, key) {
    var loadModelName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : key;

    if (hash[key]) {
      hash.async_ids['owner'] = {
        loadModelName: loadModelName,
        id: hash[key]
      };
    }
  }

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      hash.async_ids = {
        owner: {
          loadModelName: null,
          id: null
        }
      };
      findOwner(hash, 'client');
      findOwner(hash, 'lead');
      findOwner(hash, 'distributor');

      var json = this._super(typeClass, hash);

      return json;
    }
  });

  _exports.default = _default;
});