define("backend/pods/components/hm-table/render-promise/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "177SikIl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"is-fulfilled\",[[28,\"get\",[[24,[\"model\"]],[24,[\"ownerAttribute\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"get\",[[28,\"await\",[[28,\"get\",[[24,[\"model\"]],[24,[\"ownerAttribute\"]]],null]],null],[24,[\"valueAttribute\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-table/render-promise/template.hbs"
    }
  });

  _exports.default = _default;
});