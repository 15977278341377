define("backend/pods/device/starts/controller", ["exports", "ember-data", "backend/prototypes/tables/headers/device-starts"], function (_exports, _emberData, _deviceStarts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    selected: Ember.computed.reads('model.owner'),
    deviceStartsPage: 1,
    deviceStartsSize: 100,
    deviceStartsTableColumns: _deviceStarts.default,
    deviceStarts: Ember.computed('selected.id', function () {
      var _this = this;

      var device = this.get('selected.id');
      var adapter = this.get('store').adapterFor('device');
      return PromiseArray.create({
        promise: adapter.findDeviceStarts({
          device: device
        }).then(function (_ref) {
          var data = _ref.data,
              meta = _ref.meta;

          _this.set('deviceStartsMeta', meta);

          return data.map(function (obj) {
            return Ember.Object.create(obj);
          });
        })
      });
    })
  });

  _exports.default = _default;
});