define("backend/pods/logistics/material-orders/controller", ["exports", "backend/prototypes/tables/headers/material-orders"], function (_exports, _materialOrders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: _materialOrders.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    }
  });

  _exports.default = _default;
});