define("backend/pods/components/render-tbody-batch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function renderStep(ctx, id, steps, idx) {
    var step = steps[idx];

    if (!step || !ctx.isActiveRows(id)) {
      ctx.trackIncrementalRenderEnd(id);
      return function () {
        return 0;
      };
    } // console.log(`render ${id} with ${idx}`);


    var existing = ctx.get('rowsToRender');
    existing.pushObjects(step);

    if (!ctx.get('isDestroyed')) {
      ctx.set('rowsToRender', existing);
    }

    return function () {
      return window.requestAnimationFrame(renderStep(ctx, id, steps, idx + 1));
    };
  }

  var _default = Ember.Component.extend({
    tagName: 'tbody',
    batchLength: 30,
    incrementalRender: true,
    didReceiveAttrs: function didReceiveAttrs() {
      var rows = this.get('rows');
      var id = Ember.guidFor(rows);
      this.set('activeId', id); // console.log(`didReceiveAttrs: ${id}, ${rows.get('length')}`);

      var incrementalRender = this.get('incrementalRender');

      if (incrementalRender) {
        var batchLength = this.get('batchLength');
        var n = rows.get('length') / batchLength;
        var rowBatches = [];

        for (var idx = 0; idx < n; idx++) {
          var from = batchLength * idx;
          var to = from + batchLength;
          rowBatches.push(rows.slice(from, to));
        }

        this.set('rowsToRender', []);
        this.trackIncrementalRenderStart(id);
        window.requestAnimationFrame(renderStep(this, id, rowBatches, 0));
      } else {
        this.set('rowsToRender', rows);
      }
    },
    isActiveRows: function isActiveRows(id) {
      return this.get('activeId') === id;
    },
    trackIncrementalRenderStart: function trackIncrementalRenderStart(id) {// console.log(`start render of ${id}`);
      // this.set('start', performance.now());
    },
    trackIncrementalRenderEnd: function trackIncrementalRenderEnd(id) {// console.log(`${id} rendered in ${Math.round(performance.now() - this.get('start'))}`);
      // this.set('start', null);
    }
  });

  _exports.default = _default;
});