define("backend/prototypes/tables/headers/surveys", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'title',
    name: 'Title'
  }, {
    attr: 'version',
    name: 'Version'
  }, {
    attr: 'releaseDate',
    name: 'Release Date',
    format: 'date'
  }, {
    attr: 'file',
    noFormat: true
  }]);

  _exports.default = _default;
});