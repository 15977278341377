define("backend/pods/leads/filter-preordered/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      distributor: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      },
      organization: {
        refreshModel: true
      },
      department: {
        refreshModel: true
      },
      leadType: {
        refreshModel: true
      },
      t: {
        refreshModel: true
      }
    },
    filter: ['lead', 'preordered', ['distributor', 'status', 'source', 'organization', 'department', 'leadType', 't:type']],
    createQuery: function createQuery() {
      var query = this._super.apply(this, arguments);

      query.exclude_territories = true;
      return query;
    }
  });

  _exports.default = _default;
});