define("backend/serializers/movie", ["exports", "backend/serializers/application", "backend/utils/static-data/target-group"], function (_exports, _application, _targetGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      tags: {
        deserialize: 'ids',
        serialize: 'records'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      this._serializeTargetGroupIds(json);

      this._setEmptyClient(json);

      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      this._normalizeTargetGroup(payload);

      return this._super.apply(this, arguments);
    },
    _normalizeTargetGroup: function _normalizeTargetGroup(payload) {
      if (payload.data) {
        if (Ember.isArray(payload.data)) {
          payload.data.map(this._extractTargetGroupIds.bind(this));
        } else {
          this._extractTargetGroupIds(payload.data);
        }
      }
    },
    _extractTargetGroupIds: function _extractTargetGroupIds(hash) {
      var targetGroups = hash.target_group;
      var extractedTargetGroups = [];

      if (targetGroups) {
        if (Ember.isArray(targetGroups)) {
          hash.target_group = targetGroups.map(function (name) {
            return _targetGroup.default.findBy('name', name).id;
          });
        } else if (targetGroups.length === 5) {
          for (var idx = 0; idx < 5; idx++) {
            if (targetGroups[idx] == 1) {
              extractedTargetGroups.push(idx + 1);
            }
          }

          hash.target_group = extractedTargetGroups;
        }
      }
    },
    _setEmptyClient: function _setEmptyClient(hash) {
      hash.client = null;
    },
    _serializeTargetGroupIds: function _serializeTargetGroupIds(hash) {
      if (hash.target_group) {
        var targetGroups = hash.target_group;
        var serializedTargetGroup = "";

        for (var idx = 0; idx < 5; idx++) {
          if (targetGroups.includes("" + (idx + 1))) {
            serializedTargetGroup += "1";
          } else {
            serializedTargetGroup += "0";
          }
        }

        hash.target_group = serializedTargetGroup;
      }
    }
  });

  _exports.default = _default;
});