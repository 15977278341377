define("backend/pods/client-groups/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qx9x2f1n",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"All Client Groups\"]]],false],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"showPagination\",\"showFooter\",\"data\",\"isLoading\",\"rowComponent\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[false,false,[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],null],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/client-groups/index/template.hbs"
    }
  });

  _exports.default = _default;
});