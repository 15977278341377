define("backend/pods/components/hm-form/label2/component", ["exports", "backend/helpers/translate"], function (_exports, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['control-label'],
    classNameBindings: ['labelCol'],
    label: Ember.computed('field', 'customLabel', function () {
      if (this.get('customLabel')) {
        return this.get('customLabel');
      }

      return (0, _translate.translate)(this.get('field'));
    })
  });

  _exports.default = _default;
});