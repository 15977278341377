define("backend/instance-initializers/target-group", ["exports", "backend/utils/static-data/target-group"], function (_exports, _targetGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.lookup('service:store').pushPayload({
      targetGroups: _targetGroup.default
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});