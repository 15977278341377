define("backend/templates/components/chart-device-replays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2hRKSwQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"br\",true],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"statistic\",\"isPending\"]],[24,[\"devices\",\"isPending\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"loading-screen\"],[8],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n    loading...\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"chartData\",\"length\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"fromCreated\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"d3-multi-bar-chart\",null,[[\"data\",\"devices\",\"hasMultipleMonths\",\"fixedBarWidth\",\"createdAt\",\"additionalLegendDevices\",\"height\"],[[24,[\"chartData\"]],[24,[\"devices\"]],true,10,[24,[\"owner\",\"meta\",\"created\"]],[24,[\"additionalLegendDevices\"]],240]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"d3-multi-bar-chart\",null,[[\"data\",\"devices\",\"x0DataRange\"],[[24,[\"chartData\"]],[24,[\"devices\"]],[24,[\"x0DataRange\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"No data for chart found\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/chart-device-replays.hbs"
    }
  });

  _exports.default = _default;
});