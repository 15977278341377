define("backend/adapters/statistic", ["exports", "backend/adapters/statistics-summary"], function (_exports, _statisticsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _statisticsSummary.default.extend({
    pathForType: function pathForType() {
      return 'statistics/itemization';
    },
    queryOverview: function queryOverview(data) {
      var url = this.urlForOverview();
      data['owner_id'] = data['ownerId'];
      data['owner_type'] = data['ownerType'];
      data['include'] = (data['include'] || []).mapBy('id').join(',');
      delete data['ownerId'];
      delete data['ownerType'];
      return this.ajax(url, 'GET', {
        data: data
      });
    },
    urlForOverview: function urlForOverview() {
      var url = [];
      var prefix = this.urlPrefix();
      url.push('statistics');
      url.push('overview');

      if (prefix) {
        url.unshift(prefix);
      }

      return url.join('/');
    }
  });

  _exports.default = _default;
});