define("backend/templates/components/create-return", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N7jX+Kec",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"tabbable\"],[8],[0,\"\\n        \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs nav-tabs-bottom\"],[8],[0,\"\\n          \"],[7,\"li\",false],[12,\"class\",[29,[[28,\"if\",[[24,[\"showEssentials\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"changePage\",\"essentials\"]],[8],[0,\"\\n            \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Shipping Label\"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"li\",false],[12,\"class\",[29,[[28,\"if\",[[24,[\"showOther\"]],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"changePage\",\"other\"]],[8],[0,\"\\n            \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Pickup\"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"tab-pane active\"],[8],[0,\"\\n            \"],[15,\"partials/show-error\",[]],[0,\"\\n\\n            \"],[7,\"form\",true],[10,\"class\",\"form-horizontal js-edit-entry-form\"],[8],[0,\"\\n              \"],[15,[24,[\"templateEdit\"]],[]],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/create-return.hbs"
    }
  });

  _exports.default = _default;
});