define("backend/utils/hm-transforms/date-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DATE_FORMAT = 'YYYY-MM-DD';

  function deserialize(dateStr, dateIdx) {
    if (!dateStr) {
      dateStr = ';';
    }

    var date = dateStr.split(';')[dateIdx];
    return date ? (0, _moment.default)(date) : null;
  }

  function serialize(dateStr, date, dateIdx) {
    if (!dateStr) {
      dateStr = ';';
    }

    var _dateStr$split = dateStr.split(';'),
        _dateStr$split2 = _slicedToArray(_dateStr$split, 2),
        start = _dateStr$split2[0],
        end = _dateStr$split2[1];

    var newDate = date ? date.slice(0, DATE_FORMAT.length) : '';

    if (dateIdx === 0) {
      return [newDate, end].join(';');
    }

    return [start, newDate].join(';');
  }

  var _default = {
    serialize: serialize,
    deserialize: deserialize
  };
  _exports.default = _default;
});