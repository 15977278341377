define("backend/pods/investors/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    features: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('features').isEnabled('investors')) {
        throw new Error('Permission denied');
      }
    }
  });

  _exports.default = _default;
});