define("backend/prototypes/attachments/controller", ["exports", "backend/prototypes/tables/headers/attachments"], function (_exports, _attachments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    queryParams: ['page', 'size'],
    page: 1,
    size: 100,
    tableColumns: _attachments.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    }
  });

  _exports.default = _default;
});