define("backend/services/bread-crumbs", ["exports", "backend/models/user", "ember-inflector"], function (_exports, _user, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var T_SPONSORED_CLIENTS = 'Sponored Clients';
  var T_CLIENTS = 'Clients';
  var T_LEADS = 'Leads';
  var T_PROFILE = 'Profile';
  var T_BILLINGS = 'Billings';
  var T_DEVICES = 'Devices';
  var T_OFFERS = 'Offers';
  var T_EVENTS = 'Events';
  var T_ATTACHMENTS = 'Attachments';
  var T_STATISTICS = 'Statistics';
  var T_MOVIES = 'Movies';
  var T_BATTERY = 'Battery';
  var T_STARTS = 'Starts';
  var T_LOGS = 'Logs';
  var T_CONTENT = 'Content';
  var T_CONTACTS = 'Contacts';
  var T_ESSENTIALS = 'Essentials';
  var T_DEPARTMENTS = 'Departments';
  var T_STUDIES = 'Studies';
  var A_NAME = 'displayName';
  var A_NAME_ID = 'displayNameId';
  var T = {
    'index': T_PROFILE,
    'offers': T_OFFERS,
    'contacts': T_CONTACTS,
    'events': T_EVENTS,
    'attachments': T_ATTACHMENTS,
    'devices': T_DEVICES,
    'clients': T_CLIENTS,
    'sponsoredClients': T_SPONSORED_CLIENTS,
    'leads': T_LEADS,
    'movies': T_MOVIES,
    'billings': T_BILLINGS,
    'battery': T_BATTERY,
    'starts': T_STARTS,
    'logs': T_LOGS,
    'content': T_CONTENT,
    'essentials': T_ESSENTIALS,
    'departments': T_DEPARTMENTS,
    'studies': T_STUDIES
  };
  var T_OWNER = {
    'studies-partner': 'Studies Partner',
    'sponsored-client': 'Sponsored Client'
  };

  function parentLabel(type) {
    return (0, _emberInflector.pluralize)(T_OWNER[type] || Ember.String.capitalize(type));
  }

  function listRoute(type, route, label) {
    var routePrefix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    var structure = [];
    var parent = (0, _emberInflector.pluralize)(type);

    if (!label) {
      label = "".concat(Ember.String.capitalize(route), " ").concat((0, _emberInflector.pluralize)(parentLabel(type)));
    }

    structure.addObject({
      isList: true,
      route: "".concat(parent, ".").concat(routePrefix).concat(route),
      label: label
    });
    return structure;
  }

  function listRouteFilter(type, route) {
    return listRoute(type, route, null, 'filter-');
  }

  function ownerCreateRoute(owner) {
    var owners = (0, _emberInflector.pluralize)(owner);
    return [{
      isDetail: true,
      route: "".concat(owners, ".create"),
      label: "Create ".concat(parentLabel(owner))
    }];
  }

  function ownerRoute(type, route, label, isList) {
    var parentRoute = "".concat((0, _emberInflector.pluralize)(type), ".all");
    var detailRoute = type;
    var routeLabel = label || T[route];
    var showId = type === 'client' || type === 'lead' || type === 'studies-partner' || type === 'sponsored-client';
    return [{
      isList: true,
      route: parentRoute,
      label: parentLabel(type)
    }, {
      isDetail: true,
      route: detailRoute,
      labelOf: showId ? A_NAME_ID : A_NAME
    }, {
      isList: isList,
      isDetail: !isList,
      route: "".concat(type, ".").concat(route),
      label: routeLabel,
      modelOf: type
    }];
  }

  function studiesRoute(detailRoute, detailLabel) {
    return [{
      isList: true,
      route: 'studies-partners.all',
      label: 'Studies Partners'
    }, {
      isDetail: true,
      isList: false,
      route: 'studies-partner',
      labelOf: A_NAME_ID,
      modelOf: {
        modelFor: 'study',
        modelAttr: 'owner'
      }
    }, // Model Client
    {
      isList: true,
      isDetail: false,
      route: 'studies-partner.studies',
      label: 'Studies',
      modelOf: {
        modelFor: 'study',
        modelAttr: 'owner'
      }
    }, // Model Client
    {
      isList: false,
      isDetail: true,
      route: 'study',
      labelOf: 'title',
      modelOf: 'study'
    }, // Model Study
    {
      isList: false,
      isDetail: true,
      route: "study.".concat(detailRoute),
      label: detailLabel || T[detailRoute],
      modelOf: 'study'
    } // Model Study
    ];
  }

  var STRUCTURE_FOR_PATH = {
    'lead.index': ownerRoute('lead', 'index', T_ESSENTIALS),
    'lead.profile': ownerRoute('lead', 'profile', T_PROFILE),
    'lead.departments': ownerRoute('lead', 'departments', T_DEPARTMENTS),
    'lead.contacts': ownerRoute('lead', 'contacts'),
    'lead.billings': ownerRoute('lead', 'billings'),
    'lead.devices': ownerRoute('lead', 'devices', null, true),
    'lead.statistics.table': ownerRoute('lead', 'statistics', T_STATISTICS),
    'lead.offers': ownerRoute('lead', 'offers'),
    'lead.events': ownerRoute('lead', 'events'),
    'lead.attachments': ownerRoute('lead', 'attachments'),
    'lead.studies': ownerRoute('lead', 'studies'),
    'leads.filter-active': [{
      isList: true,
      route: 'leads.filter-active',
      label: 'Active Leads'
    }],
    'leads.filter-lost': [{
      isList: true,
      route: 'leads.filter-lost',
      label: 'Lost Leads'
    }],
    'leads.filter-cold-lead': [{
      isList: true,
      route: 'leads.filter-cold-lead',
      label: 'Leads: Cold Leads'
    }],
    'leads.filter-interest-expressed': [{
      isList: true,
      route: 'leads.filter-interest-expressed',
      label: 'Leads: Interest Expressed'
    }],
    'leads.filter-demo-planned': [{
      isList: true,
      route: 'leads.filter-demo-planned',
      label: 'Leads: Demo Planned'
    }],
    'leads.filter-demo-done': [{
      isList: true,
      route: 'leads.filter-demo-done',
      label: 'Leads: Demo Done'
    }],
    'leads.filter-testing': [{
      isList: true,
      route: 'leads.filter-testing',
      label: 'Leads: Testing'
    }],
    'leads.filter-offer-phase-done': [{
      isList: true,
      route: 'leads.filter-offer-phase-done',
      label: 'Leads: Offer Phase Done'
    }],
    'leads.filter-preordered': [{
      isList: true,
      route: 'leads.filter-preordered',
      label: 'Won Leads'
    }],
    'leads.create': [{
      isDetail: true,
      route: 'leads.create',
      label: 'Create Lead'
    }],
    'clients.all': listRoute('client', 'all'),
    'clients.filter-active': listRouteFilter('client', 'active'),
    'clients.filter-new': listRouteFilter('client', 'new'),
    'clients.new': [{
      isDetail: true,
      route: 'clients.new',
      label: 'Create Client'
    }],
    'sponsored-clients.all': listRoute('sponsored-client', 'all'),
    'sponsored-clients.filter-active': listRouteFilter('sponsored-client', 'active'),
    'sponsored-clients.filter-new': listRouteFilter('sponsored-client', 'new'),
    'sponsored-clients.new': [{
      isDetail: true,
      route: 'sponsored-clients.new',
      label: 'Create Sponsored Client'
    }],
    'studies-partner.index': ownerRoute('studies-partner', 'index', T_ESSENTIALS),
    'studies-partner.studies': ownerRoute('studies-partner', 'studies'),
    'studies-partner.contacts': ownerRoute('studies-partner', 'contacts'),
    'studies-partner.devices': ownerRoute('studies-partner', 'devices', null, true),
    'studies-partner.statistics.table': ownerRoute('studies-partner', 'statistics', T_STATISTICS),
    'studies-partner.events': ownerRoute('studies-partner', 'events'),
    'studies-partner.attachments': ownerRoute('studies-partner', 'attachments'),
    'studies-partners.filter-active': [{
      isList: true,
      route: 'studies-partners.filter-active',
      label: 'Active Studies Partners'
    }],
    'study.index': studiesRoute('index', T_ESSENTIALS),
    'study.details': studiesRoute('details', 'Details'),
    'study.events': studiesRoute('events', T_EVENTS),
    'study.attachments': studiesRoute('attachments', T_ATTACHMENTS),
    'distributors.all': listRoute('distributor', 'all'),
    'distributors.filter-active': listRouteFilter('distributor', 'active'),
    'distributors.filter-cancelled': listRouteFilter('distributor', 'cancelled'),
    'distributors.create': ownerCreateRoute('distributor'),
    'licensors.all': listRoute('licensor', 'all'),
    'licensors.create': ownerCreateRoute('licensor'),
    'testers.all': listRoute('tester', 'all'),
    'testers.create': ownerCreateRoute('tester'),
    'devices.overview': listRoute('device', 'overview', 'Devices Overview'),
    'devices.all': listRoute('device', 'all'),
    'devices.store': listRoute('device', 'store', 'Devices in Store'),
    'devices.filter-low-battery': listRoute('device', 'filter-low-battery', 'Devices with Low Battery'),
    'devices.filter-no-connection': listRoute('device', 'filter-no-connection', 'Devices with No Connection'),
    'devices.create': ownerCreateRoute('device'),
    'attachments': [{
      isList: true,
      route: 'attachments'
    }],
    'apps': [{
      isList: true,
      route: 'apps'
    }],
    // 'movies.index'       : [{ route: 'movies.index' }],
    'packages': [{
      isList: true,
      route: 'packages'
    }],
    'categories': [{
      isList: true,
      route: 'categories'
    }],
    'package-overview': [{
      isList: true,
      route: 'package-overview',
      label: 'Package Overview'
    }],
    'countries': [{
      isList: true,
      route: 'countries'
    }],
    'languages': [{
      isList: true,
      route: 'languages'
    }],
    'users': [{
      isList: true,
      route: 'users'
    }],
    'statistics.summary.table': [{
      isList: true,
      route: 'statistics.summary',
      label: 'Statistic Summary'
    }],
    'statistics.details.table': [{
      isList: true,
      route: 'statistics.details',
      label: 'Statistic Details'
    }],
    'statistics.billing.table': [{
      isList: true,
      route: 'statistics.billing',
      label: 'Statistic Billing'
    }],
    'logs.server.table': [{
      isList: true,
      route: 'logs.server',
      label: 'Server Logs'
    }],
    'logs.error.table': [{
      isList: true,
      route: 'logs.error',
      label: 'Error Logs'
    }],
    'logs.battery.table': [{
      isList: true,
      route: 'logs.battery',
      label: 'Battery Logs'
    }],
    'logs.other.table': [{
      isList: true,
      route: 'logs.other',
      label: 'Other Logs'
    }],
    'distributor.index': ownerRoute('distributor', 'index', 'Essentials'),
    'distributor.profile': ownerRoute('distributor', 'profile', T_PROFILE),
    'distributor.devices': ownerRoute('distributor', 'devices', null, true),
    'distributor.clients': ownerRoute('distributor', 'clients', null, true),
    'distributor.leads': ownerRoute('distributor', 'leads', null, true),
    'distributor.events': ownerRoute('distributor', 'events', null, true),
    'distributor.attachments': ownerRoute('distributor', 'attachments'),
    'distributor.statistics.table': ownerRoute('distributor', 'statistics', T_STATISTICS),
    'licensor.index': ownerRoute('licensor', 'index'),
    'licensor.devices': ownerRoute('licensor', 'devices', null, true),
    'licensor.movies': ownerRoute('licensor', 'movies'),
    'licensor.events': ownerRoute('licensor', 'events'),
    'licensor.attachments': ownerRoute('licensor', 'attachments'),
    'licensor.statistics.table': ownerRoute('licensor', 'statistics', T_STATISTICS),
    'tester.index': ownerRoute('tester', 'index'),
    'tester.devices': ownerRoute('tester', 'devices', null, true),
    'tester.events': ownerRoute('tester', 'events'),
    'tester.attachments': ownerRoute('tester', 'attachments'),
    'tester.statistics.table': ownerRoute('tester', 'statistics', T_STATISTICS),
    'client.index': ownerRoute('client', 'index', 'Essentials'),
    'client.billings': ownerRoute('client', 'billings'),
    'client.devices': ownerRoute('client', 'devices', null, true),
    'client.contacts': ownerRoute('client', 'contacts'),
    'client.events': ownerRoute('client', 'events'),
    'client.attachments': ownerRoute('client', 'attachments'),
    'client.studies': ownerRoute('client', 'studies'),
    'client.profile': ownerRoute('client', 'profile', 'Profile'),
    'client.statistics.table': ownerRoute('client', 'statistics', T_STATISTICS),
    'sponsored-client.index': ownerRoute('sponsored-client', 'index', 'Essentials'),
    'sponsored-client.devices': ownerRoute('sponsored-client', 'devices', null, true),
    'sponsored-client.contacts': ownerRoute('sponsored-client', 'contacts'),
    'sponsored-client.events': ownerRoute('sponsored-client', 'events'),
    'sponsored-client.attachments': ownerRoute('sponsored-client', 'attachments'),
    'sponsored-client.profile': ownerRoute('sponsored-client', 'profile', 'Profile'),
    'device.index': ownerRoute('device', 'index', 'Details'),
    'device.battery': ownerRoute('device', 'battery'),
    'device.starts': ownerRoute('device', 'starts'),
    'device.logs': ownerRoute('device', 'logs'),
    'device.events': ownerRoute('device', 'events'),
    'device.attachments': ownerRoute('device', 'attachments'),
    'device.content': ownerRoute('device', 'content'),
    'movie.index': ownerRoute('movie', 'index'),
    'movie.events': ownerRoute('movie', 'events'),
    'movie.attachments': ownerRoute('movie', 'attachments'),
    'movies.create': ownerCreateRoute('movie')
  };
  var DEVICE_ROUTES = ['device.index', 'device.battery', 'device.starts', 'device.logs', 'device.events', 'device.attachments', 'device.content'];
  var MOVIE_ROUTES = ['movie.index', 'movie.events', 'movie.attachments', 'movies.create'];
  var LEAD_ROUTES = ['lead.index', 'lead.profile', 'lead.contacts', 'lead.billings', 'lead.devices', 'lead.statistics', 'lead.offers', 'lead.events', 'lead.attachments'];
  var MERGE_HISTORY_ROUTE_FROM = ['client.devices', 'distributor.devices', 'lead.devices', 'licensor.devices', 'tester.devices', 'studies-partner.devices', 'devices.overview', 'package-overview', 'distributor.leads'];
  var MERGE_HISTORY_ROUTE_TO = ['device.index', 'movie.index', 'movies.create', 'lead.index'];
  var BreadCrumb = Ember.Object.extend({
    label: null,
    route: null,
    model: null,
    hasModel: Ember.computed.notEmpty('model'),
    isList: false,
    isDetail: false,
    labelAttr: null,
    modelIsPromise: Ember.computed('hasModel', 'model', function () {
      return this.get('hasModel') && !!this.get('model').then;
    }),
    serialize: function serialize() {
      var json = this.getProperties('label', 'route', 'isList', 'isDetail');

      if (this.get('hasModel')) {
        var model = this.get('model');
        json['model'] = _typeof(model) === 'object' ? model.get('id') : "" + model;
      }

      return json;
    },
    traceDebug: function traceDebug() {
      console.log('BREADCRUMB', this.serialize());
    }
  });

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    history: Ember.computed(function () {
      return [];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var app = Ember.getOwner(this).lookup('route:application');
      this.set('applicationRoute', app);
      this.get('currentUser.user').then(function () {
        _this.loadHistory();

        Ember.getOwner(_this).lookup('router:main').on('didTransition', _this.track.bind(_this));

        _this.setFullHistory();
      });
    },
    track: function track() {
      this.setFullHistory();
    },
    setFullHistory: function setFullHistory() {
      var _this2 = this;

      var currentPath = Ember.getOwner(this).lookup('controller:application').get('currentPath');
      var hierarchy = this.structureFor(currentPath);

      if (!hierarchy) {
        return this.get('history').clear();
      }

      var crumbs = hierarchy.map(function (_ref) {
        var route = _ref.route,
            modelOf = _ref.modelOf,
            labelOf = _ref.labelOf,
            label = _ref.label,
            _ref$isList = _ref.isList,
            isList = _ref$isList === void 0 ? false : _ref$isList,
            _ref$isDetail = _ref.isDetail,
            isDetail = _ref$isDetail === void 0 ? false : _ref$isDetail;
        var model, labelAttr; // .all routes don't have a model, but others list routes might have, like client.devices

        if (!isList || !route.includes('.all') && !route.includes('.overview')) {
          model = _this2.modelFor(route, modelOf);
        }

        if (!label) {
          label = _this2.labelFor(route, labelOf, model, label);

          if (!label) {
            labelAttr = labelOf;
          }
        }

        if (route === 'devices.all' && !_this2.get('currentUser.canViewAllDevices')) {
          route = 'devices.my-devices';
          label = 'My Devices';
        }

        return BreadCrumb.create({
          route: route,
          model: model,
          label: label,
          labelAttr: labelAttr,
          isList: isList,
          isDetail: isDetail
        });
      });
      this.setHistory(currentPath, crumbs);
    },
    setHistory: function setHistory(currentPath, currentCrumbs) {
      this.setMergeHistory(currentPath);

      var _this$getProperties = this.getProperties('history', 'mergeCrumbs'),
          history = _this$getProperties.history,
          mergeCrumbs = _this$getProperties.mergeCrumbs;

      if (mergeCrumbs && (DEVICE_ROUTES.includes(currentPath) || MOVIE_ROUTES.includes(currentPath) || LEAD_ROUTES.includes(currentPath))) {
        var newHistory = [];
        newHistory.addObjects(mergeCrumbs);
        currentCrumbs.removeAt(0);
        newHistory.addObjects(currentCrumbs);
        history.setObjects(newHistory);
      } else {
        history.setObjects(currentCrumbs);
      }

      this.backupHistory(currentPath, currentCrumbs);
    },
    backupHistory: function backupHistory(path, history) {
      this.setProperties({
        lastPath: path,
        lastHistory: Ember.copy(history)
      });
    },
    loadHistory: function loadHistory() {// const mergeHistory = this.get('userSettings').getBackupHistory('mergeHistory');
      // if (mergeHistory && mergeHistory.mergeCrumbs) {
      //   const { mergeCrumbs } = mergeHistory;
      //   this.setProperties({
      //     mergeCrumbs: mergeCrumbs.map((s) => BreadCrumb.create(s))
      //   });
      // }
    },
    setMergeHistory: function setMergeHistory(currentPath) {
      if (MERGE_HISTORY_ROUTE_TO.includes(currentPath)) {
        var lastPath = this.get('lastPath');

        if (!lastPath) {
          return false;
        } // Inject merge crumbs


        if (MERGE_HISTORY_ROUTE_FROM.includes(lastPath)) {
          var mergeCrumbs = Ember.copy(this.get('lastHistory'));
          this.set('mergeCrumbs', mergeCrumbs);
          var serialized = {
            mergeCrumbs: mergeCrumbs.map(function (crumb) {
              return crumb.serialize();
            })
          };
          this.get('userSettings').backupHistory('mergeHistory', serialized);
        } else if (!DEVICE_ROUTES.includes(lastPath) || !MOVIE_ROUTES.includes(lastPath) || !LEAD_ROUTES.includes(lastPath)) {
          this.set('mergeCrumbs', null);
          this.get('userSettings').backupHistory('mergeHistory', null);
        }
      }
    },
    structureFor: function structureFor(currentPath) {
      return STRUCTURE_FOR_PATH[currentPath];
    },
    modelFor: function modelFor(route, modelOf) {
      if (modelOf === false) {
        return null;
      }

      var model;

      if (_typeof(modelOf) === 'object') {
        var modelFor = modelOf.modelFor,
            modelAttr = modelOf.modelAttr;
        model = this.get('applicationRoute').modelFor(modelFor).get(modelAttr);
      } else {
        model = this.get('applicationRoute').modelFor(modelOf || route);
      }

      if (model) {
        if (!(model instanceof Ember.Object)) {
          return model['owner'];
        }

        if (model instanceof _user.default) {
          return null;
        }
      }

      return model;
    },
    labelFor: function labelFor(route, labelOf, model) {
      if (model && !!model.then) {
        return null;
      }

      return model && labelOf && model.get(labelOf) || Ember.String.capitalize(route);
    },
    transitionUp: function transitionUp(router) {
      var parentList = this.get('history').slice().reverse().findBy('isList', true);

      if (parentList) {
        var model = parentList.get('model');

        if (model) {
          router.transitionTo(parentList.get('route'), model);
        } else {
          router.transitionTo(parentList.get('route'));
        }

        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});