define("backend/serializers/client", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMBEDDED_ALWAYS = {
    embedded: 'always'
  };
  var EMBEDDED_NEVER = {
    serialize: false
  };
  var EMBEDDED_IDS = {
    deserialize: 'ids',
    serialize: 'ids'
  };

  var _default = _application.default.extend({
    attrs: {
      address: EMBEDDED_ALWAYS,
      departments: EMBEDDED_ALWAYS,
      bank: EMBEDDED_ALWAYS,
      contacts: EMBEDDED_NEVER,
      sources: EMBEDDED_ALWAYS,
      billings: EMBEDDED_NEVER,
      distributor: EMBEDDED_IDS,
      clientGroup: EMBEDDED_IDS,
      descendants: EMBEDDED_IDS,
      shippingAddress: EMBEDDED_ALWAYS,
      billingAddress: EMBEDDED_ALWAYS,
      materialOrderAddress: EMBEDDED_ALWAYS,
      repairOrderAddress: EMBEDDED_ALWAYS
    },
    normalize: function normalize(modelClass, resourceHash) {
      resourceHash.async_ids = {
        distributor: {
          loadModelName: 'distributor',
          id: resourceHash.distributor
        }
      };
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (json.shipping_address) {
        this._removeEmptyShippingAddress(json, snapshot.record.get('buffers.selected.showShippingAddress'));
      }

      if (json.billing_address) {
        this._removeEmptyAddress(json, 'billing_address');
      }

      if (json.material_order_address) {
        this._removeEmptyAddress(json, 'material_order_address');
      }

      if (json.repair_order_address) {
        this._removeEmptyAddress(json, 'repair_order_address');
      }

      return json;
    },
    _removeEmptyShippingAddress: function _removeEmptyShippingAddress(json, uiShowAddress) {
      if (uiShowAddress === false) {
        json.shipping_address = null;
      } else {
        var _json$shipping_addres = json.shipping_address,
            address_1 = _json$shipping_addres.address_1,
            zip = _json$shipping_addres.zip,
            city = _json$shipping_addres.city,
            country = _json$shipping_addres.country;

        if (!address_1 && !zip && !city && !country) {
          json.shipping_address = null;
        }
      }
    },
    _removeEmptyAddress: function _removeEmptyAddress(json, key) {
      var _json$key = json[key],
          address_1 = _json$key.address_1,
          zip = _json$key.zip,
          city = _json$key.city,
          country = _json$key.country;

      if (!address_1 && !zip && !city && !country) {
        json[key] = null;
      }
    }
  });

  _exports.default = _default;
});