define("backend/pods/studies/filter-inactive/controller", ["exports", "backend/prototypes/tables/headers/studies"], function (_exports, _studies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    clientSort: {
      sortBy: 'displayName',
      order: 'asc'
    },
    tableColumns: _studies.default,
    queryParams: ['page', 'size'],
    page: 1,
    size: 10000
  });

  _exports.default = _default;
});