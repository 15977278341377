define("backend/mixins/person-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = Ember.Mixin.create({
    gender: attr('string'),
    titlePrefix: attr('string'),
    titleSuffix: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    personName: Ember.computed('titlePrefix', 'titleSuffix', 'lastName', 'firstName', {
      get: function get() {
        var titlePref = this.get('titlePrefix') || '';
        var firstName = this.get('firstName') || '';
        var lastName = this.get('lastName') || '';
        var titleSuff = this.get('titleSuffix') || '';
        return (titlePref + " " + firstName + " " + lastName + " " + titleSuff).trim();
      },
      set: function set(key, newName) {
        return newName;
      }
    })
  });

  _exports.default = _default;
});