define("backend/pods/components/hm-form/duration/component", ["exports", "backend/helpers/format-duration"], function (_exports, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    durationStr: Ember.computed('value', {
      get: function get() {
        return (0, _formatDuration.formatDuration)([this.get('value')]);
      },
      set: function set(key, value) {
        var seconds = null;

        if (!Ember.isEmpty(value) && value.indexOf(':') > -1) {
          var timeArray = value.replace(/[^0-9h:]/g, '').split(':');

          if (timeArray.length === 1) {
            // "13" mm
            seconds = timeArray[0] * 60;
          } else if (timeArray.length === 2) {
            // "2:53" hh:mm
            seconds = timeArray[0] * 60 * 60 + timeArray[1] * 60;
          } else if (timeArray.length === 3) {
            // "2:53:13" hh:mm:ss
            seconds = timeArray[0] * 60 * 60 + timeArray[1] * 60 + Number(timeArray[2]);
          }
        }

        this.set('value', seconds);
        return value;
      }
    })
  });

  _exports.default = _default;
});