define("backend/models/material-order", ["exports", "ember-data", "backend/utils/static-data/material-order-products"], function (_exports, _emberData, _materialOrderProducts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    deliveryDate: attr('string'),
    deliveryNote: attr('string'),
    deliveryType: attr('string'),
    headphoneCovers: attr('string'),
    individualProducts: attr('string'),
    marketingMaterials: attr('string'),
    shipping: attr('string'),
    billingTask: attr('number'),
    reparatur: attr('string'),
    hasReturnLabel: false,
    billingTaskFmt: Ember.computed('billingTask', function () {
      return this.billingTask === 1 ? 'Yes' : 'No';
    }),
    headphoneCoversFmt: Ember.computed('headphoneCovers', function () {
      var prod = _materialOrderProducts.HEADPHONE_COVERS.findBy('articleNo', this.headphoneCovers);

      if (!prod) {
        return null;
      }

      return prod['articleNo'] + ': ' + prod['description'] + ' (' + prod['amount'] + ' ' + prod['unit'] + ', ' + prod['price'] + '€)';
    }),
    individualProductsFmt: Ember.computed('individualProducts', function () {
      var individualProducts = this.individualProducts;

      if (!individualProducts) {
        return null;
      }

      var prodIds = individualProducts.split(',');
      return _materialOrderProducts.INDIVIDUAL_PRODUCTS.filter(function (prod) {
        return prodIds.includes(prod.articleNo);
      }).map(function (prod) {
        return prod['articleNo'] + ': ' + prod['description'] + ' (' + prod['amount'] + ' ' + prod['unit'] + ', ' + prod['price'] + '€)';
      }).join('\n');
    }),
    marketingMaterialsFmt: Ember.computed('marketingMaterials', function () {
      var marketingMaterials = this.marketingMaterials;

      if (!marketingMaterials) {
        return null;
      }

      var prodIds = marketingMaterials.split(',');
      return _materialOrderProducts.MARKETING_PRODUCTS.filter(function (prod) {
        return prodIds.includes(prod.articleNo);
      }).map(function (prod) {
        return prod['articleNo'] + ': ' + prod['description'] + ' (' + prod['amount'] + ' ' + prod['unit'] + ', ' + prod['price'] + '€)';
      }).join('\n');
    }),
    shippingFmt: Ember.computed('shipping', function () {
      return _materialOrderProducts.SHIPPING_PRODUCTS.findBy('articleNo', this.shipping);
    }),
    tracking: attr('string'),
    timestamp: attr('string'),
    status: belongsTo('order-status', {
      async: false
    }),
    events: hasMany('event', {
      async: true
    }),
    user: attr('string'),
    client: attr(),
    distributor: attr(),
    asyncIds: attr(),
    ownerName: attr('string'),
    ownerType: Ember.computed('asyncIds.owner.loadModelName', function () {
      return this.get('asyncIds.owner.loadModelName');
    }),
    requiresDeliveryNote: Ember.computed('ownerType', function () {
      return ['client', 'lead'].includes(this.get('ownerType'));
    }),
    canAddAttachment: Ember.computed('ownerType', function () {
      return ['client', 'lead'].includes(this.get('ownerType'));
    }),
    updateStatus: function updateStatus(status) {
      var _this = this;

      var file = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'hellotest.pdf';
      var trackingNo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var adapter = this.get('store').adapterFor('material-order');

      if (this.get('requiresDeliveryNote') && status.get('id') === '4' && !file) {
        if (!confirm('Status "Delivered" requires a signed delivery note. Save anyway?')) {
          return Ember.RSVP.reject();
        }
      }

      return adapter.updateStatus(this.get('id'), status.get('id'), file, trackingNo).then(function () {
        _this.reload();

        _this.get('events').reload();
      }).catch(function (errorClass) {
        alert('Could not update status: ' + errorClass.errors[0].error);
        throw errorClass;
      });
    },
    addAttachment: function addAttachment() {
      var _this2 = this;

      return this.get('store').adapterFor('attachment').generateUploadUrl('attachment').then(function (_ref) {
        var upload_url = _ref.data.upload_url;
        return _this2.set('uploadUrl', upload_url);
      });
    },
    removeAttachment: function removeAttachment() {
      var _this3 = this;

      this.addAttachment().then(function () {
        _this3.setProperties({
          newAttachment: null,
          newAttachmentType: null,
          newAttachmentTypeDetail: null
        });
      });
    },
    createAttachment: function createAttachment() {
      var _this4 = this;

      return this.get('store').adapterFor('material-order').createAttachment(this.get('id'), {
        file: this.get('newAttachment'),
        type: this.get('newAttachmentType.id'),
        type_detail: this.get('newAttachmentTypeDetail')
      }).then(function () {
        _this4.setProperties({
          newAttachment: null,
          newAttachmentType: null,
          newAttachmentTypeDetail: null,
          uploadUrl: null
        });

        _this4.get('events').reload();
      });
    },
    cancelAttachment: function cancelAttachment() {
      this.setProperties({
        newAttachment: null,
        newAttachmentType: null,
        newAttachmentTypeDetail: null,
        uploadUrl: null
      });
    }
  });

  _exports.default = _default;
});