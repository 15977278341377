define("backend/templates/components/link-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dJyH3lOb",
    "block": "{\"symbols\":[\"@model\",\"@attribute\"],\"statements\":[[7,\"div\",false],[3,\"on\",[\"click\",[23,0,[\"preventPropagation\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[]],\"device\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[29,[[23,1,[\"deviceMeta\",\"self\"]]]]],[10,\"target\",\"_blank\"],[8],[1,[23,1,[\"deviceMeta\",\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"replacedDevice\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[29,[[23,1,[\"replacedDeviceMeta\",\"self\"]]]]],[10,\"target\",\"_blank\"],[8],[1,[23,1,[\"replacedDeviceMeta\",\"name\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/link-device.hbs"
    }
  });

  _exports.default = _default;
});