define("backend/pods/sales/pipeline/controller", ["exports", "backend/pods/components/hm-model/mixins/department-status", "backend/pods/components/hm-model/mixins/department-won-lost"], function (_exports, _departmentStatus, _departmentWonLost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_departmentStatus.default, _departmentWonLost.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: ['probability', 'closing_month', 'distributor', 'lead_probability', 'lead_closing_month', 'lead_closing_month_incl'],
    probability: null,
    closing_month: null,
    lead_probability: null,
    lead_closing_month: null,
    lead_closing_month_incl: 'includes',
    closingOptions: [Ember.Object.create({
      id: 'includes',
      displayName: '<= Include Until'
    }), Ember.Object.create({
      id: 'equals',
      displayName: '= Equals To'
    }), Ember.Object.create({
      id: 'afterwards',
      displayName: '> Afterwards'
    })],
    distributor: Ember.computed('currentUser.modelId', function () {
      if (this.get('currentUser.isDistributor')) {
        return "" + this.get('currentUser.modelId');
      }

      return null;
    }),
    isEditDisabled: Ember.computed('currentUser.isAdmin', 'currentUser.modelId', 'distributor', function () {
      return !this.get('currentUser.isAdmin') && "" + this.get('currentUser.modelId') !== this.get('distributor');
    }),
    availCountries: Ember.computed('model.summary.length', function () {
      var options = [];
      options.pushObjects((this.get('model.summary') || []).mapBy('country').uniq().sort().map(function (country) {
        return Ember.Object.create({
          id: country,
          displayName: country
        });
      }));
      return options;
    }),
    availDistributors: Ember.computed('model.summary.length', function () {
      return (this.get('model.summary') || []).reduce(function (acc, _ref) {
        var displayName = _ref.distributor,
            distributor_id = _ref.distributor_id;
        var id = distributor_id + "";

        if (!acc.findBy('id', id)) {
          acc.push(Ember.Object.create({
            id: id,
            displayName: displayName
          }));
        }

        return acc;
      }, []).sortBy('name');
    }),
    availDistributorsTerritory: Ember.computed('model.summary.length', 'availDistributors', 'currentUser.model.address.country', function () {
      if (!this.get('currentUser.isDistributor')) {
        return this.get('availDistributors');
      }

      var userCountry = this.get('currentUser.model.address.country').toUpperCase();

      if (userCountry === 'GB') {
        userCountry = 'UK';
      }

      return (this.get('model.summary') || []).reduce(function (acc, _ref2) {
        var displayName = _ref2.distributor,
            distributor_id = _ref2.distributor_id,
            country = _ref2.country;
        var id = distributor_id + "";

        if (!acc.findBy('id', id) && country === userCountry) {
          acc.push(Ember.Object.create({
            id: id,
            displayName: displayName
          }));
        }

        return acc;
      }, []).sortBy('name');
    }),
    filter: Ember.computed(function () {
      return Ember.Object.create();
    }),
    summary: Ember.computed('model.summary.length', 'filter.country', 'filter.distributor', function () {
      var data = this.get('model.summary') || [];
      var filter = this.get('filter');
      var filterBy = [];

      if (filter.country) {
        filterBy.push(function (_ref3) {
          var country = _ref3.country;
          return filter.country === country;
        });
      }

      if (filter.distributor) {
        filterBy.push(function (_ref4) {
          var distributor_id = _ref4.distributor_id;
          return filter.distributor === distributor_id;
        });
      }

      if (filterBy.get('length') > 0) {
        return data.filter(function (d) {
          return filterBy.every(function (func) {
            return func(d);
          });
        });
      }

      return data;
    }),
    allDepartments: Ember.computed('model.pipelines.length', function () {
      return this.get('model.pipelines').reduce(function (acc, _ref5) {
        var departments = _ref5.departments;
        return acc.concat(departments);
      }, []);
    }),
    reloadAfterSave: function reloadAfterSave() {
      this.clearData();
      this.send('reloadModel');
    },
    cancelOverlay: function cancelOverlay() {
      var department = this.get('lostDepartment') || this.get('wonDepartment');

      if (department) {
        department.rollbackAttributes();
      }

      this.clearData();
    },
    actions: {
      save: function save(department) {
        var _this = this;

        var reloadAll = department.get('bufferStatus') !== department.get('status');
        department.save().then(function () {
          if (reloadAll) {
            _this.send('reloadModel');
          } else {
            _this.send('reloadStatisticsOnly');
          }
        });
      },
      cancel: function cancel(department) {
        department.rollbackAttributesAndRelationships();
      },
      sum: function sum(a, b) {
        return Number(a) + Number(b);
      },
      createUploadUrl: function createUploadUrl() {
        return this.get('store').adapterFor('attachment').generateUploadUrl();
      },
      getStatusOptions: function getStatusOptions(department) {
        var curStatus = department.get('status.id');
        return this.get('leadStatusList').filter(function (status) {
          return status.get('id') >= curStatus;
        });
      }
    }
  });

  _exports.default = _default;
});