define("backend/pods/licensors/filter-fee-proposition/controller", ["exports", "backend/pods/licensors/filter-active/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend();

  _exports.default = _default;
});