define("backend/pods/components/hm-simple-forms/create-material-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qnhp36H7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"tabbable\"],[8],[0,\"\\n        \"],[7,\"ul\",true],[10,\"class\",\"nav nav-tabs nav-tabs-bottom\"],[8],[0,\"\\n          \"],[7,\"li\",true],[10,\"class\",\"active\"],[8],[0,\"\\n            \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"New Material Order\"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"tab-pane active\"],[8],[0,\"\\n            \"],[15,\"partials/show-error\",[]],[0,\"\\n\\n            \"],[7,\"form\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n              \"],[15,[24,[\"templateEdit\"]],[]],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/pods/components/hm-simple-forms/create-material-order/template.hbs"
    }
  });

  _exports.default = _default;
});