define("backend/components/collapsable-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSettings: Ember.inject.service(),
    tagName: 'a',
    classNames: ['control-arrow'],
    isCollapsed: Ember.computed('userSettings.inactiveBoxes.length', function () {
      return this.get('userSettings').isBoxCollapsed(this.get('boxId'));
    }),
    actions: {
      uncollapse: function uncollapse() {
        this.get('userSettings').setBoxActive(this.get('boxId'), true);
      },
      collapse: function collapse() {
        this.get('userSettings').setBoxActive(this.get('boxId'), false);
      }
    }
  });

  _exports.default = _default;
});