define("backend/services/store-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    lazyLoad: function lazyLoad(modelName, id) {
      if (modelName === 'in-store') {
        return Ember.RSVP.resolve(this.get('store').peekAll('in-store').objectAt(0));
      }

      return this.loadAll(modelName).then(function (records) {
        return records.findBy('id', "" + id);
      });
    },
    loadAll: function loadAll(modelName) {
      var loadingKey = 'loading' + modelName;
      var promise = this.get(loadingKey);

      if (!promise) {
        promise = this.get('store').query(modelName, {
          page: {
            size: 10000
          }
        });
        this.set(loadingKey, promise);
      }

      return promise;
    }
  });

  _exports.default = _default;
});