define("backend/components/page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INDEX_ROUTES = ['client', 'distributor', 'licensor', 'studies-partner', 'tester', 'device', 'study', 'sponsored-client'];

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    breadCrumbs: Ember.inject.service(),
    crumbs: Ember.computed('breadCrumbs.history', 'breadCrumbs.history.@each.label', function () {
      // Recalculate when only label changes, like transitioning from one client to another
      return this.get('breadCrumbs.history');
    }),
    routePath: function routePath() {
      return Ember.getOwner(this).lookup('controller:application').get('currentPath').split('.');
    },
    pageTitle: Ember.computed('title', 'crumbs.lastObject.label', function () {
      var crumbs = this.get('crumbs');
      var title = crumbs.get('lastObject.label') || this.get('title');

      if (crumbs && crumbs.length) {
        document.title = crumbs.slice().reverse().mapBy('label').join(' - ') + ' - HappyMed';
      } else {
        document.title = title + ' - HappyMed';
      }

      return title;
    }),
    hasMoreThanOneCrumb: Ember.computed.gt('crumbs.length', 1),
    visibleBreadCrumbs: Ember.computed('crumbs.length', 'model', function () {
      var crumbs = this.get('crumbs');
      var len = crumbs.get('length');

      if (len > 1) {
        return crumbs.slice(0, len - 1);
      }

      return crumbs;
    }),
    actions: {
      goBack: function goBack() {
        var controller = Ember.getOwner(this).lookup('controller:application');
        var history = this.get('crumbs');
        var len = history.get('length');

        if (len > 1) {
          var last = history.objectAt(len - 2);

          if (INDEX_ROUTES.includes(last.get('route'))) {
            last = history.objectAt(len - 3);
          }

          var _last$getProperties = last.getProperties('route', 'model'),
              route = _last$getProperties.route,
              model = _last$getProperties.model;

          if (model) {
            controller.transitionToRoute(route, model);
          } else {
            controller.transitionToRoute(route);
          }
        } else {
          window.history.go(-1);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});