define("backend/pods/components/hm-table/render-promise/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ownerAttribute: Ember.computed('attribute', function () {
      return this.get('attribute').split('.')[0];
    }),
    valueAttribute: Ember.computed('attribute', function () {
      return this.get('attribute').slice(this.get('attribute').indexOf('.') + 1);
    })
  });

  _exports.default = _default;
});