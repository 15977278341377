define("backend/components/create-return", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Return = Ember.Object.extend({
    owner: null,
    notes: null,
    adapter: null,
    save: function save(store) {
      var _store$adapterFor$ada;

      var owner = this.get('owner');

      if (!owner) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a device owner"
          }]
        });
      }

      var notes = this.get('notes');

      if (!notes) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Enter a note"
          }]
        });
      }

      var ownerType = owner.constructor.modelName;
      var adapter = this.get('adapter');
      return store.adapterFor('application')[adapter]((_store$adapterFor$ada = {}, _defineProperty(_store$adapterFor$ada, Ember.String.underscore(ownerType), owner.get('id')), _defineProperty(_store$adapterFor$ada, "notes", notes), _store$adapterFor$ada));
    }
  });

  var _default = _routeComponent.default.extend({
    templateEdit: 'components/forms/create-return',
    showEssentials: true,
    isPending: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('shippingLabel', Return.create({
        adapter: 'createReturnShippingLabel'
      }));
      this.set('pickup', Return.create({
        adapter: 'createReturnPickup'
      }));
    },
    returnLabel: function returnLabel() {
      var _this = this;

      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('shippingLabel').save(this.get('store')).then(function () {
        var flashes = _this.get('flashes');

        var succFlash = {
          alert: 'Return Label requested'
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this.set('isPending', false);
      });
    },
    returnPickup: function returnPickup() {
      var _this2 = this;

      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('pickup').save(this.get('store')).then(function () {
        var flashes = _this2.get('flashes');

        var succFlash = {
          alert: 'Pickup request'
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this2.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this2.set('isPending', false);
      });
    },
    actions: {
      returnLabel: function returnLabel() {
        return this.returnLabel();
      },
      returnPickup: function returnPickup() {
        return this.returnPickup();
      },
      changePage: function changePage(page) {
        this.setProperties({
          showEssentials: page === 'essentials',
          showOther: page === 'other'
        });
      }
    }
  });

  _exports.default = _default;
});