define("backend/utils/static-data/department-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [Ember.Object.create({
    groupName: 'Medical Departments',
    options: ['Anesthesia', 'Angiology', 'Cardiology', 'Dental', 'Dermatology', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Gynecology', 'Intensive Care', 'Internal Medicine', 'Nephrology', 'Neurology', 'Oncology', 'Orthopedics', 'Other', 'Pain Care', 'Palliative Care', 'Pediatrics', 'Phlebology', 'Plastic Surgery', 'Psychiatry', 'Pulmonology', 'Radiotherapy', 'Radiologie', 'Rehabilitation', 'Urology', 'Vascular Surgery', 'Visceral Surgery'].map(function (name) {
      return Ember.Object.create({
        id: name,
        displayName: name
      });
    })
  }), Ember.Object.create({
    groupName: 'Organizational Departments',
    options: ['IT', 'Management', 'Med Tech', 'Office', 'PR / Marketing', 'Purchasing'].map(function (name) {
      return Ember.Object.create({
        id: name,
        displayName: name
      });
    })
  }), Ember.Object.create({
    groupName: 'Other',
    options: ['Data Import'].map(function (name) {
      return Ember.Object.create({
        id: name,
        displayName: name
      });
    })
  })];
  _exports.default = _default;
});