define("backend/components/apps-component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    createModel: 'app',
    prefill: function prefill(entry) {
      if (entry.get('isNew')) {
        this._updateUploadUrl(entry);
      }
    },
    _updateUploadUrl: function _updateUploadUrl(attachment) {
      var buffer = attachment.get('buffers.selected');
      this.createUploadUrl('app').then(function (_ref) {
        var upload_url = _ref.data.upload_url;
        buffer.setProperties({
          file: null,
          uploadUrl: upload_url
        });
      });
    },
    actions: {
      deleteLinkedEntry: function deleteLinkedEntry() {
        this._updateUploadUrl(this.get('selected'));
      }
    }
  });

  _exports.default = _default;
});