define("backend/helpers/get-name", ["exports", "backend/db-cache/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getName = getName;
  _exports.default = void 0;

  function getName(_, _ref) {
    var _ref$data = _ref.data,
        data = _ref$data === void 0 ? _countries.default : _ref$data,
        id = _ref.id;

    if (data && id) {
      var match = data.findBy('id', id);

      if (match) {
        return match.get ? match.get('displayName') : match.displayName;
      }
    }

    return id;
  }

  var _default = Ember.Helper.helper(getName);

  _exports.default = _default;
});