define("backend/templates/components/inline-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7AQq4OCh",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[4,\"each\",[[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"label label--\",[23,1,[\"priority\"]]]]],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/inline-label.hbs"
    }
  });

  _exports.default = _default;
});