define("backend/pods/distributor/statistics/route", ["exports", "backend/pods/client/statistics/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      year: {
        refreshModel: true
      },
      month: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      device: {
        refreshModel: true
      },
      deviceTypes: {
        refreshModel: true
      },
      include: {
        refreshModel: true
      }
    },
    ownerType: 'distributor'
  });

  _exports.default = _default;
});