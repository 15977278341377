define("backend/components/packages-component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    createModel: 'package',
    nameRecordType: 'packageName',
    buildOptions: function () {
      this.set('data.packageOptions', this.get('data.packages').filterBy('isNew', false));
    }.on('init')
  });

  _exports.default = _default;
});