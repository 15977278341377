define("backend/components/categories-component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    createModel: 'category',
    packageCategories: Ember.computed('selected.package.id', 'selected.buffers.selected.package.id', function () {
      var packageId = this.get('selected.buffers.selected.package.id') || this.get('selected.package.id');

      if (!packageId) {
        return [];
      }

      var categories = this.get('data.categories').filterBy('package.id', packageId);
      categories.removeObject(this.get('selected'));
      return categories;
    }),
    clearDeleteWarning: Ember.observer('selected.id', function () {
      this.setProperties({
        deleteConfirmed: false,
        'data.showDeleteWarning': false
      });
    }),

    /**
      @override
    */
    confirmDelete: function confirmDelete() {
      if (this.get('deleteConfirmed')) {
        return true;
      }

      this.set('data.showDeleteWarning', true);
      return false;
    },
    actions: {
      selectItem: function selectItem() {
        this.set('selected.buffers.selected.parent', null);
      },
      deleteEntry: function deleteEntry(isConfirmed) {
        if (isConfirmed === false) {
          this.set('data.showDeleteWarning', false);
          return false;
        } else if (isConfirmed === true) {
          this.set('deleteConfirmed', true);
        }

        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});