define("backend/utils/static-data/owner-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'A',
    name: 'A',
    color: 'red',
    marker_suffix: 40
  }, {
    id: 'B',
    name: 'B',
    color: 'orange',
    marker_suffix: 80
  }, {
    id: 'C',
    name: 'C',
    color: 'green',
    marker_suffix: 120
  }];
  _exports.default = _default;
});