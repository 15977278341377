define("backend/models/device-package", ["exports", "ember-data", "backend/utils/current-day-utc", "ember-cp-validations"], function (_exports, _emberData, _currentDayUtc, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    device: belongsTo('device', {
      async: true
    }),
    // Package
    "package": belongsTo('package', {
      async: true
    }),
    // Package Information
    sellingPrice: attr('number'),
    startDate: attr('string', {
      defaultValue: (0, _currentDayUtc.default)()
    }),
    validators: {
      "package": (0, _emberCpValidations.validator)('presence', true),
      sellingPrice: (0, _emberCpValidations.validator)('number', {
        gt: -1,
        allowString: true
      }),
      startDate: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});