define("backend/templates/components/survey-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CLrDyPhW",
    "block": "{\"symbols\":[\"@f\"],\"statements\":[[7,\"div\",false],[3,\"did-insert\",[[23,0,[\"loadSurveys\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"surveys\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[\"select\"]],[],[[\"@field\",\"@data\"],[\"survey\",[23,0,[\"surveys\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex flex-col items-center\"],[8],[0,\"\\n      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/survey-dropdown.hbs"
    }
  });

  _exports.default = _default;
});