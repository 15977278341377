define("backend/db-cache/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": "ae",
    "displayName": "UAE"
  }, {
    "id": "at",
    "displayName": "Austria"
  }, {
    "id": "au",
    "displayName": "Australia"
  }, {
    "id": "be",
    "displayName": "Belgium"
  }, {
    "id": "ca",
    "displayName": "Canada"
  }, {
    "id": "ch",
    "displayName": "Switzerland"
  }, {
    "id": "cl",
    "displayName": "Chile"
  }, {
    "id": "de",
    "displayName": "Germany"
  }, {
    "id": "dk",
    "displayName": "Denmark"
  }, {
    "id": "ee",
    "displayName": "Estonia"
  }, {
    "id": "es",
    "displayName": "Spain"
  }, {
    "id": "eu",
    "displayName": "Europe"
  }, {
    "id": "fi",
    "displayName": "Finland"
  }, {
    "id": "fr",
    "displayName": "France"
  }, {
    "id": "gb",
    "displayName": "United Kingdom"
  }, {
    "id": "ie",
    "displayName": "Ireland"
  }, {
    "id": "is",
    "displayName": "Iceland"
  }, {
    "id": "it",
    "displayName": "Italy"
  }, {
    "id": "lu",
    "displayName": "Luxemburg"
  }, {
    "id": "nl",
    "displayName": "Netherlands"
  }, {
    "id": "no",
    "displayName": "Norway"
  }, {
    "id": "se",
    "displayName": "Sweden"
  }, {
    "id": "sk",
    "displayName": "Slovakia"
  }, {
    "id": "si",
    "displayName": "Slovenia"
  }, {
    "id": "us",
    "displayName": "USA"
  }, {
    "id": "ww",
    "displayName": "Worldwide"
  }];
  _exports.default = _default;
});