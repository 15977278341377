define("backend/pods/components/hm-model/fieldset-essentials/list-add/tasks/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'fieldset',
    actions: {
      toggleTask: function toggleTask(task) {
        if (task.get('isSavingChecked')) {
          return false;
        }

        task.setProperties({
          isSavingChecked: true,
          isSavingError: false,
          isSavingSlow: false
        });
        window.setTimeout(function () {
          if (!task.isDestroyed && task.get('isSavingChecked')) {
            task.set('isSavingSlow', true);
          }
        }, 250);
        return task.toggleChecked().then(function () {
          return task.reload();
        }).catch(function (xhr) {
          return task.setProperties({
            errorMessage: xhr.errors[0].error,
            isSavingError: true
          });
        }).finally(function () {
          return task.set('isSavingChecked', false);
        });
      }
    }
  });

  _exports.default = _default;
});