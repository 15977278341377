define("backend/templates/components/billing-send-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FFnMQwqA",
    "block": "{\"symbols\":[\"async\",\"@model\"],\"statements\":[[1,[28,\"secure-download\",null,[[\"url\",\"downloadUrl\",\"openOnDownload\",\"target\",\"fileName\",\"name\",\"label\",\"icon\",\"mimeType\"],[[23,0,[\"previewLink\"]],[23,0,[\"previewLink\"]],true,\"_blank\",\"email.html\",[23,2,[\"number\"]],\"Preview\",\"file-eye\",\"text/html\"]]],false],[0,\"\\n\"],[5,\"async\",[],[[\"@do\",\"@onSuccess\",\"@onError\",\"@autoReset\"],[[23,0,[\"sendEmail\"]],[23,0,[\"onSuccess\"]],[23,0,[\"onError\"]],3000]],{\"statements\":[[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-sm transition-bg\\n      \",[28,\"if\",[[23,1,[\"state\",\"didSucceed\"]],\"btn-success\"],null],\"\\n      \",[28,\"if\",[[23,1,[\"state\",\"didError\"]],\"btn-danger\"],null],\"\\n      \",[28,\"if\",[[23,1,[\"state\",\"isIdle\"]],\"bg-slate-300\"],null]]]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,1,[\"actions\",\"do\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"state\",\"isBusy\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"icon-mail5\"],[8],[9],[0,\"\\n      Send\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/billing-send-email.hbs"
    }
  });

  _exports.default = _default;
});