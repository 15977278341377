define("backend/pods/components/hm-models/list/filter-devices/component", ["exports", "backend/pods/mixins/model/owner-type-by-role"], function (_exports, _ownerTypeByRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ownerTypeByRole.default, {});

  _exports.default = _default;
});