define("backend/templates/components/forms/-presentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6TsvfK9Z",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[5,\"hm-form/wrapper\",[],[[\"@model\"],[[24,[\"buffers\",\"selected\"]]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"string\"]],[],[[\"@field\"],[\"title\"]]],[0,\"\\n  \"],[6,[23,1,[\"string\"]],[],[[\"@field\"],[\"version\"]]],[0,\"\\n  \"],[6,[23,1,[\"day\"]],[],[[\"@field\",\"@label\"],[\"releaseDate\",\"Release Date\"]]],[0,\"\\n  \"],[6,[23,1,[\"file\"]],[],[[\"@field\",\"@accept\",\"@acts\"],[\"file\",\"application/zip\",[28,\"hash\",null,[[\"deleteAction\"],[[28,\"action\",[[23,0,[]],\"deleteLinkedEntry\"],null]]]]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/forms/-presentation.hbs"
    }
  });

  _exports.default = _default;
});