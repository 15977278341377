define("backend/templates/components/loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s3nI2sHF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"loading-indicator \",[28,\"if\",[[24,[\"white\"]],\"loading-indicator--white\"],null]]]],[8],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/loading-indicator.hbs"
    }
  });

  _exports.default = _default;
});