define("backend/pods/components/hm-models/list/row-w-departments/row-department/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COLS_DEPARTMENT = {
    'Name': 'nameWithOwner',
    'Status': 'status.displayName'
  };

  var _default = Ember.Component.extend({
    tagName: 'tr',
    tdClass: 'display-td',
    classNames: ['cursor-pointer'],
    columns: Ember.computed('row.columns.length', function () {
      return this.get('row.columns').map(function (c) {
        var obj = c.get('obj');
        return {
          attr: COLS_DEPARTMENT[obj.get('name')],
          parent: obj
        };
      });
    }),
    click: function click() {
      this.get('clickRow')();
    }
  });

  _exports.default = _default;
});