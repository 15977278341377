define("backend/pods/components/secure-image/component", ["exports", "backend/config/environment", "backend/utils/ajax-file"], function (_exports, _environment, _ajaxFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['secure-image'],
    mimeType: 'image/jpeg',
    downloadUrl: Ember.computed('url', function () {
      var url = this.get('url');

      if (url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1)) {
        return url;
      }

      return _environment.default.host + '/' + url;
    }),
    resetUrl: Ember.observer('url', function () {
      this.set('fileConverted', false);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.triggerDownload();
    },
    didInsertElement: function didInsertElement() {
      this.triggerDownload();
    },
    willDestroyElement: function willDestroyElement() {
      this.releaseFile();
    },
    triggerDownload: function triggerDownload() {
      var _this = this;

      this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;

        if (!_this.isDestroyed) {
          _this.set('isDownloading', true);
        }

        (0, _ajaxFile.default)(_this.get('downloadUrl'), headers, _this.get('mimeType')).then(function (href) {
          if (!_this.isDestroyed) {
            _this.displayFile(href);
          }
        }).finally(function () {
          if (!_this.isDestroyed) {
            _this.set('isDownloading', false);
          }
        });
      });
    },
    displayFile: function displayFile(href) {
      this.setProperties({
        imageSrc: href,
        fileConverted: true
      });

      if (this.get('getImageUrl')) {
        this.get('getImageUrl')(href);
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('clearFile')) {
          this.set('url', null);
        }
      });
    },
    releaseFile: function releaseFile() {
      var href = this.get('imageSrc');
      Ember.run.later(this, function () {
        var URL = window.URL || window.webkitURL;

        if (URL && typeof URL.revokeObjectURL === 'function') {
          URL.revokeObjectURL(href);
        }

        if (!this.isDestroyed) {
          this.setProperties({
            imageSrc: '#',
            fileConverted: false
          });
        }
      }, 1000);
    }
  });

  _exports.default = _default;
});