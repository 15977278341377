define("backend/pods/studies-partner/attachments/route", ["exports", "backend/prototypes/attachments/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    beforeModel: function beforeModel() {
      this.setProperties({
        owner: this.modelFor('studies-partner'),
        ownerType: this.modelFor('studies-partner').get('ownerType')
      });
    }
  });

  _exports.default = _default;
});