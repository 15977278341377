define("backend/utils/buffer-proxy", ["exports", "ember-buffered-proxy/proxy", "ember-buffered-proxy/helpers", "ember-cp-validations"], function (_exports, _proxy, _helpers, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createBuffer;
  var _Ember = Ember,
      notifyPropertyChange = _Ember.notifyPropertyChange,
      meta = _Ember.meta; // eslint-disable-line ember/new-module-imports

  var hasOwnProp = Object.prototype.hasOwnProperty;

  function testEmpty(obj) {
    var key;

    for (key in obj) {
      if (!hasOwnProp.call(obj, key)) {
        continue;
      }

      return false;
    }

    return true;
  }

  function testEqualBlank(current, value) {
    return Ember.isBlank(value) && Ember.isBlank(current);
  }

  function testEqualProxy(current, value) {
    if (current instanceof Ember.ObjectProxy) {
      var content = current.content;
      return content === value || testEqualBlank(content, value);
    }

    return false;
  }

  function createBuffer(content) {
    var buffer;
    var bufferClass = _proxy.default;

    if (content) {
      var extValidations = content.get('validators');
      var extAdditional = content.getBufferMixin ? content.getBufferMixin() : null;

      if (!Ember.isEmpty(extValidations)) {
        bufferClass = bufferClass.extend((0, _emberCpValidations.buildValidations)(extValidations));
      }

      if (!Ember.isEmpty(extAdditional)) {
        bufferClass = bufferClass.extend(extAdditional);
      }

      buffer = bufferClass.create({
        content: content
      });

      if (!Ember.isEmpty(extValidations)) {
        Ember.setOwner(buffer, Ember.getOwner(content));
      }
    } else {
      buffer = bufferClass.create({
        content: content
      });
    }

    buffer.setUnknownProperty = function (key, value) {
      var m = meta(this);

      if (m.proto === this || m.isInitializing && m.isInitializing()) {
        // if marked as prototype or object is initializing then just
        // defineProperty rather than delegate
        Ember.defineProperty(this, key, null, value);
        return value;
      }

      var _EmberGetProperties = Ember.getProperties(this, ['buffer', 'content']),
          buffer = _EmberGetProperties.buffer,
          content = _EmberGetProperties.content;

      var current;
      var previous;

      if (content != null) {
        current = Ember.get(content, key);
      }

      previous = hasOwnProp.call(buffer, key) ? buffer[key] : current;

      if (previous === value) {
        return;
      }

      if (current === value || testEqualBlank(current, value) || testEqualProxy(current, value)) {
        delete buffer[key];

        if ((0, _helpers.empty)(buffer)) {
          Ember.set(this, 'hasBufferedChanges', false);
        }
      } else {
        buffer[key] = value;
        Ember.set(this, 'hasBufferedChanges', true);
      }

      notifyPropertyChange(content, key);
      return value;
    };

    return buffer;
  }
});