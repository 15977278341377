define("backend/pods/components/hm-models/create/leads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "87KnMwqN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"role\",\"tagName\",\"route\"],[\"btn btn-primary\",\"button\",\"button\",\"leads.create\"]],{\"statements\":[[0,\"    Create Lead\\n\"]],\"parameters\":[]},null],[0,\"   \\n\"],[4,\"link-to\",null,[[\"class\",\"role\",\"tagName\",\"route\"],[\"btn btn-primary\",\"button\",\"button\",\"leads.import\"]],{\"statements\":[[0,\"    Import\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/create/leads/template.hbs"
    }
  });

  _exports.default = _default;
});