define("backend/pods/components/hm-form/string/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    email: Ember.computed.equal('type', 'email'),
    website: Ember.computed.equal('type', 'website'),
    phone: Ember.computed.equal('type', 'phone'),
    type: 'text',
    attrPlaceholder: Ember.computed('placeholder', 'phone', function () {
      if (this.get('phone')) {
        return '+43 664 12 34 56 78';
      }

      return this.get('placeholder');
    }),
    hasInputGroup: Ember.computed.or('email', 'phone', 'website'),
    actions: {
      focusOut: function focusOut(value) {
        if (this.get('acts.focusOutAction')) {
          this.get('acts.focusOutAction')(value);
        }
      }
    }
  });

  _exports.default = _default;
});