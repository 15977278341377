define("backend/pods/package-overview/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    model: function model() {
      var store = this.get('store');
      return this.queryModel({
        packages: store.peekAll('package'),
        categories: store.query('category', {
          page: {
            size: 10000
          }
        }),
        movies: store.query('movie', {
          page: {
            size: 10000
          }
        })
      });
    },
    preloadDependencies: function preloadDependencies() {
      return Ember.RSVP.all([this.store.findByCache('package'), this.store.findAll('licensor')]);
    },
    lazyloadDependencies: function lazyloadDependencies() {
      var store = this.store;
      return {
        languages: store.findAll('language'),
        licensors: store.peekAll('licensor'),
        countries: store.findAll('country'),
        tags: store.query('tag', {
          type: 'movie'
        }),
        statusList: store.peekAll('movieStatus'),
        categoryStatusList: store.peekAll('categoryStatus'),
        targetGroups: store.peekAll('targetGroup')
      };
    },
    actions: {
      duplicateCategory: function duplicateCategory(category) {
        var _this = this;

        var name = category.get('displayName');

        if (confirm("The category will be duplicated and set \"Inactive\". Additionally all movies in this category will be duplicated.\n\n Please confirm this action for \"".concat(name, "\"."))) {
          if (category.duplicate) {
            category.duplicate().then(function () {
              return _this.refresh();
            });
          } else {
            category.content.duplicate().then(function () {
              return _this.refresh();
            });
          }
        }
      },
      deleteCategory: function deleteCategory(category) {
        var _this2 = this;

        var name = category.get('displayName').trim();

        if (confirm("The category will be deleted. Additionally all sub categories and movies will be deleted. \n\n Please confirm this action for \"".concat(name, "\"."))) {
          var input = prompt("Type the name of the category:").trim();

          if (name === input) {
            category.deleteRecursive().then(function () {
              return _this2.refresh();
            });
          } else {
            alert("'".concat(input, " does not match ").concat(name, "..."));
          }
        }
      },
      deletePackage: function deletePackage(hmPackage) {
        var _this3 = this;

        var name = hmPackage.get('displayName').trim();

        if (confirm("The package will be deleted. Additionally all sub categories and movies will be deleted, as well as devices using this package won't have access to it anymore. \n\n Please confirm this action for \"".concat(name, "\"."))) {
          var input = prompt("Type the name of the package:").trim();

          if (name === input) {
            hmPackage.deleteRecursive().then(function () {
              return _this3.refresh();
            });
          } else {
            alert("'".concat(input, " does not match ").concat(name, "..."));
          }
        }
      }
    }
  });

  _exports.default = _default;
});