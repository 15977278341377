define("backend/pods/lead/offers/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    model: function model(params) {
      var store = this.store;
      var lead = this.modelFor('lead');
      var queryParams = Ember.merge(this.curPage(params), {
        lead: lead.get('id')
      });
      return this.queryModel({
        lead: lead,
        offers: store.query('offer', queryParams)
      });
    },
    loadDependencies: function loadDependencies() {
      var store = this.store;
      return {
        statusList: store.peekAll('billingStatus')
      };
    }
  });

  _exports.default = _default;
});