define("backend/prototypes/events/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    isDevice: false,
    isMovie: false,
    ownerQP: function ownerQP(owner) {
      var qp = {};
      qp[Ember.String.camelize(this.get('ownerType'))] = owner.get('id');
      return qp;
    },
    beforeModel: function beforeModel() {
      this.set('owner', this.modelFor(this.get('ownerType')));
    },
    model: function model(params) {
      var store = this.store;
      var owner = this.get('owner');
      var queryParams = Ember.merge(this.curPage(params), this.ownerQP(owner));
      return this.queryModel({
        owner: owner,
        events: store.query('event', queryParams)
      });
    },
    loadDependencies: function loadDependencies() {
      var store = this.store;
      var ownerType = this.get('ownerType');
      var eventTypes = store.peekAll('eventType').filter(function (eventType) {
        return eventType.get('canEdit') && eventType.isVisibleFor(ownerType);
      });
      return {
        eventTypes: eventTypes
      };
    }
  });

  _exports.default = _default;
});