define("backend/pods/distributor/devices/route", ["exports", "backend/prototypes/owner/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devices.default.extend({
    ownerType: 'distributor',
    ownerQP: function ownerQP() {
      var qp = this._super.apply(this, arguments);

      qp.exclude_territories = true;
      return qp;
    }
  });

  _exports.default = _default;
});