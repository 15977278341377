define("backend/components/create-material-order", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELDS = ['BusinessCards', 'BusinessCardsArabella', 'Pens', 'Head', 'Brochures', 'Banner', 'Kit', 'Areas', 'Presentation', 'Instructions', 'Patient', 'Conformity', 'Pin', 'Tie'];
  var Order = Ember.Object.extend({
    save: function save(store) {
      var _this = this;

      var data = FIELDS.reduce(function (acc, field) {
        var isChecked = _this.get("check".concat(field));

        if (isChecked) {
          acc.push({
            id: field,
            info: _this.get("data".concat(field))
          });
        }

        return acc;
      }, []);
      return store.adapterFor('application').createMaterialOrder(data);
    }
  });

  var _default = _routeComponent.default.extend({
    isPending: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('order', Order.create());
    },
    submit: function submit() {
      var _this2 = this;

      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('order').save(this.get('store')).then(function () {
        var flashes = _this2.get('flashes');

        var succFlash = {
          alert: 'Order has been sent!'
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this2.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this2.set('isPending', false);
      });
    },
    actions: {
      order: function order() {
        return this.submit();
      },
      input: function input(field, jqEv) {
        if (jqEv.target.value) {
          this.set("order.check".concat(field), true);
        }
      }
    }
  });

  _exports.default = _default;
});