define("backend/prototypes/tables/headers/statistics-summary", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'movie.id',
    name: 'Movie ID'
  }, {
    attr: 'replays',
    name: 'Replays'
  }, {
    attr: 'duration',
    name: 'Duration'
  }, {
    attr: 'percentage',
    name: 'Percentage'
  }, {
    attr: 'movie.title',
    name: 'Movie Title'
  }, {
    attr: 'movie.licensor.displayName',
    name: 'Licensor Name'
  }, {
    attr: 'movie.formattedDurationLong',
    name: 'Movie Duration'
  }, {
    attr: 'movie.revenueShare',
    name: 'Revenue Share'
  }, {
    attr: 'totalRevenueShare',
    name: '% of Revenue Share'
  }]);

  _exports.default = _default;
});