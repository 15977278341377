define("backend/models/task-type", ["exports", "ember-data", "backend/mixins/localize-mixin"], function (_exports, _emberData, _localizeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend(_localizeMixin.default, {
    suffix: attr('string'),
    canCreate: attr('boolean', {
      defaultValue: false
    }),
    canEdit: attr('boolean', {
      defaultValue: false
    }),
    roles: attr(),
    isVisibleFor: function isVisibleFor(role) {
      return this.get('roles').includes(role);
    }
  });

  _exports.default = _default;
});