define("backend/utils/static-data/billing-template-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Contract',
    requires_contract: true
  }, {
    id: 2,
    name: 'Material Order',
    period_date_is_today: true,
    is_material_order: true
  }, {
    id: 3,
    name: 'Repair Order',
    period_date_is_today: true,
    is_repair_order: true
  }];
  _exports.default = _default;
});