define("backend/serializers/investor", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMBEDDED_ALWAYS = {
    embedded: 'always'
  };
  var EMBEDDED_NEVER = {
    serialize: false
  };
  var EMBEDDED_IDS = {
    deserialize: 'ids',
    serialize: 'ids'
  };

  var _default = _application.default.extend({
    attrs: {
      address: EMBEDDED_ALWAYS,
      bank: EMBEDDED_ALWAYS,
      contacts: EMBEDDED_NEVER,
      sources: EMBEDDED_ALWAYS
    }
  });

  _exports.default = _default;
});