define("backend/pods/sales/pipeline/route", ["exports", "moment", "backend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function flattenBy(attribute) {
    return function (collection, model) {
      collection.pushObjects(model.get(attribute).toArray());
      return collection;
    };
  }

  var STATUS_OFFER_PHASE_DONE = '17';
  var STATUS_DEMO_DONE = '14';
  /**
   * DepartmentsPipeline is a set of lead departments grouped by status.
   *
   * Additionally some departments might be grouped a second time, e.g.:
   *   - Expected Closing Date or
   *   - Categorized Projects vs. Uncategorized Projects
   *   - ...
   */

  var DepartmentsPipeline = Ember.Object.extend({
    id: null,
    name: null,
    groupBy: Ember.computed('id', function () {
      if (this.get('id') === STATUS_OFFER_PHASE_DONE) {
        return 'expectedDate';
      } else if (this.get('id') === STATUS_DEMO_DONE) {
        return 'pipelineCategory';
      }

      return null;
    }),
    groupByFormat: function groupByFormat(value) {
      if (this.get('id') === STATUS_OFFER_PHASE_DONE) {
        return (0, _moment.default)(value).format('MMMM YYYY');
      } else if (this.get('id') === STATUS_DEMO_DONE) {
        return value === 'pipeline-project' ? 'Pipeline Projects' : 'Uncategorized Projects';
      }

      return value;
    },
    groupHasMRR: function groupHasMRR(value) {
      return this.get('id') === STATUS_OFFER_PHASE_DONE || value === 'pipeline-project';
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('departments', []);
    }
  }).reopenClass({
    createFromLeads: function createFromLeads(leads) {
      var _this = this;

      return leads.reduce(flattenBy('departments'), []).sort(function (a, b) {
        return a.get('probability') > b.get('probability') ? -1 : 1;
      }).reduce(function (pipelines, department) {
        var status = department.get('status').getProperties('id', 'name');

        if (status.id >= 12 && status.id <= 18) {
          var pipeline = _this.findOrCreate(pipelines, status);

          pipeline.get('departments').push(department);
        }

        return pipelines;
      }, []).sortBy('id').reverse();
    },
    findOrCreate: function findOrCreate(pipelines, attrs) {
      var pipeline = pipelines.findBy('id', attrs.id);

      if (!pipeline) {
        pipeline = this.create(attrs);
        pipelines.push(pipeline);
      }

      return pipeline;
    }
  });

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      probability: {
        refreshModel: true
      },
      closing_month: {
        refreshModel: true
      },
      lead_probability: {
        refreshModel: true
      },
      lead_closing_month: {
        refreshModel: true
      },
      lead_closing_month_incl: {
        refreshModel: true
      },
      distributor: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      return this.get('currentUser.model');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        summary: this.loadSummary(params),
        pipelines: this.loadPipelines(params)
      });
    },
    loadPipelines: function loadPipelines(_ref) {
      var distributor = _ref.distributor,
          lead_probability = _ref.lead_probability,
          lead_closing_month = _ref.lead_closing_month,
          lead_closing_month_incl = _ref.lead_closing_month_incl;

      if (!distributor) {
        return Ember.RSVP.resolve([]);
      }

      var queryId = distributor + lead_probability + lead_closing_month + lead_closing_month_incl;

      if (this.currentModel && this.currentModel.pipelines && !this.get('forceReload') && this.get('queryId') === queryId) {
        return this.currentModel.pipelines;
      }

      this.set('queryId', queryId);
      var query = {
        distributor: distributor,
        probability: lead_probability,
        closing_month: lead_closing_month,
        closing_type: lead_closing_month_incl,
        exclude_territories: true,
        filter: 'active_but_cold',
        page: {
          size: 10000
        }
      };
      return this.store.query('lead', query).then(function (leads) {
        return DepartmentsPipeline.createFromLeads(leads);
      });
    },
    loadSummary: function loadSummary(_ref2) {
      var _this2 = this;

      var _ref2$probability = _ref2.probability,
          probability = _ref2$probability === void 0 ? null : _ref2$probability,
          _ref2$closing_month = _ref2.closing_month,
          closing_month = _ref2$closing_month === void 0 ? null : _ref2$closing_month;
      return new Ember.RSVP.Promise(function (resolve) {
        _this2.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var qp = '';

          if (probability || closing_month) {
            qp = '?';

            if (probability) {
              qp += "&probability=".concat(probability);
            }

            if (closing_month) {
              qp += "&closing_month=".concat(closing_month);
            }
          }

          _this2.get('ajax').request(_environment.default.host + "/sales/pipeline_statistics".concat(qp), {
            headers: headers
          }).then(function (_ref3) {
            var data = _ref3.data;
            data.forEach(function (d) {
              return d.distributor_id = "" + d.distributor_id;
            });
            resolve(data);
          });
        });
      });
    },
    actions: {
      reloadModel: function reloadModel() {
        var _this3 = this;

        this.set('forceReload', true);
        this.refresh().then(function () {
          return _this3.set('forceReload', false);
        });
      },
      reloadStatisticsOnly: function reloadStatisticsOnly() {
        this.refresh();
      },
      willTransition: function willTransition(transition) {
        var unsaved = this.currentModel.pipelines.mapBy('departments').reduce(function (acc, dep) {
          return acc.pushObjects(dep);
        }, []).filterBy('hasDirtyAttributes');

        if (unsaved.get('length') > 0) {
          var names = unsaved.map(function (department) {
            return "  - ".concat(department.get('lead.displayName'), " (").concat(department.get('name'), ", ").concat(department.get('status.name'), ")");
          }).join('\n');

          if (confirm("".concat(unsaved.get('length'), " unsaved leads:\n\n").concat(names, "\n\nDo you want to discard these changes?"))) {
            unsaved.forEach(function (dep) {
              return dep.rollbackAttributes();
            });
          } else {
            transition.abort();
          }
        }
      }
    }
  });

  _exports.default = _default;
});