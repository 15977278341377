define("backend/pods/components/hm-invoice/columns/overdue-since/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NeJgMwB8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"overdueSince\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"label label-danger\"],[11,\"title\",[29,[\"since \",[28,\"moment-format\",[[24,[\"model\",\"overdueAt\"]],\"DD.MM.YYYY\"],null]]]],[8],[1,[24,[\"model\",\"overdueSince\"]],false],[0,\" days\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-invoice/columns/overdue-since/template.hbs"
    }
  });

  _exports.default = _default;
});