define("backend/prototypes/tables/headers/owner", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([// Owner
  {
    attr: 'displayName',
    name: 'Name'
  }, // Person, Company
  {
    attr: 'personName',
    name: 'Person',
    display: false
  }, {
    attr: 'companyName',
    name: 'Company',
    display: false
  }, {
    attr: 'companyDepartment',
    name: 'Department',
    display: false
  }, {
    attr: 'organization'
  }, {
    attr: 'status.displayName',
    name: 'Status'
  }, // { attr: 'rating.displayName', name: 'Rating', display: false },
  {
    attr: 'startDate',
    name: 'Start Date',
    format: 'date',
    display: false
  }, // Address
  {
    attr: 'address.zip',
    name: 'ZIP',
    display: false
  }, {
    attr: 'address.city',
    name: 'City'
  }, {
    attr: 'address.state',
    name: 'State'
  }, {
    attr: 'address.country',
    name: 'Country',
    display: false
  }, // Contact
  {
    attr: 'email',
    name: 'E-Mail',
    display: false
  }, {
    attr: 'phone',
    display: false
  }, // Devices
  {
    attr: 'counts.devices',
    name: 'Devices',
    display: false
  }]);

  _exports.default = _default;
});