define("backend/pods/components/hm-model/mixins/validations/vat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ApiValidation = Ember.Object.extend({
    type: null,
    isValid: null,
    isPending: null,
    isNotEmpty: Ember.computed.notEmpty('isPending'),
    run: function run(container, value) {
      var _this = this;

      var adapter = Ember.getOwner(container).lookup('adapter:validation');
      this.set('isPending', true);
      return adapter.validate(this.get('type'), value).then(function (_ref) {
        var data = _ref.data;
        return _this.setProperties({
          isValid: data.validity,
          data: data
        });
      }).catch(function () {
        return _this.setProperties({
          isValid: false,
          data: null
        });
      }).finally(function () {
        return _this.set('isPending', false);
      });
    },
    clear: function clear() {
      this.setProperties({
        isPending: null,
        isValid: null,
        data: null
      });
    }
  });

  var _default = Ember.Mixin.create({
    runValidationVAT: function runValidationVAT(value) {
      var validation = this.get('apiValidations.vat') || ApiValidation.create({
        type: 'vat'
      });

      if (!this.get('apiValidations')) {
        this.set('apiValidations', Ember.Object.create({
          vat: validation
        }));
      }

      if (Ember.isBlank(value)) {
        validation.clear();
      } else {
        validation.run(this, value);
      }
    },
    actions: {
      validateVAT: function validateVAT(value) {
        this.runValidationVAT(value);
      }
    }
  });

  _exports.default = _default;
});