define("backend/pods/sales-cockpit/tasks/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      user: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params && params.user) {
        return this.get('store').query('hm-task', {
          due: '7_days',
          user: params.user
        });
      }

      return [];
    },
    actions: {
      toggleTask: function toggleTask() {
        return false;
      }
    }
  });

  _exports.default = _default;
});