define("backend/pods/components/hm-model/fieldset-profile/contact/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'fieldset',
    buffers: Ember.computed.alias('model.buffers'),
    distributors: Ember.computed(function () {
      return this.get('store').query('distributor', {
        filter: 'active'
      });
    }),
    showDistributor: true
  });

  _exports.default = _default;
});