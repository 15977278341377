define("backend/components/owner-select", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  var EMPTY_OWNER = {
    'client': null,
    'distributor': null,
    'licensor': null,
    'lead': null,
    'tester': null,
    'device': null,
    'sponsoredClient': null
  };
  var OWNER_TYPES = [{
    id: 'client',
    displayName: 'Client'
  }, {
    id: 'distributor',
    displayName: 'Distributor'
  }, {
    id: 'licensor',
    displayName: 'Licensor'
  }, {
    id: 'lead',
    displayName: 'Lead'
  }, {
    id: 'tester',
    displayName: 'Tester'
  }, {
    id: 'device',
    displayName: 'Device'
  }, {
    id: 'sponsoredClient',
    displayName: 'Sponsored Client'
  }];

  function loadCollection(modelName) {
    return Ember.computed(function () {
      var promise = this.get('store').findByCache(modelName);
      return PromiseArray.create({
        promise: promise
      });
    });
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    clients: loadCollection('client'),
    distributors: loadCollection('distributor'),
    licensors: loadCollection('licensor'),
    leads: loadCollection('lead'),
    testers: loadCollection('tester'),
    devices: loadCollection('device'),
    sponsoredClients: loadCollection('sponsored-client'),
    ownerTypes: OWNER_TYPES.map(function (type) {
      return Ember.Object.create(type);
    }),
    actions: {
      clearOwners: function clearOwners() {
        this.get('model').setProperties(EMPTY_OWNER);
      }
    }
  });

  _exports.default = _default;
});