define("backend/pods/logs/other/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function paramEmpty(param) {
    return Ember.isBlank(param) || param === 'null';
  }

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      device: {
        refreshModel: true
      },
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      return this.store.findByCache('device');
    },
    model: function model(params) {
      var store = this.store;
      var model = {
        devices: store.findByCache('device').then(function (devices) {
          return devices.sortBy('serialNumber');
        })
      };

      if (!paramEmpty(params.device)) {
        model['device'] = store.query('device', params.device);
      }

      return Ember.RSVP.hash(model);
    },
    actions: {
      deleteEntry: function deleteEntry(logEntry) {
        if (confirm('Delete Entry?')) {
          return logEntry.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});