define("backend/components/alert-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['fadeOut:flash'],
    fadeOut: true
  });

  _exports.default = _default;
});