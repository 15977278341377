define("backend/pods/languages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dwVPr0EF",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Languages\"]]],false],[0,\"\\n\"],[1,[28,\"localized-model-component\",null,[[\"page\",\"size\",\"data\",\"createModel\",\"tableColumns\",\"clientSort\"],[[24,[\"page\"]],[24,[\"size\"]],[24,[\"model\"]],\"language\",[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/languages/template.hbs"
    }
  });

  _exports.default = _default;
});