define("backend/pods/device/logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "npCxcUum",
    "block": "{\"symbols\":[\"logEntry\"],\"statements\":[[1,[28,\"hm-form/control2\",null,[[\"opts\",\"model\",\"field\",\"data\",\"ctrl\",\"placeholder\"],[\"fullsize\",[23,0,[]],\"errorType\",[24,[\"errorTypes\"]],\"hm-form/select-value\",\"Select Type\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"hasErrorType\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"table table-striped table-hover\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"text-center\"],[8],[0,\"Timestamp\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"text-center\"],[8],[0,\"Log\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"is-pending\",[[24,[\"logs\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"Loading...\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"logs\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[10,\"class\",\"text-nowrap\"],[8],[1,[23,1,[\"timestamp\"]],false],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[28,\"format-log\",[[23,1,[\"log\"]]],null],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"No entries found\"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/device/logs/template.hbs"
    }
  });

  _exports.default = _default;
});