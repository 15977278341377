define("backend/prototypes/tables/headers/leads", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([// Owner
  {
    attr: 'id',
    name: 'Id'
  }, {
    attr: 'displayName',
    name: 'Name',
    component: 'hm-models/list/row-w-departments/name'
  }, // Person, Company
  {
    attr: 'personName',
    name: 'Person',
    display: false
  }, {
    attr: 'companyName',
    name: 'Company',
    display: false
  }, {
    attr: 'departmentsNames',
    name: 'All Departments',
    display: false,
    style: 'whitespace-no-wrap'
  }, {
    attr: 'organization',
    display: false
  }, // Contact
  {
    attr: 'email',
    name: 'E-Mail'
  }, {
    attr: 'website',
    display: false
  }, {
    attr: 'phone'
  }, // Address
  {
    attr: 'address.zip',
    name: 'ZIP',
    display: false
  }, {
    attr: 'address.city',
    name: 'City'
  }, {
    attr: 'address.state',
    name: 'State',
    display: false
  }, {
    attr: 'address.country',
    name: 'Country',
    display: false
  }, // Status
  {
    attr: 'departments',
    name: 'Status',
    component: 'hm-models/list/row-w-departments/status'
  }, {
    attr: 'trialAgo',
    name: 'Trial Ago'
  }, {
    attr: 'departments',
    name: 'Probability',
    component: 'hm-models/list/row-w-departments/probability'
  }, {
    attr: 'leadType.displayName',
    name: 'Lead Type',
    display: false
  }, {
    attr: 'type.displayName',
    name: 'Client Type',
    display: false
  }, // Info
  {
    modelName: 'distributor',
    attr: 'displayName',
    name: 'Distributor',
    component: 'lazy-load'
  }, {
    attr: 'startDate',
    name: 'Start Date',
    format: 'date',
    display: false
  }, {
    attr: 'priority.displayName',
    name: 'Priority'
  }, {
    attr: 'source',
    name: 'Source',
    display: false
  }, {
    attr: 'tipster',
    name: 'Tipster',
    display: false
  }, {
    attr: 'bestReachability',
    name: 'Reachability',
    display: false
  }, {
    attr: 'counts.offers',
    name: 'Offers #',
    display: false
  }]);

  _exports.default = _default;
});