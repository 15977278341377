define("backend/pods/components/hm-model/form/create/component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CREATE_CONTACT_FOR = ['client', 'sponsored-client', 'lead', 'licensor', 'investor'];
  var CREATE_DEPARTMENT_FOR = ['client', 'sponsored-client', 'lead'];
  var FIRST_STATUS_FOR = {
    client: '1',
    lead: '11',
    'sponsored-client': '31'
  };
  var DUPLICATE_CHECK_FOR = ['lead'];

  var _default = _routeComponent.default.extend({
    ownerType: null,
    // lead, client, distributor etc.
    createModel: Ember.computed.reads('ownerType'),
    afterCancelAction: 'listOwners',
    afterCreateAction: 'showOwner',
    displayName: Ember.computed('selected.buffers.selected.companyName', 'selected.buffers.selected.titlePrefix', 'selected.buffers.selected.titleSuffix', 'selected.buffers.selected.firstName', 'selected.buffers.selected.lastName', function () {
      var buffer = this.get('selected.buffers.selected');

      if (!buffer) {
        return null;
      }

      var companyName = buffer.get('companyName');
      if (companyName) return companyName;

      var _buffer$getProperties = buffer.getProperties('titlePrefix', 'firstName', 'lastName', 'titleSuffix'),
          titlePrefix = _buffer$getProperties.titlePrefix,
          firstName = _buffer$getProperties.firstName,
          lastName = _buffer$getProperties.lastName,
          titleSuffix = _buffer$getProperties.titleSuffix;

      return [titlePrefix, firstName, lastName, titleSuffix].join(' ');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.create();
    },
    prefill: function prefill(model) {
      this._createFirstContact();

      this._createFirstDepartment();

      this._selectFirstStatus(model);

      this._watchForDuplicate();
    },
    save: function save() {
      this._removeEmptyContacts();

      this._removeEmptyDepartments();

      this._super.apply(this, arguments);
    },
    notifyCancel: function notifyCancel() {
      this._super.apply(this, arguments);

      this.sendAction('afterCancelAction', this.get('ownerType'));
    },
    notifySaveComplete: function notifySaveComplete(model) {
      this._super.apply(this, arguments);

      this.sendAction('afterCreateAction', this.get('ownerType'), model);
    },
    _createFirstContact: function _createFirstContact() {
      if (CREATE_CONTACT_FOR.includes(this.get('ownerType'))) {
        this.addContact();
      }
    },
    _createFirstDepartment: function _createFirstDepartment() {
      if (CREATE_DEPARTMENT_FOR.includes(this.get('ownerType'))) {
        this.addDepartment();
      }
    },
    _watchForDuplicate: function _watchForDuplicate() {
      if (DUPLICATE_CHECK_FOR.includes(this.get('ownerType'))) {
        this._addDuplicateObserver();
      }
    },
    _removeEmptyContacts: function _removeEmptyContacts() {
      var contacts = this.get('selected.buffers.contacts');

      if (contacts) {
        for (var idx = contacts.length - 1; idx >= 0; idx--) {
          var contact = contacts[idx];

          if (!contact.get('hasChanges')) {
            contacts.removeObject(contact);
          }
        }
      }
    },
    _removeEmptyDepartments: function _removeEmptyDepartments() {
      var departments = this.get('selected.buffers.departments');

      if (departments) {
        for (var idx = departments.length - 1; idx >= 0; idx--) {
          var department = departments[idx];

          if (!department.get('hasChanges')) {
            departments.removeObject(department);
          }
        }
      }
    },
    _selectFirstStatus: function _selectFirstStatus(model) {
      if (CREATE_DEPARTMENT_FOR.includes(this.get('ownerType'))) {
        return this._selectFirstDepartmentStatus(model);
      }

      if (Ember.isEmpty(model.get('status'))) {
        var statusModel = "".concat(this.get('ownerType'), "-status");
        var firstStatus = this.get('store').peekAll(statusModel).findBy('id', '1');
        model.set('buffers.selected.status', firstStatus);
      }
    },
    _selectFirstDepartmentStatus: function _selectFirstDepartmentStatus(model) {
      var firstStatus = this.get('store').peekAll('department-status').findBy('id', FIRST_STATUS_FOR[this.get('ownerType')]);
      model.set('buffers.departments.firstObject.status', firstStatus);
    },
    _addDuplicateObserver: function _addDuplicateObserver() {
      var _this = this;

      Ember.addObserver(this, 'displayName', this, function () {
        var displayName = _this.get('displayName');

        if (displayName) {
          Ember.run.debounce(_this, _this._runDuplicateCheck, displayName, 2000);
        }
      });
    },
    _runDuplicateCheck: function _runDuplicateCheck(args) {
      var _this2 = this;

      this.get('acts.checkDuplicate')(args).then(function (duplicates) {
        return _this2.set('duplicates', duplicates);
      }).catch(function () {
        return _this2.set('duplicates', null);
      });
    }
  });

  _exports.default = _default;
});