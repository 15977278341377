define("backend/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    type: 'file',
    change: function change(e) {
      var self = this;
      var inputFiles = e.target.files;

      if (inputFiles.length < 1) {
        return;
      }

      var inputFile = inputFiles[0];
      var fileInfo = {
        name: inputFile.name,
        type: inputFile.type || 'n/a',
        size: inputFile.size,
        date: inputFile.lastModifiedDate ? inputFile.lastModifiedDate.toLocaleDateString() : 'n/a'
      };
      var fileReader = new FileReader();

      fileReader.onload = function (e) {
        var fileReader = e.target;
        fileInfo.dataURL = fileReader.result;
        self.sendAction('fileChanged', fileInfo);
      };

      var firstFile = e.target.files[0];
      fileReader.readAsDataURL(firstFile);
    }
  });

  _exports.default = _default;
});