define("backend/pods/components/hm-uploader/new/component", ["exports", "backend/helpers/format-filesize"], function (_exports, _formatFilesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploader: Ember.inject.service(),
    tagName: 'form',
    file: null,
    filename: Ember.computed('file', function () {
      var file = this.get('file');

      if (!file) {
        return null;
      }

      var size = (0, _formatFilesize.formatFilesize)([file.size], {});
      return "".concat(file.name, " (").concat(size, ")");
    }),
    actions: {
      selectFile: function selectFile(evt) {
        this.set('file', evt.target.files[0]);
      },
      upload: function upload() {
        this.get('uploader').upload(this.get('file'));
      }
    }
  });

  _exports.default = _default;
});