define("backend/pods/studies-partners/all/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aMNoZ+hx",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"All Studies Partners\"]]],false],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"title\",\"showPagination\",\"showFooter\",\"clickRoute\",\"data\",\"isLoading\",\"rowComponent\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"lead\",\"Leads\",false,false,\"studies-partner.index\",[24,[\"model\",\"leads\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],[[\"filterBy\"],[\"isStudyPartner\"]]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n  \"],[7,\"hr\",true],[10,\"style\",\"margin: 0 -20px 40px -20px\"],[8],[9],[0,\"\\n  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"title\",\"showPagination\",\"showFooter\",\"clickRoute\",\"data\",\"isLoading\",\"rowComponent\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"client\",\"Clients\",false,false,\"studies-partner.index\",[24,[\"model\",\"clients\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],[[\"filterBy\"],[\"isStudyPartner\"]]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/studies-partners/all/template.hbs"
    }
  });

  _exports.default = _default;
});