define("backend/mixins/query-model-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },
    curPage: function curPage(params) {
      if (params && params.page) {
        return {
          page: {
            number: params.page,
            size: params.size || 100
          }
        };
      }

      return null;
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.preloadDependencies();
    },
    queryModel: function queryModel(model) {
      var dependencies = this.loadCachedDependencies();
      return Ember.RSVP.hash(Ember.merge(model, dependencies));
    },
    setupController: function setupController(controller, model) {
      var dependencies = this.lazyloadDependencies();
      Ember.merge(model, dependencies);
      controller.set('model', model);
    },
    preloadDependencies: function preloadDependencies() {
      return null;
    },
    loadDependencies: function loadDependencies() {
      return {};
    },
    lazyloadDependencies: function lazyloadDependencies() {
      return null;
    },
    loadCachedDependencies: function loadCachedDependencies() {
      var dependencies;

      if (this.get('dependenciesLoaded')) {
        dependencies = this.get('dependencies') || this.loadDependencies();
      } else {
        dependencies = this.loadDependencies();
        this.set('dependencies', dependencies);
      }

      return dependencies;
    },
    actions: {
      // loading: function() {
      //   if (this.controller) {
      //     this.controller.set('model.isLoading', true);
      //     return false;
      //   }
      //   return true;
      // },
      // didTransition: function() {
      //   this.controller.set('model.isLoading', false);
      //   this.set('dependenciesLoaded', true);
      //   return true;
      // },
      saveComplete: function saveComplete() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});