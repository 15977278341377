define("backend/serializers/billing", ["exports", "backend/serializers/application", "backend/models/simple/billing-position"], function (_exports, _application, _billingPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    normalize: function normalize(_, hash) {
      this.normalizePositions(hash);
      hash.owner_meta = hash.owner.meta;

      var json = this._super.apply(this, arguments);

      return json;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      this.serializePositions(json);
      return json;
    },
    normalizePositions: function normalizePositions(json) {
      var positions = json && json['positions'] || [];
      json['positions'] = positions.map(function (position) {
        var _position = _slicedToArray(position, 9),
            posNo = _position[0],
            artNo = _position[1],
            description = _position[2],
            amount = _position[3],
            unit = _position[4],
            netUnitPrice = _position[5],
            netTotalPrice = _position[6],
            interval = _position[7],
            title = _position[8];

        return _billingPosition.default.create({
          posNo: posNo,
          artNo: artNo,
          title: title,
          description: description,
          amount: amount,
          unit: unit,
          netUnitPrice: netUnitPrice,
          netTotalPrice: netTotalPrice,
          interval: interval
        });
      });
    },
    serializePositions: function serializePositions(json) {
      var _ref = json || [],
          positions = _ref.positions;

      var total = 0;
      json['positions'] = positions.filter(function (_ref2) {
        var posNo = _ref2.posNo,
            artNo = _ref2.artNo,
            title = _ref2.title,
            description = _ref2.description,
            amount = _ref2.amount,
            unit = _ref2.unit,
            netUnitPrice = _ref2.netUnitPrice,
            interval = _ref2.interval,
            netTotalPrice = _ref2.netTotalPrice;
        return ![amount, unit, netUnitPrice, netTotalPrice].every(function (d) {
          return d == null;
        });
      }).map(function (position) {
        var _position$getProperti = position.getProperties('posNo', 'artNo', 'title', 'description', 'amount', 'unit', 'netUnitPrice', 'interval', 'netTotalPrice'),
            posNo = _position$getProperti.posNo,
            artNo = _position$getProperti.artNo,
            title = _position$getProperti.title,
            description = _position$getProperti.description,
            amount = _position$getProperti.amount,
            unit = _position$getProperti.unit,
            netUnitPrice = _position$getProperti.netUnitPrice,
            interval = _position$getProperti.interval,
            netTotalPrice = _position$getProperti.netTotalPrice;

        total += Number(netTotalPrice);
        return [posNo, artNo, description, amount, unit, netUnitPrice, netTotalPrice, interval, title];
      });
      json['total_amount'] = total;
    }
  });

  _exports.default = _default;
});