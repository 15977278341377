define("backend/pods/users/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_NEW = '1';

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    model: function model(params) {
      var store = this.store;
      return this.queryModel({
        users: store.query('user', this.curPage(params))
      });
    },
    loadDependencies: function loadDependencies() {
      var store = this.store;
      return {
        clients: store.findAll('client'),
        licensors: store.findAll('licensor'),
        distributors: store.findAll('distributor'),
        testers: store.findAll('tester'),
        statusNew: store.peekAll('userStatus').filter(function (status) {
          return status.get('id') === STATUS_NEW;
        }),
        statusEdit: store.peekAll('userStatus').filter(function (status) {
          return status.get('id') !== STATUS_NEW;
        }),
        roles: store.peekAll('role')
      };
    }
  });

  _exports.default = _default;
});