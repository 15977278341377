define("backend/pods/client-features/statistics/route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function paramEmpty(param) {
    return Ember.isBlank(param) || param === 'null';
  }

  var CLEAR_FILTER = {
    'device': null,
    'deviceTypes': null,
    'include': null,
    'startDate': null,
    'endDate': null
  };
  var INCLUDE_TYPES = [{
    id: 'client',
    displayName: 'Client'
  }, {
    id: 'lead',
    displayName: 'Lead'
  }];

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ownerType: 'client',
    getOwner: function getOwner() {
      return this.get('store').find('client', this.get('currentUser.modelId'));
    },
    ownerParams: function ownerParams() {
      var ownerType = this.get('ownerType');
      return {
        ownerType: ownerType,
        owner: this.getOwner()
      };
    },
    queryParams: {
      year: {
        refreshModel: true
      },
      month: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      device: {
        refreshModel: true
      },
      deviceTypes: {
        refreshModel: true
      }
    },
    deserializeArray: function deserializeArray(key, value) {
      var ids = value.split(',');
      var types = this.get('store').peekAll((0, _emberInflector.singularize)(key));

      if (!Ember.isEmpty(ids)) {
        return ids.map(function (typeId) {
          return types.findBy('id', typeId);
        });
      }

      return [];
    },
    serializeArray: function serializeArray(value) {
      if (!Ember.isEmpty(value)) {
        return value.mapBy('id').join(',');
      }

      return null;
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (value) {
        if (urlKey === 'deviceTypes' || urlKey === 'clientTypes') {
          return this.deserializeArray(urlKey, value);
        } else if (urlKey === 'include') {
          var ids = value.split(',');

          if (!Ember.isEmpty(ids)) {
            return ids.map(function (typeId) {
              return INCLUDE_TYPES.findBy('id', typeId);
            });
          }

          return [];
        }
      }

      return this._super(value, urlKey, defaultValueType);
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (Ember.isArray(value)) {
        if (urlKey === 'deviceTypes' || urlKey === 'clientTypes' || urlKey === 'include') {
          return this.serializeArray(value);
        }
      } else if (value === 'null' || Ember.isNone(value)) {
        return '';
      }

      return this._super(value, urlKey, defaultValueType);
    },
    beforeModel: function beforeModel() {
      var store = this.get('store');
      return store.findByCache('package').then(function () {
        return Ember.RSVP.allSettled([store.findByCache('category')]);
      });
    },
    model: function model(params) {
      var store = this.store;
      var ownerParams = this.ownerParams();
      var model = {
        owner: ownerParams.owner,
        ownerType: ownerParams.ownerType
      };

      if (!paramEmpty(params.device)) {
        model['device'] = store.query('device', params.device);
      }

      if (!Ember.isEmpty(params.deviceTypes)) {
        model['deviceTypes'] = params.deviceTypes;
      }

      if (!Ember.isEmpty(params.include)) {
        model['include'] = params.include;
      }

      model['includeOptions'] = INCLUDE_TYPES;
      return Ember.RSVP.hash(model);
    },
    setupController: function setupController(controller, model) {
      var ownerDevices = model.owner.get('devices') || Ember.RSVP.resolve([]);
      controller.setProperties({
        deviceTypes: model['deviceTypes'],
        include: model['include'],
        ownerDevices: ownerDevices,
        model: model
      });
    },
    actions: {
      resetFilter: function resetFilter() {
        this.controller.setProperties(CLEAR_FILTER);
      }
    }
  });

  _exports.default = _default;
});