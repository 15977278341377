define("backend/components/partners-component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    templateList: 'components/tables/partners',
    templateEdit: 'components/forms/partner',
    labelCreate: function () {
      return "Create " + Ember.String.capitalize(this.get('createModel'));
    }.property('createModel')
  });

  _exports.default = _default;
});