define("backend/pods/studies/overview/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    filter: ['study'],
    actions: {
      statusChanged: function statusChanged() {
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});