define("backend/utils/static-data/target-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: '6-11'
  }, {
    id: 2,
    name: '12-17'
  }, {
    id: 3,
    name: '18-29'
  }, {
    id: 4,
    name: '30-59'
  }, {
    id: 5,
    name: '60+'
  }];
  _exports.default = _default;
});