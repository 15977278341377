define("backend/mixins/contact-details-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = Ember.Mixin.create({
    email: attr('string'),
    phone: attr('string'),
    picture: attr('string'),
    website: attr('string')
  });

  _exports.default = _default;
});