define("backend/pods/components/hm-form/rating-inline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2mcN7lJ",
    "block": "{\"symbols\":[\"starClass\",\"idx\"],\"statements\":[[4,\"if\",[[28,\"is-pending\",[[24,[\"promise\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[10,\"style\",\"width: 55px\"],[8],[0,\"\\n    \"],[1,[22,\"loading-indicator\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"starts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"rating-star \",[23,1,[]],\" rating-star__\",[23,2,[]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"setRating\",[23,2,[]]],null]],[11,\"onmouseover\",[28,\"action\",[[23,0,[]],\"hoverStar\",[23,2,[]]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/rating-inline/template.hbs"
    }
  });

  _exports.default = _default;
});