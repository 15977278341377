define("backend/templates/components/panel-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rt9wUtZy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\" {{#if color}}\\n  .panel.panel-color.panel-gray\\n    = yield\\n{{else}}\\n  .panel.panel-default\\n    = yield\\n{{/if}} \"],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\n  \"],[2,\" Basic datatable \"],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"panel \",[28,\"if\",[[24,[\"title\"]],\"panel-white\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h5\",true],[10,\"class\",\"panel-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[2,\" <div class=\\\"heading-elements\\\">\\n        <ul class=\\\"icons-list\\\">\\n          <li><a data-action=\\\"collapse\\\"></a></li>\\n          <li><a data-action=\\\"reload\\\"></a></li>\\n          <li><a data-action=\\\"close\\\"></a></li>\\n        </ul>\\n      </div> \"],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[2,\"\\n    <div class=\\\"panel-body\\\">\\n    </div>\\n\"],[0,\"\\n\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/panel-container.hbs"
    }
  });

  _exports.default = _default;
});