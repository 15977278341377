define("backend/pods/sales-cockpit/monthly-activities/route", ["exports", "moment", "backend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/sales/monthly_activities";
          return _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            var distributors = data.mapBy('distributor').uniq().sort();
            var len = (0, _moment.default)().date();
            var model = {};
            var days = [];

            for (var idx = 1; idx <= len; idx++) {
              days.push(idx);
            }

            model.days = days;
            model.distributors = distributors.map(function (distributor) {
              return {
                name: distributor,
                days: days.map(function (day) {
                  var row = data.filterBy('distributor', distributor).findBy('day', "" + day);
                  return row ? row.count : 0;
                })
              };
            });
            resolve(model);
          });
        });
      });
    }
  });

  _exports.default = _default;
});