define("backend/pods/components/hm-model/fieldset-essentials/study/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gehv6RxQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold\"],[8],[7,\"i\",true],[10,\"class\",\"icon-atom2\"],[8],[9],[0,\" Study\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"business-card\"],[10,\"style\",\"position: relative; padding: 12px 0 15px 13px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"position: absolute; bottom: 5px; right: 5px; width: 200px;\"],[8],[0,\"\\n    \"],[1,[28,\"hm-form/select\",null,[[\"model\",\"field\",\"data\",\"allowClear\"],[[24,[\"model\",\"buffers\",\"selected\"]],\"status\",[24,[\"statusList\"]],false]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"font-size: 14px; margin-top: 2px;\"],[8],[7,\"b\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui-space--sm\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"Type: \"],[1,[24,[\"model\",\"type\",\"displayName\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"Period: \"],[1,[24,[\"model\",\"periodFmt\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui-space--sm\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"notes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui-space--sm\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-notes\"],[8],[0,\"\\n      \"],[1,[28,\"format-changelog\",[[24,[\"model\",\"notes\"]]],[[\"showBullets\"],[false]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-essentials/study/template.hbs"
    }
  });

  _exports.default = _default;
});