define("backend/utils/cp/cast-number-cp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(property) {
    return Ember.computed(property, function () {
      try {
        return parseInt(this.get(property, 10));
      } catch (ex) {
        console.log('cast number cp exception', ex);
      }

      return this.get(property);
    });
  }
});