define("backend/models/tester-status", ["exports", "ember-data", "backend/mixins/localize-mixin"], function (_exports, _emberData, _localizeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_localizeMixin.default);

  _exports.default = _default;
});