define("backend/adapters/movie", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      return this.urlForQueryEx(query, modelName, ['client', 'licensor']);
    }
  });

  _exports.default = _default;
});