define("backend/templates/components/navigation-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QxoLxlOB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"navigation navigation-main navigation-accordion\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"tagName\",\"route\"],[\"li\",\"client-features.index\"]],{\"statements\":[[0,\"    \"],[7,\"a\",true],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/logos/hm_logo_icon_no-padding.png\"],[10,\"style\",\"width:20px;\"],[8],[9],[0,\" \"],[7,\"span\",true],[8],[0,\"  Mein HappyMed\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"tagName\",\"route\"],[\"li\",\"client-features.statistics\"]],{\"statements\":[[0,\"    \"],[7,\"a\",true],[8],[7,\"i\",true],[10,\"class\",\"icon-statistics\"],[8],[9],[0,\" \"],[7,\"span\",true],[8],[0,\"Über Patienten lernen\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"tagName\",\"route\"],[\"li\",\"client-features.movies\"]],{\"statements\":[[0,\"    \"],[7,\"a\",true],[8],[7,\"i\",true],[10,\"class\",\"icon-film\"],[8],[9],[0,\" \"],[7,\"span\",true],[8],[0,\"Film bereitstellen\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/navigation-client.hbs"
    }
  });

  _exports.default = _default;
});