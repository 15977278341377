define("backend/templates/components/panel-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y1nw5qpF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canCreate\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showCreateLink\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",null,[[\"class\",\"role\",\"tagName\",\"route\"],[\"btn btn-primary js-create-link\",\"button\",\"button\",[24,[\"createLink\"]]]],{\"statements\":[[14,1]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[4,\"if\",[[24,[\"showCreateButton\"]]],null,{\"statements\":[[7,\"button\",false],[12,\"role\",\"button\"],[12,\"class\",\"btn btn-primary js-create-btn\"],[3,\"action\",[[23,0,[]],[24,[\"createAction\"]]],[[\"on\"],[\"click\"]]],[8],[14,1],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[4,\"if\",[[24,[\"yieldHeadingElements\"]]],null,{\"statements\":[[14,1]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/panel-header.hbs"
    }
  });

  _exports.default = _default;
});