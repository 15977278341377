define("backend/mixins/localize-name-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = Ember.Mixin.create({
    name: attr('string'),
    code: attr('string'),
    label: function () {
      return "Name (" + this.get('code') + ")";
    }.property('code')
  });

  _exports.default = _default;
});