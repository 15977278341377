define("backend/pods/components/hm-form/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        attrLabel: this.get('selectLabel') || 'displayName'
      });
    },
    actions: {
      setOption: function setOption(option) {
        var _this$getProperties = this.getProperties(['model', 'field']),
            model = _this$getProperties.model,
            field = _this$getProperties.field;

        if (model.set) {
          model.set(field, option);
        } else {
          model[field] = option;
        }

        if (this.get('acts.setAction')) {
          this.get('acts.setAction')(option);
        }
      },
      onOpen: function onOpen() {
        if (this.get('acts.openAction')) {
          this.get('acts.openAction')();
        }
      }
    }
  });

  _exports.default = _default;
});