define("backend/pods/movies/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['template', 'modelName'],
    modelName: 'movie'
  });

  _exports.default = _default;
});