define("backend/pods/components/hm-model/fieldset-profile/payment-light/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TANrfwEZ",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold\"],[8],[7,\"i\",true],[10,\"class\",\"icon-credit-card position-left\"],[8],[9],[0,\" Payment\"],[9],[0,\"\\n\"],[4,\"hm-form/wrapper\",null,[[\"model\"],[[24,[\"model\",\"buffers\",\"bank\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/pods/components/hm-model/fieldset-profile/payment-light/template.hbs' @ L3:C4) \"],null]],[[\"field\"],[\"depositor\"]]],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/pods/components/hm-model/fieldset-profile/payment-light/template.hbs' @ L4:C4) \"],null]],[[\"field\"],[\"bic\"]]],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"string\"]],\"expected `f.string` to be a contextual component but found a string. Did you mean `(component f.string)`? ('backend/pods/components/hm-model/fieldset-profile/payment-light/template.hbs' @ L5:C4) \"],null]],[[\"field\"],[\"iban\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-profile/payment-light/template.hbs"
    }
  });

  _exports.default = _default;
});