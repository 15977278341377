define("backend/serializers/package", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      if (hash.id === null) {
        hash.id = "deleted-".concat(Ember.guidFor(hash));
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});