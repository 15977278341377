define("backend/pods/components/hm-model/form/profile/component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    router: Ember.inject.service(),
    ownerType: null,
    // lead, client, distributor etc.
    keepSelected: true,
    afterCancelAction: 'listOwners',
    afterDeleteAction: 'listOwners',
    init: function init() {
      this._super.apply(this, arguments);

      this.edit(this.get('selected'));
    },
    reInitModelChanges: Ember.observer('selected', function () {
      this.edit(this.get('selected'));
    }),
    notifyCancel: function notifyCancel() {
      this._super.apply(this, arguments);

      this.sendAction('afterCancelAction', this.get('ownerType'));
    },
    notifySaveComplete: function notifySaveComplete(model) {
      this._super.apply(this, arguments);

      this._reloadCounters(model);
    },
    notifyDeleteComplete: function notifyDeleteComplete() {
      this._super.apply(this, arguments);

      this.sendAction('afterDeleteAction', this.get('ownerType'));
    },
    _reloadCounters: function _reloadCounters(model) {
      if (model) {
        if (model.then) {
          return model.then(function (m) {
            return m.reload();
          });
        } else {
          return model.reload();
        }
      }
    }
  });

  _exports.default = _default;
});