define("backend/pods/attachments/controller", ["exports", "backend/prototypes/tables/headers/attachments-all"], function (_exports, _attachmentsAll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'type'],
    page: 1,
    size: 100,
    tableColumns: _attachmentsAll.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    }
  });

  _exports.default = _default;
});