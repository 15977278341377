define("backend/pods/components/hm-model/fieldset-essentials/quick-add/event/component", ["exports", "backend/utils/hm-transforms/date-time"], function (_exports, _dateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'fieldset',
    showLabel: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.createNewEvent();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('newEvent.isNew') && !this.get('newEvent.isSaving')) {
        this.get('newEvent').rollbackAttributes();
      }
    },
    createNewEvent: function createNewEvent() {
      var _this = this;

      var evt = this.get('store').createRecord('event');
      evt.createBuffers();
      this.set('newEvent', evt);

      if (this.model.contacts && this.model.contacts.then) {
        this.model.contacts.then(function (rel) {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          var contacts = rel.toArray();

          if (contacts.length === 1) {
            evt.set('reference', contacts[0].displayName);
          }
        });
      }
    },
    saveNewEvent: function saveNewEvent() {
      var _this$getProperties = this.getProperties('model', 'modelName', 'newEvent'),
          model = _this$getProperties.model,
          modelName = _this$getProperties.modelName,
          newEvent = _this$getProperties.newEvent;

      newEvent.set(Ember.String.camelize(modelName), model);
      newEvent.applyBuffers();
      return newEvent.save();
    },
    reloadAllEvents: function reloadAllEvents() {
      var model = this.get('model');
      model.reload();
      model.get('events').reload();
      var tasks = model.get('tasks');

      if (tasks) {
        model.get('tasks').reload();
      }
    },
    listOfEventTypes: Ember.computed(function () {
      var modelName = this.get('modelName');
      return this.get('store').peekAll('eventType').filter(function (eventType) {
        return eventType.get('canEdit') && eventType.isVisibleFor(modelName);
      }).sortBy('order');
    }),
    timestampTime: Ember.computed('newEvent.buffers.selected.timestamp', {
      get: function get() {
        var timestamp = this.get('newEvent.buffers.selected.timestamp');
        return _dateTime.default.deserialize(timestamp);
      },
      set: function set(_, newTime) {
        var curTimestamp = this.get('newEvent.buffers.selected.timestamp');

        var newTimestamp = _dateTime.default.serialize(curTimestamp, newTime);

        if (newTimestamp) {
          this.set('newEvent.buffers.selected.timestamp', newTimestamp);
          return newTime;
        }

        return null;
      }
    }),
    actions: {
      saveEvent: function saveEvent() {
        var _this2 = this;

        this.set('promise', this.saveNewEvent().then(function () {
          _this2.reloadAllEvents();

          _this2.createNewEvent();
        }));
      }
    }
  });

  _exports.default = _default;
});