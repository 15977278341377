define("backend/templates/components/edit-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZN802Rry",
    "block": "{\"symbols\":[],\"statements\":[[15,\"partials/show-error\",[]],[0,\"\\n\"],[4,\"if\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"  \"],[4,\"panel-container\",null,[[\"color\"],[true]],{\"statements\":[[1,[28,\"panel-header\",null,[[\"title\"],[[24,[\"labelHeader\"]]]]],false],[1,[28,\"entry-form\",null,[[\"selected\",\"errors\",\"data\",\"template\"],[[24,[\"selected\"]],[24,[\"validationErrors\"]],[24,[\"data\"]],[24,[\"templateEdit\"]]]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/edit-entry.hbs"
    }
  });

  _exports.default = _default;
});