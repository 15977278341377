define("backend/pods/leads/filter-testing/controller", ["exports", "backend/pods/leads/filter-active/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    page: 1,
    size: 10000
  });

  _exports.default = _default;
});