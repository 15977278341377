define("backend/pods/components/hm-model/fieldset-essentials/movie-device-statistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUnjo/gH",
    "block": "{\"symbols\":[\"async\",\"data\",\"@model\"],\"statements\":[[7,\"fieldset\",true],[8],[0,\"\\n  \"],[7,\"legend\",true],[10,\"class\",\"text-semibold label-small-gap\"],[8],[7,\"i\",true],[10,\"class\",\"icon-film position-left\"],[8],[9],[0,\" Movie/Device Summary\"],[9],[0,\"\\n\\n  \"],[5,\"async\",[],[[\"@do\"],[[23,0,[\"loadMovieDeviceSummary\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"pb-3\"],[3,\"did-insert\",[[23,1,[\"actions\",\"do\"]]]],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"state\",\"isBusy\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"state\",\"didSucceed\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"state\",\"result\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[1,[23,2,[\"movies\"]],false],[0,\"/\"],[1,[23,2,[\"total_movies\"]],false],[0,\" \"],[1,[23,3,[\"displayName\"]],false],[0,\" movies active on \"],[1,[23,2,[\"devices\"]],false],[0,\"/\"],[1,[23,2,[\"total_devices\"]],false],[0,\" \"],[1,[23,2,[\"device_type\"]],false],[0,\" devices\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"state\",\"didError\"]]],null,{\"statements\":[[0,\"      Could not load movie/device stats\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-essentials/movie-device-statistics/template.hbs"
    }
  });

  _exports.default = _default;
});