define("backend/components/chart-device-replays", ["exports", "ember-data", "moment", "d3-time-format"], function (_exports, _emberData, _moment, _d3TimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var PromiseArray = _emberData.default.PromiseArray;

  function durationToSeconds(formattedDuration) {
    var _formattedDuration$sp = formattedDuration.split(':'),
        _formattedDuration$sp2 = _slicedToArray(_formattedDuration$sp, 3),
        hour = _formattedDuration$sp2[0],
        minute = _formattedDuration$sp2[1],
        sec = _formattedDuration$sp2[2];

    return Number(sec) + minute * 60 + hour * 60 * 60;
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    statistic: Ember.computed('year', 'month', 'fromCreated', 'ownerType', 'ownerId', 'device', 'deviceType', 'include', function () {
      var _this = this;

      var query = this.getProperties('year', 'month', 'ownerType', 'ownerId', 'device', 'deviceType', 'include');

      if (!query.ownerId) {
        return [];
      }

      query['ownerType'] = Ember.String.underscore(query['ownerType']);
      var adapter = this.get('store').adapterFor('statistic');

      if (this.get('fromCreated')) {
        return PromiseArray.create({
          promise: this.get('overviewStatistic').then(function (_ref) {
            var data = _ref.data;
            var totalDuration = data.map(function (_ref2) {
              var duration = _ref2.duration;
              return durationToSeconds(duration);
            }).reduce(function (sum, timeInSeconds) {
              return sum + timeInSeconds;
            }, 0);

            _this.set('totalDuration', (0, _d3TimeFormat.timeFormat)("%-H:%M")(new Date(2012, 0, 1, 0, 0, totalDuration || 0)));

            var duration = _moment.default.duration(totalDuration || 0, 'second');

            var days = Math.trunc(duration.asDays() || 0);
            var hours = Math.trunc(duration.asHours() || 0);
            var minutes = Math.trunc(duration.asMinutes() || 0);

            if (days > 0) {
              _this.set('totalDuration', "".concat(days, " days ").concat(hours - days * 24, "h ").concat(minutes - hours * 60, "m"));
            } else if (hours > 0) {
              _this.set('totalDuration', "".concat(hours, "h ").concat(minutes - hours * 60, "m"));
            } else {
              _this.set('totalDuration', "".concat(minutes, "m"));
            }

            return data.map(function (obj) {
              return Ember.Object.create(obj);
            });
          })
        });
      }

      return PromiseArray.create({
        promise: adapter.queryOverview(query).then(function (_ref3) {
          var data = _ref3.data;
          return data.map(function (obj) {
            return Ember.Object.create(obj);
          });
        })
      });
    }),
    chartData: Ember.computed('statistic.length', 'devices.length', function () {
      this.get('devices');
      return this.get('statistic').reduce(function (data, _ref4) {
        var x = _ref4.date,
            deviceId = _ref4.device,
            duration = _ref4.duration;
        var label = deviceId;
        var dataPoint = data.findBy('x', x);

        if (dataPoint) {
          dataPoint[label] = durationToSeconds(duration) / 60;
        } else {
          dataPoint = {
            x: x
          };
          dataPoint[label] = durationToSeconds(duration) / 60;
          data.push(dataPoint);
        }

        return data;
      }, []).map(function (d) {
        var date = new Date(d.x);
        var dateUTC = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        d.x = dateUTC;
        return d;
      }).sortBy('x');
    }),
    additionalLegendDevices: Ember.computed('owner.devices.length', function () {
      var devices = this.get('owner.devices');
      return devices ? devices.mapBy('id') : [];
    }),
    x0DataRange: Ember.computed('year', 'month', function () {
      var _this$getProperties = this.getProperties('year', 'month'),
          year = _this$getProperties.year,
          month = _this$getProperties.month;

      return [(0, _moment.default)().year(year).month(month - 1).startOf('month').toDate(), (0, _moment.default)().year(year).month(month - 1).endOf('month').toDate()];
    })
  });

  _exports.default = _default;
});