define("backend/prototypes/owners/index", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    ownerQuery: function ownerQuery(params) {
      return this.curPage(params);
    },
    model: function model(params) {
      var store = this.store;
      return this.queryModel({
        owners: store.query(this.get('ownerType'), this.ownerQuery(params))
      });
    },
    lazyloadDependencies: function lazyloadDependencies() {
      var store = this.store;
      return {
        statusList: store.peekAll(Ember.String.dasherize(this.get('ownerType') + 'Status'))
      };
    }
  });

  _exports.default = _default;
});