define("backend/helpers/formatters/trend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _default(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        percent = _ref3[0];

    var model = _ref2.model;
    var trend = model.get('batteryLevelTrend');
    var icon, color;

    if (trend === 'up') {
      icon = 'arrow-up-right2';
      color = 'green';
    } else if (trend === 'down') {
      icon = 'arrow-down-right2';
      color = 'red';
    } else if (trend === 'constant') {
      icon = 'arrow-right7';
      color = 'orange';
    }

    if (icon) {
      icon = 'icon-' + icon;
      return Ember.String.htmlSafe("".concat(percent, " <i class=\"").concat(icon, "\" style=\"color: ").concat(color, "\"></i>"));
    }

    return percent;
  }
});