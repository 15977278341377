define("backend/pods/components/hm-models/list/orders/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3dQQJFa3",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"if\",[[23,1,[\"isOpenAndDeliveryInPast\"]],\"bg-red-lighter rounded-sm font-medium\"],null]],[8],[0,\"\\n  \"],[1,[28,\"moment-format\",[[23,1,[\"deliveryDate\"]],\"DD.MM.YYYY\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[\"deliveryDateInFuture\"]],[28,\"not\",[[23,1,[\"deliveryFlexible\"]]],null]],null]],null,{\"statements\":[[0,\"    (\"],[1,[23,1,[\"deliveryInDays\"]],false],[0,\" days)\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"not\",[[23,1,[\"deliveryDateInFuture\"]]],null],[23,1,[\"deliveryFlexible\"]]],null]],null,{\"statements\":[[0,\"    (\"],[7,\"span\",true],[10,\"class\",\"uppercase font-medium text-base\"],[8],[0,\"FLEXIBLE\"],[9],[0,\")\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[23,1,[\"deliveryDateInFuture\"]],[23,1,[\"deliveryFlexible\"]]],null]],null,{\"statements\":[[0,\"    (\"],[1,[23,1,[\"deliveryInDays\"]],false],[0,\" days, \"],[7,\"span\",true],[10,\"class\",\"uppercase font-medium text-base\"],[8],[0,\"FLEXIBLE\"],[9],[0,\")\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/orders/date/template.hbs"
    }
  });

  _exports.default = _default;
});