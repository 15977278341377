define("backend/pods/components/hm-form/file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "51mAmeO3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"uploader\"],[11,\"title\",[29,[[22,\"value\"]]]],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"class\",\"name\",\"type\",\"disabled\"],[[24,[\"value\"]],\"form-control filename\",\"file\",\"text\",true]]],false],[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",\"action btn bg-danger\"],[12,\"style\",\"-webkit-user-select: none;\"],[3,\"action\",[[23,0,[]],[24,[\"acts\",\"deleteAction\"]]]],[8],[7,\"i\",true],[10,\"class\",\"icon-cross3\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"hm-form/file-upload\",null,[[\"url\",\"file\",\"accept\",\"placeholder\",\"createUploadUrl\"],[[24,[\"model\",\"uploadUrl\"]],[24,[\"value\"]],[24,[\"accept\"]],[24,[\"placeholder\"]],[24,[\"acts\",\"createUploadUrl\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/file/template.hbs"
    }
  });

  _exports.default = _default;
});