define("backend/pods/movie/events/route", ["exports", "backend/prototypes/events/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ownerType: 'movie',
    isMovie: true
  });

  _exports.default = _default;
});