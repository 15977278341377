define("backend/pods/components/hm-model/fieldset-profile/lead-source/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    keywords: Ember.inject.service(),
    tagName: 'fieldset',
    buffers: Ember.computed.alias('model.buffers'),
    actions: {
      createTipster: function createTipster(name) {
        var _this = this;

        this.get('store').createRecord('tipster', {
          name: name
        });
        Ember.run.schedule('afterRender', function () {
          return _this.set('buffers.selected.tipster', name);
        });
      }
    }
  });

  _exports.default = _default;
});