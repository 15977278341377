define("backend/validators/shipping-or-billing-address", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ShippingOrBillingAddress = _base.default.extend({
    validate: function validate(value, options, model) {
      if (model.get('inverseAttr') === 'shippingAddress' || model.get('inverseAttr') === 'billingAddress' || model.get('inverseAttr') === 'materialOrderAddress' || model.get('inverseAttr') === 'repairOrderAddress') {
        return true;
      } // TODO: Cleanup; Currently server-side validation take care of following
      // if (model && model.get('client.buffers.selected.showShippingAddress') === false && model.get('inverseAttr') === 'shippingAddress') {
      //   return true;
      // }
      // if (model && model.get('lead.buffers.selected.showShippingAddress') === false  && model.get('inverseAttr') === 'shippingAddress') {
      //   return true;
      // }
      // if (model && model.get('client.buffers.selected.showBillingAddress') === false  && model.get('inverseAttr') === 'billingAddress') {
      //   return true;
      // }


      if (Ember.isEmpty(value)) {
        return 'This field can\'t be blank';
      }

      return true;
    }
  });

  ShippingOrBillingAddress.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor()
    /* attribute, options */
    {
      return [];
    }
  });
  var _default = ShippingOrBillingAddress;
  _exports.default = _default;
});