define("backend/pods/components/hm-model/fieldset-essentials/manage-events-tasks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJ/HBQki",
    "block": "{\"symbols\":[\"@skipTasks\"],\"statements\":[[1,[28,\"hm-model/fieldset-essentials/quick-add\",null,[[\"page\",\"model\",\"modelName\",\"skipTasks\"],[[24,[\"page\"]],[24,[\"model\"]],[24,[\"modelName\"]],[23,1,[]]]]],false],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[28,\"hm-model/fieldset-essentials/list-add\",null,[[\"page\",\"model\",\"modelName\",\"skipTasks\"],[[24,[\"page\"]],[24,[\"model\"]],[24,[\"modelName\"]],[23,1,[]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-essentials/manage-events-tasks/template.hbs"
    }
  });

  _exports.default = _default;
});