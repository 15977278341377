define("backend/prototypes/tables/headers/notes-any", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Meta'
    },
    columns: [{
      attr: 'ID',
      name: 'Id'
    }, {
      attr: 'created_at',
      name: 'Created',
      format: 'datetime'
    }, {
      attr: 'modified_at',
      name: 'Modified',
      format: 'datetime'
    }]
  }, {
    group: {
      name: 'Jour Fixe'
    },
    columns: [{
      attr: 'distributor',
      name: 'Distributor'
    }, {
      attr: 'start_date',
      name: 'Start Date',
      format: 'date'
    }, {
      attr: 'end_date',
      name: 'End Date',
      format: 'date'
    }]
  }, {
    group: {
      name: 'Note'
    },
    columns: [{
      attr: 'mobiledoc',
      name: 'Note',
      component: 'render-notes'
    }]
  }]);

  _exports.default = _default;
});