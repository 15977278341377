define("backend/pods/studies-partner/devices/route", ["exports", "backend/prototypes/owner/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devices.default.extend({
    beforeModel: function beforeModel() {
      this.setProperties({
        owner: this.modelFor('studies-partner'),
        ownerType: this.modelFor('studies-partner').get('ownerType')
      });
    }
  });

  _exports.default = _default;
});