define("backend/pods/components/hm-form/wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: {
      layout: {
        inputCol: 'col-lg-9',
        labelCol: 'col-lg-3',
        inputOnly: false
      },
      control: {
        disabled: false
      }
    },
    yesNo: Ember.computed(function () {
      return [Ember.Object.create({
        id: true,
        displayName: 'Yes'
      }), Ember.Object.create({
        id: false,
        displayName: 'No'
      })];
    })
  });

  _exports.default = _default;
});