define("backend/prototypes/tables/headers/documents-all", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'title',
    canEdit: true
  }, {
    attr: 'date',
    format: 'date',
    canEdit: true
  }, {
    attr: 'language',
    format: 'language',
    canEdit: true
  }, {
    attr: 'description',
    format: 'description',
    canEdit: true
  }, {
    attr: 'file',
    component: 'public-link'
  }]);

  _exports.default = _default;
});