define("backend/pods/leads/all/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.transitionTo('leads.filter-active');
    }
  });

  _exports.default = _default;
});