define("backend/pods/components/hm-simple-forms/create-material-order/component", ["exports", "backend/components/route-component", "backend/db-cache/countries", "backend/utils/static-data/material-order-products"], function (_exports, _routeComponent, _countries, _materialOrderProducts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var Order = Ember.Object.extend({
    owner: null,
    department: null,
    headphoneCoverProduct: null,
    shippingOption: null,
    billingTask: null,
    reparatur: null,
    individualProducts: [],
    marketingProducts: [],
    files: [],
    save: function save(store, options) {
      if (!this.get('owner')) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a client or lead"
          }]
        });
      }

      if (!this.get('department')) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a department"
          }]
        });
      }

      if (!this.headphoneCoverProduct && this.individualProducts.length == 0 && this.marketingProducts.length == 0) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a product"
          }]
        });
      }

      var data = JSON.parse(JSON.stringify(this));
      data.files = this.files.filter(function (f) {
        return !!f.get('url');
      }).mapBy('url').join(',');
      data.owner = {
        id: this.get('owner.id'),
        displayName: this.get('owner.displayName')
      };
      var country = _countries.default.findBy('id', this.get('owner.address.country')) ? _countries.default.findBy('id', this.get('owner.address.country')).displayName : this.get('owner.address.country');
      data.owner_address = [[this.get('owner.address.address1'), this.get('owner.address.address2'), this.get('owner.address.address3')].join(' '), [this.get('owner.address.zip'), this.get('owner.address.city')].join(' '), [country, this.get('owner.address.state')].join(' ')].join('\n');
      return store.adapterFor('application').createNewMaterialOrder(data);
    }
  });

  var _default = _routeComponent.default.extend({
    isPending: false,
    options: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.set('order', Order.create());
    },
    sendOrder: function sendOrder() {
      var _this = this;

      console.log('material sendOrder');
      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('order').save(this.get('store'), this.get('options')).then(function () {
        var flashes = _this.get('flashes');

        var succFlash = {
          alert: 'Material order has been sent!'
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this.set('isPending', false);
      });
    },
    headphoneCovers: _materialOrderProducts.HEADPHONE_COVERS,
    individualProducts: _materialOrderProducts.INDIVIDUAL_PRODUCTS,
    marketingProducts: _materialOrderProducts.MARKETING_PRODUCTS,
    shippingOptions: _materialOrderProducts.SHIPPING_PRODUCTS,
    actions: {
      submit: function submit() {
        var _document$querySelect, _document$querySelect2;

        console.log('material submit');
        var headphoneCoverId = (_document$querySelect = document.querySelector('input[name="headphone"]:checked')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.value;
        var shippingId = (_document$querySelect2 = document.querySelector('input[name="shipping"]:checked')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.value;

        var individualProductIds = _toConsumableArray(document.querySelectorAll('input[name="individual"]:checked')).map(function (d) {
          return d.id;
        });

        var marketingProductIds = _toConsumableArray(document.querySelectorAll('input[name="marketing"]:checked')).map(function (d) {
          return d.id;
        });

        var billingTask = document.querySelector('input#billingTask:checked');
        var reparatur = document.querySelector('textarea#reparatur');
        this.order.set('headphoneCoverProduct', headphoneCoverId ? this.headphoneCovers.findBy('articleNo', headphoneCoverId) : null);
        this.order.set('individualProducts', this.individualProducts.filter(function (p) {
          return individualProductIds.includes(p.articleNo);
        }));
        this.order.set('marketingProducts', this.marketingProducts.filter(function (p) {
          return marketingProductIds.includes(p.articleNo);
        }));
        this.order.set('shippingOption', shippingId ? this.shippingOptions.findBy('articleNo', shippingId) : null);
        this.order.set('billingTask', billingTask ? true : false);
        this.order.set('reparatur', reparatur.value); //console.log('material submit: '. this.order.get('individualProducts'));

        return this.sendOrder();
      },
      selectOwner: function selectOwner(owner) {
        this.get('order').set('department', owner.get('departments.firstObject.id'));
      },
      addFile: function addFile() {
        var _this2 = this;

        this.createUploadUrl('attachment').then(function (_ref) {
          var upload_url = _ref.data.upload_url;
          return upload_url;
        }).then(function (uploadUrl) {
          _this2.get('order.files').pushObject(Ember.Object.create({
            url: null,
            uploadUrl: uploadUrl
          }));
        });
      },
      removeFile: function removeFile(file) {
        this.get('order.files').removeObject(file);
      }
    }
  });

  _exports.default = _default;
});