define("backend/components/manage-owner-devices", ["exports", "backend/components/devices-component"], function (_exports, _devicesComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devicesComponent.default.extend({
    devices: Ember.computed.alias('data.devices'),
    createPath: 'data.owner.devices',
    templateEdit: 'components/forms/owner-device',
    // don't set
    _setClientHappyMed: function _setClientHappyMed() {}
  });

  _exports.default = _default;
});