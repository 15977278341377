define("backend/pods/components/hm-form/label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ENGLISH_FIELDS = {
    email: 'E-Mail',
    vat: 'VAT',
    zip: 'ZIP',
    iban: 'IBAN',
    bic: 'BIC',
    titlePrefix: 'Title (Before Name)',
    titleSuffix: 'Title (After Name)',
    firstName: 'First Name',
    lastName: 'Last Name',
    companyName: 'Company Name',
    companyDepartment: 'Department',
    startDate: 'Start Date',
    studiesPartner: 'Studies Partner',
    // DEVICES
    serialNumber: 'Serial Number',
    deviceVersion: 'Device Version',
    deviceModel: 'Device Model',
    deliveryDate: 'Assembly Date',
    returnDate: 'Return Date',
    ownerType: 'Type',
    licenseStatus: 'License Status',
    // DEVICE PACKAGE
    sellingPrice: 'Selling Price',
    expirationDate: 'Expiration Date'
  };

  var _default = Ember.Component.extend({
    classNameBindings: ['fullwidth:row:form-group'],
    fullwidth: false,
    inputCol: 'col-lg-9',
    labelCol: 'col-lg-3',
    label: Ember.computed('field', function () {
      var f = this.get('field');
      return ENGLISH_FIELDS[f] || Ember.String.capitalize(f);
    })
  });

  _exports.default = _default;
});