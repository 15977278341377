define("backend/utils/export-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var CSV_MIME = 'text/csv;charset=utf-8;';
  var UTF8_BOM = "\uFEFF";

  function _default(csv, fileName) {
    var blob = new Blob([UTF8_BOM, csv], {
      type: CSV_MIME
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      var link = document.createElement("a");

      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
});