define("backend/pods/studies/overview/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size'],
    page: 1,
    size: 10000,
    byStatusPlanned: Ember.computed.filterBy('model', 'status.id', '1'),
    byStatusActive: Ember.computed.filterBy('model', 'status.id', '2'),
    byStatusDone: Ember.computed.filterBy('model', 'status.id', '6'),
    byStatusCancelled: Ember.computed.filterBy('model', 'status.id', '7'),
    actions: {
      goTo: function goTo(study) {
        var _this = this;

        return study.reload().then(function () {
          return _this.transitionToRoute('study.index', study);
        });
      },
      updateStatus: function updateStatus(study, _ref) {
        var _this2 = this;

        var newStatus = _ref.target.newStatus;
        return study.updateStatus(newStatus).then(function () {
          return study.get('events').reload();
        }).finally(function () {
          return _this2.send('statusChanged');
        });
      }
    }
  });

  _exports.default = _default;
});