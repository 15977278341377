define("backend/pods/logs/battery/table/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var params = this.paramsFor('logs.battery');

      if (params && params.page) {
        params['page'] = {
          number: params.page,
          size: params.size
        };
      }

      return Ember.RSVP.hash({
        logs: this.store.query('log', params),
        page: params.page.number,
        size: params.page.size
      });
    }
  });

  _exports.default = _default;
});