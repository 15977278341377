define("backend/components/render-mobiledoc", ["exports", "ember-mobiledoc-dom-renderer/components/render-mobiledoc"], function (_exports, _renderMobiledoc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _renderMobiledoc.default;
    }
  });
});