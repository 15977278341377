define("backend/pods/statistics/summary/route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function paramEmpty(param) {
    return Ember.isBlank(param) || param === 'null';
  }

  var CLEAR_FILTER = {
    'movie': null,
    'licensor': null,
    'revenueShare': null,
    'device': null,
    'deviceTypes': null,
    'clientTypes': null,
    'ownerType': null,
    'ownerId': null,
    'startDate': null,
    'endDate': null,
    'totalRevenueShare': null
  }; // let paramsRevShare = null;

  var _default = Ember.Route.extend({
    queryParams: {
      year: {
        refreshModel: true
      },
      month: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      ownerId: {
        refreshModel: true
      },
      ownerType: {
        refreshModel: true
      },
      clientTypes: {
        refreshModel: true
      },
      device: {
        refreshModel: true
      },
      deviceTypes: {
        refreshModel: true
      },
      movie: {
        refreshModel: true
      },
      licensor: {
        refreshModel: true
      },
      revenueShare: {
        refreshModel: true
      }
    },
    deserializeArray: function deserializeArray(key, value) {
      var ids = value.split(',');
      var types = this.get('store').peekAll((0, _emberInflector.singularize)(key));

      if (!Ember.isEmpty(ids)) {
        return ids.map(function (typeId) {
          return types.findBy('id', typeId);
        });
      }

      return [];
    },
    serializeArray: function serializeArray(value) {
      if (!Ember.isEmpty(value)) {
        return value.mapBy('id').join(',');
      }

      return null;
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (value) {
        if (urlKey === 'deviceTypes' || urlKey === 'clientTypes') {
          return this.deserializeArray(urlKey, value);
        }
      }

      return this._super(value, urlKey, defaultValueType);
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (Ember.isArray(value)) {
        if (urlKey === 'deviceTypes' || urlKey === 'clientTypes') {
          return this.serializeArray(value);
        }
      } else if (value === 'null' || Ember.isNone(value)) {
        return '';
      }

      return this._super(value, urlKey, defaultValueType);
    },
    // ownerParams: function() {
    //
    // },
    model: function model(params) {
      console.log('params', params); // const store = this.store;

      var model = {// devices: store.findByCache('device').then((devices) => devices.sortBy('serialNumber')),
        // licensors: store.findByCache('licensor').then((licensors) => licensors.sortBy('displayName')),
        // movies: store.findByCache('movie', { page: { size: 10000 }}).then((movies) => movies.filterBy('isLoaded')),
        // allClientTypes: store.peekAll('client-type')
      }; // if (!paramEmpty(params.device)) {
      //   model['device'] = store.query('device', params.device);
      // }
      // if (!paramEmpty(params.ownerId) && !paramEmpty(params.ownerType)) {
      //   if (params.ownerType === 'store') {
      //     model['owner'] = store.peekAll('in-store').objectAt(0);
      //   } else {
      //     model['owner'] = store.find(params.ownerType, params.ownerId);
      //   }
      // }
      // if (!paramEmpty(params.licensor)) {
      //   model['licensor'] = store.query('licensor', params.licensor);
      // }
      // if (!paramEmpty(params.movie)) {
      //   model['movie'] = store.query('movie', params.movie).catch((xhr) => {
      //     if (xhr && xhr.status === 404) {
      //       return store.peekAll('movie').findBy('id', params.movie);
      //     }
      //     return null;
      //   });
      // }
      // if (!isEmpty(params.deviceTypes)) {
      //   model['deviceTypes'] = params.deviceTypes;
      // }
      // if (!isEmpty(params.clientTypes)) {
      //   model['clientTypes'] = params.clientTypes;
      // }
      // return RSVP.hash(model);
    },
    //
    // compareQueryParams: function(params1, params2) {
    //   if (!params1 || !params2) { return false; }
    //   let isEqual = true;
    //   Object.keys(params1).forEach((key) => {
    //     if (isEqual && !isBlank(params1[key])) {
    //       isEqual = params1[key] === params2[key];
    //     }
    //   })
    //   return isEqual;
    // },
    actions: {// resetFilter: function() {
      //   this.controller.setProperties(CLEAR_FILTER);
      // },
      // queryParamsDidChange: function(_, params) {
      //   if (paramsRevShare && !this.compareQueryParams(params, JSON.parse(paramsRevShare))) {
      //     paramsRevShare = null;
      //     this.controller && this.controller.set('totalRevenueShare', null);
      //   } else if (params && params.hasOwnProperty('totalRevenueShare')) {
      //     paramsRevShare = JSON.stringify(params);
      //   } else {
      //     paramsRevShare = null;
      //     this.controller && this.controller.set('totalRevenueShare', null);
      //   }
      //   return this._super(...arguments);
      // }
    }
  });

  _exports.default = _default;
});