define("backend/pods/billings/tasks/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CURRENT_ROUTE_URL = '/billings/tasks';

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').query('hm-task', {
        due: '7_days',
        billing: true
      });
    },
    actions: {
      toggleTask: function toggleTask() {
        return false;
      },
      createBilling: function createBilling(task) {
        var client = task.get('client.id');
        var info = task.get('info');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'billings'].join('/') + "?create=".concat(info);
        window.open(url, '_blank').focus();
      },
      viewContract: function viewContract(task) {
        var client = task.get('client.id');
        var contract = task.get('contract');
        var url = [location.href.slice(0, location.href.indexOf(CURRENT_ROUTE_URL)), 'clients', client, 'contracts'].join('/') + "?open=".concat(contract);
        window.open(url, '_blank').focus();
      }
    }
  });

  _exports.default = _default;
});