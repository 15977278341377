define("backend/pods/components/hm-uploader/progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FC3jgsKN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"padding-left:20px;\"],[8],[0,\"\\n  \"],[4,\"unless\",[[24,[\"inline\"]]],null,{\"statements\":[[7,\"h4\",true],[8],[7,\"b\",true],[8],[0,\"Current Upload\"],[9],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"Upload in progress: \"],[7,\"b\",true],[8],[1,[24,[\"uploader\",\"progress\"]],false],[0,\"%\"],[9],[0,\" of \"],[1,[24,[\"uploader\",\"filename\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-uploader/progress/template.hbs"
    }
  });

  _exports.default = _default;
});