define("backend/templates/partials/table/-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lYrXkiry",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[7,\"thead\",true],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tableColumnsSorted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"th\",false],[12,\"class\",[29,[\"hide-td cursor-pointer \",[28,\"if\",[[23,1,[\"display\"]],\"display-td\"],null],\" \",[28,\"if\",[[23,1,[\"isSorted\"]],[23,1,[\"sortClass\"]],\"sorting\"],null]]]],[12,\"title\",\"Click to sort\"],[3,\"action\",[[23,0,[]],\"clientSortBy\",[23,1,[\"attr\"]],[23,1,[\"sortAttr\"]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/partials/table/-table-header.hbs"
    }
  });

  _exports.default = _default;
});