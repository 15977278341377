define("backend/templates/components/main-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dXfRDXZi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"left-sidebar\",null,null,{\"statements\":[[0,\"\\n  \"],[2,\" Main navigation \"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sidebar-category sidebar-category-visible\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"category-content no-padding\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"feature-flag\",[\"offboarding\"],null]],null,{\"statements\":[[0,\"        \"],[1,[22,\"navigation-offboarding\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"feature-flag\",[\"restricted-lead-access\"],null]],null,{\"statements\":[[0,\"        \"],[1,[22,\"navigation-lead-only\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"adminInterface\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,\"navigation-admin\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"isDistributor\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,\"navigation-distributor\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"isClient\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,\"navigation-client\"],false],[0,\"\\n        \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[2,\" /main navigation \"],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/main-navigation.hbs"
    }
  });

  _exports.default = _default;
});