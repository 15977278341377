define("backend/pods/export/controller", ["exports", "backend/prototypes/tables/headers/exports"], function (_exports, _exports2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    clientSort: {
      sortBy: 'title',
      order: 'asc'
    },
    tableColumns: _exports2.default
  });

  _exports.default = _default;
});