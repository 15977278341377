define("backend/components/secure-download", ["exports", "backend/config/environment", "backend/utils/ajax-file"], function (_exports, _environment, _ajaxFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'a',
    attributeBindings: ['href', 'download', 'target'],
    mimeType: 'application/pdf',
    icon: 'file-pdf',
    name: Ember.computed.reads('url'),
    openOnDownload: false,
    downloadUrl: Ember.computed('url', function () {
      return _environment.default.host + '/' + this.get('url');
    }),
    fileName: Ember.computed('url', function () {
      var url = this.get('url');
      return url && url.substring(url.lastIndexOf('/') + 1);
    }),
    noFile: Ember.computed.empty('url'),
    resetUrl: Ember.observer('url', function () {
      this.set('fileConverted', false);
    }),
    url: Ember.computed('model', 'attribute', function () {
      var _this$getProperties = this.getProperties('model', 'attribute'),
          model = _this$getProperties.model,
          attribute = _this$getProperties.attribute;

      if (model && attribute) {
        return model.get(attribute);
      }

      return this.get('model.file');
    }),
    didInsertElement: function didInsertElement() {
      if (this.get('startImmediately')) {
        this.triggerDownload();
      }
    },
    click: function click(jqEv) {
      if (jqEv && !this.get('fileConverted') && !this.get('noFile')) {
        jqEv.preventDefault();
        this.triggerDownload();
      }
    },
    triggerDownload: function triggerDownload() {
      var _this = this;

      this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;

        _this.set('isDownloading', true);

        (0, _ajaxFile.default)(_this.get('downloadUrl'), headers, _this.get('mimeType')).then(function (href) {
          _this.downloadFile(href);

          _this.releaseFile(href);

          _this.set('isDownloading', false);
        });
      });
    },
    downloadFile: function downloadFile(href) {
      this.setProperties({
        href: href,
        download: this.openOnDownload ? null : this.get('fileName'),
        fileConverted: true
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        var link = this.$()[0];

        try {
          link.click();
        } catch (ex) {
          console.log(ex);
        } finally {
          if (this.get('clearFile')) {
            this.set('url', null);
          }
        }
      });
    },
    releaseFile: function releaseFile(href) {
      Ember.run.later(this, function () {
        var URL = window.URL || window.webkitURL;

        if (URL && typeof URL.revokeObjectURL === 'function') {
          URL.revokeObjectURL(href);
        }

        if (!this.isDestroyed) {
          this.setProperties({
            href: '#',
            download: '',
            fileConverted: false
          });
        }
      }, 1000);
    }
  });

  _exports.default = _default;
});