define("backend/pods/studies-partners/all/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        leads: this.get('store').query('lead', {
          page: {
            number: 1,
            size: 10000
          },
          filter: 'study'
        }),
        clients: this.get('store').query('client', {
          page: {
            number: 1,
            size: 10000
          },
          filter: 'study'
        })
      });
    }
  });

  _exports.default = _default;
});