define("backend/pods/usage/load-yesterday/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wPHRjTwM",
    "block": "{\"symbols\":[\"async\",\"&default\"],\"statements\":[[5,\"async\",[],[[\"@do\"],[[23,0,[\"loadDate\"]]]],{\"statements\":[[0,\"\\n  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/usage/load-yesterday/template.hbs"
    }
  });

  _exports.default = _default;
});