define("backend/pods/components/hm-form/date/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT_NO_TZ = 'YYYY-MM-DD';

  var _default = Ember.Component.extend({
    selectDay: false,
    date: Ember.computed('value', function () {
      var value = this.get('value');

      if (value) {
        if (typeof value === 'string') {
          if (this.get('selectInUTC')) {
            value = _moment.default.utc(value);
          } else {
            value = (0, _moment.default)(value);
          }

          if (!value.isValid()) {
            return null;
          }
        }

        if (this.get('selectInUTC') || this.get('selectDay')) {
          return new Date(value.format(DATE_FORMAT_NO_TZ));
        }

        return value.toDate();
      }
    }),
    setDateValue: function setDateValue(datetime) {
      if (!datetime) {
        return this.set('value', null);
      }

      var value;

      if (this.get('selectInUTC')) {
        var selectedDay = datetime.getFullYear() + '-' + ('0' + (datetime.getMonth() + 1)).slice(-2) + '-' + ('0' + datetime.getDate()).slice(-2);

        var dayInUtc = _moment.default.utc(selectedDay);

        value = dayInUtc.toJSON();
      } else if (this.get('selectDay')) {
        value = datetime.getFullYear() + '-' + ('0' + (datetime.getMonth() + 1)).slice(-2) + '-' + ('0' + datetime.getDate()).slice(-2);
      } else {
        value = datetime.toISOString();
      }

      this.set('value', value);
      return value;
    },
    actions: {
      setDate: function setDate(datetime) {
        var value = this.setDateValue(datetime);

        if (this.get('acts.setAction')) {
          this.get('acts.setAction')(value);
        }
      }
    }
  });

  _exports.default = _default;
});