define("backend/prototypes/attachments/index", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEVICE_ATTACHMENT_TYPES = ['1', '2', '4', '23'];
  var OWNER_ATTACHMENT_TYPES = ['3', '5', '7', '11', '12', '13', '14', '15', '16'];
  var CLIENT_ATTACHMENT_TYPES = ['3', '5', '7', '8', '11', '12', '13', '14', '15', '16'];
  var STUDY_ATTACHMENT_TYPES = ['10'];
  var MOVIE_ATTACHMENT_TYPES = DEVICE_ATTACHMENT_TYPES;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    ownerQP: function ownerQP(owner) {
      var qp = {};
      qp[Ember.String.camelize(this.get('ownerType'))] = owner.get('id');
      return qp;
    },
    beforeModel: function beforeModel() {
      this.set('owner', this.modelFor(this.get('ownerType')));
    },
    model: function model(params) {
      var store = this.get('store');
      var owner = this.get('owner');
      var queryParams = Ember.merge(this.curPage(params), this.ownerQP(owner));
      return this.queryModel({
        owner: owner,
        attachments: store.query('attachment', queryParams)
      });
    },
    loadDependencies: function loadDependencies() {
      var store = this.store;
      var types = store.peekAll('attachmentType');

      if (this.get('isDevice')) {
        types = types.filter(function (type) {
          return DEVICE_ATTACHMENT_TYPES.includes(type.get('id'));
        });
      } else if (this.get('isMovie')) {
        types = types.filter(function (type) {
          return MOVIE_ATTACHMENT_TYPES.includes(type.get('id'));
        });
      } else if (this.get('isClient')) {
        types = types.filter(function (type) {
          return CLIENT_ATTACHMENT_TYPES.includes(type.get('id'));
        });
      } else if (this.get('isStudy')) {
        types = types.filter(function (type) {
          return STUDY_ATTACHMENT_TYPES.includes(type.get('id'));
        });
      } else {
        types = types.filter(function (type) {
          return OWNER_ATTACHMENT_TYPES.includes(type.get('id'));
        });
      }

      return {
        types: types
      };
    }
  });

  _exports.default = _default;
});