define("backend/models/category", ["exports", "ember-data", "backend/models/buffered-model", "backend/models/package", "backend/utils/cp/cast-number-cp", "ember-cp-validations"], function (_exports, _emberData, _bufferedModel, _package, _castNumberCp, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var STATUS_INACTIVE = '2';
  var STATUS_TESTING = '3';

  var _default = _bufferedModel.default.extend({
    name: attr('string'),
    language: attr('string'),
    order: attr('number'),
    status: belongsTo('categoryStatus', {
      async: false
    }),
    parent: belongsTo('category', {
      inverse: null,
      async: true
    }),
    'package': belongsTo('package', {
      async: true
    }),
    inheritedPackages: hasMany('package', {
      async: true
    }),
    movies: hasMany('movies', {
      inverse: 'category',
      async: true
    }),
    hasParent: Ember.computed.notEmpty('parent.content'),
    idAndName: Ember.computed('id', 'name', function () {
      var _this$getProperties = this.getProperties('id', 'name'),
          id = _this$getProperties.id,
          name = _this$getProperties.name;

      var packageName = this.get('package.name');
      return "".concat(id, " - ").concat(name, " (Package: ").concat(packageName, ")");
    }),
    childCategories: Ember.computed('package.categories.length', function () {
      var categoryId = this.get('id');
      return this.get('package.categories').filterBy('hasParent', true).filter(function (category) {
        return category.get('parent.id') === categoryId;
      });
    }),
    sortedChildCategories: Ember.computed.sort('childCategories', 'sortByOrder'),
    sortByOrder: ['order:asc'],
    hasChildren: Ember.computed.gt('childCategories.length', 0),
    duration: Ember.computed('counts.duration', 'childCategories.length', function () {
      return Number(this.get('counts.duration') || 0) + this.get('childCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('duration'));
      }, 0);
    }),
    items: Ember.computed('childCategories.length', 'movies.length', function () {
      return Number(this.get('counts.movies') || 0) + this.get('childCategories').filterBy('status.counts').reduce(function (sum, c) {
        return sum + Number(c.get('items'));
      }, 0);
    }),
    counts: attr(),
    sortedMovies: Ember.computed.sort('movies', 'sortByOrder'),
    sortableId: (0, _castNumberCp.default)('id'),
    isInactive: Ember.computed.equal('status.id', STATUS_INACTIVE),
    isTesting: Ember.computed.equal('status.id', STATUS_TESTING),
    displayName: Ember.computed('name', {
      get: function get() {
        return this.get('name');
      }
    }),
    prefill: function prefill(template) {
      var buffer = this.get('buffers.selected');

      if (buffer && template instanceof _package.default) {
        this.set('package', template);
      }
    },
    duplicate: function duplicate() {
      var adapter = this.get('store').adapterFor('category');
      return adapter.duplicate(this.get('id'));
    },
    deleteRecursive: function deleteRecursive() {
      var _this = this;

      var adapter = this.get('store').adapterFor('category');
      return adapter.deleteRecursive(this.get('id')).then(function () {
        return _this.deleteRecord();
      });
    },
    // ----------------------------- VALIDATIONS ---------------------------------
    validators: {
      'package': (0, _emberCpValidations.validator)('presence', true),
      status: (0, _emberCpValidations.validator)('presence', true)
    },
    nameRecordType: 'categoryName'
  });

  _exports.default = _default;
});