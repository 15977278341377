define("backend/pods/statistics/billing/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CLEAR_FILTER = {
    'startDate': null,
    'endDate': null
  };

  var _default = Ember.Route.extend({
    queryParams: {
      year: {
        refreshModel: true
      },
      month: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (Ember.isNone(value)) {
        return '';
      }

      return this._super(value, urlKey, defaultValueType);
    },
    beforeModel: function beforeModel() {
      var store = this.get('store');
      return store.findByCache('licensor');
    },
    actions: {
      resetFilter: function resetFilter() {
        this.controller.setProperties(CLEAR_FILTER);
      }
    }
  });

  _exports.default = _default;
});