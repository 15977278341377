define("backend/access-control-list/device", ["exports", "backend/access-control-list/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    aclIsOwner: (0, _util.isCurrentUserOwner)({
      when: 'isDistributor',
      match: 'owner.id'
    }),
    aclIsOwnersDistributor: (0, _util.isCurrentUserOwner)({
      when: 'isDistributor',
      match: 'owner.distributor.id'
    }),
    canEdit: Ember.computed.reads('currentUser.isAdmin'),
    canEditLocation: Ember.computed.or('aclIsOwner', 'aclIsOwnersDistributor'),
    canEditOwner: Ember.computed.or('aclIsOwner', 'aclIsOwnersDistributor'),
    canEditDeliveryDate: Ember.computed.or('aclIsOwner', 'aclIsOwnersDistributor'),
    canEditReturnDate: Ember.computed.or('aclIsOwner', 'aclIsOwnersDistributor'),
    canSave: Ember.computed.or('aclIsOwner', 'aclIsOwnersDistributor'),
    canSaveDevicePackages: Ember.computed.reads('currentUser.isAdmin'),
    canDestroyDevicePackages: Ember.computed.reads('currentUser.isAdmin')
  });

  _exports.default = _default;
});