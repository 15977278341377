define("backend/serializers/address", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      address1: 'address_1',
      address2: 'address_2',
      address3: 'address_3'
    }
  });

  _exports.default = _default;
});