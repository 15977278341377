define("backend/pods/components/render-tbody-batch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7DUZsfLb",
    "block": "{\"symbols\":[\"row\",\"idx\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"rowsToRender\"]]],null,{\"statements\":[[0,\"  \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/render-tbody-batch/template.hbs"
    }
  });

  _exports.default = _default;
});