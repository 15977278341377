define("backend/pods/logs/other/table/controller", ["exports", "backend/prototypes/tables/headers/logs"], function (_exports, _logs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    size: Ember.computed.reads('model.size'),
    page: Ember.computed.reads('model.page'),
    tableColumns: _logs.default,
    changedPageSize: Ember.observer('size', 'page', function () {
      // this.getProeprties()
      this.transitionToRoute({
        queryParams: {
          size: this.get('size'),
          page: this.get('page')
        }
      });
    })
  });

  _exports.default = _default;
});