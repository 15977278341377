define("backend/pods/client-features/statistics/table/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function durationMinutes(formattedDuration) {
    if (Ember.isEmpty(formattedDuration)) {
      return 0;
    }

    var _formattedDuration$sp = formattedDuration.split(':'),
        _formattedDuration$sp2 = _slicedToArray(_formattedDuration$sp, 3),
        hour = _formattedDuration$sp2[0],
        minute = _formattedDuration$sp2[1],
        sec = _formattedDuration$sp2[2];

    return Number(sec) / 60 + minute * 1 + hour * 60;
  }

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ownerType: 'client',
    ownerParams: function ownerParams() {
      var ownerType = this.get('ownerType');
      return {
        ownerType: ownerType,
        ownerId: this.get('currentUser.modelId'),
        ownerRoute: ownerType
      };
    },
    model: function model() {
      var adapter = this.get('store').adapterFor('statistic');
      var url = adapter.buildURL('statistic');

      var params = this._buildParams();

      return adapter.ajax(url, 'GET', {
        data: params
      }).then(function (_ref) {
        var meta = _ref.meta,
            data = _ref.data;
        return Ember.Object.create({
          meta: meta,
          table: data.map(function (item) {
            item['durationMinutes'] = durationMinutes(item.total_duration);
            return Ember.Object.create(item);
          })
        });
      });
    },
    _buildParams: function _buildParams() {
      var _this = this;

      var _this$ownerParams = this.ownerParams(),
          ownerType = _this$ownerParams.ownerType,
          ownerId = _this$ownerParams.ownerId,
          ownerRoute = _this$ownerParams.ownerRoute;

      var params = this.paramsFor('client-features.statistics');
      params = Object.keys(params).reduce(function (acc, key) {
        if (key === 'startDate') {
          acc['date[start]'] = params[key];
        } else if (key === 'endDate') {
          acc['date[end]'] = params[key];
        } else if (key === 'deviceTypes' || key === 'clientTypes' || key === 'include') {
          acc[Ember.String.underscore(key)] = _this.serializeArray(params[key]);
        } else {
          acc[Ember.String.underscore(key)] = params[key];
        }

        return acc;
      }, {});
      params.owner_id = ownerId;
      params.owner_type = Ember.String.underscore(ownerType);
      return params;
    },
    serializeArray: function serializeArray(value) {
      if (!Ember.isEmpty(value)) {
        return value.mapBy('id').join(',');
      }

      return null;
    }
  });

  _exports.default = _default;
});