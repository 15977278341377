define("backend/serializers/hm-task", ["exports", "backend/serializers/application", "moment"], function (_exports, _application, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var date = (0, _moment.default)(json['due_date']);

      if (date.hours() === 0 && date.minutes() === 0) {
        json['due_date'] = date.format('YYYY-MM-DD');
      }

      return json;
    }
  });

  _exports.default = _default;
});