define("backend/components/billing-table-contract-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    refreshModelAction: 'refreshModelAction',
    // contract
    hasSelectedContract: Ember.computed.notEmpty('contract'),
    submitClass: Ember.computed('hasSelectedContract', function () {
      return this.get('hasSelectedContract') ? 'btn-primary' : 'bg-slate-300';
    }),
    cursorClass: Ember.computed('hasSelectedContract', function () {
      return this.get('hasSelectedContract') ? '' : 'cursor--not-allowed';
    }),
    actions: {
      saveContract: function saveContract() {
        var _this = this;

        if (this.get('hasSelectedContract')) {
          var billing = this.get('model');
          var promise = billing.saveContract(this.get('contract'));
          this.set('promise', ObjectPromiseProxy.create({
            promise: promise
          }));
          promise.then(function () {
            _this.sendAction('refreshModelAction');
          });
        }
      }
    }
  });

  _exports.default = _default;
});