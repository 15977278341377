define("backend/pods/leads/filter-active/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3CqyWD9Y",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\",\"additional\"],[\"Active Leads\",[24,[\"tName\"]]]]],false],[0,\"\\n\"],[7,\"a\",true],[10,\"id\",\"csv-export-link\"],[11,\"href\",[29,[[22,\"href\"]]]],[11,\"download\",[29,[[22,\"filename\"]]]],[10,\"style\",\"opacity: 0;\"],[8],[9],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[0,\"  \"],[1,[22,\"hm-models/create/leads\"],false],[0,\"\\n  \"],[1,[28,\"hm-models/list/filter-leads\",null,[[\"model\"],[[23,0,[]]]]],false],[0,\"\\n  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"data\",\"isLoading\",\"rowComponent\",\"acts\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"lead\",[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],[[\"filterBy\"],[\"status.isActive\"]]],[28,\"hash\",null,[[\"exportTable\"],[[28,\"route-action\",[\"exportCSV\"],null]]]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/leads/filter-active/template.hbs"
    }
  });

  _exports.default = _default;
});