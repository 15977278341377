define("backend/pods/sales-cockpit/monthly-activities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vt78otQI",
    "block": "{\"symbols\":[\"row\",\"count\",\"day\"],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Monthly Activities\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel overflow-x-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"text-semibold\"],[8],[0,\"\\n        Activities of \"],[1,[22,\"currentMonth\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"table table-hover text-nowrap\"],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[10,\"style\",\"width: 150px;\"],[8],[0,\" \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"days\"]]],null,{\"statements\":[[0,\"            \"],[7,\"th\",true],[8],[1,[23,3,[]],false],[0,\".\"],[1,[22,\"currentMonthShort\"],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"distributors\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"],[4,\"each\",[[23,1,[\"days\"]]],null,{\"statements\":[[0,\"              \"],[7,\"td\",true],[8],[4,\"if\",[[28,\"gt\",[[23,2,[]],0],null]],null,{\"statements\":[[1,[23,2,[]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/sales-cockpit/monthly-activities/template.hbs"
    }
  });

  _exports.default = _default;
});