define("backend/pods/attachments/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    queryParams: {
      type: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var store = this.store;
      var qp = this.curPage(params);

      if (params.type) {
        qp['type'] = params.type;
      }

      return this.queryModel({
        attachments: store.query('attachment', qp)
      });
    }
  });

  _exports.default = _default;
});