define("backend/pods/licensor/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JnfIBc/k",
    "block": "{\"symbols\":[],\"statements\":[[4,\"hm-model/form/essentials\",null,[[\"selected\",\"ownerType\"],[[24,[\"model\"]],\"licensor\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/company\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/movie-device-statistics\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/contacts\",null,[[\"model\"],[[24,[\"model\",\"contacts\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/quick-add/event\",null,[[\"model\",\"modelName\"],[[24,[\"model\"]],\"licensor\"]]],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/list-add/events\",null,[[\"model\"],[[24,[\"model\",\"events\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/licensor/index/template.hbs"
    }
  });

  _exports.default = _default;
});