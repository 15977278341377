define("backend/templates/components/create-material-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nr5vU3lt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[15,\"partials/show-error\",[]],[0,\"\\n\\n      \"],[7,\"form\",true],[10,\"class\",\"form-horizontal js-edit-entry-form\"],[8],[0,\"\\n        \"],[15,[24,[\"templateEdit\"]],[]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/create-material-order.hbs"
    }
  });

  _exports.default = _default;
});