define("backend/pods/sales-cockpit/activities/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      resource: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var query = this.curPage(params);

      if (params) {
        if (params.user) {
          query.user = params.user;
        }

        if (params.startDate && params.startDate !== 'null') {
          query['date[start]'] = params.startDate;
        }

        if (params.endDate && params.endDate !== 'null') {
          query['date[end]'] = params.endDate;
        }

        if (params.resource) {
          query.resource = params.resource;
        }
      }

      return this.get('store').query('event', query);
    }
  });

  _exports.default = _default;
});