define("backend/utils/sales/sales-overview", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var EVENT_CALL = '11';
  var EVENT_EMAIL = '12';

  function isBetween(list, attribute, attrDateBefore, attrDateAfter) {
    return Ember.computed("".concat(list, ".length"), attrDateBefore, attrDateAfter, function () {
      var dateBefore = this.get(attrDateBefore);
      var dateAfter = this.get(attrDateAfter);
      return this.get(list).filter(function (item) {
        var date = (0, _moment.default)(item.get(attribute));
        return date.isValid() && !(date.isBefore(dateBefore) || date.isAfter(dateAfter));
      });
    });
  }

  function filterStatus(collection, filterBy) {
    return Ember.computed("".concat(collection, ".length"), function () {
      return this.get(collection).filter(function (item) {
        return !!item.get('departments').findBy("status.".concat(filterBy));
      });
    });
  }

  var DistributorLeadOverview = Ember.Object.extend({
    distributor: null,
    overview: null,
    leads: Ember.computed(function () {
      return [];
    }),
    users: Ember.computed(function () {
      return [];
    }),
    // Events filtered by date
    events: Ember.computed('users.length', 'overview.from', 'overview.to', function () {
      var _this = this;

      var users = this.get('users').mapBy('id');

      if (Ember.isEmpty(users)) {
        console.log('No user found for ', this.get('distributor.displayName'));
        return null;
      }

      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(users.map(function (user) {
          return _this.get('overview.store').query('event', {
            user: user,
            'date[start]': _this.get('overview.from'),
            'date[end]': _this.get('overview.to'),
            page: {
              number: 1,
              size: 10000
            }
          });
        })).then(function (usersEvents) {
          return usersEvents.reduce(function (allEvents, userEvents) {
            allEvents.pushObjects(userEvents.toArray());
            return allEvents;
          }, []);
        })
      });
    }),
    // Event Type
    callEvents: Ember.computed.filterBy('events', 'type.id', EVENT_CALL),
    emailEvents: Ember.computed.filterBy('events', 'type.id', EVENT_EMAIL),
    totalCallEvents: Ember.computed('events.length', 'callEvents.length', function () {
      if (!Ember.isNone(this.get('events'))) {
        return this.get('callEvents.length');
      }

      return null;
    }),
    totalEMailEvents: Ember.computed('events.length', 'emailEvents.length', function () {
      if (!Ember.isNone(this.get('events'))) {
        return this.get('emailEvents.length');
      }

      return null;
    }),
    totalEvents: Ember.computed.reads('events.length'),
    // Filter Lead Type
    inboundLeads: Ember.computed.filterBy('leads', 'leadType.isInbound'),
    outboundLeads: Ember.computed.filterBy('leads', 'leadType.isOutbound'),
    referralLeads: Ember.computed.filterBy('leads', 'leadType.isReferral'),
    exhibitionLeads: Ember.computed.filterBy('leads', 'leadType.isExhibition'),
    // Filter CreatedAt
    openLeadsBetween: isBetween('openLeads', 'meta.latest_status_change', 'overview.from', 'overview.to'),
    contactedLeadsBetween: isBetween('contactedLeads', 'meta.latest_status_change', 'overview.from', 'overview.to'),
    testingLeadsBetween: isBetween('testingLeads', 'meta.latest_status_change', 'overview.from', 'overview.to'),
    preorderedLeadsBetween: isBetween('preorderedLeads', 'meta.latest_status_change', 'overview.from', 'overview.to'),
    lostLeadsBetween: isBetween('lostLeads', 'meta.latest_status_change', 'overview.from', 'overview.to'),
    // Filter Lead Status
    openLeads: filterStatus('leads', 'isOpen'),
    contactedLeads: filterStatus('leads', 'isContacted'),
    testingLeads: filterStatus('leads', 'isTesting'),
    preorderedLeads: filterStatus('leads', 'isWon'),
    lostLeads: filterStatus('leads', 'isLost'),
    // Filter CreatedAt
    inboundLeadsBetween: isBetween('inboundLeads', 'meta.created', 'overview.from', 'overview.to'),
    outboundLeadsBetween: isBetween('outboundLeads', 'meta.created', 'overview.from', 'overview.to'),
    referralLeadsBetween: isBetween('referralLeads', 'meta.created', 'overview.from', 'overview.to'),
    exhibitionLeadsBetween: isBetween('exhibitionLeads', 'meta.created', 'overview.from', 'overview.to'),
    // Count New By Type
    inboundNew: Ember.computed.reads('inboundLeadsBetween.length'),
    outboundNew: Ember.computed.reads('outboundLeadsBetween.length'),
    referralNew: Ember.computed.reads('referralLeadsBetween.length'),
    exhibitionNew: Ember.computed.reads('exhibitionLeadsBetween.length'),
    // Count Total By Status
    openTotal: Ember.computed.reads('openLeads.length'),
    contactedTotal: Ember.computed.reads('contactedLeads.length'),
    testingTotal: Ember.computed.reads('testingLeads.length'),
    preorderedTotal: Ember.computed.reads('preorderedLeads.length'),
    lostTotal: Ember.computed.reads('lostLeads.length'),
    // Count New By Status
    openNew: Ember.computed.reads('openLeadsBetween.length'),
    contactedNew: Ember.computed.reads('contactedLeadsBetween.length'),
    testingNew: Ember.computed.reads('testingLeadsBetween.length'),
    preorderedNew: Ember.computed.reads('preorderedLeadsBetween.length'),
    lostNew: Ember.computed.reads('lostLeadsBetween.length')
  });
  var SalesOverview = Ember.Object.extend({
    store: null,
    distributorsLeads: Ember.computed(function () {
      return [];
    }),
    from: null,
    to: null,
    distributors: Ember.computed.mapBy('distributorsLeads', 'distributor.displayName'),
    distributorsInbound: Ember.computed.mapBy('distributorsLeads', 'inboundNew'),
    distributorsOutbound: Ember.computed.mapBy('distributorsLeads', 'outboundNew'),
    distributorsReferral: Ember.computed.mapBy('distributorsLeads', 'referralNew'),
    distributorsExhibition: Ember.computed.mapBy('distributorsLeads', 'exhibitionNew'),
    distributorsOpen: Ember.computed('distributorsLeads', 'from', 'to', function () {
      return this.get('distributorsLeads').map(function (distributorLeads) {
        return {
          total: distributorLeads.get('openTotal'),
          new: distributorLeads.get('openNew')
        };
      });
    }),
    distributorsContacted: Ember.computed('distributorsLeads', 'from', 'to', function () {
      return this.get('distributorsLeads').map(function (distributorLeads) {
        return {
          total: distributorLeads.get('contactedTotal'),
          new: distributorLeads.get('contactedNew')
        };
      });
    }),
    distributorsTesting: Ember.computed('distributorsLeads', 'from', 'to', function () {
      return this.get('distributorsLeads').map(function (distributorLeads) {
        return {
          total: distributorLeads.get('testingTotal'),
          new: distributorLeads.get('testingNew')
        };
      });
    }),
    distributorsPreordered: Ember.computed('distributorsLeads', 'from', 'to', function () {
      return this.get('distributorsLeads').map(function (distributorLeads) {
        return {
          total: distributorLeads.get('preorderedTotal'),
          new: distributorLeads.get('preorderedNew')
        };
      });
    }),
    distributorsLost: Ember.computed('distributorsLeads', 'from', 'to', function () {
      return this.get('distributorsLeads').map(function (distributorLeads) {
        return {
          total: distributorLeads.get('lostTotal'),
          new: distributorLeads.get('lostNew')
        };
      });
    }),
    distributorsCalls: Ember.computed.mapBy('distributorsLeads', 'totalCallEvents'),
    distributorsEMails: Ember.computed.mapBy('distributorsLeads', 'totalEMailEvents'),
    distributorsTotalEvents: Ember.computed.mapBy('distributorsLeads', 'totalEvents'),
    filterByFrom: function filterByFrom(from) {
      this.set('from', from.format('YYYY-MM-DD'));
    },
    filterByTo: function filterByTo(to) {
      this.set('to', to.format('YYYY-MM-DD'));
    }
  });

  function _default(store, _ref) {
    var leads = _ref.leads,
        distributors = _ref.distributors,
        clients = _ref.clients,
        users = _ref.users;
    var overview = SalesOverview.create({
      store: store
    });
    var distributorsLeads = distributors.map(function (distributor) {
      var distrUsers = users.filterBy('distributor.id', distributor.get('id'));
      return DistributorLeadOverview.create({
        overview: overview,
        distributor: distributor,
        leads: leads.filterBy('distributor.id', distributor.get('id')),
        clients: clients.filterBy('distributor.id', distributor.get('id')),
        users: distrUsers
      });
    }).sortBy('leads.length').reverse();
    overview.set('distributorsLeads', distributorsLeads);
    return overview;
  }
});