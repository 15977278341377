define("backend/utils/static-data/owner-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'client',
    display_name: 'Client'
  }, {
    id: 'distributor',
    display_name: 'Distributor'
  }, {
    id: 'lead',
    display_name: 'Lead'
  }, {
    id: 'licensor',
    display_name: 'Licensor'
  }, {
    id: 'tester',
    display_name: 'Tester'
  }, {
    id: 'inStore',
    display_name: 'Store'
  }, {
    id: 'sponsoredClient',
    display_name: 'Sponsored Client'
  }];
  _exports.default = _default;
});