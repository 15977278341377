define("backend/prototypes/tables/headers/billings-all", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Details'
    },
    columns: [{
      attr: 'id',
      display: false
    }, {
      attr: 'number',
      style: 'text-right text-nowrap'
    }, {
      attr: 'status.displayName',
      name: 'Status'
    }, {
      attr: 'language',
      display: false
    }, {
      attr: 'zusatz',
      display: false
    }, {
      attr: 'reverseCharge',
      name: 'Reverse Charge',
      display: false
    }]
  }, {
    group: {
      name: 'Client/Lead'
    },
    columns: [{
      attr: 'owner',
      name: 'Name',
      component: 'link-owner2'
    }, {
      attr: 'ownerMeta.vat',
      name: 'VAT',
      display: false
    }, {
      attr: 'ownerMeta.country',
      name: 'Country',
      display: false
    }]
  }, {
    group: {
      name: 'Period'
    },
    columns: [{
      attr: 'periodStart',
      name: 'Period Start',
      format: 'date',
      display: false
    }, {
      attr: 'periodEnd',
      name: 'Period End',
      format: 'date',
      display: false
    }]
  }, {
    group: {
      name: 'Payment'
    },
    columns: [{
      attr: 'paymentTarget',
      name: 'Payment Target',
      style: 'text-center text-nowrap'
    }, {
      attr: 'paymentMethod',
      name: 'Payment Method',
      display: false
    }]
  }, {
    group: {
      name: 'Amount'
    },
    columns: [{
      attr: 'totalAmountGross',
      format: 'currency',
      name: 'Total Gross',
      style: 'text-right text-nowrap'
    }, {
      attr: 'totalStornoGross',
      format: 'currency',
      name: 'Storno Gross',
      style: 'text-right text-nowrap',
      display: false
    }, {
      attr: 'totalAmountNet',
      format: 'currency',
      name: 'Total Net',
      style: 'text-right text-nowrap',
      display: false
    }, {
      attr: 'totalStornoNet',
      format: 'currency',
      name: 'Storno Net',
      style: 'text-right text-nowrap',
      display: false
    }, {
      attr: 'discount',
      format: 'currency',
      name: 'Discount',
      style: 'text-right text-nowrap',
      display: false
    }, {
      attr: 'tax',
      display: false,
      style: 'text-right'
    }]
  }, {
    group: {
      name: 'Dates'
    },
    columns: [{
      attr: 'date',
      style: 'text-center text-nowrap'
    }, {
      attr: 'dateOfShipment',
      name: 'Shipment Date',
      format: 'date'
    }, {
      attr: 'dateOfPayment',
      name: 'Payment Date',
      format: 'date'
    }, {
      attr: 'dateOfCancellation',
      name: 'Cancel Date',
      format: 'date',
      display: false
    }]
  }, {
    group: {
      name: 'Files'
    },
    columns: [{
      attr: 'file',
      name: 'PDF',
      component: 'secure-download',
      style: 'text-nowrap'
    }, {
      attr: 'cancellation',
      name: 'PDF (Cancellation)',
      component: 'secure-download',
      style: 'text-nowrap'
    }]
  }]);

  _exports.default = _default;
});