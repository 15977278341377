define("backend/pods/logs/server/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3hDVEuX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Server Log\"]]],false],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[4,\"panel-body\",null,null,{\"statements\":[[0,\"    \"],[1,[28,\"hm-form/control2\",null,[[\"ctrl\",\"opts\",\"model\",\"field\",\"data\",\"placeholder\"],[\"hm-form/select-value\",\"fullsize\",[23,0,[]],\"type\",[24,[\"types\"]],\"Select Type\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/logs/server/template.hbs"
    }
  });

  _exports.default = _default;
});