define("backend/pods/statistics/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tFPV/hlC",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Statistic Summary\"]]],false],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[4,\"panel-body\",null,[[\"minHeight\"],[true]],{\"statements\":[[0,\"    \"],[1,[28,\"hm-models/list/filter-statistic-summary\",null,[[\"model\"],[[23,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/statistics/summary/template.hbs"
    }
  });

  _exports.default = _default;
});