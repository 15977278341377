define("backend/serializers/serverlog", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass) {
      var json = this._super.apply(this, arguments);

      json.id = typeClass.getId();
      return json;
    }
  });

  _exports.default = _default;
});