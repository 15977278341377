define("backend/pods/studies/filter-active/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFgziBOL",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\",\"additional\"],[\"Active Studies\",[24,[\"tName\"]]]]],false],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"clickRoute\",\"title\",\"headerComponent\",\"data\",\"isLoading\",\"showFooter\",\"showPagination\",\"tableColumns\",\"defaultClientSort\"],[\"study\",\"Active Studies\",[28,\"component\",[\"hm-models/list/header-contracts\"],null],[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],false,false,[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/studies/filter-active/template.hbs"
    }
  });

  _exports.default = _default;
});