define("backend/pods/investor/contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dsNKkHy5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"hm-model/form/contacts\",null,[[\"selected\",\"ownerType\"],[[24,[\"model\"]],\"client\"]],{\"statements\":[[0,\"  \"],[1,[28,\"hm-model/fieldset-contacts/contacts\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/investor/contacts/template.hbs"
    }
  });

  _exports.default = _default;
});