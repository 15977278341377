define("backend/pods/components/hm-model/form/departments/component", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend({
    router: Ember.inject.service(),
    ownerType: null,
    // lead, client, distributor etc.
    keepSelected: true,
    forceReloadSelected: true,
    afterCancelAction: 'listOwners',
    afterDeleteAction: 'listOwners',
    init: function init() {
      this._super.apply(this, arguments);

      this.edit(this.get('selected'));
    },
    notifyCancel: function notifyCancel() {
      this._super.apply(this, arguments);

      this.sendAction('afterCancelAction', this.get('ownerType'));
    },
    notifySaveComplete: function notifySaveComplete(model) {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('ownerType') === 'lead' && model.get('departments').isAny('status.isWon')) {
        this.get('router').transitionTo('client.index', model.get('id'));
      } else {
        var selected = this.get('selected');
        selected.reload().then(function (newSelected) {
          return _this.edit(newSelected);
        });

        this._reloadEvents(model);
      }
    },
    _reloadEvents: function _reloadEvents(model) {
      model.get('events').reload();
    }
  });

  _exports.default = _default;
});