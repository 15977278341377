define("backend/pods/billings/all/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b+x8G9+K",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"All Billings\"]]],false],[0,\"\\n\"],[7,\"a\",true],[10,\"id\",\"csv-export-link\"],[11,\"href\",[29,[[22,\"href\"]]]],[11,\"download\",[29,[[22,\"filename\"]]]],[10,\"style\",\"opacity: 0;\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"headerComponent\",\"data\",\"isLoading\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[[28,\"component\",[\"hm-models/list/header-contracts\"],null],[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/billings/all/template.hbs"
    }
  });

  _exports.default = _default;
});