define("backend/pods/components/hm-chart/movie-expiration/component", ["exports", "d3-time-format", "d3-collection", "d3-zoom", "d3-selection", "d3-array", "d3-scale", "d3-axis", "moment"], function (_exports, _d3TimeFormat, _d3Collection, _d3Zoom, _d3Selection, _d3Array, _d3Scale, _d3Axis, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    height: 300,
    margin: {
      top: 30,
      right: 45,
      bottom: 55,
      left: 55
    },
    classNames: ['movie-expiration-chart__wrapper'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.paint();
    },
    paint: function paint() {
      var _this = this;

      var wrapper = document.querySelector('.movie-expiration-chart');
      var width = wrapper.clientWidth;
      var data = this.get('data');
      var minX = (0, _d3Array.min)(data.map(function (d) {
        return (0, _d3TimeFormat.isoParse)(d.x1);
      }));
      var maxX = (0, _d3Array.max)(data.map(function (d) {
        return (0, _d3TimeFormat.isoParse)(d.x2);
      }));
      var x = (0, _d3Scale.scaleTime)().domain([minX, maxX]).rangeRound([0, width]);
      var symbols = (0, _d3Collection.nest)().key(function (d) {
        return d.ID + d.title;
      }).entries(data);

      var spanX = function spanX(d) {
        return x((0, _d3TimeFormat.isoParse)(d.x1));
      };

      var spanW = function spanW(d) {
        return x((0, _d3TimeFormat.isoParse)(d.x2)) - x((0, _d3TimeFormat.isoParse)(d.x1));
      }; // const tooltip = select(wrapper)
      //   .append('div')
      //     .attr('class', 'tooltip');


      var lastCategory,
          lastPackage = null;
      var grid = (0, _d3Selection.select)(wrapper).append('svg').attr('class', 'movie-expiration-chart__grid-lines').attr('width', width).attr('height', wrapper.getBoundingClientRect().bottom);
      var allCharts = (0, _d3Selection.select)(wrapper).selectAll('svg.movie-expiration-chart__svg').data(symbols).enter().append('svg').attr('height', 14).attr('class', 'movie-expiration-chart__svg').each(function (symbol) {
        var svg = (0, _d3Selection.select)(this);
        var curCategory = symbol.values[0].category_id;
        var curPackage = symbol.values[0].package_id;
        var marginTop = 0;

        if (lastPackage !== curPackage) {
          marginTop += 10;
          svg.append('text').attr('x', 0).attr('y', marginTop).attr('class', 'movie-expiration-chart__package').text(symbol.values[0].package);
          svg.attr('height', 45);
        }

        if (lastCategory !== curCategory) {
          marginTop += 10;
          svg.append('text').attr('x', 20).attr('y', marginTop + 5).attr('class', 'movie-expiration-chart__category').text(symbol.values[0].category);

          if (lastPackage === curPackage) {
            svg.attr('height', 35);
          }
        }

        if (marginTop > 0) {
          svg.attr('style', "margin-top: ".concat(marginTop, "px"));
        }

        var group = svg.selectAll('g').data(symbol.values).enter().append('g');

        if (lastCategory !== curCategory && lastPackage !== curPackage) {
          group.attr('transform', "translate(0, 30)");
        } else if (lastCategory !== curCategory) {
          group.attr('transform', "translate(0, 20)");
        }

        group.append('rect').attr('x', function (d) {
          return spanX(d);
        }).attr('y', 0).attr('width', function (d) {
          return spanW(d);
        }).attr('height', 12).attr('class', 'movie-expiration-chart__rect');
        group.append('text').attr('x', 40).attr('y', 10).attr('class', 'movie-expiration-chart__movie').text(function (d) {
          return "".concat(d.title, " (").concat(d.ID, ")");
        });
        group.append('text').attr('x', function (d) {
          return spanX(d) + spanW(d) + 10;
        }).attr('y', 10).attr('class', 'movie-expiration-chart__rect-text').text(function (d) {
          return "".concat((0, _moment.default)(d.x2).format('DD.MM.YYYY'));
        });
        lastCategory = curCategory;
        lastPackage = curPackage;
      });
      var xAxis = (0, _d3Axis.axisBottom)(x).ticks(width / 100);
      var globalX = (0, _d3Selection.select)(wrapper).append('svg').attr('class', 'axis').call(xAxis);
      var catchAllSvg = (0, _d3Selection.select)(this.element).append('svg').attr('class', 'zoom');
      var catchAll = catchAllSvg.append('rect').attr('fill', 'none').attr('width', width).attr('height', wrapper.getBoundingClientRect().bottom);
      this.paintMonthlyLines(grid, xAxis);
      catchAll.call((0, _d3Zoom.zoom)().scaleExtent([0.1, 10]).on('zoom', function () {
        var transform = _d3Selection.event.transform;
        var scale = transform.rescaleX(x);
        globalX.call(xAxis.scale(scale));
        allCharts.selectAll('rect').attr('x', function (d) {
          return transform.applyX(spanX(d));
        }).attr('width', function (d) {
          return transform.k * spanW(d);
        });
        allCharts.selectAll('text.movie-expiration-chart__rect-text').attr('x', function (d) {
          return transform.applyX(spanX(d)) + transform.k * spanW(d) + 10;
        });

        _this.paintMonthlyLines(grid, xAxis);
      }));
    },
    paintMonthlyLines: function paintMonthlyLines(group, xAxis) {
      group.selectAll('line').remove();
      var scale = xAxis.scale();
      scale.ticks().forEach(function (date) {
        group.append('line').attr('class', 'movie-expiration-chart__hover__line').attr("x1", scale(date)).attr("x2", scale(date)).attr("y1", 50).attr("y2", 'calc(100% - 38px)');
      });
    }
  });

  _exports.default = _default;
});