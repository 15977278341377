define("backend/access-control-list/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCurrentUserOwner = isCurrentUserOwner;
  _exports.isCurrentUserRole = isCurrentUserRole;
  var ATTR_IS_ADMIN = 'currentUser.isAdmin';
  var ATTR_CURRENT_USER = 'currentUser.model.id';

  function isCurrentUserOwner(_ref) {
    var match = _ref.match,
        when = _ref.when;
    var whenAttr = "currentUser.".concat(when);
    return Ember.computed(ATTR_IS_ADMIN, ATTR_CURRENT_USER, whenAttr, match, function () {
      if (this.get(ATTR_IS_ADMIN)) {
        return true;
      }

      if (this.get(whenAttr)) {
        return this.get(match) === this.get(ATTR_CURRENT_USER);
      }
    });
  }

  function isCurrentUserRole(when) {
    var whenAttr = "currentUser.".concat(when);
    return Ember.computed(ATTR_IS_ADMIN, ATTR_CURRENT_USER, whenAttr, function () {
      if (this.get(ATTR_IS_ADMIN)) {
        return true;
      }

      return this.get(whenAttr);
    });
  }
});