define("backend/pods/components/hm-models/list/row-w-departments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NFWPGctf",
    "block": "{\"symbols\":[\"row\",\"descendant\"],\"statements\":[[4,\"render-tbody-batch\",null,[[\"rows\"],[[24,[\"rowsGroupByClientGroup\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"hm-models/list/row-w-departments/row\",null,[[\"row\",\"filterBy\",\"clickRow\"],[[23,1,[]],[24,[\"filterBy\"]],[28,\"action\",[[23,0,[]],\"clickRow\",[23,1,[\"selected\"]],null],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[\"selected\",\"uiToggleDeps\"]],[23,1,[\"selected\",\"isClientGroup\"]]],null]],null,{\"statements\":[[4,\"each\",[[28,\"filter-by\",[\"selected.clientGroup.id\",[23,1,[\"selected\",\"id\"]],[24,[\"rows\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"hm-models/list/row-w-departments/row\",null,[[\"row\",\"filterBy\",\"clickRow\",\"tdClass\"],[[23,2,[]],[24,[\"filterBy\"]],[28,\"action\",[[23,0,[]],\"clickRow\",[23,2,[\"selected\"]],null],null],\"display-td hide-td-border\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/row-w-departments/template.hbs"
    }
  });

  _exports.default = _default;
});