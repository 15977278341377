define("backend/pods/components/hm-models/list/orders/material-order-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ALLOWED_TYPES = ['3', '12', '13', '18', '19', '20', '21'];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('attachmentTypes', this.get('store').peekAll('attachment-type').filter(function (type) {
        return ALLOWED_TYPES.includes(type.get('id'));
      }));
    },
    actions: {
      expand: function expand(model, evt) {
        model.toggleProperty('isExpanded');
      },
      reload: function reload(model) {
        model.reload();
      }
    }
  });

  _exports.default = _default;
});