define("backend/utils/ajax-file", ["exports", "backend/utils/array-buffer-to-string"], function (_exports, _arrayBufferToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = Object.keys;

  function supportsFileAPI() {
    var URL = window.URL || window.webkitURL;
    return typeof URL.createObjectURL === 'function';
  }

  function ajaxFile(url, headers, mimeType) {
    return new Ember.RSVP.Promise(function (resolve) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = supportsFileAPI() ? 'blob' : 'arraybuffer';
      keys(headers).forEach(function (headerName) {
        request.setRequestHeader(headerName, headers[headerName]);
      });

      request.onload = function () {
        if (supportsFileAPI()) {
          var blob = this.response;
          resolve((window.URL || window.webkitURL).createObjectURL(blob));
        } else {
          var raw = (0, _arrayBufferToString.default)(this.response);
          resolve("data:".concat(mimeType, ";base64,").concat(btoa(raw)));
        }
      };

      request.send();
    });
  }

  var _default = ajaxFile;
  _exports.default = _default;
});