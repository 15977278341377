define("backend/adapters/sponsored-client", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      return this._buildURL(modelName, null, {
        addUserPrefix: !query.show_all,
        query: query,
        queryUrlParts: ['distributor']
      });
    },
    urlForCreateRecord: function urlForCreateRecord(modelName) {
      return this._buildURL(modelName, null, {
        addUserPrefix: true
      });
    }
  });

  _exports.default = _default;
});