define("backend/adapters/user", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    saveUserSettings: function saveUserSettings(user) {
      var url = this.urlForSaveUserSettings(user.get('id'));
      return this.ajax(url, 'PUT', {
        data: {
          data: {
            interface: user.get('interface')
          }
        }
      });
    },
    urlForSaveUserSettings: function urlForSaveUserSettings(userId) {
      var url = [];
      var prefix = this.urlPrefix();
      url.push('users');
      url.push(userId);
      url.push('interface');

      if (prefix) {
        url.unshift(prefix);
      }

      return url.join('/');
    }
  });

  _exports.default = _default;
});