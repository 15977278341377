define("backend/pods/licensors/filter-open/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A49F3sID",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Open Licensors\"]]],false],[0,\"\\n\\n\"],[4,\"panel-container\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"hm-models/create\",null,[[\"route\",\"label\"],[\"licensors.create\",\"Create Licensor\"]]],false],[0,\"\\n  \"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"clickRoute\",\"data\",\"isLoading\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"licensor\",\"licensor\",[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/licensors/filter-open/template.hbs"
    }
  });

  _exports.default = _default;
});