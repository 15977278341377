define("backend/utils/static-data/user-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Admin'
  }, {
    id: 2,
    name: 'Editor'
  }, {
    id: 3,
    name: 'Client'
  }, {
    id: 4,
    name: 'Licensor'
  }, {
    id: 5,
    name: 'Distributor'
  }, {
    id: 7,
    name: 'Tester'
  }, {
    id: 8,
    name: 'Service'
  }];
  _exports.default = _default;
});