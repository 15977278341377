define("backend/pods/sales/device-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TFG5IKcd",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-header\",[],[[\"@title\"],[\"Device Order\"]]],[0,\"\\n\\n\"],[5,\"create-order\",[],[[\"@data\",\"@templateEdit\"],[[22,\"model\"],\"components/forms/create-order\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/sales/device-order/template.hbs"
    }
  });

  _exports.default = _default;
});