define("backend/access-control-list/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    canCreateDevice: Ember.computed.reads('isAdmin'),
    canViewAllDevices: Ember.computed.reads('isAdmin'),
    canSaveAttachments: Ember.computed.reads('isAdmin'),
    canDestroyAttachments: Ember.computed.reads('isAdmin'),
    canViewStatistics: Ember.computed.or('isAdmin', 'isDistributor')
  });

  _exports.default = _default;
});