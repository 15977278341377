define("backend/modifiers/focus", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function (element) {
    Ember.run.schedule('afterRender', null, function () {
      element.focus();
    });
  });

  _exports.default = _default;
});