define("backend/mixins/route-component/reload-counts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EVT = {
    CREATE: 1
  };

  var _default = Ember.Mixin.create({
    // --------------------------------- HOOKS -----------------------------------
    save: function save() {
      this._prepareCountReload();

      return this._super.apply(this, arguments);
    },
    "delete": function _delete() {
      this._prepareCountReload();

      return this._super.apply(this, arguments);
    },
    notifySaveComplete: function notifySaveComplete() {
      this._reloadCounts(EVT.CREATE);

      return this._super.apply(this, arguments);
    },
    notifyDeleteComplete: function notifyDeleteComplete() {
      this._reloadCounts();

      return this._super.apply(this, arguments);
    },
    // ---------------------------------------------------------------------------
    subjectIsNew: 'selected.isNew',
    subjectReload: 'data.owner',
    forceReloadSelected: false,
    _prepareCountReload: function _prepareCountReload() {
      this.setProperties({
        reloadIsNewEntry: this.get(this.get('subjectIsNew')),
        reloadOwner: this.get(this.get('subjectReload'))
      });
    },
    _reloadCounts: function _reloadCounts(evt) {
      if (this.get('forceReloadSelected')) {
        this._reloadModel('selected');
      } else {
        if (evt === EVT.CREATE) {
          if (!this.get('reloadIsNewEntry')) {
            return false;
          }
        }

        this._reloadModel('reloadOwner');
      }
    },
    _reloadModel: function _reloadModel(attr) {
      var owner = this.get(attr);

      if (owner) {
        if (owner.then) {
          owner.then(function (o) {
            return o.reload();
          });
        } else if (owner.reload) {
          owner.reload();
        }
      }
    }
  });

  _exports.default = _default;
});