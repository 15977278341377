define("backend/components/import-leads", ["exports", "backend/components/route-component"], function (_exports, _routeComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IMPORT_LEAD_TYPES = ['1', '2'];

  var _default = _routeComponent.default.extend({
    store: Ember.inject.service(),
    keywords: Ember.inject.service(),
    existingSources: Ember.computed.mapBy('keywords.sources', 'displayName'),
    sortedSources: Ember.computed('existingSources', 'existingSources.length', function () {
      return this.get('existingSources').sort().map(function (s) {
        return Ember.Object.create({
          id: s,
          displayName: s
        });
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadData();
      this.updateUploadUrl();
    },
    loadData: function loadData() {
      this.set('data', Ember.Object.create({
        leadTypes: this.loadLeadTypes()
      }));
    },
    loadLeadTypes: function loadLeadTypes() {
      return this.get('store').peekAll('leadType').filter(function (leadType) {
        return IMPORT_LEAD_TYPES.includes(leadType.get('id'));
      });
    },
    updateUploadUrl: function updateUploadUrl() {
      var selected = this.get('selected');
      this.createUploadUrl('import').then(function (_ref) {
        var upload_url = _ref.data.upload_url;
        selected.setProperties({
          file: null,
          uploadUrl: upload_url
        });
      });
    },
    actions: {
      createLinkedAction: function createLinkedAction(type, value) {
        if (type === 'source') {
          return this.send('addNewSource', value);
        }
      },
      addNewSource: function addNewSource(name) {
        this.get('store').createRecord('source', {
          name: name
        });
        var lead = this.get('selected');

        if (!Ember.isNone(lead)) {
          // Wait for updating selectbox with new list of sources
          Ember.run.schedule('afterRender', function () {
            return lead.set('source', name);
          });
        }
      },
      deleteLinkedEntry: function deleteLinkedEntry() {
        this.updateUploadUrl();
      },
      cancelEdit: function cancelEdit() {
        this.sendAction('listLeadsAction');
      },
      saveEntry: function saveEntry() {
        var _this = this;

        this.get('selected').save(this.get('store')).then(function () {
          return _this.sendAction('listLeadsAction');
        }).catch(this.showServerError.bind(this));
      }
    }
  });

  _exports.default = _default;
});