define("backend/helpers/formatters/comma-seperated-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _default(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        value = _ref2[0];

    if (!value) {
      return null;
    }

    var li = value.split(',').map(function (v) {
      if (!v) {
        return '&nbsp;';
      }

      return v;
    }).join('</li><li>');
    value = "<ul class=\"no-ul-style\"><li>".concat(li, "</li></ul>");
    return Ember.String.htmlSafe(value);
  }
});