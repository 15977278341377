define("backend/serializers/tester", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMBEDDED_ALWAYS = {
    embedded: 'always'
  };
  var EMBEDDED_NEVER = {
    serialize: false
  };

  var _default = _application.default.extend({
    attrs: {
      address: EMBEDDED_ALWAYS,
      bank: EMBEDDED_ALWAYS,
      contacts: EMBEDDED_NEVER // billings: EMBEDDED_NEVER,
      // distributor: EMBEDDED_IDS,
      // shippingAddress: EMBEDDED_ALWAYS,
      // includedPackages: EMBEDDED_IDS,
      // targetGroup: EMBEDDED_IDS,
      // license: EMBEDDED_ALWAYS,
      // names: EMBEDDED_ALWAYS,
      // countries: EMBEDDED_IDS,
      // address1: 'address_1',
      // address2: 'address_2',
      // address3: 'address_3'

    }
  });

  _exports.default = _default;
});