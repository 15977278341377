define("backend/pods/components/hm-models/list/row-w-departments/row-department/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F22nH+Q8",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[11,\"class\",[29,[\"hide-td \",[28,\"if\",[[23,1,[\"parent\",\"display\"]],[24,[\"tdClass\"]]],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"attr\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"attr\"]],\"nameWithOwner\"],null]],null,{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"row\",\"selected\",\"isClientGroup\"]],[24,[\"row\",\"selected\",\"clientGroup\",\"id\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[10,\"class\",\"inline-block\"],[10,\"style\",\"width: 22px;\"],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"get\",[[24,[\"department\"]],[23,1,[\"attr\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/row-w-departments/row-department/template.hbs"
    }
  });

  _exports.default = _default;
});