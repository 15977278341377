define("backend/pods/sales-cockpit/activities/controller", ["exports", "backend/prototypes/tables/headers/events-all", "moment"], function (_exports, _eventsAll, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'user', 'startDate', 'endDate', 'resource'],
    page: 1,
    size: 100,
    startDate: (0, _moment.default)().subtract(2, 'weeks').format('YYYY-MM-DD'),
    tableColumns: _eventsAll.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    },
    filteredEvents: Ember.computed('model.length', 'selEventTypes.length', function () {
      var model = this.get('model');
      var filters = (this.get('selEventTypes') || []).mapBy('id');

      if (filters.get('length') > 0) {
        return (model || []).filter(function (activity) {
          return filters.includes('' + activity.get('type.id'));
        });
      }

      return model;
    }),
    showAllWhenFiltering: Ember.observer('selEventTypes.length', function () {
      this.set('size', this.get('selEventTypes.length') === 0 ? 100 : 10000);
    })
  });

  _exports.default = _default;
});