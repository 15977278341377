define("backend/utils/static-data/device-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'HappyMed V1',
    key: 'v1'
  }, {
    id: 2,
    name: 'HappyMed V2',
    key: 'v2'
  }, {
    id: 3,
    name: 'HappyMed V3',
    key: 'v3'
  }, {
    id: 100,
    name: 'Easy Info Tablet',
    key: 'easy_info_tablet',
    is_easy_info_tablet: true
  }, {
    id: 101,
    name: 'Easy Movie Tablet',
    key: 'easy_movie_tablet',
    is_easy_movie_tablet: true
  }, {
    id: 102,
    name: 'EasyTv',
    key: 'easy_tv',
    is_easy_tv: true
  }];
  _exports.default = _default;
});