define("backend/pods/components/hm-uploader/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8iAgcHK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"padding-left:20px;\"],[8],[0,\"\\n  \"],[4,\"unless\",[[24,[\"inline\"]]],null,{\"statements\":[[7,\"h4\",true],[8],[7,\"b\",true],[8],[0,\"New Upload\"],[9],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-4\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"uploader\"],[8],[0,\"\\n          \"],[7,\"input\",true],[10,\"class\",\"file-styled\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"selectFile\"],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"filename\"],[10,\"style\",\"user-select: none;\"],[8],[4,\"if\",[[24,[\"filename\"]]],null,{\"statements\":[[1,[22,\"filename\"],false]],\"parameters\":[]},{\"statements\":[[0,\"No file selected\"]],\"parameters\":[]}],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"action btn btn-default\"],[10,\"style\",\"user-select: none;\"],[8],[0,\"Choose File\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-1\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"upload\"]],[8],[0,\"Start\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-uploader/new/template.hbs"
    }
  });

  _exports.default = _default;
});