define("backend/pods/client-features/statistics/controller", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;

  function loadCollection(modelName) {
    return Ember.computed(function () {
      var promise = this.get('store').findByCache(modelName);
      return PromiseArray.create({
        promise: promise
      });
    });
  }

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: [{
      year: {
        scope: 'controller'
      }
    }, {
      month: {
        scope: 'controller'
      }
    }, {
      device: {
        scope: 'controller'
      }
    }, {
      deviceTypes: {
        scope: 'controller'
      }
    }, {
      startDate: {
        scope: 'controller'
      }
    }, {
      endDate: {
        scope: 'controller'
      }
    }],
    // Default Values
    year: "" + new Date().getFullYear(),
    month: ("0" + (new Date().getMonth() + 1)).slice(-2),
    fullStartDate: Ember.computed('startDate', function () {
      var date = this.get('startDate');

      if (date) {
        return new Date(date);
      }

      return null;
    }),
    fullEndDate: Ember.computed('endDate', function () {
      var date = this.get('endDate');

      if (date) {
        return new Date(date);
      }

      return null;
    }),
    last2Years: Ember.computed(function () {
      var year = (0, _moment.default)().year();
      return [Ember.Object.create({
        displayName: "" + year,
        id: "" + year
      }), Ember.Object.create({
        displayName: "" + (year - 1),
        id: "" + (year - 1)
      })];
    }),
    allMonths: Ember.computed(function () {
      return _moment.default.months().map(function (name, idx) {
        return Ember.Object.create({
          displayName: name,
          id: ("0" + (idx + 1)).slice(-2)
        });
      });
    }),
    allDeviceTypes: Ember.computed('ownerDevices.length', function () {
      var ownerDevices = this.get('ownerDevices');
      return PromiseArray.create({
        promise: ownerDevices.then(function (devices) {
          return devices.reduce(function (types, device) {
            var type = device.get('type');

            if (!types.includes(type)) {
              types.pushObject(type);
            }

            return types;
          }, []);
        })
      });
    }),
    selectOptionName: 'content.displayName',
    selectOptionValue: 'content.id',
    setDateValue: function setDateValue(key, datetime) {
      if (datetime) {
        var selectedDay = datetime.getFullYear() + '-' + ('0' + (datetime.getMonth() + 1)).slice(-2) + '-' + ('0' + datetime.getDate()).slice(-2);

        var dayInUtc = _moment.default.utc(selectedDay);

        this.set(key, dayInUtc.format('YYYY-MM-DD'));
      } else {
        this.set(key, null);
      }
    },
    actions: {
      clearOwners: function clearOwners(_, sel) {
        if (sel === 'store') {
          this.set('ownerId', '1');
        } else {
          this.setProperties({
            ownerId: null,
            clientTypes: null
          });
        }
      },
      setStartDate: function setStartDate(datetime) {
        this.setDateValue('startDate', datetime);
      },
      setEndDate: function setEndDate(datetime) {
        this.setDateValue('endDate', datetime);
      }
    }
  });

  _exports.default = _default;
});