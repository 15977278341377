define("backend/components/billings-component", ["exports", "backend/components/route-component", "backend/models/simple/billing-position"], function (_exports, _routeComponent, _billingPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VAT_TEXT_GERMAN = ['Innergemeinschaftliche Lieferung, steuerfrei gemäß Art.6 Abs.1 UStG.', 'Übergang der Steuerschuld auf den Leistungsempfänger (Reverse Charge).'];
  var VAT_TEXT_ENGLISH = ['Intra-EU supply of goods, Article 138 EC VAT Directive, Customer to self-account for VAT.', 'Change of tax debt to recipient of benefits (Reverse Charge).'];
  var VAT_TEXT_FRENCH = ['FR Intra-EU supply of goods, Article 138 EC VAT Directive, Customer to self-account for VAT.', 'FR Change of tax debt to recipient of benefits (Reverse Charge).'];
  var ART_NOS_GERMAN = [{
    id: '01',
    displayName: '01 - HappyMed Videobrille Miete '
  }, {
    id: '02',
    displayName: '02 - HappyMed Videobrille Miete '
  }, {
    id: '09',
    displayName: '09 - Setup Gebühr'
  }, {
    id: '10',
    displayName: '10 - Versicherung'
  }, {
    id: '11',
    displayName: '11 - Hygienische Kopfhörer-Überzieher'
  }, {
    id: '12',
    displayName: '12 - Hygienische Kopfhörer-Überzieher'
  }, {
    id: '13',
    displayName: '13 - Hygienische Kopfhörer-Überzieher'
  }, {
    id: '14',
    displayName: '14 - Hygienische Kopfhörer-Überzieher'
  }, {
    id: '20',
    displayName: '20 - Sprachpaket Deutsch'
  }, {
    id: '21',
    displayName: '21 - Sprachpaket Englisch'
  }, {
    id: '22',
    displayName: '22 - Sprachpaket Französisch'
  }, {
    id: '23',
    displayName: '23 - Sprachpaket Arabisch'
  }, {
    id: '24',
    displayName: '24 - Sprachpaket Türkisch'
  }, {
    id: '25',
    displayName: '25 - Sprachpaket Russisch'
  }, {
    id: '26',
    displayName: '26 - Sprachpaket Italienisch'
  }, {
    id: '27',
    displayName: '27 - Sprachpaket Spanisch'
  }, {
    id: '30',
    displayName: '30 - Versandkostenpauschale'
  }, {
    id: '31',
    displayName: '31 - HappyMed Lagerungskissen '
  }, {
    id: '32',
    displayName: '32 - HappyMed Eyeshield'
  }, {
    id: '33',
    displayName: '33 - HappyMed Batterien'
  }, {
    id: '34',
    displayName: '34 - HappyMed Ladenetzteil'
  }, {
    id: '35',
    displayName: '35 - HappyMed Mikrofasertuch'
  }, {
    id: '36',
    displayName: '36 - HappyMed Broschüren'
  }, {
    id: '37',
    displayName: '37 - HappyMed Training'
  }, {
    id: '40',
    displayName: '40 - Mahnspesen'
  }, {
    id: '41',
    displayName: '41 - Mahnspesen'
  }, {
    id: '42',
    displayName: '42 - Mahnspesen'
  }];
  var ART_NOS_ENGLISH = [{
    id: '01',
    displayName: '01 - HappyMed Video Glasses: rental'
  }, {
    id: '02',
    displayName: '02 - HappyMed Video Glasses: rental'
  }, {
    id: '09',
    displayName: '09 - Setup fee'
  }, {
    id: '10',
    displayName: '10 - Insurance'
  }, {
    id: '11',
    displayName: '11 - Packed Hygienic Headphone-Covers'
  }, {
    id: '12',
    displayName: '12 - Packed Hygienic Headphone-Covers'
  }, {
    id: '13',
    displayName: '13 - Packed Hygienic Headphone-Covers'
  }, {
    id: '14',
    displayName: '14 - Packed Hygienic Headphone-Covers'
  }, {
    id: '20',
    displayName: '20 - Language Package German'
  }, {
    id: '21',
    displayName: '21 - Language Package English'
  }, {
    id: '22',
    displayName: '22 - Language Package French'
  }, {
    id: '23',
    displayName: '23 - Language Package Arabic'
  }, {
    id: '24',
    displayName: '24 - Language Package Turkish'
  }, {
    id: '25',
    displayName: '25 - Language Package Russian'
  }, {
    id: '26',
    displayName: '26 - Language Package Italian'
  }, {
    id: '27',
    displayName: '27 - Language Package Spanish'
  }, {
    id: '30',
    displayName: '30 - Shipment fee'
  }, {
    id: '31',
    displayName: '31 - HappyMed Cushion'
  }, {
    id: '32',
    displayName: '32 - HappyMed Eyeshield'
  }, {
    id: '33',
    displayName: '33 - HappyMed Batteries'
  }, {
    id: '34',
    displayName: '34 - HappyMed Charger'
  }, {
    id: '35',
    displayName: '35 - HappyMed Microfibre cloth'
  }, {
    id: '36',
    displayName: '36 - HappyMed Brochures'
  }, {
    id: '37',
    displayName: '37 - HappyMed Training'
  }, {
    id: '40',
    displayName: '40 - Reminder fees'
  }, {
    id: '41',
    displayName: '41 - Reminder fees'
  }, {
    id: '42',
    displayName: '42 - Reminder fees'
  }];
  var ART_NOS_FRENCH = [{
    id: '01',
    displayName: '01 - Lunettes-Vidéo HappyMed: location'
  }, {
    id: '02',
    displayName: '02 - Lunettes-Vidéo HappyMed: location'
  }, {
    id: '09',
    displayName: '09 - Frais d’installation'
  }, {
    id: '10',
    displayName: '10 - Extension de garantie'
  }, {
    id: '11',
    displayName: '11 - Boite de paire de housses hygiéniques pour écouteurs'
  }, {
    id: '12',
    displayName: '12 - Boite de paire de housses hygiéniques pour écouteurs'
  }, {
    id: '13',
    displayName: '13 - Boite de paire de housses hygiéniques pour écouteurs'
  }, {
    id: '14',
    displayName: '14 - Boite de paire de housses hygiéniques pour écouteurs'
  }, {
    id: '20',
    displayName: '20 - Module de langue – allemand'
  }, {
    id: '21',
    displayName: '21 - Module de langue – anglais'
  }, {
    id: '22',
    displayName: '22 - Module de langue – français'
  }, {
    id: '23',
    displayName: '23 - Module de langue – arabe'
  }, {
    id: '24',
    displayName: '24 - Module de langue – turc'
  }, {
    id: '25',
    displayName: '25 - Module de langue – russe'
  }, {
    id: '26',
    displayName: '26 - Module de langue – italien'
  }, {
    id: '27',
    displayName: '27 - Module de langue – espagnol'
  }, {
    id: '30',
    displayName: '30 - Frais de livraison'
  }, {
    id: '31',
    displayName: '31 - Coussin HappyMed'
  }, {
    id: '32',
    displayName: '32 - Eyeshield HappyMed'
  }, {
    id: '33',
    displayName: '33 - Piles HappyMed'
  }, {
    id: '34',
    displayName: '34 - Chargeur HappyMed'
  }, {
    id: '35',
    displayName: '35 - Chiffons en microfibre HappyMed'
  }, {
    id: '36',
    displayName: '36 - Brochures HappyMed'
  }, {
    id: '37',
    displayName: '37 - Formation HappyMed'
  }, {
    id: '40',
    displayName: '40 - Pénalités de retard'
  }, {
    id: '41',
    displayName: '41 - Pénalités de retard'
  }, {
    id: '42',
    displayName: '42 - Pénalités de retard'
  }];
  var VAT_TEXT = VAT_TEXT_GERMAN.concat(VAT_TEXT_ENGLISH).concat(VAT_TEXT_FRENCH);
  var ART_NOS = ART_NOS_GERMAN.mapBy('id');
  /**
    Works for client.billings and billings route.
   */

  var _default = _routeComponent.default.extend({
    artNos: Ember.computed('selected.buffers.selected.language', function () {
      var language = this.get('selected.buffers.selected.language');

      switch (language) {
        case 'deu':
          return ART_NOS_GERMAN;

        case 'fra':
          return ART_NOS_FRENCH;
      }

      return ART_NOS_ENGLISH;
    }),
    // ------------------------------ HOOKS --------------------------------------
    createModel: 'billing',
    createPath: Ember.computed('data.owner', function () {
      if (this.get('data.owner')) {
        return 'data.owner.billings';
      }

      return null;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var template = this.get('createBilling');

      if (template) {
        this.set('createBilling', false);
        this.send('createEntry', template);
      }
    },
    edit: function edit() {
      return this._super.apply(this, arguments).then(this._createMinimumPosition.bind(this));
    },
    saveComplete: function saveComplete() {
      if (this.get('hasClient')) {
        var client = this.get('data.client');

        if (client) {
          client.reload();
        }
      } else if (this.get('hasLead')) {
        var lead = this.get('data.lead');

        if (lead) {
          lead.reload();
        }
      }

      return this._super.apply(this, arguments);
    },
    createLinkedEntry: function createLinkedEntry() {
      this._addPosition();
    },
    deleteLinkedEntry: function deleteLinkedEntry(position) {
      this._removePosition(position);
    },
    actions: {
      selectItem: function selectItem(value, _, position) {
        if (VAT_TEXT.includes(value)) {
          this._didSelectVATText(value);
        } else if (ART_NOS.includes(value)) {
          this._didSelectArtNo(position, value);
        } else {
          this._didSelectContact(value);
        }
      },
      refreshModelAction: function refreshModelAction() {
        this.sendAction('refreshModelAction');
      }
    },
    // ----------------------------- BILLINGS ------------------------------------
    store: Ember.inject.service(),
    hasClient: Ember.computed.notEmpty('data.client'),
    hasLead: Ember.computed.notEmpty('data.lead'),
    canCreate: Ember.computed.notEmpty('tableColumnsWaiting'),
    // ----------------------------- CONTACTS ------------------------------------
    listOfContacts: Ember.computed('data.client.contacts.length', 'data.lead.contacts.length', function () {
      if (this.get('hasClient')) {
        return (this.get('data.client.contacts') || []).map(function (c) {
          return c.get('displayName');
        });
      } else if (this.get('hasLead')) {
        return (this.get('data.lead.contacts') || []).map(function (c) {
          return c.get('displayName');
        });
      }

      return [];
    }),
    _updateListOfContacts: Ember.observer('selected.buffers.selected.client.id', 'selected.buffers.selected.lead.id', function () {
      var _this = this;

      // Client's contacts have not been loaded yet
      var promise;

      if (this.get('hasClient')) {
        promise = this.get('selected.buffers.selected.client.contacts');
      } else if (this.get('hasLead')) {
        promise = this.get('selected.buffers.selected.lead.contacts');
      }

      if (promise && promise.then) {
        promise.then(function (contacts) {
          _this.set('listOfContacts', contacts.map(function (c) {
            return c.get('displayName');
          }));
        });
      } else {
        this.set('listOfContacts', []);
      }
    }),
    // ----------------------------- VAT TEXT ------------------------------------
    listOfVATText: Ember.computed('selected.buffers.selected.language', function () {
      var language = this.get('selected.buffers.selected.language');

      switch (language) {
        case 'deu':
          return VAT_TEXT_GERMAN;

        case 'fra':
          return VAT_TEXT_FRENCH;
      }

      return VAT_TEXT_ENGLISH;
    }),
    _didSelectVATText: function _didSelectVATText(vatText) {
      var _this2 = this;

      Ember.run.schedule('afterRender', function () {
        var billing = _this2.get('selected.buffers.selected');

        var curVATText = billing.get('text') || '';

        if (VAT_TEXT.includes(curVATText)) {
          billing.set('text', vatText);
        } else {
          billing.set('text', curVATText + vatText);
        }
      });
    },
    // ----------------------------- POSITIONS -----------------------------------
    positions: Ember.computed.alias('selected.buffers.selected.positions'),
    _updateMinimumPosition: Ember.observer('selected.buffers.selected.language', function () {
      var positions = this.get('positions');

      if (positions && positions.get('length') === 1) {
        var language = this.get('selected.buffers.selected.language');

        if (language) {
          positions.get('firstObject').setLanguage(language);
        }
      }
    }),
    _createMinimumPosition: function _createMinimumPosition() {
      var positions = this.get('positions');

      if (positions.get('length') === 0) {
        this.createLinkedEntry();
      }
    },
    _addPosition: function _addPosition() {
      var positions = this.get('positions');

      var position = _billingPosition.default.create({
        posNo: positions.get('length') + 1
      });

      var language = this.get('selected.buffers.selected.language');

      if (language) {
        position.setLanguage(language);
      }

      positions.addObject(position);
    },
    _removePosition: function _removePosition(position) {
      var positions = this.get('positions');

      if (positions.get('length') > 1) {
        positions.removeObject(position);
      } else if (positions.get('length') === 1) {
        var language = this.get('selected.buffers.selected.language');
        positions.objectAt(0).clear(language);
      }
    },
    _didSelectArtNo: function _didSelectArtNo(position, artNo) {
      Ember.run.schedule('afterRender', function () {
        position.set('artNo', artNo);
      });
    },
    _didSelectContact: function _didSelectContact(contact) {
      var _this3 = this;

      Ember.run.schedule('afterRender', function () {
        _this3.set('selected.buffers.selected.contact', contact);
      });
    }
  });

  _exports.default = _default;
});