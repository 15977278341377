define("backend/pods/components/hm-form/select-multiple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    actions: {
      setOption: function setOption(option) {
        var _this$getProperties = this.getProperties(['model', 'field']),
            model = _this$getProperties.model,
            field = _this$getProperties.field;

        if (!model.set && _typeof(model) === 'object') {
          model[field].setObjects(option);
        } else {
          model.set(field, option);
        }

        if (this.get('acts.setAction')) {
          this.get('acts.setAction')(option);
        }
      },
      onOpen: function onOpen() {
        if (this.get('acts.openAction')) {
          this.get('acts.openAction')();
        }
      }
    }
  });

  _exports.default = _default;
});