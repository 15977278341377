define("backend/pods/movie-convert/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      return this.loadAvailFiles();
    },
    loadAvailFiles: function loadAvailFiles() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;

          _this.get('ajax').request(_environment.default.host + "/transcode/list_movies", {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            return resolve(data.map(function (d) {
              return Ember.Object.create({
                displayName: d,
                id: d
              });
            }));
          });
        });
      });
    }
  });

  _exports.default = _default;
});