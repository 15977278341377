define("backend/pods/statistics/billing/table/controller", ["exports", "backend/helpers/fix-decimal", "backend/utils/export-csv", "backend/utils/format-csv", "backend/prototypes/tables/headers/statistics-billings"], function (_exports, _fixDecimal, _exportCsv, _formatCsv, _statisticsBillings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CSV_HEAD = ['Licensor Name', 'Replays', 'Duration', 'Revenue Share', 'Movie Percentage'].join(';');

  var _default = Ember.Controller.extend({
    tableAsCSV: Ember.computed('model', function () {
      return this.get('model').map(function (sel) {
        return [sel.get('licensor.displayName'), sel.get('replays'), sel.get('duration'), (0, _fixDecimal.fixDecimal)([sel.get('revenueShare'), 2]), sel.get('percentage')].map(_formatCsv.default).join(';');
      });
    }),
    tableColumns: _statisticsBillings.default,
    clientSort: {
      sortBy: 'percentage',
      order: 'desc'
    },
    createCSV: function createCSV() {
      return [CSV_HEAD].concat(this.get('tableAsCSV')).join('\n');
    },
    actions: {
      exportAsCSV: function exportAsCSV() {
        (0, _exportCsv.default)(this.createCSV(), 'billings-summary.csv');
      }
    }
  });

  _exports.default = _default;
});