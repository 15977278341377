define("backend/templates/components/inline-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DOplAEkA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"validation\",\"isNotEmpty\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"validation\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"inline-validation text-center\"],[8],[0,\"\\n      \"],[1,[22,\"loading-indicator\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"validation\",\"isValid\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\",true],[10,\"class\",\"inline-validation validation-error-label validation-valid-label\"],[8],[0,\"\\n        Valid (\"],[1,[24,[\"validation\",\"data\",\"name\"]],false],[0,\")\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"label\",true],[10,\"class\",\"inline-validation validation-error-label\"],[8],[0,\"\\n        Not Valid\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/inline-validation.hbs"
    }
  });

  _exports.default = _default;
});