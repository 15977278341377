define("backend/adapters/lead", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      return this._buildURL(modelName, null, {
        addUserPrefix: true,
        query: query
      });
    },
    urlForCreateRecord: function urlForCreateRecord(modelName) {
      return this._buildURL(modelName, null, {
        addUserPrefix: true
      });
    },
    findAllSources: function findAllSources() {
      var url = [];
      var host = this.get('host');
      var prefix = this.urlPrefix();
      url.push(this.pathForType('lead'));

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return this.ajax(url + '/all/sources', 'GET');
    },
    findAllTipsters: function findAllTipsters() {
      var url = [];
      var host = this.get('host');
      var prefix = this.urlPrefix();
      url.push(this.pathForType('lead'));

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return this.ajax(url + '/all/tipsters', 'GET');
    },
    findAllOrganizations: function findAllOrganizations() {
      var url = [];
      var host = this.get('host');
      var prefix = this.urlPrefix();
      url.push(this.pathForType('lead'));

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url) {
        url = '/' + url;
      }

      return this.ajax(url + '/all/organizations', 'GET');
    }
  });

  _exports.default = _default;
});