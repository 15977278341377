define("backend/pods/sales/devices/route", ["exports", "backend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function prepareData(_ref) {
    var distributor = _ref.distributor,
        devices = _ref.devices,
        testing = _ref.testing;
    var usedDevices = [];
    var leadsMultipleTesting = [];
    var chart = testing.reduce(function (acc, _ref2) {
      var id = _ref2.id,
          department_id = _ref2.department_id,
          name = _ref2.name,
          display_name = _ref2.display_name,
          x1 = _ref2.trial_start_date,
          x2 = _ref2.trial_end_date,
          num_devices = _ref2.num_devices,
          device_count = _ref2.device_count,
          device_ids = _ref2.device_ids,
          device_serial_numbers = _ref2.device_serial_numbers;
      var leadDepartments = testing.filterBy('id', id);
      var hasNumDevices = !Ember.isNone(num_devices);
      var categoryTitle = "".concat(display_name, " (").concat(device_count, "/").concat(hasNumDevices ? num_devices : '?', ")");

      if (device_count > 0) {
        if (leadDepartments.length === 1) {
          var _devices = device_serial_numbers.split(',');

          var devices_ids = device_ids.split(',');

          for (var idx = 0; idx < device_count; idx++) {
            usedDevices.push(_devices[idx]);
            acc.push({
              ID: "".concat(department_id).concat(idx),
              x1: x1 ? x1 : null,
              x2: x2 ? x2 : null,
              title: _devices[idx],
              color: '#4caf50',
              category: categoryTitle,
              category_id: "".concat(display_name),
              package: 'Lead Devices (Testing)',
              package_id: 'Lead Devices (Testing)',
              device_id: devices_ids[idx],
              lead_id: id
            });
          }
        } else {
          leadsMultipleTesting.push(id);
        }
      } else {
        acc.push({
          ID: department_id,
          x1: x1 ? x1 : null,
          x2: x2 ? x2 : null,
          title: null,
          color: '#ff5722',
          category: categoryTitle,
          category_id: "".concat(display_name),
          package: 'Lead Devices (Testing)',
          package_id: 'Lead Devices (Testing)',
          device_id: null,
          lead_id: id
        });
      }

      return acc;
    }, []);
    leadsMultipleTesting.forEach(function (id) {
      var allDepartments = testing.filterBy('id', id);
      allDepartments.forEach(function (_ref3) {
        var id = _ref3.id,
            department_id = _ref3.department_id,
            name = _ref3.name,
            display_name = _ref3.display_name,
            x1 = _ref3.trial_start_date,
            x2 = _ref3.trial_end_date,
            num_devices = _ref3.num_devices,
            device_count = _ref3.device_count,
            device_ids = _ref3.device_ids,
            device_serial_numbers = _ref3.device_serial_numbers;
        var hasNumDevices = !Ember.isNone(num_devices);
        var title = "".concat(name, " (").concat(hasNumDevices ? num_devices : '?', ")");
        chart.push({
          ID: department_id,
          x1: x1 ? x1 : null,
          x2: x2 ? x2 : null,
          title: title,
          color: 'lightgrey',
          category: "".concat(display_name),
          category_id: "".concat(display_name),
          package: 'Lead Devices (Testing)',
          package_id: 'Lead Devices (Testing)',
          device_id: null,
          lead_id: id
        });
      });
      var firstDep = allDepartments[0];
      var devices = firstDep.device_serial_numbers.split(',');
      var devices_ids = firstDep.device_ids.split(',');

      for (var idx = 0; idx < firstDep.device_count; idx++) {
        if (!usedDevices.includes(devices[idx])) {
          usedDevices.push(devices[idx]);
          chart.push({
            ID: "".concat(id).concat(idx),
            x1: null,
            x2: null,
            title: "".concat(devices[idx], " (cannot link to department)"),
            color: 'lightgrey',
            category: "".concat(firstDep.display_name),
            category_id: "".concat(firstDep.display_name),
            package: 'Lead Devices (Testing)',
            package_id: 'Lead Devices (Testing)',
            device_id: devices_ids[idx],
            lead_id: id
          });
        }
      }
    });
    devices.forEach(function (_ref4) {
      var client_id = _ref4.client_id,
          device_id = _ref4.device_id,
          serial_number = _ref4.serial_number,
          is_client = _ref4.is_client,
          display_name = _ref4.display_name;

      if (usedDevices.includes(serial_number)) {
        return true;
      }

      if (!client_id) {
        chart.push({
          ID: serial_number,
          x1: null,
          x2: null,
          title: "".concat(serial_number),
          color: 'grey',
          category: distributor,
          category_id: distributor,
          package: 'Unassigned Devices',
          package_id: 'Unassigned Devices',
          device_id: device_id
        });
      } else {// chart.push({
        //   ID: serial_number,
        //   x1: null,
        //   x2: null,
        //   title: `${serial_number}`,
        //   color: 'grey',
        //   category: 'Clients',
        //   category_id: 'Clients',
        //   package: 'Other Devices',
        //   package_id: 'Other Devices'
        // });
      }
    });
    return chart;
  }

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      distributor: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + "/sales/devices_chart" + (params.distributor ? '?distributor=' + params.distributor : '');

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref5) {
            var data = _ref5.data;
            return resolve(prepareData(data));
          });
          /*
            Testing Leads: (filter data.leads[].status = 15)
              - [x1, x2] = [trial_start_date, trial_end_date]
              - title = device_count
              - color = device_count > 0 ? 'green' : 'red'
              Unassigned: (filter data.devices[].client_id = null)
              - title = serial_number
              - color = 'grey'
              Other: (filter data.devices[].id where not in <Unassigned> and <Testing Leads>)
               */

        });
      });
    }
  });

  _exports.default = _default;
});