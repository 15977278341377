define("backend/helpers/format-boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatBoolean = formatBoolean;
  _exports.default = void 0;

  function formatBoolean(args) {
    var value = Ember.isArray(args) ? args[0] : args;

    switch (value) {
      case true:
        return 'Yes';

      case false:
        return 'No';

      default:
        return '';
    }
  }

  var _default = Ember.Helper.helper(formatBoolean);

  _exports.default = _default;
});