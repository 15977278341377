define("backend/pods/components/hm-model/fieldset-essentials/descendants/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'fieldset'
  });

  _exports.default = _default;
});