define("backend/pods/components/hm-models/list/export-csv/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filename: Ember.computed(function () {
      return "export-".concat(this.get('model.id'), "-").concat((0, _moment.default)().format('YYYY-MM-DD'), ".csv");
    })
  });

  _exports.default = _default;
});