define("backend/helpers/format-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDecimal = formatDecimal;
  _exports.default = void 0;

  function formatDecimal(args) {
    var value = args[0];

    if (Intl) {
      return new Intl.NumberFormat('de').format(value);
    }

    return value;
  }

  var _default = Ember.Helper.helper(formatDecimal);

  _exports.default = _default;
});