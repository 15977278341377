define("backend/pods/devices/sales/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      t: {
        refreshModel: true
      },
      distributor: {
        refreshModel: true
      }
    },
    filter: ['device', 'sales:true', ['t:type', 'distributor']],
    createQuery: function createQuery(params) {
      var query = this._super.apply(this, arguments);

      if (params.distributor) {
        query.exclude_territories = true;
        query.include_leads = true;
      }

      return query;
    }
  });

  _exports.default = _default;
});