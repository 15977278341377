define("backend/pods/components/hm-models/list/filter-clients/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pryhTHcK",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"panel-body\",null,null,{\"statements\":[[4,\"hm-form/wrapper\",null,[[\"model\",\"opts\"],[[23,0,[]],\"fullsize\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-sm-3\"],[8],[0,\"\\n        \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"select-value\"]],\"expected `f.select-value` to be a contextual component but found a string. Did you mean `(component f.select-value)`? ('backend/pods/components/hm-models/list/filter-clients/template.hbs' @ L5:C10) \"],null]],[[\"field\",\"data\",\"placeholder\",\"selectValue\",\"acts\"],[\"organization\",[24,[\"organizations\"]],\"Select Organization\",\"displayName\",[28,\"hash\",null,[[\"setAction\",\"openAction\"],[[28,\"action\",[[23,0,[]],\"setQueryParam\",\"organization:displayName\"],null],[28,\"action\",[[23,0,[]],\"loadOrganizations\"],null]]]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/filter-clients/template.hbs"
    }
  });

  _exports.default = _default;
});