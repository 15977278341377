define("backend/mixins/validation-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ['validateShowError'],
    validateShowError: false,
    validationErrors: null,
    showValidationErrors: function showValidationErrors() {
      var _this = this;

      var buffers = this.get('selected.buffers');
      var validationErrors = {};
      Object.keys(buffers).forEach(function (key) {
        validationErrors[key] = _this.gatherBufferErrors(buffers[key]);
      });
      this.set('validationErrors', validationErrors);
    },
    gatherBufferErrors: function gatherBufferErrors(buffer) {
      var _this2 = this;

      if (Ember.isArray(buffer)) {
        var errors = [];
        buffer.forEach(function (manyItem) {
          errors.pushObject(_this2.copyErrors(manyItem));
        });
        return errors;
      } else {
        return this.copyErrors(buffer);
      }
    },
    copyErrors: function copyErrors(buffer) {
      var copy = {};
      var errors = buffer.get('errors');
      Object.keys(errors).forEach(function (prop) {
        return copy[prop] = errors.get(prop);
      });
      return copy;
    },
    validationKeys: function validationKeys(backup) {
      var bufferKeys = Object.keys(backup.buffers);
      var hasManyKeys = Object.keys(backup.hasMany);
      var belongsToKeys = Object.keys(backup.belongsTo);
      return bufferKeys.concat(hasManyKeys).concat(belongsToKeys).uniq().removeObject('selected');
    },
    checkValidation: function checkValidation(entry, dependencies) {
      var valid = false;

      if (dependencies) {
        var validEntry = this.isValid(entry);
        var validDeps = this.isValid(entry, dependencies);
        valid = validEntry && validDeps;
      } else {
        valid = this.isValid(entry);
      }

      if (!valid) {
        this.set('validateShowError', true);
        return false;
      }

      return true;
    },
    isValid: function isValid(entry, attrKeys, attrKey) {
      if (Ember.isArray(attrKeys)) {
        return this.eachIsValid(entry, attrKeys);
      } else if (typeof attrKeys === 'string') {
        var dep;
        entry.constructor.eachRelationship(function (name, descr) {
          if (name === attrKeys && descr.options.useBuffer) {
            dep = entry.get("buffers.".concat(entry.keyToPath(attrKeys)));
          }
        });

        if (!dep) {
          dep = entry.get(entry.keyToPath(attrKeys));
        }

        if (Ember.isArray(dep)) {
          return this.hasManyIsValid(dep);
        } else {
          return this.belongsToIsValid(dep, attrKeys);
        }
      } else {
        if (!entry.validateSync && entry.get('buffers')) {
          return this.isValid(entry.get('buffers.selected'));
        }

        if (entry.validateSync) {
          if (entry.content) {
            entry.set('content.inverseAttr', attrKey);
          }

          entry.validateSync();

          if (entry.content) {
            entry.set('content.didValidate', true);
          } else {
            entry.set('didValidate', true);
          }

          return entry.get('validations.isValid');
        }

        return true;
      }
    },
    eachIsValid: function eachIsValid(entry, keys) {
      var _this3 = this;

      var allValid = true;
      keys.forEach(function (dependency) {
        if (allValid) {
          allValid = _this3.isValid(entry, dependency);
        }
      });
      return allValid;
    },
    hasManyIsValid: function hasManyIsValid(dep) {
      var _this4 = this;

      var allValid = true;
      dep.forEach(function (manyItem) {
        if (allValid) {
          allValid = _this4.isValid(manyItem);
        }
      });
      return allValid;
    },
    belongsToIsValid: function belongsToIsValid(dep, key) {
      if (dep === null) {
        return true;
      } // if (typeof dep.isValid === 'function') {
      //   return dep.isValid(key);
      // }


      if (dep.validateSync) {
        return this.isValid(dep, null, key);
      }

      return Ember.isEmpty(dep.get('content')) || this.isValid(dep);
    }
  });

  _exports.default = _default;
});