define("backend/pods/components/hm-models/list/orders/id-and-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOYdSh7w",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"isExpanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"icon-arrow-up12\"],[10,\"style\",\"color: #aaa; font-size:12px;\"],[8],[9],[0,\"\\n    \"],[1,[24,[\"model\",\"id\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"icon-arrow-down12\"],[10,\"style\",\"color: #aaa; font-size:12px;\"],[8],[9],[0,\"\\n    \"],[1,[24,[\"model\",\"id\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/orders/id-and-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});