define("backend/utils/api/serialization-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EMBEDDED_ALWAYS = void 0;

  /**
   * Used in combination with the `DS.EmbeddedRecordsMixin`
   *
   *   - embedded: 'always' -> means the payload for (a) related resource(s) is included
   *   - embedded: 'ids' -> means only the id/ids for a related source is included
   *
   * Note: Should be replaced with better `json:api` spec and make used of the
   * `included` payload once migrated to `JSONAPISerializer`
   */
  var EMBEDDED_ALWAYS = {
    embedded: "always"
  };
  _exports.EMBEDDED_ALWAYS = EMBEDDED_ALWAYS;
});