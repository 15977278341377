define("backend/templates/components/alert-flashes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "irhINWao",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"alert alert-danger\"],[8],[1,[28,\"format-log\",[[23,1,[\"error\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"alert alert-success\"],[8],[1,[28,\"format-log\",[[23,1,[\"alert\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/alert-flashes.hbs"
    }
  });

  _exports.default = _default;
});