define("backend/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function withThousandsSeparator(n) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  function getFractions(f) {
    return f.toFixed(2).split(".")[1];
  }

  function formatCurrency(args) {
    var value = Ember.isArray(args) ? args[0] : args,
        number;

    if (Ember.isEmpty(value)) {
      return '';
    }

    if (typeof value === 'string') {
      number = parseFloat(value);
    } else {
      number = value;
    }

    var fractions = getFractions(number);
    var integerStr = withThousandsSeparator(Math.trunc(number));
    var padFractions = ("00" + fractions).substr(-2, 2);
    return "".concat(integerStr, ",").concat(padFractions, " \u20AC");
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});