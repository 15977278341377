define("backend/utils/static-data/contract-cancel-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Kündigung'
  }, {
    id: 2,
    name: 'Verlängerung'
  }, {
    id: 3,
    name: 'Aufstockung'
  }, {
    id: 4,
    name: 'Reduktion'
  }];
  _exports.default = _default;
});