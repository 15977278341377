define("backend/pods/mixins/route/paginate-and-filter", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Mixin.create(_queryModelMixin.default, {
    filter: [
      /*'modelName', [ ... additional query params ... ]*/

      /*'modelName', 'filterQueryParameter', [ ... additional query params ... ]*/

      /*'modelName', 'filterQueryName:filterQueryValue'*/

      /*'modelName', 'filterQueryParameter', ['queryAttribute', 'queryAttribute:queryKey']*/
    ],
    createQuery: function createQuery(params, filter) {
      var _this = this;

      var queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var query = this.curPage(params);

      if (typeof filter === 'string') {
        if (filter.includes(':')) {
          var _filter$split = filter.split(':'),
              _filter$split2 = _slicedToArray(_filter$split, 2),
              k = _filter$split2[0],
              v = _filter$split2[1];

          query[k] = v;
        } else {
          query.filter = filter;
        }
      } else if (Ember.isArray(filter) && queryParams.get('length') === 0) {
        queryParams = filter;
      }

      queryParams.forEach(function (keyForQuery) {
        var keyForValue = keyForQuery;

        if (keyForQuery.includes(':')) {
          var _keyForQuery$split = keyForQuery.split(':');

          var _keyForQuery$split2 = _slicedToArray(_keyForQuery$split, 2);

          keyForValue = _keyForQuery$split2[0];
          keyForQuery = _keyForQuery$split2[1];
        }

        if (_this.isValidParam(keyForValue, params[keyForValue])) {
          _this.setQuery(query, keyForQuery, params[keyForValue]);
        }
      });
      return query;
    },
    setQuery: function setQuery(query, key, value) {
      query[key] = value;
    },
    isValidParam: function isValidParam(key, value) {
      return value && value !== 'null';
    },
    model: function model(params) {
      var _this$get = this.get('filter'),
          _this$get2 = _slicedToArray(_this$get, 3),
          modelName = _this$get2[0],
          filter = _this$get2[1],
          queryParams = _this$get2[2];

      return this.get('store').query(modelName, this.createQuery(params, filter, queryParams));
    },
    afterModel: function afterModel(model, transition) {
      this._resetPageWhenEmptyResult(model, transition);
    },
    _resetPageWhenEmptyResult: function _resetPageWhenEmptyResult(model, transition) {
      if (model && model.meta && transition && transition.queryParams) {
        var page = transition.queryParams.page;
        var totalPages = model.meta['total-pages'];

        if (!Ember.isNone(page) && !Ember.isNone(totalPages) && totalPages === 0 && Number(page) > 1) {
          this.transitionTo({
            queryParams: {
              page: '1'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});