define("backend/helpers/formatters/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function formatCurrency(args) {
    var value = Ember.isArray(args) ? args[0] : args,
        number;

    if (Ember.isEmpty(value)) {
      return '';
    }

    if (typeof value === 'string') {
      number = parseFloat(value);
    } else {
      number = value;
    }

    return number.toFixed(2).toString().replace('.', ',') + ' €';
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});