define("backend/pods/components/hm-form/control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['inputCol'],
    inputCol: Ember.computed.reads('options.layout.inputCol')
  });

  _exports.default = _default;
});