define("backend/prototypes/tables/headers/events-movie", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Event'
    },
    columns: [{
      attr: 'timestamp',
      name: 'Date',
      format: 'datetime',
      style: 'text-nowrap'
    }, {
      attr: 'type.displayName',
      name: 'Type',
      style: 'text-nowrap'
    }, {
      attr: 'reference'
    }, {
      attr: 'notes',
      name: 'Notes',
      format: 'log'
    }, {
      attr: 'user.displayName',
      name: 'User',
      component: 'hm-table/render-promise',
      style: 'text-nowrap'
    }]
  }, {
    group: {
      name: 'Movie'
    },
    columns: [{
      attr: 'owner',
      name: 'Title',
      component: 'link-owner'
    }, {
      attr: 'owner.status.displayName',
      name: 'Status',
      component: 'hm-table/render-promise'
    }, {
      attr: 'owner.category.displayName',
      name: 'Category',
      component: 'hm-table/render-promise'
    }, {
      attr: 'owner.category.package.displayName',
      name: 'Package',
      component: 'hm-table/render-promise'
    }, {
      attr: 'owner.licensor.displayName',
      name: 'Licensor',
      component: 'hm-table/render-promise',
      display: false
    }, {
      attr: 'owner.language',
      name: 'Language',
      component: 'hm-table/render-promise',
      display: false
    }, {
      attr: 'owner.startDate',
      name: 'Start Date',
      component: 'hm-table/render-promise',
      display: false
    }, {
      attr: 'owner.expirationDate',
      name: 'Expiration Date',
      component: 'hm-table/render-promise',
      display: false
    }]
  }]);

  _exports.default = _default;
});