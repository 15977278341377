define("backend/pods/licensors/overview/route", ["exports", "backend/prototypes/owners/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    ownerType: 'licensor',
    actions: {
      licensorChanged: function licensorChanged() {
        return this.refresh();
      }
    }
  });

  _exports.default = _default;
});