define("backend/pods/client/billings/route", ["exports", "backend/utils/current-day-utc"], function (_exports, _currentDayUtc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend({
    model: function model(queryParams) {
      var _queryParams$create;

      var owner = this.modelFor('client');
      var client = owner.get('id');
      var store = this.get('store');

      var _split = ((_queryParams$create = queryParams.create) !== null && _queryParams$create !== void 0 ? _queryParams$create : '').split(';'),
          _split2 = _slicedToArray(_split, 5),
          _ = _split2[0],
          __ = _split2[1],
          contractId = _split2[2],
          materialOrderId = _split2[3],
          repairOrderId = _split2[4];

      return Ember.RSVP.hash({
        owner: owner,
        client: owner,
        contract: contractId ? this.store.findRecord('contract', contractId) : null,
        materialOrder: materialOrderId ? this.store.findRecord('material-order', materialOrderId) : null,
        repairOrder: repairOrderId ? this.store.findRecord('support-case', repairOrderId) : null,
        queuedBillings: store.query('billing', {
          client: client,
          filter: 'waiting'
        }),
        unpaidBillings: store.query('billing', {
          client: client,
          filter: 'unpaid'
        }),
        paidBillings: store.query('billing', {
          client: client,
          filter: 'paid'
        }),
        cancelledBillings: store.query('billing', {
          client: client,
          filter: 'cancelled'
        }),
        templateBillings: store.query('billing', {
          client: client,
          filter: 'template'
        })
      });
    },
    setupController: function setupController(controller, model) {
      var create = controller.get('create');

      if (create) {
        var _model$contract, _model$templateBillin;

        var _create$split = create.split(';'),
            _create$split2 = _slicedToArray(_create$split, 2),
            periodStart = _create$split2[0],
            periodEnd = _create$split2[1];

        var contractId = (_model$contract = model.contract) === null || _model$contract === void 0 ? void 0 : _model$contract.id;
        var materialOrder = model.materialOrder;
        var repairOrder = model.repairOrder;
        var templates = (_model$templateBillin = model.templateBillings) !== null && _model$templateBillin !== void 0 ? _model$templateBillin : [];
        var template = contractId ? templates.find(function (billing) {
          return billing.status.isTemplate && billing.belongsTo('contract').id() == contractId;
        }) : materialOrder ? templates.find(function (billing) {
          return billing.status.isTemplate && billing.templateType.isMaterialOrder;
        }) : repairOrder ? templates.find(function (billing) {
          return billing.status.isTemplate && billing.templateType.isRepairOrder;
        }) : null;
        model.createTemplate = this.get('store').createRecord('billing', {
          periodStart: periodStart || (0, _currentDayUtc.default)(),
          periodEnd: periodEnd || (0, _currentDayUtc.default)(),
          owner: model.owner,
          contract: model.contract,
          materialOrder: materialOrder,
          repairOrder: repairOrder,
          template: template
        });
      }

      controller.set('model', model);
    },
    actions: {
      refreshModelAction: function refreshModelAction() {
        this.refresh();
      },
      willTransition: function willTransition() {
        this.controller.set('create', null);
        var template = this.controller.get('model.createTemplate');

        if (template) {
          template.rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});