define("backend/pods/components/hm-model/fieldset-departments/departments/component", ["exports", "backend/pods/components/hm-model/mixins/department-status"], function (_exports, _departmentStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_departmentStatus.default, {
    keywords: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      createDepartment: function createDepartment() {
        this.get('model').addDepartment();
      },
      removeDepartment: function removeDepartment(contact) {
        this.get('model').removeDepartment(contact);
      }
    }
  });

  _exports.default = _default;
});