define("backend/models/language", ["exports", "backend/models/localization-model", "ember-cp-validations"], function (_exports, _localizationModel, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localizationModel.default.extend({
    validators: {
      id: (0, _emberCpValidations.validator)('presence', true),
      name: (0, _emberCpValidations.validator)('presence', true),
      nameEng: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});