define("backend/serializers/user", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMBEDDED_IDS = {
    deserialize: 'ids',
    serialize: 'ids'
  };

  var _default = _application.default.extend({
    attrs: {
      distributor: EMBEDDED_IDS
    }
  });

  _exports.default = _default;
});