define("backend/mixins/company-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = Ember.Mixin.create({
    companyName: attr('string'),
    companyDepartment: attr('string'),
    organization: attr('string'),
    hasCompanyName: Ember.computed.notEmpty('companyName'),
    nameAndDevices: Ember.computed('displayName', 'counts.devices', function () {
      var name = this.get('displayName');
      var count = this.get('counts.devices');

      if (Ember.isNone(count)) {
        return name;
      }

      return "".concat(name, " (").concat(count, " devices)");
    })
  });

  _exports.default = _default;
});