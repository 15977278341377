define("backend/components/manage-owner-events", ["exports", "backend/components/route-component", "backend/mixins/route-component/reload-counts", "backend/utils/hm-transforms/date-time"], function (_exports, _routeComponent, _reloadCounts, _dateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routeComponent.default.extend(_reloadCounts.default, {
    createModel: 'event',
    createPath: 'data.owner.events',
    events: Ember.computed.alias('data.events'),
    contacts: Ember.computed.reads('data.owner.contacts'),
    canEdit: true,
    templateEdit: 'components/forms/owner-event',
    listOfContacts: Ember.computed('contacts', function () {
      var contacts = this.get('contacts') || [];
      return contacts.map(function (contact) {
        return contact.get('displayName');
      });
    }),
    timestampTime: Ember.computed('selected.buffers.selected.timestamp', {
      get: function get() {
        var timestamp = this.get('selected.buffers.selected.timestamp');
        return _dateTime.default.deserialize(timestamp);
      },
      set: function set(_, newTime) {
        var curTimestamp = this.get('selected.buffers.selected.timestamp');

        var newTimestamp = _dateTime.default.serialize(curTimestamp, newTime);

        if (newTimestamp) {
          this.set('selected.buffers.selected.timestamp', newTimestamp);
          return newTime;
        }

        return null;
      }
    }),
    actions: {
      selectItem: function selectItem(contact) {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          _this.set('selected.buffers.selected.reference', contact);
        });
      }
    }
  });

  _exports.default = _default;
});