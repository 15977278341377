define("backend/pods/sales/offers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7RPbk3Y9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Offers\"]]],false],[0,\"\\n\\n\"],[1,[28,\"hm-simple-forms/create-offer\",null,[[\"data\",\"templateEdit\"],[[24,[\"model\"]],\"components/forms/create-offer\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/sales/offers/template.hbs"
    }
  });

  _exports.default = _default;
});