define("backend/prototypes/tables/headers/events-all", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'timestamp',
    name: 'Date',
    format: 'datetime'
  }, {
    attr: 'ownerTypeName',
    name: 'Resource Type'
  }, {
    attr: 'owner',
    name: 'Resource',
    component: 'link-owner'
  }, {
    attr: 'type.displayName',
    name: 'Type',
    style: 'text-nowrap'
  }, {
    attr: 'reference'
  }, {
    attr: 'notes',
    name: 'Notes',
    format: 'log'
  }, {
    attr: 'user.displayName',
    name: 'User',
    style: 'text-nowrap',
    component: 'hm-table/render-promise'
  }]);

  _exports.default = _default;
});