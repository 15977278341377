define("backend/models/movie", ["exports", "ember-data", "backend/models/buffered-model", "backend/models/category", "backend/utils/current-day-utc", "backend/utils/next-year-utc", "backend/utils/cp/cast-number-cp", "backend/utils/cp/buffer-changes", "ember-cp-validations"], function (_exports, _emberData, _bufferedModel, _category, _currentDayUtc, _nextYearUtc, _castNumberCp, _bufferChanges, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var STATUS_INACTIVE = '2';
  var STATUS_TESTING = '3';

  var _default = _bufferedModel.default.extend({
    title: attr('string'),
    language: attr('string'),
    file: attr('string'),
    description: attr('string'),
    duration: attr('number', {
      defaultValue: 0
    }),
    thumbnail: attr('string'),
    startDate: attr('string', {
      defaultValue: (0, _currentDayUtc.default)()
    }),
    expirationDate: attr('string', {
      defaultValue: (0, _nextYearUtc.default)()
    }),
    notes: attr('string'),
    checksum: attr('string'),
    filesize: attr('number'),
    revenueShare: attr('boolean'),
    order: attr('number'),
    isOrdered: Ember.computed.notEmpty('order'),
    isUnordered: Ember.computed.not('isOrdered'),
    status: belongsTo('movieStatus', {
      async: false
    }),
    targetGroup: hasMany('targetGroup', {
      async: false
    }),
    category: belongsTo('category', {
      async: true
    }),
    licensor: belongsTo('licensor', {
      async: true
    }),
    countries: hasMany('country', {
      async: true
    }),
    tags: hasMany('tag', {
      async: true
    }),
    events: hasMany('event', {
      async: true
    }),
    attachments: hasMany('attachment', {
      async: true
    }),
    labels: attr(),
    counts: attr(),
    labelClasses: Ember.computed('labels', function () {
      return this.get('labels').mapBy('title').map(function (label) {
        return label.toLowerCase();
      }).map(function (label) {
        return "movie-label--".concat(label);
      }).join(' ');
    }),
    displayName: Ember.computed.reads('title'),
    uniqueName: Ember.computed('title', 'category', 'category.package.name', function () {
      return "".concat(this.get('title') || '', " (").concat(this.get('category.name') || '', ", ").concat(this.get('category.package.name') || '', ")");
    }),
    categoryId: Ember.computed('category', function () {
      return this.belongsTo('category').id();
    }),
    formattedDuration: Ember.computed('duration', {
      get: function get() {
        var minutes = Math.round(this.get('duration'));
        var hours = Math.trunc(minutes / 60);
        return ('00' + hours).slice(-2) + ':' + ('00' + (minutes - hours * 60)).slice(-2);
      }
    }),
    formattedDurationLong: Ember.computed.reads('formattedDuration'),
    formattedFilesize: Ember.computed('filesize', function () {
      var kb = this.get('filesize');

      if (!Ember.isEmpty(kb)) {
        return "".concat((kb / 1024 / 1024).toFixed(2), " MB");
      }

      return '';
    }),
    sortableId: (0, _castNumberCp.default)('id'),
    isInactive: Ember.computed.equal('status.id', STATUS_INACTIVE),
    isTesting: Ember.computed.equal('status.id', STATUS_TESTING),
    isAvailableInSelCountries: function isAvailableInSelCountries(selCountries) {
      return this.get('isAvailableWorldWide') || selCountries.isAny('inEurope') && this.get('isAvailableEurope') || this.findAvailableCountry(selCountries);
    },
    findAvailableCountry: function findAvailableCountry(countries) {
      var movieCountries = this.get('countries');
      return countries.any(function (c) {
        return movieCountries.includes(c);
      });
    },
    isAvailableWorldWide: Ember.computed('id', function () {
      return this.get('countries').mapBy('id').includes('ww');
    }),
    isAvailableEurope: Ember.computed('id', function () {
      return this.get('countries').mapBy('id').includes('eu');
    }),
    createDependencies: function createDependencies() {
      this._super();

      if (this.get('isNew')) {
        this._setTargetGroup();
      }
    },
    _setTargetGroup: function _setTargetGroup() {
      var allTargetGroups = this.store.peekAll('targetGroup');
      this.get('targetGroup').setObjects(allTargetGroups);
    },
    prefill: function prefill(template) {
      var buffer = this.get('buffers.selected');

      if (buffer) {
        var properties = {};

        if (template instanceof _category.default) {
          properties = {
            language: template.get('language'),
            category: template
          };
        } else if (template instanceof this.constructor) {
          properties = template.getProperties('title', 'language', 'file', 'description', 'duration', 'thumbnail', 'startDate', 'expirationDate', 'notes', 'status', 'licensor');
          this.get('countries').setObjects(template.get('countries'));
          this.get('targetGroup').setObjects(template.get('targetGroup'));
        }

        buffer.setProperties(properties);
      }
    },
    supportsDirtyBufferTracking: true,
    hasBufferedChanges: (0, _bufferChanges.watchBufferChanges)([], []),
    typeName: 'Movie',
    validators: {
      title: (0, _emberCpValidations.validator)('presence', true),
      file: (0, _emberCpValidations.validator)('presence', true),
      thumbnail: (0, _emberCpValidations.validator)('presence', true),
      licensor: (0, _emberCpValidations.validator)('presence', true),
      category: (0, _emberCpValidations.validator)('presence', true),
      language: (0, _emberCpValidations.validator)('presence', true),
      countries: (0, _emberCpValidations.validator)('presence', true),
      status: (0, _emberCpValidations.validator)('presence', true),
      revenueShare: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});