define("backend/pods/components/in-viewport/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isElementInViewport(ctx, el) {
    if (!el || ctx.get('isDestroyed')) {
      return false;
    }

    var rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  }

  var _default = Ember.Component.extend({
    scrollService: Ember.inject.service(),
    inViewport: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this$getProperties = this.getProperties('element', 'scrollService'),
          element = _this$getProperties.element,
          scrollService = _this$getProperties.scrollService;

      if (isElementInViewport(this, element)) {
        this.set('inViewport', true);
      } else {
        scrollService.on('evtVisibilityChange', this, this._checkVisibility);
      }
    },
    _checkVisibility: function _checkVisibility() {
      if (isElementInViewport(this, this.get('element'))) {
        this.set('inViewport', true);
        this.get('scrollService').off('evtVisibilityChange', this, this._checkVisibility);
      }
    }
  });

  _exports.default = _default;
});