define("backend/adapters/device", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      if (type === 'movieComparison') {
        return 'movie_comparison';
      }

      return this._super.apply(this, arguments);
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var urlParts = ['client', 'distributor', 'licensor', 'lead', 'tester', 'sponsoredClient'];
      return this.urlForQueryEx(query, modelName, urlParts);
    },
    urlForLinks: function urlForLinks(modelName, deviceId) {
      return this._prefixUrl(modelName, {
        device: deviceId
      });
    },
    urlForDeviceStarts: function urlForDeviceStarts(query) {
      return this._prefixUrl('deviceStarts', query);
    },
    urlForDeviceContentComparison: function urlForDeviceContentComparison(device) {
      return this._prefixUrl('movieComparison', {
        device: device.get('id')
      });
    },
    query: function query(store, type, _query) {
      if (_query.userDevices) {
        var url = this.urlForFindAll('device') + '/user';
        return this.ajax(url, 'GET');
      }

      return this._super.apply(this, arguments);
    },
    findDeviceStarts: function findDeviceStarts(query) {
      var url = this.urlForDeviceStarts(query);
      delete query.device;
      return this.ajax(url, 'GET', {
        data: query
      });
    },
    getComparison: function getComparison(device) {
      var url = this.urlForDeviceContentComparison(device);
      return this.ajax(url, 'GET');
    },
    assignTo: function assignTo(_ref) {
      var device = _ref.device,
          owner = _ref.owner;
      var url = this.urlForUpdateRecord(device.get('id'), 'device') + '/assign';
      var payload = {
        owner: owner.id,
        owner_type: owner.ownerType
      };
      return this.ajax(url, 'PUT', {
        data: {
          data: payload
        }
      });
    },
    _prefixUrl: function _prefixUrl(type, query) {
      var url = [];
      var prefix = this.urlPrefix();

      if (query['device']) {
        url.push(this.pathForType('device'));
        url.push(query['device']);
      }

      if (type) {
        url.push(this.pathForType(type));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      return url.join('/');
    }
  });

  _exports.default = _default;
});