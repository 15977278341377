define("backend/utils/static-data/device-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Test',
    label: 'device-type--test'
  }, {
    id: 2,
    name: 'Sales',
    label: 'device-type--sales'
  }, {
    id: 4,
    name: 'Study',
    label: 'device-type--study'
  }];
  _exports.default = _default;
});