define("backend/utils/static-data/order-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: '-',
    label: ''
  }, {
    id: 1,
    name: 'Open',
    next: 'Scheduled',
    is_selectable: true,
    label: 'label-default',
    is_open: true
  }, {
    id: 2,
    name: 'Scheduled',
    next: 'In Transit',
    is_selectable: true,
    label: 'label-info'
  }, {
    id: 3,
    name: 'In Transit',
    next: 'Delivered',
    is_selectable: true,
    label: 'label-primary',
    is_in_transit: true
  }, {
    id: 4,
    name: 'Delivered',
    label: 'label-success',
    is_selectable: true
  }, {
    id: 7,
    name: 'In Return',
    label: 'label-striped',
    is_selectable: true
  }, {
    id: 6,
    name: 'Returned',
    label: 'label-striped',
    is_selectable: true
  }, {
    id: 5,
    name: 'Cancelled',
    label: 'label-danger',
    is_selectable: true
  }, {
    id: 8,
    name: 'Other',
    label: 'label-danger',
    is_selectable: true
  }];
  _exports.default = _default;
});