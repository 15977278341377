define("backend/pods/testers/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TOlaQIkS",
    "block": "{\"symbols\":[\"newRecord\"],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Create Tester\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n\"],[4,\"hm-model/form/create\",null,[[\"ownerType\"],[\"tester\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n            \"],[1,[28,\"hm-model/fieldset-profile/company\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n            \"],[1,[28,\"hm-model/fieldset-profile/contact\",null,[[\"model\",\"modelHasDistributor\"],[[23,1,[]],false]]],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"form-gap--full-row\"],[8],[9],[0,\"\\n            \"],[1,[28,\"hm-model/fieldset-profile/detail\",null,[[\"model\",\"statusModel\"],[[23,1,[]],\"tester-status\"]]],false],[0,\"\\n          \"],[9],[0,\"\\n\\n          \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n            \"],[1,[28,\"hm-model/fieldset-profile/address\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n            \"],[1,[28,\"hm-model/fieldset-profile/bank\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n            \"],[1,[28,\"hm-model/fieldset-profile/notes\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/testers/create/template.hbs"
    }
  });

  _exports.default = _default;
});