define("backend/pods/clients/filter-active/route", ["exports", "backend/pods/mixins/route/paginate-and-filter"], function (_exports, _paginateAndFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginateAndFilter.default, {
    queryParams: {
      organization: {
        refreshModel: true
      },
      t: {
        refreshModel: true
      }
    },
    filter: ['client', 'active', ['organization', 't:type', 'show_all']]
  });

  _exports.default = _default;
});