define("backend/pods/sales/documents/controller", ["exports", "backend/prototypes/tables/headers/documents-all"], function (_exports, _documentsAll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Controller.extend({
    tableColumns: _documentsAll.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    },
    curLang: 'deu',
    availLanguages: Ember.computed('languages.length', 'model.@each.language', function () {
      var languages = this.get('languages');
      var modelLanguages = this.get('model').mapBy('language').map(function (id) {
        return languages.findBy('id', id);
      });
      return [languages.findBy('id', 'deu')].concat(_toConsumableArray(modelLanguages)).uniq();
    }),
    canEditDocuments: Ember.computed.reads('currentUser.isAdmin'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('languages', this.get('store').findAll('language'));
    }
  });

  _exports.default = _default;
});