define("backend/pods/components/hm-form/rating-inline/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ratings'],
    classNameBindings: ['activeStarts'],
    total: 3,
    filled: Ember.computed.reads('rating'),
    empty: Ember.computed('total', 'filled', function () {
      return this.get('total') - (this.get('filled') || 0);
    }),
    starts: Ember.computed('rating', 'total', function () {
      var filled = this.get('rating') || 0;
      var stars = [];

      for (var idx = 0; idx < this.get('total'); idx++) {
        var isEmpty = idx >= filled;
        stars.push(Ember.String.htmlSafe("icon-star-".concat(isEmpty ? 'empty3' : 'full2')));
      }

      return stars;
    }),
    actions: {
      hoverStar: function hoverStar(idx) {
        this.set('activeStarts', "ratings__active-".concat(idx));
      },
      setRating: function setRating(idx) {
        this.set('promise', this.get('setAction')(idx + 1));
      }
    }
  });

  _exports.default = _default;
});