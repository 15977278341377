define("backend/pods/components/hm-form/select-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3gdFey1l",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select-with-create\",null,[[\"options\",\"selected\",\"searchField\",\"searchEnabled\",\"allowClear\",\"placeholder\",\"disabled\",\"onChange\",\"onCreate\"],[[24,[\"data\"]],[28,\"find-by\",[\"displayName\",[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null],[24,[\"data\"]]],null],\"displayName\",true,[24,[\"allowClear\"]],[24,[\"placeholder\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"setValue\"],null],[28,\"action\",[[23,0,[]],[24,[\"acts\",\"createAction\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/select-create/template.hbs"
    }
  });

  _exports.default = _default;
});