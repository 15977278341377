define("backend/templates/components/alert-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CefHQXTv",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[1,[28,\"format-log\",[[23,1,[\"message\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[1,[28,\"format-log\",[[24,[\"error\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"success\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"alert alert-success\"],[8],[1,[22,\"success\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/alert-flash.hbs"
    }
  });

  _exports.default = _default;
});