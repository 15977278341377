define("backend/pods/client-features/statistics/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nog2RMll",
    "block": "{\"symbols\":[\"meta\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"dataTable\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table table-striped table-hover\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"text-center\"],[8],[0,\"Dauer gesamt\"],[9],[0,\"\\n        \"],[7,\"th\",true],[10,\"class\",\"text-center\"],[8],[0,\"Einträge gesamt\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"table\",\"length\"]]],null,{\"statements\":[[4,\"with\",[[24,[\"model\",\"meta\"]]],null,{\"statements\":[[0,\"            \"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[1,[23,1,[\"total-duration\"]],false],[9],[0,\"\\n            \"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[1,[23,1,[\"total-count\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[0,\"0\"],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"text-center\"],[8],[0,\"0\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"data-table\",null,[[\"data\",\"isLoading\",\"template\",\"headerComponent\",\"tableColumns\",\"clientSort\",\"showPagination\",\"showFooter\",\"useTemplate\"],[[24,[\"model\",\"table\"]],[24,[\"model\",\"isLoading\"]],\"components/tables/statistic-details-client\",[28,\"component\",[\"hm-models/list/header-contracts\"],null],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],false,false,true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/client-features/statistics/table/template.hbs"
    }
  });

  _exports.default = _default;
});