define("backend/pods/components/hm-model/fieldset-profile/-detail-base", ["exports", "backend/pods/components/hm-model/mixins/validations/vat"], function (_exports, _vat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_vat.default, {
    store: Ember.inject.service(),
    statusList: Ember.computed(function () {
      return this.get('store').peekAll(this.get('statusModel'));
    }),
    ratingList: Ember.computed(function () {
      return this.get('store').peekAll('owner-rating');
    }),
    tagName: 'fieldset',
    buffers: Ember.computed.alias('model.buffers'),
    didInsertElement: function didInsertElement() {
      //this.send('validateVAT', this.get('model.vat')); 
      this.send('validateVAT', this.get('model.vat') + "&" + this.get('model.id') + "&" + this.get('model.displayName'));
    }
  });

  _exports.default = _default;
});