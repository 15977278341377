define("backend/templates/components/manage-owner-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ruF4G6v",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"  \"],[15,\"partials/show-error\",[]],[0,\"\\n\\n  \"],[1,[28,\"entry-form\",null,[[\"selected\",\"errors\",\"data\",\"template\",\"disableLicenseStatus\",\"showLicenseWarning\"],[[24,[\"selected\"]],[24,[\"validationErrors\"]],[24,[\"data\"]],[24,[\"templateEdit\"]],[24,[\"disableLicenseStatus\"]],[24,[\"showLicenseWarning\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"createDevice\"]]],null,{\"statements\":[[0,\"    Loading ...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"panel-header\",null,[[\"createLink\",\"canCreate\"],[\"devices.create\",[24,[\"currentUser\",\"canCreateDevice\"]]]],{\"statements\":[[0,\"      \"],[1,[22,\"labelCreate\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[22,\"panel-body\"],false],[0,\"\\n    \"],[1,[28,\"data-table\",null,[[\"data\",\"isLoading\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\",\"clickRoute\"],[[24,[\"table\"]],[24,[\"data\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]],\"device\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/manage-owner-devices.hbs"
    }
  });

  _exports.default = _default;
});