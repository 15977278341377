define("backend/prototypes/tables/headers/events", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'timestamp',
    name: 'Date',
    format: 'datetime'
  }, {
    attr: 'type.displayName',
    name: 'Type',
    style: 'text-nowrap'
  }, {
    attr: 'reference',
    style: 'text-nowrap'
  }, {
    attr: 'notes',
    name: 'Notes',
    format: 'log'
  }, {
    modelName: 'user',
    attr: 'displayName',
    name: 'User',
    component: 'lazy-load',
    style: 'text-nowrap'
  }]);

  _exports.default = _default;
});