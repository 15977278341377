define("backend/pods/sales/analysis/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function reduceSum(list, attr) {
    return Ember.computed("wonSummary.".concat(list, ".length"), function () {
      return this.get("wonSummary.".concat(list)).mapBy(attr).reduce(function (a, b) {
        return a + b;
      }, 0);
    });
  }

  var _default = Ember.Controller.extend({
    keywords: Ember.inject.service(),
    queryParams: ['funnel_start', 'funnel_end', 'funnel_source', 'funnel_distributor', 'activitiy_start', 'activity_end', 'activity_distributor'],
    funnel_start: null,
    funnel_end: null,
    funnel_source: null,
    funnel_distributor: null,
    activitiy_start: null,
    activitiy_end: null,
    activity_distributor: null,
    options: Ember.Object.create({
      years: [Ember.Object.create({
        id: '2019',
        displayName: '2019'
      }), Ember.Object.create({
        id: '2018',
        displayName: '2018'
      }), Ember.Object.create({
        id: '2017',
        displayName: '2017'
      })],
      tertiaries: [Ember.Object.create({
        id: '1',
        displayName: 'T1'
      }), Ember.Object.create({
        id: '2',
        displayName: 'T2'
      }), Ember.Object.create({
        id: '3',
        displayName: 'T3'
      })]
    }),
    availCountries: Ember.computed('model.won.total.all.length', function () {
      var options = [];
      options.pushObjects((this.get('model.won.total.all') || []).mapBy('country').uniq().sort().filter(function (country) {
        return !!country;
      }).map(function (country) {
        return Ember.Object.create({
          id: country,
          displayName: country
        });
      }));
      return options;
    }),
    availDistributors: Ember.computed('model.won.total.all.length', function () {
      return (this.get('model.won.total.all') || []).reduce(function (acc, _ref) {
        var displayName = _ref.distributor,
            distributor_id = _ref.distributor_id;
        var id = distributor_id + "";

        if (!acc.findBy('id', id)) {
          acc.push(Ember.Object.create({
            id: id,
            displayName: displayName
          }));
        }

        return acc;
      }, []).sortBy('name');
    }),
    filterWonLeaderboard: Ember.Object.create({
      year: null,
      tertiary: null
    }),
    filterWonSummary: Ember.Object.create({
      country: null,
      distributor: null
    }),
    clientsWonLastYear: Ember.computed.reads('wonSummary.lastYear.length'),
    clientsWonCurrentYear: Ember.computed.reads('wonSummary.currentYear.length'),
    clientsWonCurrentT1: Ember.computed.reads('wonSummary.currentT1.length'),
    clientsWonCurrentT2: Ember.computed.reads('wonSummary.currentT2.length'),
    clientsWonCurrentT3: Ember.computed.reads('wonSummary.currentT3.length'),
    devicesWonLastYear: reduceSum('lastYear', 'devices'),
    devicesWonCurrentYear: reduceSum('currentYear', 'devices'),
    devicesWonCurrentT1: reduceSum('currentT1', 'devices'),
    devicesWonCurrentT2: reduceSum('currentT2', 'devices'),
    devicesWonCurrentT3: reduceSum('currentT3', 'devices'),
    mrrWonLastYear: reduceSum('lastYear', 'mrr'),
    mrrWonCurrentYear: reduceSum('currentYear', 'mrr'),
    mrrWonCurrentT1: reduceSum('currentT1', 'mrr'),
    mrrWonCurrentT2: reduceSum('currentT2', 'mrr'),
    mrrWonCurrentT3: reduceSum('currentT3', 'mrr'),
    wonSummary: Ember.computed('model.won.total.all.length', 'filterWonSummary.country', 'filterWonSummary.distributor', function () {
      var filter = this.get('filterWonSummary').getProperties('country', 'distributor');
      var filterBy = [];

      if (filter.country) {
        filterBy.push(function (_ref2) {
          var country = _ref2.country;
          return filter.country === country;
        });
      }

      if (filter.distributor) {
        filterBy.push(function (_ref3) {
          var distributor_id = _ref3.distributor_id;
          return filter.distributor === "" + distributor_id;
        });
      }

      var summary = this.get('model.won.total');

      if (filterBy.get('length') === 0) {
        return summary;
      }

      return Ember.Object.create({
        lastYear: summary.lastYear.filter(function (d) {
          return filterBy.every(function (func) {
            return func(d);
          });
        }),
        currentYear: summary.currentYear.filter(function (d) {
          return filterBy.every(function (func) {
            return func(d);
          });
        }),
        currentT1: summary.currentT1.filter(function (d) {
          return filterBy.every(function (func) {
            return func(d);
          });
        }),
        currentT2: summary.currentT2.filter(function (d) {
          return filterBy.every(function (func) {
            return func(d);
          });
        }),
        currentT3: summary.currentT3.filter(function (d) {
          return filterBy.every(function (func) {
            return func(d);
          });
        })
      });
    }),
    wonLeaderboard: Ember.computed('model.won.total.all.length', 'filterWonLeaderboard.year', 'filterWonLeaderboard.tertiary', function () {
      var filter = this.get('filterWonLeaderboard').getProperties('year', 'tertiary');
      var filterBy = [];

      if (filter.year) {
        filterBy.push(function (effectiveWon) {
          return filter.year === effectiveWon.slice(0, 4);
        });
      }

      if (filter.tertiary) {
        filterBy.push(function (effectiveWon) {
          var effectiveMonth = Number(effectiveWon.slice(5, 7));
          var effectiveTertiary = effectiveMonth < 5 ? '1' : effectiveMonth < 9 ? '2' : '3';
          return filter.tertiary === effectiveTertiary;
        });
      }

      return this.get('model.won.total.all').reduce(function (acc, _ref4) {
        var effectiveWon = _ref4.effectiveWon,
            distributor = _ref4.distributor,
            distributor_id = _ref4.distributor_id,
            mrr = _ref4.mrr;

        if (!distributor) {
          distributor = ' - ';
        }

        var group = acc.findBy('id', distributor_id);

        if (!group) {
          group = Ember.Object.create({
            id: distributor_id,
            name: distributor,
            mrr: 0
          });
          acc.push(group);
        }

        if (filterBy.get('length') === 0 || filterBy.every(function (func) {
          return func(effectiveWon);
        })) {
          group.set('mrr', group.get('mrr') + mrr);
        }

        return acc;
      }, []).sortBy('mrr').reverse();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var today = (0, _moment.default)();
      var curMonth = today.month() + 1;
      var curTertiary = curMonth < 5 ? '1' : curMonth < 9 ? '2' : '3';
      var options = this.get('options');
    },
    conversionRSHCWon: Ember.computed('model.funnel.[]', function () {
      var funnel = this.get('model.funnel');
      var contacted = funnel.contacted;

      if (!contacted) {
        return '-';
      }

      var won = funnel.won || 0;
      return (won / contacted * 100).toFixed(2) + ' %';
    }),
    conversionDemoDoneWon: Ember.computed('model.funnel.[]', function () {
      var funnel = this.get('model.funnel');
      var demoDone = funnel.demo;
      var won = funnel.won || 0;

      if (!demoDone || demoDone === 0) {
        return '-';
      }

      return (won / demoDone * 100).toFixed(2) + ' %';
    }),
    actions: {
      div: function div(a, b) {
        if (!a || !b) {
          return 0;
        }

        return (a / b).toFixed(1);
      },
      sum: function sum(a, b) {
        return a + b;
      }
    }
  });

  _exports.default = _default;
});