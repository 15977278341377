define("backend/components/sortable-categories", ["exports", "moment", "backend/utils/package-overview"], function (_exports, _moment, _packageOverview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UICategory = Ember.ObjectProxy.extend({
    uiPackage: null,
    uiOptions: null,
    isManualOpen: false,
    isManual: false,
    isOpen: Ember.computed('uiOptions.toggleCategories', 'isManualOpen', function () {
      if (this.get('isManual')) {
        return this.get('isManualOpen');
      }

      return this.get('uiOptions.toggleCategories');
    }),
    isInherited: Ember.computed('uiPackage', 'package.id', function () {
      return this.get('uiPackage') !== this.get('package.id');
    }),
    cssStyle: Ember.computed('isOpen', function () {
      if (this.get('isOpen')) {
        return null;
      }

      return Ember.String.htmlSafe('display:none;');
    }),
    parentToggled: Ember.observer('uiOptions.toggleCategories', function () {
      this.set('isManual', false);
    }),
    toggle: function toggle() {
      this.set('isManual', true);
      this.toggleProperty('isManualOpen');
    },
    showInactive: Ember.computed('uiOptions.toggleInactiveOrExpired', 'isInactive', function () {
      return this.get('uiOptions.toggleInactiveOrExpired') && this.get('isInactive');
    })
  });
  var UIMovie = Ember.ObjectProxy.extend({
    uiOptions: null,
    uiPackage: null,
    isInherited: Ember.computed('uiPackage', 'category.package.id', function () {
      return this.get('uiPackage') !== this.get('category.package.id');
    }),
    uiInactiveOrExpired: Ember.computed('uiOptions.toggleInactiveOrExpired', 'labels.length', function () {
      if (this.get('uiOptions.toggleInactiveOrExpired')) {
        var labels = this.get('labels').mapBy('title');
        return labels.any(function (l) {
          return ['Expired', 'Inactive'].includes(l);
        });
      }

      return false;
    }),
    uiLicensorWrong: Ember.computed('uiOptions.licensor', function () {
      var selLicensor = this.get('uiOptions.licensor');

      if (selLicensor) {
        return this.get('licensor.id') !== selLicensor;
      }

      return false;
    }),
    uiCountriesWrong: Ember.computed('uiOptions.countries', function () {
      var countries = this.get('uiOptions.countries') || [];

      if (countries && countries.length > 0) {
        return !this.get('content').isAvailableInSelCountries(countries);
      }

      return false;
    }),
    uiTagsWrong: Ember.computed('uiOptions.tags', function () {
      var tags = (this.get('uiOptions.tags') || []).mapBy('id');

      if (tags && tags.length > 0) {
        var movieTags = this.get('tags').mapBy('id');
        var anySelTagMatches = tags.any(function (c) {
          return movieTags.includes(c);
        });
        return !anySelTagMatches;
      }

      return false;
    }),
    uiExpireDateWrong: Ember.computed('uiOptions.minExpire', 'uiOptions.maxExpire', function () {
      var uiOptions = this.get('uiOptions');

      var _uiOptions$getPropert = uiOptions.getProperties('minExpire', 'maxExpire'),
          minExpire = _uiOptions$getPropert.minExpire,
          maxExpire = _uiOptions$getPropert.maxExpire;

      if (minExpire || maxExpire) {
        var expirationDate = (0, _moment.default)(this.get('expirationDate'));

        if (minExpire && !maxExpire) {
          return !(0, _moment.default)(minExpire).isBefore(expirationDate);
        } else if (maxExpire && !minExpire) {
          return !(0, _moment.default)(maxExpire).isAfter(expirationDate);
        } else {
          return !(0, _moment.default)(minExpire).isBefore(expirationDate) || !(0, _moment.default)(maxExpire).isAfter(expirationDate);
        }
      }

      return false;
    }),
    uiRuntimeWrong: Ember.computed('uiOptions.minRuntime', 'uiOptions.maxRuntime', function () {
      var uiOptions = this.get('uiOptions');

      var _uiOptions$getPropert2 = uiOptions.getProperties('minRuntime', 'maxRuntime'),
          minRuntime = _uiOptions$getPropert2.minRuntime,
          maxRuntime = _uiOptions$getPropert2.maxRuntime;

      if (!Ember.isBlank(minRuntime) || !Ember.isBlank(maxRuntime)) {
        var duration = this.get('duration') / 60;

        if (minRuntime && Ember.isBlank(maxRuntime)) {
          return minRuntime > duration;
        } else if (maxRuntime && Ember.isBlank(minRuntime)) {
          return maxRuntime < duration;
        } else {
          return minRuntime > duration || maxRuntime < duration;
        }
      }

      return false;
    }),
    showInactive: Ember.computed.or('uiInactiveOrExpired', 'uiLicensorWrong', 'uiCountriesWrong', 'uiExpireDateWrong', 'uiRuntimeWrong', 'uiTagsWrong'),
    sortByOrder: ['order:asc'],
    uiSortedMovies: Ember.computed.sort('uiMovies', 'sortByOrder')
  });

  var _default = Ember.Component.extend({
    childCategories: Ember.computed('parent.childCategories.length', 'parent.childCategories.[]', 'parent.isDeleted', 'parent.similarCategory', function () {
      var uiOptions = this.get('uiOptions');
      var uiPackage;

      if (this.get('parent').constructor.modelName === 'package') {
        uiPackage = this.get('parent.id');
      } else {
        uiPackage = this.get('parent.uiPackage');
      }

      var childCategories = this.get('parent.sortedChildCategories');
      var ppackage;

      if (this.get('parent').constructor.modelName === 'package') {
        ppackage = this.get('parent');
      } else {
        ppackage = this.get('parent.package.content');
      }

      var similarCategory = this.get('parent.similarCategory');

      if (similarCategory) {
        // childCategories = childCategories.concat(similarCategory.get('sortedChildCategories'));
        childCategories = (0, _packageOverview.default)(ppackage, {
          children: childCategories,
          inherited: similarCategory.get('sortedChildCategories')
        });
      }

      return childCategories.filterBy('isDeleted', false).map(function (category) {
        var movies = category.get('movies').toArray();
        var similarCategory = category.get('similarCategory');

        if (similarCategory) {
          movies = movies.concat(similarCategory.get('movies').toArray());
        }

        return UICategory.create({
          content: category,
          uiPackage: uiPackage,
          uiOptions: uiOptions,
          uiMovies: movies.map(function (movie) {
            return UIMovie.create({
              content: movie,
              uiPackage: uiPackage,
              uiOptions: uiOptions
            });
          })
        });
      });
    }),
    sortedChildCategories: Ember.computed.sort('childCategories', 'sortByOrder'),
    sortByOrder: ['order:asc'],
    actions: {
      onChange: function onChange() {
        this.sendAction.apply(this, ['onChangeAction'].concat(Array.prototype.slice.call(arguments)));
      },
      onChangeMovie: function onChangeMovie() {
        this.sendAction.apply(this, ['onChangeMovieAction'].concat(Array.prototype.slice.call(arguments)));
      },
      editCategory: function editCategory() {
        this.sendAction.apply(this, ['editCategoryAction'].concat(Array.prototype.slice.call(arguments)));
      },
      editMovie: function editMovie() {
        this.sendAction.apply(this, ['editMovieAction'].concat(Array.prototype.slice.call(arguments)));
      },
      createMovie: function createMovie() {
        this.sendAction.apply(this, ['createMovieAction'].concat(Array.prototype.slice.call(arguments)));
      },
      dragStarted: function dragStarted() {
        this.sendAction.apply(this, ['dragStartedAction'].concat(Array.prototype.slice.call(arguments)));
      },
      dragStopped: function dragStopped() {
        this.sendAction.apply(this, ['dragStoppedAction'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});