define("backend/pods/device/support-cases/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L3Sdg4g9",
    "block": "{\"symbols\":[],\"statements\":[[5,\"manage-support-cases\",[],[[\"@data\",\"@page\",\"@size\"],[[23,0,[\"model\"]],[23,0,[\"page\"]],[23,0,[\"size\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/device/support-cases/template.hbs"
    }
  });

  _exports.default = _default;
});