define("backend/pods/licensor/devices/route", ["exports", "backend/prototypes/owner/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devices.default.extend({
    ownerType: 'licensor'
  });

  _exports.default = _default;
});