define("backend/pods/components/hm-models/list/contracts/cancellation-date/component", ["exports", "moment", "backend/utils/contracts"], function (_exports, _moment, _contracts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  var _default = Ember.Component.extend((_obj = {
    store: Ember.inject.service(),
    refreshModelAction: 'refreshModelAction',
    hasSelectedDate: Ember.computed('date', 'endDate', 'file', function () {
      return !!this.get('date') && !!this.get('endDate') && !!this.get('file');
    }),
    submitClass: Ember.computed('hasSelectedDate', function () {
      return this.get('hasSelectedDate') ? 'btn-primary' : 'bg-slate-300';
    }),
    cursorClass: Ember.computed('hasSelectedDate', function () {
      return this.get('hasSelectedDate') ? '' : 'cursor--not-allowed';
    }),
    file: null,
    cancelReason: null,
    cancelReasons: Ember.computed(function () {
      return this.store.peekAll('contract-cancel-reason');
    }),
    setUploadUrl: function setUploadUrl() {
      var _this = this;

      return this.get('store').adapterFor('attachment').generateUploadUrl('attachment').then(function (_ref) {
        var upload_url = _ref.data.upload_url;

        _this.setProperties({
          file: null,
          uploadUrl: upload_url
        });
      });
    },
    updateEndDate: function updateEndDate() {
      var cancellationDate = this.get('date');

      if (cancellationDate) {
        var model = this.get('model');
        this.set('endDate', (0, _contracts.calcActualEndDate)(model.get('deliveryDate'), model.get('duration'), model.get('noticePeriod'), cancellationDate));
      } else {
        this.set('endDate', null);
      }
    },
    actions: {
      enterDate: function enterDate() {
        this.set('enterDate', true);
        this.setUploadUrl();
      },
      deleteFile: function deleteFile() {
        this.setUploadUrl();
      },
      save: function save() {
        var _this2 = this;

        if (!this.get('hasSelectedDate')) {
          return false;
        }

        var _this$getProperties = this.getProperties('date', 'endDate', 'file', 'cancelReason', 'notes'),
            date = _this$getProperties.date,
            endDate = _this$getProperties.endDate,
            file = _this$getProperties.file,
            notes = _this$getProperties.notes,
            cancelReason = _this$getProperties.cancelReason;

        var dateFmt = (0, _moment.default)(date).format('DD.MM.YYYY');
        var endDateFmt = (0, _moment.default)(endDate).format('DD.MM.YYYY');

        if (confirm("The contract will be set as cancelled.\n\n Please confirm the cancellation date for ".concat(dateFmt, " and the contract end date for ").concat(endDateFmt, "."))) {
          var promise = this.get('model').saveCancellation(date, endDate, file, notes, cancelReason.id);
          this.set('promise', ObjectPromiseProxy.create({
            promise: promise
          }));
          promise.then(function () {
            _this2.sendAction('refreshModelAction');
          });
          promise.catch(function (_ref2) {
            var errors = _ref2.errors;
            alert("Could not save date: ".concat(errors.get('firstObject.error')));
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "updateEndDate", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "updateEndDate"), _obj)), _obj));

  _exports.default = _default;
});