define("backend/pods/components/hm-models/list/support-actions/merge-clients/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "whNXowcM",
    "block": "{\"symbols\":[\"f\",\"async\"],\"statements\":[[4,\"hm-form/wrapper\",null,[[\"model\",\"class\"],[[23,0,[\"formObject\"]],\"w-full\"]],{\"statements\":[[0,\"  \"],[5,\"async\",[],[[\"@do\",\"@onSuccess\",\"@onError\"],[[23,0,[\"submit\"]],[23,0,[\"onSuccess\"]],[23,0,[\"onError\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"form\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"searchOwner\"]],\"expected `f.searchOwner` to be a contextual component but found a string. Did you mean `(component f.searchOwner)`? ('backend/pods/components/hm-models/list/support-actions/merge-clients/template.hbs' @ L9:C8) \"],null]],[[\"field\",\"label\",\"types\"],[\"duplicate\",\"Duplicate\",[28,\"array\",[\"client\",\"lead\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"searchOwner\"]],\"expected `f.searchOwner` to be a contextual component but found a string. Did you mean `(component f.searchOwner)`? ('backend/pods/components/hm-models/list/support-actions/merge-clients/template.hbs' @ L14:C8) \"],null]],[[\"field\",\"label\",\"types\"],[\"original\",\"Original\",[28,\"array\",[\"client\",\"lead\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-danger btn-sm\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,2,[\"actions\",\"do\"]]]],[8],[0,\"\\n        Merge\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/support-actions/merge-clients/template.hbs"
    }
  });

  _exports.default = _default;
});