define("backend/templates/components/billing-cancellation-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IgF9ApnD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"dateOfCancellation\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center text-nowrap\"],[8],[0,\"\\n    \"],[1,[28,\"moment-format\",[[24,[\"model\",\"dateOfCancellation\"]],\"DD.MM.YYYY\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-sm \",[22,\"submitClass\"],\" \",[22,\"cursorClass\"],\" transition-bg\"]]],[12,\"title\",\"Cancel Billing\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"confirmCancellation\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"promise\",\"isPending\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n        \"],[1,[28,\"loading-indicator\",null,[[\"white\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"b\",true],[8],[0,\"Cancel\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/billing-cancellation-date.hbs"
    }
  });

  _exports.default = _default;
});