define("backend/utils/static-data/licensor-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Open'
  }, {
    id: 2,
    name: 'Contacted'
  }, {
    id: 3,
    name: 'Discussion'
  }, {
    id: 4,
    name: 'Movie Selecting'
  }, {
    id: 5,
    name: 'Fee Proposition'
  }, {
    id: 6,
    name: 'Contract Negotiating'
  }, {
    id: 7,
    name: 'Active'
  }, {
    id: 8,
    name: 'Inactive'
  }];
  _exports.default = _default;
});