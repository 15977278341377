define("backend/pods/components/hm-form/base/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['showError:validation-error', 'addStyle:form-group'],
    addStyle: Ember.computed.reads('showLabel'),
    attrClass: 'form-control',
    showLabel: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.initValidations();
      this.initSort();
    },
    // ------------------------ VALIDATIONS --------------------------------------
    initValidations: function initValidations() {
      var validatePath = this.get('field');

      if (validatePath) {
        Ember.defineProperty(this, 'validation', Ember.computed.reads("model.validations.attrs.".concat(validatePath)));
        Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(validatePath)));
      }
    },
    didValidate: Ember.computed.reads('model.didValidate'),
    isInvalid: Ember.computed.reads('validation.isInvalid'),
    isDirty: Ember.computed('value', function () {
      var value = this.get('value');

      if (value instanceof Ember.ObjectProxy || value instanceof Ember.ArrayProxy) {
        return !Ember.isNone(value.get('content'));
      }

      return !Ember.isNone(value);
    }),
    showError: Ember.computed('isDirty', 'isInvalid', 'didValidate', function () {
      return this.get('didValidate') && this.get('isInvalid');
    }),
    // -------------------------- SORTING ----------------------------------------
    initSort: function initSort() {
      this._super.apply(this, arguments);

      var defaultSort = this.get('sort') || 'displayName';
      this.setProperties({
        sortBy: ["".concat(defaultSort, ":asc")]
      });
    },
    sortedOptions: Ember.computed.sort('data', 'sortBy') // ---------------------------------------------------------------------------

  });

  _exports.default = _default;
});