define("backend/pods/distributor/statistics/table/controller", ["exports", "backend/pods/client/statistics/table/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend();

  _exports.default = _default;
});