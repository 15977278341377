define("backend/pods/components/hm-model/fieldset-profile/company-sponsored-client/component", ["exports", "backend/pods/components/hm-model/fieldset-profile/-company-base"], function (_exports, _companyBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_companyBase.default, {
    modelTypes: Ember.computed(function () {
      return this.get('store').peekAll('client-type');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('model.client.id')) {
        this.set('allClients', [this.get('model.client')]);
      }
    },
    actions: {
      loadClients: function loadClients() {
        if (this.get('allClients.length') > 2) {
          return false;
        }

        this.set('allClients', Ember.RSVP.hash({
          clients: this.get('store').query('client', {
            filter: 'available',
            page: {
              number: 1,
              size: 10000
            }
          })
        }).then(function (_ref) {
          var leads = _ref.leads,
              clients = _ref.clients;
          return [Ember.Object.create({
            groupName: 'Clients',
            options: clients.sortBy('displayName')
          })];
        }));
      }
    }
  });

  _exports.default = _default;
});