define("backend/pods/components/hm-model/fieldset-create/essentials/component", ["exports", "backend/pods/components/hm-model/fieldset-create/-essentials-base"], function (_exports, _essentialsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_essentialsBase.default, {});

  _exports.default = _default;
});