define("backend/pods/studies-partner/events/route", ["exports", "backend/prototypes/events/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    beforeModel: function beforeModel() {
      this.setProperties({
        owner: this.modelFor('studies-partner'),
        ownerType: this.modelFor('studies-partner').get('ownerType')
      });
    }
  });

  _exports.default = _default;
});