define("backend/components/main-navigation", ["exports", "ember-perfect-scrollbar"], function (_exports, _emberPerfectScrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberPerfectScrollbar.PerfectScrollbarMixin, {
    perfectScrollbarOptions: {
      suppressScrollX: true
    },
    classNames: 'sidebar sidebar-main sidebar-fixed',
    currentUser: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadThemeJs(); // this.initNiceScroll();
      // this.removeNiceScrollOnMobile();
      // this.initMiniSidebar();
    },
    loadThemeJs: function loadThemeJs() {
      // Disable CSS transitions on page load
      Ember.$('body').addClass('no-transitions'); // ========================================
      //
      // Content area height
      //
      // ========================================
      // Calculate min height

      function containerHeight() {
        var availableHeight = Ember.$(window).height() - Ember.$('.page-container').offset().top - (Ember.$('.navbar-fixed-bottom').outerHeight() || 0);
        Ember.$('.page-container').attr('style', 'min-height:' + availableHeight + 'px');
      } // Initialize


      containerHeight(); // ========================================
      //
      // Heading elements
      //
      // ========================================
      // Heading elements toggler
      // -------------------------
      // Add control button toggler to page and panel headers if have heading elements

      Ember.$('.panel-heading, .page-header-content, .panel-body, .panel-footer').has('> .heading-elements').append('<a class="heading-elements-toggle"><i class="icon-more"></i></a>'); // Toggle visible state of heading elements

      Ember.$('.heading-elements-toggle').on('click', function () {
        Ember.$(this).parent().children('.heading-elements').toggleClass('visible');
      }); // Breadcrumb elements toggler
      // -------------------------
      // Add control button toggler to breadcrumbs if has elements

      Ember.$('.breadcrumb-line').has('.breadcrumb-elements').append('<a class="breadcrumb-elements-toggle"><i class="icon-menu-open"></i></a>'); // Toggle visible state of breadcrumb elements

      Ember.$('.breadcrumb-elements-toggle').on('click', function () {
        Ember.$(this).parent().children('.breadcrumb-elements').toggleClass('visible');
      }); // ========================================
      //
      // Navbar
      //
      // ========================================
      // Navbar navigation
      // -------------------------
      // Prevent dropdown from closing on click

      Ember.$(document).on('click', '.dropdown-content', function (e) {
        e.stopPropagation();
      }); // Disabled links

      Ember.$('.navbar-nav .disabled a').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
      }); // Show tabs inside dropdowns

      Ember.$('.dropdown-content a[data-toggle="tab"]').on('click', function () {
        Ember.$(this).tab('show');
      }); // ========================================
      //
      // Element controls
      //
      // ========================================
      // Reload elements
      // -------------------------
      // Panels

      Ember.$('.panel [data-action=reload]').click(function (e) {
        e.preventDefault();
        var block = Ember.$(this).parent().parent().parent().parent().parent();
        Ember.$(block).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        }); // For demo purposes

        window.setTimeout(function () {
          Ember.$(block).unblock();
        }, 2000);
      }); // Sidebar categories

      Ember.$('.category-title [data-action=reload]').click(function (e) {
        e.preventDefault();
        var block = Ember.$(this).parent().parent().parent().parent();
        Ember.$(block).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#000',
            opacity: 0.5,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #000'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none',
            color: '#fff'
          }
        }); // For demo purposes

        window.setTimeout(function () {
          Ember.$(block).unblock();
        }, 2000);
      }); // Light sidebar categories

      Ember.$('.sidebar-default .category-title [data-action=reload]').click(function (e) {
        e.preventDefault();
        var block = Ember.$(this).parent().parent().parent().parent();
        Ember.$(block).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        }); // For demo purposes

        window.setTimeout(function () {
          Ember.$(block).unblock();
        }, 2000);
      }); // Collapse elements
      // -------------------------
      //
      // Sidebar categories
      //
      // Hide if collapsed by default

      Ember.$('.category-collapsed').children('.category-content').hide(); // Rotate icon if collapsed by default

      Ember.$('.category-collapsed').find('[data-action=collapse]').addClass('rotate-180'); // Collapse on click

      Ember.$('.category-title [data-action=collapse]').click(function (e) {
        e.preventDefault();
        var $categoryCollapse = Ember.$(this).parent().parent().parent().nextAll();
        Ember.$(this).parents('.category-title').toggleClass('category-collapsed');
        Ember.$(this).toggleClass('rotate-180');
        containerHeight(); // adjust page height

        $categoryCollapse.slideToggle(150);
      }); //
      // Panels
      //
      // Hide if collapsed by default

      Ember.$('.panel-collapsed').children('.panel-heading').nextAll().hide(); // Rotate icon if collapsed by default

      Ember.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180'); // Collapse on click

      Ember.$('.panel [data-action=collapse]').click(function (e) {
        e.preventDefault();
        var $panelCollapse = Ember.$(this).parent().parent().parent().parent().nextAll();
        Ember.$(this).parents('.panel').toggleClass('panel-collapsed');
        Ember.$(this).toggleClass('rotate-180');
        containerHeight(); // recalculate page height

        $panelCollapse.slideToggle(150);
      }); // Remove elements
      // -------------------------
      // Panels

      Ember.$('.panel [data-action=close]').click(function (e) {
        e.preventDefault();
        var $panelClose = Ember.$(this).parent().parent().parent().parent().parent();
        containerHeight(); // recalculate page height

        $panelClose.slideUp(150, function () {
          Ember.$(this).remove();
        });
      }); // Sidebar categories

      Ember.$('.category-title [data-action=close]').click(function (e) {
        e.preventDefault();
        var $categoryClose = Ember.$(this).parent().parent().parent().parent();
        containerHeight(); // recalculate page height

        $categoryClose.slideUp(150, function () {
          Ember.$(this).remove();
        });
      }); // ========================================
      //
      // Main navigation
      //
      // ========================================
      // Main navigation
      // -------------------------
      // Add 'active' class to parent list item in all levels

      Ember.$('.navigation').find('li.active').parents('li').addClass('active'); // Hide all nested lists

      Ember.$('.navigation').find('li').not('.active, .category-title').has('ul').children('ul').addClass('hidden-ul'); // Highlight children links

      Ember.$('.navigation').find('li').has('ul').children('a').addClass('has-ul'); // Add active state to all dropdown parent levels

      Ember.$('.dropdown-menu:not(.dropdown-content), .dropdown-menu:not(.dropdown-content) .dropdown-submenu').has('li.active').addClass('active').parents('.navbar-nav .dropdown:not(.language-switch), .navbar-nav .dropup:not(.language-switch)').addClass('active'); // Collapsible functionality
      // -------------------------
      // Main navigation

      Ember.$('.navigation-main').find('li').has('ul').children('a').on('click', function (e) {
        if (e.target.classList.contains('has-link')) {
          return true;
        } // bubble


        e.preventDefault(); // Collapsible

        Ember.$(this).parent('li').not('.disabled').not(Ember.$('.sidebar-xs').not('.sidebar-xs-indicator').find('.navigation-main').children('li')).toggleClass('active').children('ul').slideToggle(250); // Accordion

        if (Ember.$('.navigation-main').hasClass('navigation-accordion')) {
          Ember.$(this).parent('li').not('.disabled').not(Ember.$('.sidebar-xs').not('.sidebar-xs-indicator').find('.navigation-main').children('li')).siblings(':has(.has-ul)').removeClass('active').children('ul').slideUp(250);
        }
      });
      Ember.$('.js-nav-trigger').on('click', function () {
        var target = Ember.$("#".concat(Ember.$(this).data('target')));
        target.slideToggle(250);
      }); // Alternate navigation

      Ember.$('.navigation-alt').find('li').has('ul').children('a').on('click', function (e) {
        e.preventDefault(); // Collapsible

        Ember.$(this).parent('li').not('.disabled').toggleClass('active').children('ul').slideToggle(200); // Accordion

        if (Ember.$('.navigation-alt').hasClass('navigation-accordion')) {
          Ember.$(this).parent('li').not('.disabled').siblings(':has(.has-ul)').removeClass('active').children('ul').slideUp(200);
        }
      }); // ========================================
      //
      // Sidebars
      //
      // ========================================
      // Mini sidebar
      // -------------------------
      // Toggle mini sidebar

      Ember.$('.sidebar-main-toggle').on('click', function (e) {
        e.preventDefault(); // Toggle min sidebar class

        Ember.$('body').toggleClass('sidebar-xs');
      }); // Sidebar controls
      // -------------------------
      // Disable click in disabled navigation items

      Ember.$(document).on('click', '.navigation .disabled a', function (e) {
        e.preventDefault();
      }); // Adjust page height on sidebar control button click

      Ember.$(document).on('click', '.sidebar-control', function () {
        containerHeight();
      }); // Hide main sidebar in Dual Sidebar

      Ember.$(document).on('click', '.sidebar-main-hide', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-main-hidden');
      }); // Toggle second sidebar in Dual Sidebar

      Ember.$(document).on('click', '.sidebar-secondary-hide', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-secondary-hidden');
      }); // Hide detached sidebar

      Ember.$(document).on('click', '.sidebar-detached-hide', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-detached-hidden');
      }); // Hide all sidebars

      Ember.$(document).on('click', '.sidebar-all-hide', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-all-hidden');
      }); //
      // Opposite sidebar
      //
      // Collapse main sidebar if opposite sidebar is visible

      Ember.$(document).on('click', '.sidebar-opposite-toggle', function (e) {
        e.preventDefault(); // Opposite sidebar visibility

        Ember.$('body').toggleClass('sidebar-opposite-visible'); // If visible

        if (Ember.$('body').hasClass('sidebar-opposite-visible')) {
          // Make main sidebar mini
          Ember.$('body').addClass('sidebar-xs'); // Hide children lists

          Ember.$('.navigation-main').children('li').children('ul').css('display', '');
        } else {
          // Make main sidebar default
          Ember.$('body').removeClass('sidebar-xs');
        }
      }); // Hide main sidebar if opposite sidebar is shown

      Ember.$(document).on('click', '.sidebar-opposite-main-hide', function (e) {
        e.preventDefault(); // Opposite sidebar visibility

        Ember.$('body').toggleClass('sidebar-opposite-visible'); // If visible

        if (Ember.$('body').hasClass('sidebar-opposite-visible')) {
          // Hide main sidebar
          Ember.$('body').addClass('sidebar-main-hidden');
        } else {
          // Show main sidebar
          Ember.$('body').removeClass('sidebar-main-hidden');
        }
      }); // Hide secondary sidebar if opposite sidebar is shown

      Ember.$(document).on('click', '.sidebar-opposite-secondary-hide', function (e) {
        e.preventDefault(); // Opposite sidebar visibility

        Ember.$('body').toggleClass('sidebar-opposite-visible'); // If visible

        if (Ember.$('body').hasClass('sidebar-opposite-visible')) {
          // Hide secondary
          Ember.$('body').addClass('sidebar-secondary-hidden');
        } else {
          // Show secondary
          Ember.$('body').removeClass('sidebar-secondary-hidden');
        }
      }); // Hide all sidebars if opposite sidebar is shown

      Ember.$(document).on('click', '.sidebar-opposite-hide', function (e) {
        e.preventDefault(); // Toggle sidebars visibility

        Ember.$('body').toggleClass('sidebar-all-hidden'); // If hidden

        if (Ember.$('body').hasClass('sidebar-all-hidden')) {
          // Show opposite
          Ember.$('body').addClass('sidebar-opposite-visible'); // Hide children lists

          Ember.$('.navigation-main').children('li').children('ul').css('display', '');
        } else {
          // Hide opposite
          Ember.$('body').removeClass('sidebar-opposite-visible');
        }
      }); // Keep the width of the main sidebar if opposite sidebar is visible

      Ember.$(document).on('click', '.sidebar-opposite-fix', function (e) {
        e.preventDefault(); // Toggle opposite sidebar visibility

        Ember.$('body').toggleClass('sidebar-opposite-visible');
      }); // Mobile sidebar controls
      // -------------------------
      // Toggle main sidebar

      Ember.$('.sidebar-mobile-main-toggle').on('click', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-opposite sidebar-mobile-detached');
      }); // Toggle secondary sidebar

      Ember.$('.sidebar-mobile-secondary-toggle').on('click', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-mobile-secondary').removeClass('sidebar-mobile-main sidebar-mobile-opposite sidebar-mobile-detached');
      }); // Toggle opposite sidebar

      Ember.$('.sidebar-mobile-opposite-toggle').on('click', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-mobile-opposite').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-detached');
      }); // Toggle detached sidebar

      Ember.$('.sidebar-mobile-detached-toggle').on('click', function (e) {
        e.preventDefault();
        Ember.$('body').toggleClass('sidebar-mobile-detached').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-opposite');
      }); // Mobile sidebar setup
      // -------------------------

      Ember.$(window).on('resize', function () {
        setTimeout(function () {
          containerHeight();

          if (Ember.$(window).width() <= 768) {
            // Add mini sidebar indicator
            Ember.$('body').addClass('sidebar-xs-indicator'); // Place right sidebar before content

            Ember.$('.sidebar-opposite').insertBefore('.content-wrapper'); // Place detached sidebar before content

            Ember.$('.sidebar-detached').insertBefore('.content-wrapper');
          } else {
            // Remove mini sidebar indicator
            Ember.$('body').removeClass('sidebar-xs-indicator'); // Revert back right sidebar

            Ember.$('.sidebar-opposite').insertAfter('.content-wrapper'); // Remove all mobile sidebar classes

            Ember.$('body').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-detached sidebar-mobile-opposite'); // Revert left detached position

            if (Ember.$('body').hasClass('has-detached-left')) {
              Ember.$('.sidebar-detached').insertBefore('.container-detached');
            } // Revert right detached position
            else if (Ember.$('body').hasClass('has-detached-right')) {
                Ember.$('.sidebar-detached').insertAfter('.container-detached');
              }
          }
        }, 100);
      }).resize(); // ========================================
      //
      // Other code
      //
      // ========================================
    },
    // Limitless theme: layout_fixed_custom.js
    initNiceScroll: function initNiceScroll() {
      Ember.$(".sidebar-content", this.element).niceScroll({
        mousescrollstep: 100,
        cursorcolor: '#ccc',
        cursorborder: '',
        cursorwidth: 6,
        hidecursordelay: 100,
        autohidemode: 'scroll',
        horizrailenabled: false,
        preservenativescrolling: false,
        railpadding: {
          left: 0,
          right: 0.5,
          top: 1.5,
          bottom: 1.5
        }
      });
    },
    removeNiceScroll: function removeNiceScroll() {
      Ember.$(".sidebar-content", this.element).getNiceScroll().remove();
      Ember.$(".sidebar-content", this.element).removeAttr('style').removeAttr('tabindex');
    },
    removeNiceScrollOnMobile: function removeNiceScrollOnMobile() {
      var _this = this;

      Ember.$(window).on('resize', function () {
        setTimeout(function () {
          if (Ember.$(window).width() <= 768) {
            _this.removeNiceScroll();
          } else {
            _this.initNiceScroll();
          }
        }, 100);
      }).resize();
    },
    initMiniSidebar: function initMiniSidebar() {
      var _this2 = this;

      Ember.$('.sidebar-main-toggle').on('click', function (e) {
        e.preventDefault();

        _this2.toggleMiniSidebar();
      });
    },
    toggleMiniSidebar: function toggleMiniSidebar() {
      // $('body').toggleClass('sidebar-xs');
      Ember.$('.sidebar-main.sidebar-fixed .sidebar-content') // Expand fixed navbar
      .on('mouseenter', function () {
        if (Ember.$('body').hasClass('sidebar-xs')) {
          Ember.$('body').removeClass('sidebar-xs').addClass('sidebar-fixed-expanded');
        }
      }) // Collapse fixed navbar
      .on('mouseleave', function () {
        if (Ember.$('body').hasClass('sidebar-fixed-expanded')) {
          Ember.$('body').removeClass('sidebar-fixed-expanded').addClass('sidebar-xs');
        }
      });
    }
  });

  _exports.default = _default;
});