define("backend/pods/components/hm-model/fieldset-billings/information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fRD+h+Ua",
    "block": "{\"symbols\":[],\"statements\":[[7,\"legend\",true],[10,\"class\",\"text-semibold\"],[8],[7,\"i\",true],[10,\"class\",\"icon-notebook position-left\"],[8],[9],[0,\" Billing Information\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-11\"],[8],[0,\"\\n    \"],[1,[28,\"hm-form/control2\",null,[[\"opts\",\"value\",\"ctrl\",\"size\"],[\"fullsize\",[24,[\"billingInformation\"]],\"hm-form/textarea\",\"small\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n        \"],[7,\"small\",true],[8],[0,\"e.g. order number from confirmation, billing contact person, other requirements to be displayed on the invoice.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-1\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"promise\",\"isPending\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary cursor--not-allowed\"],[10,\"type\",\"button\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n          \"],[1,[28,\"loading-indicator\",null,[[\"white\"],[true]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],\"saveBillingInformation\"]],[8],[0,\"\\n        Save\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/fieldset-billings/information/template.hbs"
    }
  });

  _exports.default = _default;
});