define("backend/pods/components/hm-models/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FMmt49zw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"createAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"role\",\"button\"],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],[24,[\"createAction\"]]],[[\"on\"],[\"click\"]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"class\",\"role\",\"tagName\",\"route\"],[\"btn btn-primary\",\"button\",\"button\",[24,[\"route\"]]]],{\"statements\":[[0,\"      \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/create/template.hbs"
    }
  });

  _exports.default = _default;
});