define("backend/pods/components/hm-invoice/columns/overdue-phase/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VzSgnEUu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"overduePhase\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"label \",[24,[\"model\",\"overduePhase\",\"label\"]]]]],[8],[1,[24,[\"model\",\"overduePhase\",\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-invoice/columns/overdue-phase/template.hbs"
    }
  });

  _exports.default = _default;
});