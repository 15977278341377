define("backend/adapters/material-order", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateStatus: function updateStatus(id, status, file, tracking) {
      if (status === '4' && file) {
        var _url = this.urlForUpdateRecord(id, 'material-order') + "/delivered";

        var data = {
          data: {
            file: file
          }
        };
        return this.ajax(_url, 'PUT', {
          data: data
        });
      } else if (status === '3' && tracking) {
        var _url2 = this.urlForUpdateRecord(id, 'material-order') + "/transit";

        var _data = {
          data: {
            tracking: tracking
          }
        };
        return this.ajax(_url2, 'PUT', {
          data: _data
        });
      }

      var url = this.urlForUpdateRecord(id, 'material-order') + "/status/" + status;
      return this.ajax(url, 'PUT');
    },
    createAttachment: function createAttachment(id, query) {
      var url = this.urlForUpdateRecord(id, 'material-order') + "/attachment";
      return this.ajax(url, 'POST', {
        data: {
          data: query
        }
      });
    }
  });

  _exports.default = _default;
});