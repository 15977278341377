define("backend/pods/components/hm-models/list/link-plain-owner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U/yRn7SF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"get\",[[24,[\"model\"]],\"route\"],null]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[[28,\"get\",[[24,[\"model\"]],\"route\"],null],[28,\"get\",[[24,[\"model\"]],\"ID\"],null]]],{\"statements\":[[1,[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"get\",[[24,[\"model\"]],[24,[\"attribute\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-models/list/link-plain-owner/template.hbs"
    }
  });

  _exports.default = _default;
});