define("backend/pods/sponsored-client/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5IpaFh5B",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"hm-model/form/essentials\",null,[[\"selected\",\"ownerType\"],[[24,[\"model\"]],\"sponsored-client\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/company-w-departments-sponsored\",null,[[\"model\",\"form\"],[[24,[\"model\"]],[23,1,[]]]]],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"descendants\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"hm-model/fieldset-essentials/descendants\",null,[[\"models\"],[[24,[\"model\",\"descendants\"]]]]],false],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"hm-model/fieldset-essentials/contacts\",null,[[\"model\"],[[24,[\"model\",\"contacts\"]]]]],false],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/devices\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[1,[28,\"hm-model/fieldset-essentials/manage-events-tasks\",null,[[\"model\",\"modelName\",\"skipTasks\"],[[24,[\"model\"]],\"sponsored-client\",true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/sponsored-client/index/template.hbs"
    }
  });

  _exports.default = _default;
});