define("backend/templates/components/billings-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gWHveKXj",
    "block": "{\"symbols\":[\"flash\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,1,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,1,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"serverError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[24,[\"serverError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"  \"],[14,2,[[24,[\"selected\"]],[28,\"hash\",null,[[\"createEntry\",\"saveEntry\",\"deleteEntry\",\"cancelEdit\",\"editSelected\"],[[28,\"action\",[[23,0,[]],\"createEntry\"],null],[28,\"action\",[[23,0,[]],\"saveEntry\"],null],[28,\"action\",[[23,0,[]],\"deleteEntry\"],null],[28,\"action\",[[23,0,[]],\"cancelEdit\"],null],[28,\"action\",[[23,0,[]],\"editSelected\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/billings-component.hbs"
    }
  });

  _exports.default = _default;
});