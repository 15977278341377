define("backend/pods/sales/devices/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['distributor'],
    canSelectDistributor: Ember.computed.reads('currentUser.isAdmin'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('distributor')) {
        this.send('loadDistributors');
      }
    },
    actions: {
      setDistributor: function setDistributor(option) {
        var _this = this;

        if (!Ember.isNone(option)) {
          window.requestAnimationFrame(function () {
            _this.set('distributor', option.get('id'));
          });
        }
      },
      loadDistributors: function loadDistributors() {
        if (!this.get('distributors')) {
          if (this.get('canSelectDistributor')) {
            this.set('distributors', this.get('store').query('distributor', {
              filter: 'active'
            }));
          } else {
            this.set('distributors', this.get('store').query('distributor', {
              filter: 'active',
              include_territory: true
            }));
          }
        }
      }
    }
  });

  _exports.default = _default;
});