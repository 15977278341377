define("backend/pods/components/hm-form/search-owner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8rIIXAVe",
    "block": "{\"symbols\":[\"@placeholder\",\"@field\",\"@model\",\"@types\",\"@selectLabel\"],\"statements\":[[5,\"search-owner\",[],[[\"@placeholder\",\"@onSelect\",\"@selected\",\"@types\",\"@selectLabel\"],[[23,1,[]],[23,0,[\"setValue\"]],[28,\"get\",[[23,3,[]],[23,2,[]]],null],[23,4,[]],[23,5,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/search-owner/template.hbs"
    }
  });

  _exports.default = _default;
});