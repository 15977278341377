define("backend/prototypes/tables/headers/movies", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'labels',
    format: 'labels'
  }, {
    attr: 'sortableId',
    name: 'Id'
  }, {
    attr: 'title',
    name: 'Name',
    style: 'text-nowrap'
  }, {
    attr: 'status.displayName',
    name: 'Status'
  }, {
    attr: 'duration',
    name: 'Time'
  }, {
    modelName: 'category',
    attr: 'displayName',
    title: 'categoryId',
    name: 'Category',
    component: 'lazy-load',
    style: 'text-nowrap'
  }, {
    modelName: 'licensor',
    attr: 'displayName',
    name: 'Licensor',
    component: 'lazy-load',
    style: 'text-nowrap'
  }, {
    attr: 'language'
  }, {
    attr: 'startDate',
    name: 'Start Date',
    display: false,
    format: 'date'
  }, {
    attr: 'expirationDate',
    name: 'Expiration Date',
    display: false,
    format: 'date'
  }, {
    attr: 'file',
    noFormat: true
  }, {
    attr: 'checksum',
    display: false
  }, {
    attr: 'formattedFilesize',
    name: 'Filesize',
    display: false,
    style: 'text-right text-nowrap'
  }, {
    attr: 'revenueShare',
    name: 'Revenue Share',
    display: false
  }, {
    attr: 'isOrdered',
    name: 'Is Ordered?',
    display: false
  }]);

  _exports.default = _default;
});