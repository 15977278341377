define("backend/pods/logistics/orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CROyLYHc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Device Orders\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n    \"],[1,[28,\"hm-models/list/filter-orders\",null,[[\"status\",\"deviceType\",\"onChange\"],[[23,0,[\"status\"]],[23,0,[\"deviceType\"]],[23,0,[\"onQueryParamChange\"]]]]],false],[0,\"\\n    \"],[1,[28,\"hm-models/list/table-readonly\",null,[[\"data\",\"isLoading\",\"headerComponent\",\"rowComponent\",\"tableColumns\",\"defaultClientSort\",\"showPagination\",\"showFooter\"],[[23,0,[\"model\"]],[23,0,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/header-contracts\"],null],[28,\"component\",[\"hm-models/list/orders/row\"],null],[23,0,[\"tableColumns\"]],[23,0,[\"clientSort\"]],false,false]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/logistics/orders/template.hbs"
    }
  });

  _exports.default = _default;
});