define("backend/pods/studies-partner/studies/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var owner = this.modelFor('studies-partner');
      return Ember.RSVP.hash({
        owner: owner,
        studies: this.get('store').query('study', {
          client: owner.get('id')
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('selected', null);
    },
    actions: {
      saveComplete: function saveComplete() {
        var events = this.controller.get('model.owner.events');

        if (events) {
          events.reload();
        }

        return this.refresh();
      },
      loadAllStatus: function loadAllStatus() {
        if (!this.controller.get('statusList')) {
          this.controller.set('statusList', this.get('store').peekAll('study-status'));
        }
      },
      loadAllTypes: function loadAllTypes() {
        if (!this.controller.get('typeList')) {
          this.controller.set('typeList', this.get('store').peekAll('study-type'));
        }
      }
    }
  });

  _exports.default = _default;
});