define("backend/pods/devices/chart/route", ["exports", "moment", "backend/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function prepareData(data) {
    return data.reduce(function (chart, d) {
      if (d.type === 'offer' || d.type === 'clients_preparation') {
        var serial_numbers = (d.assigned_devices_serial_numbers || '').split(',');
        var device_ids = (d.assigned_devices || '').split(',');
        var assign_dates = (d.assigned_devices_assign_date || '').split(',');
        d.owner_id = d.id;
        var ownerDevices = [];

        if (serial_numbers.length > d.count_1) {
          d.category = "".concat(d.category, " (").concat(d.count_2, "/").concat(d.count_1, ") ").concat(d.count_2, " already assigned - ").concat(d.count_1, " ordered ").concat((0, _moment.default)(d.x1).format('DD.MM.YYYY'));

          for (var idx = 0; idx < serial_numbers.length; idx++) {
            var dup = Ember.copy(d);
            dup.idx = idx;
            dup.title = serial_numbers[idx];

            if (assign_dates[idx]) {
              dup.title += " assigned ".concat((0, _moment.default)(assign_dates[idx]).format('DD.MM.YYYY'));
              dup.assign_date = (0, _moment.default)(assign_dates[idx]).unix();
            }

            dup.device_id = device_ids[idx];
            dup.color = 'lightgrey';
            ownerDevices.push(Ember.copy(dup));
          }
        } else {
          d.category = "".concat(d.category, " (").concat(d.count_2, "/").concat(d.count_1, ")");

          for (var _idx = 0; _idx < d.count_1; _idx++) {
            var _dup = Ember.copy(d);

            _dup.idx = _idx;

            if (_idx <= serial_numbers.length - 1) {
              _dup.title = serial_numbers[_idx];

              if (assign_dates[_idx]) {
                _dup.title += " assigned ".concat((0, _moment.default)(assign_dates[_idx]).format('DD.MM.YYYY'));
                _dup.assign_date = (0, _moment.default)(assign_dates[_idx]).unix();
              }

              _dup.device_id = device_ids[_idx];

              if (_dup.device_id) {
                _dup.color = '#9ccc65';
              }
            } else {
              _dup.title = null;
            }

            ownerDevices.push(_dup);
          }
        }

        chart.pushObjects(ownerDevices.sortBy('assign_date').reverse());
      } else {
        d.device_id = d.id;
        chart.push(d);
      }

      return chart;
    }, []);
  }

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      t: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          var url = _environment.default.host + '/devices/devices_chart?type=' + params.t;

          _this.get('ajax').request(url, {
            headers: headers
          }).then(function (_ref) {
            var data = _ref.data;
            return resolve(prepareData(data));
          });
        });
      });
    }
  });

  _exports.default = _default;
});