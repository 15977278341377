define("backend/pods/device/logs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    selected: Ember.computed.reads('model.owner'),
    errorTypes: [Ember.Object.create({
      id: 'errors',
      displayName: 'Error'
    }), Ember.Object.create({
      id: 'batteries',
      displayName: 'Battery'
    }), Ember.Object.create({
      id: 'others',
      displayName: 'Other'
    })],
    hasErrorType: Ember.computed.notEmpty('errorType'),
    logs: Ember.computed('selected.id', 'errorType', function () {
      var device = this.get('selected.id');
      var errorType = this.get('errorType');

      if (device && errorType) {
        return this.get('store').query('log', {
          type: errorType,
          device: device
        });
      }

      return [];
    })
  });

  _exports.default = _default;
});