define("backend/utils/next-year-utc", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return _moment.default.utc().startOf('day').add(1, 'year').subtract(1, 'days').toJSON();
  }
});