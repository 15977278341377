define("backend/models/serverlog", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var Model = _emberData.default.Model.extend({
    date: attr('string'),
    ip: attr('string'),
    method: attr('string'),
    resource: attr('string'),
    status: attr('string')
  });

  Model.reopenClass({
    lastId: 0,
    getId: function getId() {
      return this.lastId += 1;
    }
  });
  var _default = Model;
  _exports.default = _default;
});