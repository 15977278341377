define("backend/prototypes/tables/headers/exports", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'id',
    name: 'CSV',
    component: 'hm-models/list/export-csv'
  }, {
    attr: 'title'
  }]);

  _exports.default = _default;
});