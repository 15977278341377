define("backend/pods/components/hm-model/form/essentials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1donJx1Q",
    "block": "{\"symbols\":[\"flash\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,1,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,1,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"serverError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[24,[\"serverError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"entry-form\",null,[[\"selected\",\"supportsVATValidation\"],[[24,[\"selected\"]],false]],{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"save\",\"saveBtnClass\",\"createUploadUrl\",\"promise\"],[[28,\"action\",[[23,0,[]],\"saveEntry\"],null],[24,[\"saveBtnClass\"]],[28,\"action\",[[23,0,[]],\"createUploadUrl\"],null],[24,[\"promise\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-model/form/essentials/template.hbs"
    }
  });

  _exports.default = _default;
});