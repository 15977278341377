define("backend/utils/package-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeCategoryTree;

  function isDirectChild(ppackage, category) {
    return category.get('package.id') === ppackage.get('id');
  }

  function isSimilarChild(left, right) {
    return left.get('name').trim() === right.get('name').trim();
  }

  function findSimilarCategory(ppackage, merged, category) {
    return !isDirectChild(ppackage, category) && merged.find(function (compare) {
      return isSimilarChild(category, compare);
    });
  }

  function mergeCategoryTree(ppackage, _ref) {
    var children = _ref.children,
        inherited = _ref.inherited;
    return children.concat(inherited).reduce(function (merged, category) {
      var similarCategory = findSimilarCategory(ppackage, merged, category);

      if (similarCategory) {
        merged[merged.indexOf(similarCategory)] = Ember.ObjectProxy.create({
          content: similarCategory,
          similarCategory: category,
          hasSimilarCategory: true
        });
      } else {
        merged.push(category);
      }

      return merged;
    }, []);
  }
});