define("backend/pods/client/contracts/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var owner = this.modelFor('client');
      var client = owner.get('id');
      return Ember.RSVP.hash({
        owner: owner,
        client: owner,
        contracts: this.get('store').query('contract', {
          client: client
        })
      });
    },
    setupController: function setupController(controller, model) {
      var viewContract = null;
      var openContract = controller.get('open');

      if (openContract) {
        viewContract = model.contracts.findBy('id', openContract);
      }

      controller.setProperties({
        model: model,
        viewContract: viewContract,
        selected: null
      });
    },
    actions: {
      saveComplete: function saveComplete() {
        var events = this.controller.get('model.owner.events');

        if (events) {
          events.reload();
        }

        return this.refresh();
      },
      loadAllPackages: function loadAllPackages() {
        if (!this.controller.get('model.packages')) {
          this.controller.set('model.packages', this.get('store').query('package', {
            filter: 'public'
          }));
        }
      },
      setStandardPrice: function setStandardPrice(buffer, contentPackage) {
        buffer.set('packagePrice', contentPackage.get('standardPrice'));
      },
      willTransition: function willTransition() {
        this.controller.set('open', null);
      }
    }
  });

  _exports.default = _default;
});