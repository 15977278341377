define("backend/pods/components/hm-model/fieldset-essentials/company-w-departments/component", ["exports", "backend/pods/components/hm-model/mixins/department-status", "backend/pods/components/hm-model/mixins/department-won-lost"], function (_exports, _departmentStatus, _departmentWonLost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_departmentStatus.default, _departmentWonLost.default, {
    tagName: 'fieldset',
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // Bind the model so it’s accessible within the event listener


      var checkbox = document.getElementById("synced_checkbox");

      if (checkbox) {
        checkbox.addEventListener("click", function () {
          // Update model.syncedToClose based on checkbox state
          _this.set('model.syncedToClose', checkbox.checked); // Save the model


          try {
            _this.model.save(); //alert('Model saved successfully');

          } catch (error) {
            console.error("Failed to save model", error); //alert('Failed to save model');
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var checkbox = document.getElementById("synced_checkbox");

      if (checkbox) {
        checkbox.removeEventListener("click", this.handleCheckboxClick);
      }
    }
  });

  _exports.default = _default;
});