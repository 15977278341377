define("backend/templates/components/ordered-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sNMA9Z3h",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[14,2,[[23,1,[]]]]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/ordered-tree.hbs"
    }
  });

  _exports.default = _default;
});