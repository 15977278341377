define("backend/serializers/licensor", ["exports", "backend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMBEDDED_ALWAYS = {
    embedded: 'always'
  };
  var EMBEDDED_NEVER = {
    serialize: false
  };

  var _default = _application.default.extend({
    attrs: {
      address: EMBEDDED_ALWAYS,
      bank: EMBEDDED_ALWAYS,
      contacts: EMBEDDED_NEVER
    },
    normalize: function normalize(_, hash) {
      hash.attr_display_name = hash.display_name;
      delete hash.display_name;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});