define("backend/helpers/tables/table-group-header", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var Group = Ember.Object.extend({
    name: null,
    columns: null,
    visibleCols: Ember.computed('columns.@each.display', function () {
      return this.get('columns').filterBy('display');
    }),
    firstVisibleCol: Ember.computed.reads('visibleCols.firstObject'),
    isVisible: Ember.computed.gt('visibleCols.length', 0),
    isSorted: Ember.computed.reads('firstVisibleCol.isSorted'),
    isSingleGroup: Ember.computed.equal('columns.length', 1)
  });

  function _default(headerGroups) {
    return headerGroups.reduce(function (acc, _ref) {
      var groupDefinition = _ref.group,
          columns = _ref.columns;
      var tableHeaders = (0, _tableHeader.default)(columns);
      var group = Group.create({
        name: groupDefinition.name,
        columns: tableHeaders
      });
      tableHeaders.forEach(function (th) {
        th.set('group', group);
        Ember.defineProperty(th, 'isFirst', Ember.computed('group.firstVisibleCol', function () {
          return this.get('group.firstVisibleCol') === this;
        }));
      });
      return acc.concat(tableHeaders);
    }, []);
  }
});