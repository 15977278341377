define("backend/pods/studies-partner/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var _this = this;

      var id = _ref.id;
      return this.get('store').find('lead', id).catch(function () {
        return _this.get('store').find('client', id);
      });
    }
  });

  _exports.default = _default;
});