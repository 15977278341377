define("backend/pods/billings/waiting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KxqvP16d",
    "block": "{\"symbols\":[\"selected\",\"acts\"],\"statements\":[[1,[28,\"page-header\",null,[[\"title\"],[\"Waiting Billings\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-flat\"],[8],[0,\"\\n\"],[4,\"billings-component\",null,[[\"createBilling\"],[[24,[\"create\"]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n        \"],[1,[28,\"hm-model/fieldset-billings/create-billing\",null,[[\"selected\",\"displayCreateHeading\",\"refreshAction\",\"createAction\",\"saveAction\",\"cancelAction\"],[[23,1,[]],false,[28,\"route-action\",[\"refreshModelAction\"],null],[28,\"action\",[[23,0,[]],[23,2,[\"createEntry\"]]],null],[28,\"action\",[[23,0,[]],[23,2,[\"saveEntry\"]]],null],[28,\"action\",[[23,0,[]],[23,2,[\"cancelEdit\"]]],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"tableColumnsWaiting\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n      \\t\\t\"],[7,\"h5\",true],[10,\"class\",\"panel-title\"],[8],[0,\"Waiting\"],[9],[0,\"\\n      \\t\"],[9],[0,\"\\n        \"],[1,[22,\"panel-body\"],false],[0,\"\\n        \"],[1,[28,\"data-table\",null,[[\"data\",\"editAction\",\"isLoading\",\"tableColumns\",\"tableId\",\"defaultClientSort\",\"showPagination\",\"showFooter\",\"editPerColumn\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],[23,2,[\"editSelected\"]]],null],[24,[\"model\",\"isLoading\"]],[24,[\"tableColumnsWaiting\"]],\"waiting\",[24,[\"clientSort\"]],false,false,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/billings/waiting/template.hbs"
    }
  });

  _exports.default = _default;
});