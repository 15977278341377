define("backend/pods/components/hm-form/select-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vTcdlBU4",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@searchEnabled\",\"@allowClear\",\"@placeholder\",\"@triggerClass\",\"@disabled\",\"@onOpen\",\"@onChange\"],[[22,\"data\"],[28,\"find-by\",[[24,[\"attrValue\"]],[28,\"get\",[[24,[\"model\"]],[24,[\"field\"]]],null],[24,[\"dataFlattened\"]]],null],[22,\"attrLabel\"],true,[22,\"allowClear\"],[22,\"placeholder\"],[28,\"if\",[[28,\"eq\",[[24,[\"inputClass\"]],\"form-control\"],null],[28,\"concat\",[\"data-test-\",[24,[\"field\"]]],null],[24,[\"inputClass\"]]],null],[22,\"disabled\"],[28,\"action\",[[23,0,[]],\"onOpen\"],null],[28,\"action\",[[23,0,[]],\"setValue\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"optionComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"optionComponent\"]]],[[\"option\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[23,1,[]],[24,[\"attrLabel\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/components/hm-form/select-value/template.hbs"
    }
  });

  _exports.default = _default;
});