define("backend/prototypes/owner/devices", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_NEW = '1';

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    ownerQP: function ownerQP(owner) {
      var qp = {};
      qp[Ember.String.camelize(this.get('ownerType'))] = owner.get('id');
      return qp;
    },
    findOwner: function findOwner() {
      return Ember.RSVP.resolve(this.modelFor(this.get('ownerType')));
    },
    beforeModel: function beforeModel() {
      var _this = this;

      return this.findOwner().then(function (owner) {
        _this.set('owner', owner);
      });
    },
    model: function model(params) {
      var store = this.store;
      var owner = this.get('owner');
      var queryParams = Ember.merge(this.curPage(params), this.ownerQP(owner));
      return this.queryModel({
        owner: owner,
        devices: store.query('device', queryParams)
      });
    },
    loadDependencies: function loadDependencies() {
      var store = this.store;
      return {
        // packages: store.findAll('package'),
        // apps: store.findAll('app'),
        statusList: store.peekAll('deviceStatus'),
        statusNew: store.peekAll('deviceStatus').filter(function (status) {
          return status.get('id') === STATUS_NEW;
        }),
        purposeList: store.peekAll('devicePurpose'),
        licenseStatusList: store.peekAll('licenseStatus'),
        attachmentTypes: store.peekAll('attachmentType'),
        deviceTypes: store.peekAll('deviceType'),
        routeLicenseStructure: "".concat(this.get('ownerType'), ".devices.license-structure"),
        deviceModels: store.peekAll('deviceModel')
      };
    },
    actions: {
      willTransition: function willTransition(transition) {
        transition.data.deviceOwner = this.controller.get('model.owner');
        return true;
      }
    }
  });

  _exports.default = _default;
});