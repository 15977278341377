define("backend/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.decamelize(attr);
    },
    keyForRelationship: function keyForRelationship(attr) {
      return Ember.String.decamelize(attr);
    },
    // ------------- SERIALIZE ---------------
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      // Save serialized hash into 'data' instead of typeKey
      return data['data'] = this.serialize(record, options);
    },
    // ------------- DESERIALIZE ---------------
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload) {
      var _this = this;

      this._extractDataPayload((0, _emberInflector.pluralize)(primaryModelClass.modelName), payload);

      var json = this._super.apply(this, arguments);

      if (payload.included) {
        // In future should be replaced by ember json:api included serializer
        json.included = [].concat(_toConsumableArray(json.included), _toConsumableArray(Object.keys(payload.included).map(function (modelName) {
          var includedHash = payload.included[modelName];

          var modelClass = _this.store.modelFor(modelName);

          var serializer = _this.store.serializerFor(modelName);

          return includedHash.map(function (hash) {
            return serializer.normalize(modelClass, hash, null)['data'];
          });
        }).flat()));
      }

      return json;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      this._extractDataPayload(primaryModelClass.modelName, payload);

      return this._super.apply(this, arguments);
    },
    _extractDataPayload: function _extractDataPayload(modelName, payload) {
      delete payload.links;

      if (payload.data) {
        payload[modelName] = payload.data;
        delete payload.data;
      }
    }
  });

  _exports.default = _default;
});