define("backend/pods/components/hm-model/fieldset-essentials/quick-add/task/component", ["exports", "backend/utils/hm-transforms/date-time"], function (_exports, _dateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    tagName: 'form',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.createNewTask();
    },
    cleanupTask: function cleanupTask() {
      var task = this.get('newTask');

      if (!Ember.isNone(task) && task.get('isNew') && !task.get('isSaving')) {
        task.clearBuffers();
        task.rollbackAttributes();
      }
    },
    createNewTask: function createNewTask() {
      if (this.get('newTask')) {
        this.cleanupTask();
      }

      var newTask = this.get('store').createRecord('hm-task');
      newTask.createBuffers();

      if (this.get('model.departments.length') === 1) {
        newTask.set('department', this.get('model.departments.firstObject.id'));
      }

      this.set('newTask', newTask);
      this.get('currentUser').trackSession("quick-add/task: createNewTask: ".concat(this.get('model.id'), ", ").concat(this.elementId, ", ").concat(newTask + ""));
    },
    saveNewTask: function saveNewTask() {
      var _this$getProperties = this.getProperties('model', 'modelName', 'newTask'),
          model = _this$getProperties.model,
          modelName = _this$getProperties.modelName,
          newTask = _this$getProperties.newTask;

      newTask.set(Ember.String.camelize(modelName), model.get('id')); // Validate Buffer

      var buffer = newTask.get('buffers.selected');
      buffer.validateSync();
      newTask.set('didValidate', true);
      this.get('currentUser').trackSession("quick-add/task: saveNewTask: ".concat(model.get('id'), ", ").concat(this.elementId, ", ").concat(newTask + "", ", ").concat(buffer.get('description')));

      if (buffer.get('validations.isValid')) {
        // Apply Buffer + Save
        newTask.applyBuffers();
        return newTask.save();
      }

      return Ember.RSVP.reject();
    },
    reloadAllTasks: function reloadAllTasks() {
      var model = this.get('model');
      model.reload();
      model.get('tasks').reload();
    },
    listOfTaskTypes: Ember.computed(function () {
      var modelName = this.get('modelName');
      return this.get('store').peekAll('task-type').filter(function (taskType) {
        return taskType.get('canCreate') && taskType.isVisibleFor(modelName);
      });
    }),
    dueDateTime: Ember.computed('newTask.buffers.selected.dueDate', {
      get: function get() {
        var dueDate = this.get('newTask.buffers.selected.dueDate');
        return _dateTime.default.deserialize(dueDate);
      },
      set: function set(_, newTime) {
        var curDueDate = this.get('newTask.buffers.selected.dueDate');

        var newDueDate = _dateTime.default.serialize(curDueDate, newTime);

        if (newDueDate) {
          this.set('newTask.buffers.selected.dueDate', newDueDate);
          return newTime;
        }

        return null;
      }
    }),
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanupTask();
    },
    actions: {
      saveTask: function saveTask() {
        var _this = this;

        this.set('promise', this.saveNewTask().then(function () {
          _this.get('currentUser').pushClientSession();

          _this.reloadAllTasks();

          _this.createNewTask();
        }));
      }
    }
  });

  _exports.default = _default;
});