define("backend/prototypes/devices/index", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    deviceQuery: function deviceQuery(params) {
      return this.curPage(params);
    },
    model: function model(params) {
      var store = this.store;
      return this.queryModel({
        devices: store.query('device', this.deviceQuery(params))
      });
    }
  });

  _exports.default = _default;
});