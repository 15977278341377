define("backend/adapters/dashboard", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildUrl: function buildUrl(suffix) {
      return this._buildURL() + '/dashboard/' + suffix;
    }
  });

  _exports.default = _default;
});