define("backend/pods/logs/backend/controller", ["exports", "backend/prototypes/tables/headers/logs-client"], function (_exports, _logsClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['start', 'end'],
    tableColumns: _logsClient.default,
    clientSort: {
      sortBy: 'timestamp',
      order: 'desc'
    }
  });

  _exports.default = _default;
});