define("backend/pods/lead/billings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var owner = this.modelFor('lead');
      var lead = owner.get('id');
      var store = this.get('store');
      return Ember.RSVP.hash({
        owner: owner,
        lead: owner,
        queuedBillings: store.query('billing', {
          lead: lead,
          filter: 'waiting'
        }),
        unpaidBillings: store.query('billing', {
          lead: lead,
          filter: 'unpaid'
        }),
        paidBillings: store.query('billing', {
          lead: lead,
          filter: 'paid'
        }),
        cancelledBillings: store.query('billing', {
          lead: lead,
          filter: 'cancelled'
        })
      });
    },
    actions: {
      refreshModelAction: function refreshModelAction() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});