define("backend/adapters/billing", ["exports", "backend/adapters/application", "moment"], function (_exports, _application, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function strToDate(utcStr) {
    return utcStr ? _moment.default.utc(utcStr).format('YYYY-MM-DD') : null;
  }

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      if (type === 'shipment' || type === 'payment' || type === 'cancellation') {
        return type;
      }

      return this._super.apply(this, arguments);
    },
    urlForQuery: function urlForQuery(query, modelName) {
      return this.urlForQueryEx(query, modelName, ['client', 'lead', 'billing']);
    },
    updateShipmentDate: function updateShipmentDate(billing, shipmentDate) {
      var query = {
        billing: billing
      };
      var data = {
        data: {
          date_of_shipment: strToDate(shipmentDate)
        }
      };
      var url = this.urlForQuery(query, 'shipment');
      return this.ajax(url, 'PUT', {
        data: data
      });
    },
    updatePaymentDate: function updatePaymentDate(billing, paymentDate) {
      var query = {
        billing: billing
      };
      var data = {
        data: {
          date_of_payment: strToDate(paymentDate)
        }
      };
      var url = this.urlForQuery(query, 'payment');
      return this.ajax(url, 'PUT', {
        data: data
      });
    },
    updateCancellationDate: function updateCancellationDate(billing, cancellationDate) {
      var query = {
        billing: billing
      };
      var data = {
        data: {
          date_of_cancellation: strToDate(cancellationDate)
        }
      };
      var url = this.urlForQuery(query, 'cancellation');
      return this.ajax(url, 'PUT', {
        data: data
      });
    },
    updateContract: function updateContract(billing, contract) {
      var query = {
        billing: billing
      };
      var data = {
        data: {
          contract: contract.id
        }
      };
      var url = this.urlForQuery(query, 'contract');
      return this.ajax(url, 'PUT', {
        data: data
      });
    },
    sendEmail: function sendEmail(billing, type) {
      var url = this.urlForFindRecord(billing, 'billing') + '/send_' + type;
      return this.ajax(url, 'POST');
    },
    urlForEmailPreview: function urlForEmailPreview(billing, type) {
      return this.urlForFindRecord(billing, 'billing') + '/preview_' + type;
    }
  });

  _exports.default = _default;
});