define("backend/templates/components/collapsable-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lFwOIrQU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isCollapsed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",false],[12,\"class\",\"icon-arrow-up12\"],[3,\"action\",[[23,0,[]],\"uncollapse\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",false],[12,\"class\",\"icon-arrow-down12\"],[3,\"action\",[[23,0,[]],\"collapse\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/templates/components/collapsable-handle.hbs"
    }
  });

  _exports.default = _default;
});