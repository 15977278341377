define("backend/templates/components/offers-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OPVdncmO",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"if\",[[24,[\"selected\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"flashes\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"alert-flash\",null,[[\"error\"],[[23,1,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"alert-flash\",null,[[\"success\"],[[23,1,[\"alert\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n  \"],[15,\"partials/show-error\",[]],[0,\"\\n\\n  \"],[1,[28,\"entry-form\",null,[[\"selected\",\"errors\",\"data\",\"template\"],[[24,[\"selected\"]],[24,[\"validationErrors\"]],[24,[\"data\"]],\"components/forms/offer\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"panel-header\",null,[[\"createAction\"],[\"createEntry\"]],{\"statements\":[[0,\"Create Offer\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[1,[22,\"panel-body\"],false],[0,\"\\n\"],[1,[28,\"data-table\",null,[[\"data\",\"isLoading\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[[24,[\"offers\"]],[24,[\"data\",\"isLoading\"]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "backend/templates/components/offers-component.hbs"
    }
  });

  _exports.default = _default;
});