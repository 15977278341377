define("backend/pods/package-overview/controller", ["exports", "backend/models/movie"], function (_exports, _movie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function scrollToTarget($target) {
    if (!Ember.isEmpty($target)) {
      Ember.$("html, body").animate({
        scrollTop: $target.offset().top - $target.offsetParent().offset().top
      }, "fast"); // $target.removeClass("animation shake");
      // $target.addClass("animation shake");
    }
  }

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    availablePackages: Ember.computed('model.packages.length', 'model.packages.@each.isDeleted', function () {
      return (this.get('model.packages') || []).filterBy('isDeleted', false).sortBy('displayName');
    }),
    cssFilter: Ember.computed('showFilters', function () {
      if (this.get('showFilters')) {
        return null;
      }

      return Ember.String.htmlSafe('display:none;');
    }),
    setNewOrder: function setNewOrder(itemModels) {
      itemModels.mapBy('content').forEach(function (model, idx) {
        return model.set('order', idx);
      });
    },
    saveNewOrder: function saveNewOrder(itemModels, modelType) {
      var _this = this;

      this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;

        var url = _this.store.adapterFor(modelType).buildURL(modelType, 'order');

        var data = {
          data: itemModels.map(function (model) {
            return {
              id: model.get('id'),
              order: model.get('order')
            };
          })
        };
        return Ember.$.ajax({
          url: url,
          type: 'PUT',
          data: JSON.stringify(data),
          headers: headers,
          contentType: 'application/json',
          dataType: 'json'
        });
      });
    },
    scrollToMovie: function scrollToMovie(movie) {
      if (!movie || !(movie instanceof _movie.default)) {
        return true;
      }

      var $target = Ember.$("[data-movie=".concat(movie.get('id'), "]"));
      scrollToTarget($target);
      return true;
    },
    dragClass: Ember.computed('hideInactiveExpired', 'selLicensor', 'selCountries', 'isDragging', function () {
      var css = [];

      var _this$getProperties = this.getProperties('selLicensor', 'selCountries'),
          selLicensor = _this$getProperties.selLicensor,
          selCountries = _this$getProperties.selCountries;

      if (this.get('hideInactiveExpired')) {
        css.push('hide-inactive-expired');

        if (this.get('isDragging')) {
          css.push('sortable--is-dragging');
        }
      }

      if (selLicensor) {
        css.push('hide-wrong-licensor-movies');
      }

      if (selCountries && selCountries.length > 0) {
        css.push('hide-wrong-movies-countries');
      }

      return css.join(' ');
    }),
    uiOptions: null,
    showFilters: true,
    togglePackages: true,
    availableCountries: Ember.computed('model.countries.length', function () {
      return this.get('model.countries');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('uiOptions', Ember.Object.create({
        toggleCategories: false,
        toggleInactiveOrExpired: false
      }));
    },
    actions: {
      reorderCategories: function reorderCategories(itemModels) {
        this.setNewOrder(itemModels);
        this.saveNewOrder(itemModels, 'category');
      },
      reorderMovies: function reorderMovies(itemModels) {
        this.setNewOrder(itemModels);
        this.saveNewOrder(itemModels, 'movie');
      },
      edit: function edit(selected, component) {
        if (selected instanceof Ember.ObjectProxy) {
          selected = selected.get('content');
        }

        this.setProperties({
          selected: selected,
          editComponent: component
        });
      },
      create: function create(template, component) {
        if (template instanceof Ember.ObjectProxy) {
          template = template.get('content');
        }

        this.setProperties({
          createTemplate: template,
          editComponent: component
        });
      },
      linkToMovie: function linkToMovie(movie) {
        var controller = Ember.getOwner(this).lookup('controller:application');

        if (movie instanceof Ember.ObjectProxy) {
          return controller.transitionToRoute('movie', movie.get('content'));
        }

        return controller.transitionToRoute('movie', movie);
      },
      linkToNewMovie: function linkToNewMovie(template) {
        var modelName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'movie';
        var controller = Ember.getOwner(this).lookup('controller:application');
        return controller.transitionToRoute('movies.create', {
          queryParams: {
            template: template.get('id'),
            modelName: modelName
          }
        });
      },
      cancelComplete: function cancelComplete(selected) {
        return this.scrollToMovie(selected);
      },
      saveComplete: function saveComplete(selected) {
        return this.scrollToMovie(selected);
      },
      dragStarted: function dragStarted() {
        this.set('isDragging', true);
      },
      dragStopped: function dragStopped() {
        this.set('isDragging', false);
      },
      togglePackages: function togglePackages() {
        var toggle = !this.get('togglePackages');
        this.set('togglePackages', toggle);
        this.get('model.packages').forEach(function (p) {
          return p.set('isOpen', toggle);
        });
      },
      toggleCategories: function toggleCategories() {
        this.get('uiOptions').toggleProperty('toggleCategories');
      },
      toggleInactiveExpired: function toggleInactiveExpired() {
        this.get('uiOptions').toggleProperty('toggleInactiveOrExpired');
      },
      toggleOrder: function toggleOrder() {
        this.get('uiOptions').toggleProperty('toggleOrder');
      },
      toggleInherited: function toggleInherited() {
        this.get('uiOptions').toggleProperty('toggleInherited');
      }
    }
  });

  _exports.default = _default;
});