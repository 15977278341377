define("backend/mixins/localize-mixin", ["exports", "ember-data", "backend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = Ember.Mixin.create({
    name: attr('string'),
    nameDeu: attr('string'),
    nameEng: attr('string'),
    displayName: function () {
      var name = this.get('nameDeu') || this.get('nameEng') || this.get('name');

      if (_environment.default.displayIds) {
        if (!this.get('isNew')) {
          return name + " (" + this.get('id') + ")";
        }
      } else {
        return name;
      }
    }.property('nameDeu', 'nameEng', 'name')
  });

  _exports.default = _default;
});