define("backend/models/country", ["exports", "backend/models/localization-model", "ember-cp-validations", "backend/utils/static-data/europe-country-codes"], function (_exports, _localizationModel, _emberCpValidations, _europeCountryCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localizationModel.default.extend({
    inEurope: Ember.computed('id', function () {
      return _europeCountryCodes.default.includes(this.get('id'));
    }),
    validators: {
      id: (0, _emberCpValidations.validator)('presence', true),
      name: (0, _emberCpValidations.validator)('presence', true),
      nameEng: (0, _emberCpValidations.validator)('presence', true)
    }
  });

  _exports.default = _default;
});