define("backend/models/localization-model", ["exports", "ember-data", "backend/models/buffered-model"], function (_exports, _emberData, _bufferedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _bufferedModel.default.extend({
    name: attr('string'),
    nameEng: attr('string'),
    displayName: Ember.computed('nameEng', 'name', {
      get: function get() {
        return this.get('nameEng') || this.get('name');
      },
      set: function set() {}
    })
  });

  _exports.default = _default;
});