define("backend/prototypes/tables/headers/support-actions", ["exports", "backend/helpers/tables/table-group-header"], function (_exports, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableGroupHeader.default)([{
    group: {
      name: 'Support Action'
    },
    columns: [{
      attr: 'name',
      style: 'w-64'
    }, {
      attr: 'action',
      name: 'Action',
      component: 'hm-models/list/support-actions'
    }]
  }]);

  _exports.default = _default;
});