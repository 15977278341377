define("backend/pods/components/hm-form/select-create/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setValue: function setValue(option) {
        var value = !Ember.isNone(option) ? option.get('displayName') : null;

        var _this$getProperties = this.getProperties(['model', 'field']),
            model = _this$getProperties.model,
            field = _this$getProperties.field;

        model.set(field, value);

        if (this.get('acts.setAction')) {
          this.get('acts.setAction')(option);
        }
      }
    }
  });

  _exports.default = _default;
});