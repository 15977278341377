define("backend/pods/components/hm-device/toggle-license-structure/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    displayLicenseStructure: false,
    languages: Ember.computed('device', function () {
      var _this = this;

      var device = this.get('device');
      return Ember.RSVP.hash({
        categories: device.get('computedCategories'),
        movies: device.get('computedMovies'),
        languages: this.get('store').findAll('language')
      }).then(function (_ref) {
        var categories = _ref.categories,
            movies = _ref.movies,
            languages = _ref.languages;
        return languages.reduce(function (acc, language) {
          if (categories.get('length') > 0) {
            var langId = language.get('id');
            var langCategories = categories.filterBy('language', langId);

            if (!Ember.isEmpty(langCategories)) {
              acc.pushObject(Ember.Object.create({
                name: language.get('displayName'),
                categories: _this.buildCategoryTree(langCategories, movies)
              }));
            }
          }

          return acc;
        }, []);
      });
    }),
    buildCategoryTree: function buildCategoryTree(categories, movies) {
      var _this2 = this;

      var parentId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
      return categories.filterBy('parent.id', parentId).reduce(function (acc, category) {
        acc.pushObject(Ember.Object.create({
          name: category.get('name'),
          categories: _this2.buildCategoryTree(categories, movies, category.get('id')),
          movies: movies.filterBy('category.id', category.get('id'))
        }));
        return acc;
      }, []);
    },
    actions: {
      closeModal: function closeModal() {
        this.set('displayLicenseStructure', false);
      },
      toggle: function toggle() {
        this.toggleProperty('displayLicenseStructure');
      }
    }
  });

  _exports.default = _default;
});