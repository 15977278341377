define("backend/prototypes/tables/headers/investors", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([// Investor
  {
    attr: 'id',
    name: 'Id'
  }, {
    attr: 'displayName',
    name: 'Name',
    component: 'hm-models/list/row-w-departments/name'
  }, // Address
  {
    attr: 'address.zip',
    name: 'ZIP',
    display: false
  }, {
    attr: 'address.city',
    name: 'City'
  }, {
    attr: 'address.state',
    name: 'State'
  }, {
    attr: 'address.country',
    name: 'Country',
    display: false
  }, // Contact
  {
    attr: 'email',
    name: 'E-Mail',
    display: false
  }, {
    attr: 'phone',
    display: false
  }]);

  _exports.default = _default;
});