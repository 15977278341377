define("backend/pods/system-images/route", ["exports", "backend/mixins/query-model-mixin"], function (_exports, _queryModelMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_queryModelMixin.default, {
    googleStorage: Ember.inject.service(),
    queryParams: {
      t: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var store = this.store;
      var query = this.curPage(params);
      query['type'] = params.t;
      return this.queryModel({
        'system-images': store.query('system-image', query),
        deviceModels: store.peekAll('device-model')
      });
    },
    lazyloadDependencies: function lazyloadDependencies() {
      return {
        files: this.get('googleStorage').loadSystemImages()
      };
    }
  });

  _exports.default = _default;
});