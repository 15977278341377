define("backend/adapters/log", ["exports", "backend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    query: function query(store, type, _query) {
      var findUrl = this.buildURL(type.modelName) + '/' + _query.type;

      delete _query.type;
      _query = this.clearEmptyQueryParams(_query);

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      return this.ajax(findUrl, 'GET', {
        data: _query
      });
    },
    clearEmptyQueryParams: function clearEmptyQueryParams(params) {
      return Object.keys(params).reduce(function (acc, key) {
        var value = params[key];

        if (!Ember.isBlank(value) && value !== 'null') {
          if (key === 'start' || key === 'end') {
            var date = acc['date'] || {};
            date[key] = value;
            acc['date'] = date;
          } else {
            acc[Ember.String.underscore(key)] = value;
          }
        }

        return acc;
      }, {});
    }
  });

  _exports.default = _default;
});