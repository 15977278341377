define("backend/prototypes/tables/headers/categories", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'sortableId',
    name: 'Id'
  }, {
    attr: 'name',
    name: 'Name'
  }, {
    attr: 'package.displayName',
    name: 'Package'
  }, {
    attr: 'status.displayName',
    name: 'Status'
  }, {
    attr: 'parent.displayName',
    name: 'Parent Category'
  }, {
    attr: 'counts.movies',
    name: 'Anz. Filme'
  }]);

  _exports.default = _default;
});