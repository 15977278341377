define("backend/pods/devices/create/route", ["exports", "backend/prototypes/owners/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_NEW = '1';

  var _default = _create.default.extend({
    statusType: 'deviceStatus',
    setupController: function setupController(controller, model) {
      var store = this.get('store');
      controller.set('model', Ember.merge({
        packages: store.findAll('package'),
        apps: store.findAll('app'),
        statusNew: store.peekAll('deviceStatus').filter(function (status) {
          return status.get('id') === STATUS_NEW;
        }),
        purposeList: store.peekAll('devicePurpose'),
        licenseStatusList: store.peekAll('licenseStatus'),
        attachmentTypes: store.peekAll('attachmentType'),
        deviceTypes: store.peekAll('deviceType'),
        deviceModels: store.peekAll('deviceModel')
      }, model));
    }
  });

  _exports.default = _default;
});