define("backend/pods/backoffice/contracts/controller", ["exports", "backend/prototypes/tables/headers/contracts-all", "backend/prototypes/tables/headers/contracts-all-auto-cancel"], function (_exports, _contractsAll, _contractsAllAutoCancel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['status'],
    tableColumns: Ember.computed('status', function () {
      if (this.status === 'auto_cancel') {
        return _contractsAllAutoCancel.default;
      }

      return _contractsAll.default;
    }),
    clientSort: Ember.computed('status', function () {
      if (this.status === 'auto_cancel') {
        return {
          sortBy: 'daysTilLastCancelDate',
          order: 'asc'
        };
      }

      return {
        sortBy: 'deliveryDate',
        order: 'desc'
      };
    }),
    tableTitle: Ember.computed('status', function () {
      var status = this.get('status');

      if (status === 'signed') {
        return 'Signed Contracts';
      } else if (status === 'active') {
        return 'Active Contracts';
      } else if (status === 'cancelled') {
        return 'Cancelled Contracts';
      } else if (status === 'auto_cancel') {
        return 'Contracts automatically cancelling by end of period';
      }

      return 'All Contracts';
    })
  });

  _exports.default = _default;
});