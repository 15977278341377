define("backend/services/converter", ["exports", "backend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEOUT_TRANSCODE_LOGS_HEADSTART = 3000;
  var TIMEOUT_TRANSCODE_LOGS = 5000;
  var END_MARKER = ': Done';

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // -------------------------------- PUBLIC -----------------------------------
    filename: Ember.computed('_file', function () {
      var file = this.get('_file');
      return file;
    }),
    filenameBase: Ember.computed('_file', function () {
      var file = this.get('_file');

      if (!file) {
        return null;
      }

      return file.slice(file.lastIndexOf('/') + 1);
    }),
    status: Ember.computed('logs.firstObject', function () {
      var log = this.get('logs.firstObject');

      if (!log) {
        return 'No status found, yet';
      }

      return log;
    }),
    isPending: false,
    isCompleted: false,
    logs: null,
    _file: null,
    convert: function convert(file) {
      var _this = this;

      this.setProperties({
        isPending: true,
        _file: file
      });

      this._makeRequest('/transcode', {
        method: 'POST',
        data: {
          filename: file,
          method: 'default'
        }
      }).then(function () {
        Ember.run.later(function () {
          return _this._pollConvertProgress();
        }, TIMEOUT_TRANSCODE_LOGS_HEADSTART);
      });
    },
    resetState: function resetState() {
      this.setProperties({
        isPending: false,
        isCompleted: false,
        logs: null,
        _file: null
      });
    },
    // -------------------------------- PRIVATE ----------------------------------
    _makeRequest: function _makeRequest(url, options) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
          var headers = {};
          headers[headerName] = headerValue;
          options.headers = headers;
          options.contentType = 'application/json; charset=utf-8';
          options.data = {
            data: options.data
          };

          _this2.get('ajax').request(_environment.default.host + url, options).then(function (response) {
            return resolve(response);
          }).catch(function (error) {
            return reject(error);
          });
        });
      });
    },
    _pollConvertProgress: function _pollConvertProgress() {
      var _this3 = this;

      this._getConvertLogs().then(function (logs) {
        if (logs) {
          var filename = _this3.get('filenameBase');

          logs = logs.map(function (_ref) {
            var timestamp = _ref.timestamp,
                text = _ref.text;
            var status = text.slice(text.indexOf(filename) + filename.length + 1); // <timestamp>:<host>:transcode_log:<filename>:<status>

            return "".concat((0, _moment.default)(timestamp).format('DD.MM.YYYY HH:mm:ss'), ": ").concat(status);
          });
        }

        _this3.set('logs', logs);

        var isCompleted = logs && logs.get('firstObject').indexOf(END_MARKER) > -1;

        _this3.set('isCompleted', isCompleted);

        if (isCompleted) {
          alert("Conversion complete");

          _this3.resetState();
        } else {
          Ember.run.later(function () {
            return _this3._pollConvertProgress();
          }, TIMEOUT_TRANSCODE_LOGS);
        }
      });
    },
    _getConvertLogs: function _getConvertLogs() {
      var filename = this.get('filename');
      return this._makeRequest("/transcode/logs?id=".concat(filename), {
        method: 'GET'
      }).then(function (_ref2) {
        var data = _ref2.data;
        return data;
      });
    }
  });

  _exports.default = _default;
});