define("backend/pods/components/hm-models/list/row-w-departments/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    status: Ember.computed('filterBy', function () {
      if (this.get('filterBy')) {
        return this.get('model').get('departments').filterBy(this.get('filterBy')).mapBy('status.displayName').join('; ');
      }

      return this.get('model').get('departments').mapBy('status.displayName').join('; ');
    })
  });

  _exports.default = _default;
});