define("backend/pods/components/hm-models/list/row-w-departments/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    tdClass: 'display-td'
  });

  _exports.default = _default;
});