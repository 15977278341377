define("backend/utils/current-day-utc", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return (0, _moment.default)().format('YYYY-MM-DD');
  }
});