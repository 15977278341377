define("backend/helpers/format-filesize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatFilesize = formatFilesize;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatBytes(number, suffix) {
    return number.toLocaleString(['de'], {
      maximumFractionDigits: 2
    }) + " " + suffix;
  }

  function formatFilesize(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 3),
        bytes = _ref3[0],
        isMB = _ref3[1],
        isKB = _ref3[2];

    var _ref2$format = _ref2.format,
        format = _ref2$format === void 0 ? 'MB' : _ref2$format;

    if (!Ember.isEmpty(bytes)) {
      if (format === 'MB') {
        if (isMB) {
          return formatBytes(bytes, format);
        }

        if (isKB) {
          return formatBytes(bytes / 1000, format);
        }

        return formatBytes(bytes / 1000 / 1000, format);
      } else if (format === 'kB') {
        if (isMB) {
          return formatBytes(bytes * 1000, format);
        }

        if (isKB) {
          return formatBytes(bytes, format);
        }

        return formatBytes(bytes / 1000, format);
      }
    }

    return '';
  }

  var _default = Ember.Helper.helper(formatFilesize);

  _exports.default = _default;
});