define("backend/pods/logs/cron/route", ["exports", "backend/config/environment", "backend/helpers/tables/table-group-header"], function (_exports, _environment, _tableGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GROUP_LEAD = {
    group: {
      name: 'Lead'
    },
    columns: [{
      attr: 'client_id',
      name: '#'
    }, {
      attr: 'client_name',
      name: 'Name'
    }, {
      attr: 'department_id',
      name: '# Department'
    }, {
      attr: 'department_name',
      name: 'Department'
    }, {
      attr: 'contract_id',
      name: 'Contract',
      display: false
    }, {
      attr: 'user_id',
      name: 'User'
    }]
  };
  var GROUP_CLIENT = {
    group: {
      name: 'Client'
    },
    columns: [{
      attr: 'client_id',
      name: '#'
    }, {
      attr: 'client_name',
      name: 'Name'
    }, {
      attr: 'department_id',
      name: '# Department'
    }, {
      attr: 'department_name',
      name: 'Department'
    }, {
      attr: 'user_id',
      name: 'User'
    }]
  };
  var GROUP_TASK = {
    group: {
      name: 'New Task'
    },
    columns: [{
      attr: 'next_due',
      name: 'Due Date',
      style: 'text-nowrap'
    }, {
      attr: 'next_due_info',
      name: 'Due Date Info',
      style: 'text-nowrap',
      display: false
    }, {
      attr: 'create_task',
      name: 'Create'
    }]
  };
  var TABLE_COLUMNS = {
    'CONTACT_TASK': [GROUP_LEAD, {
      group: {
        name: 'Statistics'
      },
      columns: [{
        attr: 'days_since_contact',
        name: 'Days Last Contact Event',
        style: 'text-center'
      }, {
        attr: 'days_since_contact_task',
        name: 'Days Last Contact Task',
        style: 'text-center'
      }, {
        attr: 'days_since_task_due',
        name: 'Days Last Task',
        style: 'text-center'
      }, {
        attr: 'num_tasks',
        name: 'Num Tasks',
        style: 'text-center'
      }]
    }, GROUP_TASK],
    'EVENT_TASK': [GROUP_LEAD, {
      group: {
        name: 'Statistics'
      },
      columns: [{
        attr: 'days_until_event',
        name: 'Days Until Event',
        style: 'text-center'
      }, {
        attr: 'event_date',
        name: 'Event Date',
        style: 'text-center'
      }, {
        attr: 'num_tasks',
        name: 'Num Tasks',
        style: 'text-center'
      }]
    }, GROUP_TASK],
    'TESTING_TASK': [GROUP_LEAD, {
      group: {
        name: 'Statistics'
      },
      columns: [{
        attr: 'days_until_start',
        name: 'Days Until Start',
        style: 'text-center'
      }, {
        attr: 'days_until_end',
        name: 'Days Until End',
        style: 'text-center'
      }, {
        attr: 'trial_start_date',
        name: 'Start Date',
        style: 'text-center text-nowrap'
      }, {
        attr: 'trial_end_date',
        name: 'End Date',
        style: 'text-center text-nowrap'
      }, {
        attr: 'num_tasks',
        name: 'Num Of Tasks',
        style: 'text-center'
      }]
    }, GROUP_TASK],
    'SIMPLE_CLIENT_TASK': [GROUP_CLIENT, GROUP_TASK],
    'TASK_3': [GROUP_LEAD, {
      group: {
        name: 'Statistics'
      },
      columns: [{
        attr: 'days_since_trial_status',
        name: 'Days Trial Status',
        style: 'text-center'
      }, {
        attr: 'days_since_task_due',
        name: 'Days Last Task',
        style: 'text-center'
      }, {
        attr: 'num_tasks',
        name: 'Num Tasks',
        style: 'text-center'
      }]
    }, GROUP_TASK],
    'TASK_13': [{
      group: {
        name: 'Client'
      },
      columns: [{
        attr: 'client_id',
        name: '#'
      }, {
        attr: 'client_name',
        name: 'Name'
      }, {
        attr: 'department_id',
        name: '# Department'
      }, {
        attr: 'department_name',
        name: 'Department'
      }]
    }, {
      group: {
        name: 'Contract'
      },
      columns: [{
        attr: 'delivery_date',
        name: 'Delivery Date',
        style: 'text-center text-nowrap'
      }, {
        attr: 'duration',
        name: 'Duration',
        style: 'text-center',
        noFormat: true
      }, {
        attr: 'billing_interval',
        name: 'Interval',
        style: 'text-center'
      }, {
        attr: 'cancellation_date',
        name: 'Cancellation Date',
        style: 'text-center text-nowrap'
      }, {
        attr: 'notice_period',
        name: 'Notice Period',
        style: 'text-center'
      }, {
        attr: 'contract_end_date',
        name: 'Contract End',
        style: 'text-center text-nowrap'
      }]
    }, {
      group: {
        name: 'Billing Period'
      },
      columns: [{
        attr: 'period_idx',
        name: 'Idx',
        style: 'text-center text-nowrap'
      }, {
        attr: 'period_start',
        name: 'Period Start',
        style: 'text-center text-nowrap'
      }, {
        attr: 'period_end',
        name: 'Period End',
        style: 'text-center text-nowrap'
      }, {
        attr: 'billing_exists',
        name: 'Billing Exists',
        style: 'text-center'
      }, {
        attr: 'days_until_period',
        name: 'Days Until Period',
        style: 'text-center'
      }]
    }, {
      group: {
        name: 'New Task'
      },
      columns: [{
        attr: 'next_due',
        name: 'Due Date',
        style: 'text-nowrap'
      }, {
        attr: 'create_task',
        name: 'Create'
      }, {
        attr: 'task_exists',
        name: 'Task Exists',
        style: 'text-center'
      }]
    }],
    'TASK_14': [{
      group: {
        name: 'Client'
      },
      columns: [{
        attr: 'client_id',
        name: '#'
      }, {
        attr: 'client_name',
        name: 'Name'
      }]
    }, {
      group: {
        name: 'Billing'
      },
      columns: [{
        attr: 'billing_id',
        name: '#',
        style: 'text-center'
      }, {
        attr: 'billing_number',
        name: 'Number',
        style: 'text-center'
      }]
    }, {
      group: {
        name: 'Billing Period'
      },
      columns: [{
        attr: 'overdue_since',
        name: 'Overdue Since',
        style: 'text-center'
      }, {
        attr: 'existing_task',
        name: 'Existing Task',
        style: 'text-center'
      }]
    }, {
      group: {
        name: 'New Task'
      },
      columns: [{
        attr: 'next_due',
        name: 'Due Date',
        style: 'text-nowrap'
      }, {
        attr: 'create_task',
        name: 'Create'
      }, {
        attr: 'content',
        name: 'Content'
      }]
    }]
  };
  var TASK_URL = {
    'TASK_1': 'task_lead_contact',
    'TASK_2': 'task_lead_presentation',
    'TASK_3': 'task_lead_trial_no_date',
    'TASK_4': 'task_lead_confirm_trial',
    'TASK_5': 'task_lead_trial_summary',
    'TASK_6': 'task_lead_testing_start',
    'TASK_7': 'task_lead_testing_call',
    'TASK_8': 'task_lead_testing_summary',
    'TASK_9': 'task_lead_decision_making',
    'TASK_10': 'task_lead_offer_phase_done',
    'TASK_11': 'task_client_contact',
    'TASK_12': 'task_client_delivered',
    'TASK_13': 'task_contract_billings',
    'TASK_14': 'task_overdue_billings'
  };
  var TASK_COLS = {
    'TASK_1': 'CONTACT_TASK',
    'TASK_2': 'EVENT_TASK',
    'TASK_4': 'EVENT_TASK',
    'TASK_5': 'EVENT_TASK',
    'TASK_6': 'TESTING_TASK',
    'TASK_7': 'TESTING_TASK',
    'TASK_8': 'TESTING_TASK',
    'TASK_9': 'CONTACT_TASK',
    'TASK_10': 'CONTACT_TASK',
    'TASK_11': 'SIMPLE_CLIENT_TASK',
    'TASK_12': 'EVENT_TASK'
  };

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    setTable: function setTable(_ref) {
      var taskNo = _ref.taskNo,
          _ref$env = _ref.env,
          execute = _ref$env.execute,
          executeTest = _ref$env.executeTest,
          debug = _ref$env.debug,
          data = _ref.data;
      var columns;

      if ((execute || executeTest) && data.length > 0) {
        columns = [{
          group: {
            name: 'Task'
          },
          columns: Object.keys(data[0]).map(function (attr) {
            return {
              attr: attr,
              style: 'text-nowrap'
            };
          })
        }];
      } else {
        if (TASK_COLS["TASK_".concat(taskNo)]) {
          columns = TABLE_COLUMNS[TASK_COLS["TASK_".concat(taskNo)]];
        } else {
          columns = TABLE_COLUMNS["TASK_".concat(taskNo)];
        }
      }

      this.controller.set('table', Ember.Object.create({
        title: "Task #".concat(taskNo, " (").concat(debug ? 'Show All' : execute ? 'Run' : 'Test', "), ").concat(data.length, " records"),
        data: data.map(function (d) {
          return Ember.Object.create(d);
        }),
        columns: (0, _tableGroupHeader.default)(columns),
        tableId: "task-".concat(taskNo, "-").concat(debug, "-").concat(executeTest, "-").concat(execute)
      }));
    },
    executeTask: function executeTask(_ref2) {
      var _this = this;

      var taskNo = _ref2.taskNo,
          _ref2$env = _ref2.env,
          debug = _ref2$env.debug,
          executeTest = _ref2$env.executeTest,
          execute = _ref2$env.execute;

      if (execute && !confirm("Are you sure?")) {
        return false;
      }

      this.controller.set('table', null);
      this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;
        var url = TASK_URL["TASK_".concat(taskNo)];

        _this.get('ajax').request(_environment.default.host + "/cron/hm_tasks/".concat(url, "?debug=").concat(debug, "&execute_test=").concat(executeTest, "&execute=").concat(execute), {
          headers: headers
        }).then(function (_ref3) {
          var data = _ref3.data;

          _this.setTable({
            taskNo: taskNo,
            env: {
              debug: debug,
              executeTest: executeTest,
              execute: execute
            },
            data: data
          });
        });
      });
    },
    actions: {
      cronTask: function cronTask(taskNo) {
        var debug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var executeTest = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var execute = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        this.executeTask({
          env: {
            debug: debug,
            executeTest: executeTest,
            execute: execute
          },
          taskNo: taskNo
        });
      }
    }
  });

  _exports.default = _default;
});