define("backend/pods/components/hm-form/mask/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MASKS = {
    'percent': '99%',
    'months': '99 months',
    'days': '999 d\\ays'
  };

  var _default = Ember.Component.extend({
    type: 'text',
    mask: Ember.computed('type', function () {
      return MASKS[this.get('type')];
    }),
    actions: {
      setValue: function setValue(value) {
        if (this.model.set) {
          this.get('model').set(this.get('field'), value);
        } else {
          this.model[this.field] = value;
        }
      }
    }
  });

  _exports.default = _default;
});