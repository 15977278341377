define("backend/utils/static-data/task-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROLE_OWNER = {
    L: 'lead',
    C: 'client',
    SC: 'sponsored-client',
    D: 'distributor',
    T: 'tester',
    LI: 'licensor'
  };
  var _default = [{
    id: 2,
    name: 'Contact',
    can_create: true,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C, ROLE_OWNER.SC]
  }, {
    id: 1,
    name: 'Other',
    can_create: true,
    roles: [ROLE_OWNER.L, ROLE_OWNER.C, ROLE_OWNER.SC]
  }];
  _exports.default = _default;
});