define("backend/pods/client-features/statistics/table/controller", ["exports", "backend/helpers/format-boolean", "backend/helpers/fix-decimal", "backend/utils/export-csv", "backend/utils/format-csv", "backend/prototypes/tables/headers/statistics-details-client"], function (_exports, _formatBoolean, _fixDecimal, _exportCsv, _formatCsv, _statisticsDetailsClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CSV_HEAD = ['ID', 'Session Number', 'Session Timestamp', 'Session Details', 'Session Language', 'Session Gender', 'Session Age', 'Session Duration', 'Start Time', 'Stop Time', 'Start Timestamp', 'Stop Timestamp', 'Total Duration', 'Replay Duration', 'Pause Duration', 'Pauses', 'Owner Name', 'Owner Type', 'Device ID', 'Device Serial Number', 'Category', 'Package', 'Movie ID', 'Movie Title', 'Licensor Name', 'Movie Duration', 'Movie Viewed', 'Revenue Share'].join(';');

  var _default = Ember.Controller.extend({
    tableColumns: _statisticsDetailsClient.default,
    clientSort: {
      sortBy: 'sessionTimestamp',
      order: 'desc'
    },
    tableAsCSV: Ember.computed('model.table', function () {
      return this.get('model.table').map(function (sel) {
        var session_details = sel.get('session_details');
        return [sel.get('id'), sel.get('session_id'), sel.get('session_timestamp'), session_details ? "\"".concat(session_details, "\"") : "", sel.get('session_language'), sel.get('session_gender'), sel.get('session_age'), sel.get('session_duration'), sel.get('session_application'), sel.get('start_time'), sel.get('stop_time'), sel.get('start_timestamp'), sel.get('stop_timestamp'), sel.get('total_duration'), sel.get('replay_duration'), sel.get('pause_duration'), sel.get('pauses'), sel.get('owner_name'), sel.get('owner_type'), sel.get('device'), sel.get('serial_number'), sel.get('movie_category'), sel.get('movie_package'), sel.get('movie_id'), sel.get('movie_title'), sel.get('licensor_name'), sel.get('movie_duration'), (0, _fixDecimal.fixDecimal)([sel.get('movie_viewed'), 2]), (0, _formatBoolean.formatBoolean)([sel.get('revenue_share')])].map(_formatCsv.default).join(';');
      });
    }),
    createCSV: function createCSV() {
      return [CSV_HEAD].concat(this.get('tableAsCSV')).join('\n');
    },
    actions: {
      exportAsCSV: function exportAsCSV() {
        (0, _exportCsv.default)(this.createCSV(), 'statistics-details.csv');
      }
    }
  });

  _exports.default = _default;
});