define("backend/prototypes/tables/headers/billings-paid", ["exports", "backend/helpers/tables/table-header"], function (_exports, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _tableHeader.default)([{
    attr: 'number',
    style: 'text-right text-nowrap',
    canEdit: true
  }, {
    attr: 'date',
    style: 'text-center text-nowrap',
    canEdit: true
  }, {
    attr: 'file',
    name: 'PDF',
    component: 'secure-download',
    style: 'text-nowrap'
  }, {
    attr: 'owner',
    name: 'Client/Lead',
    component: 'link-owner2',
    canEdit: false
  }, {
    attr: 'totalAmountNet',
    format: 'currency',
    name: 'Total Amount (Net)',
    style: 'text-right text-nowrap',
    canEdit: true,
    display: false
  }, {
    attr: 'totalAmountGross',
    format: 'currency',
    name: 'Total Amount (Gross)',
    style: 'text-right text-nowrap',
    canEdit: true
  }, {
    attr: 'discount',
    format: 'currency',
    name: 'Discount',
    style: 'text-right text-nowrap',
    canEdit: true,
    display: false
  }, {
    attr: 'paymentTarget',
    name: 'Payment Target',
    style: 'text-center text-nowrap',
    canEdit: true
  }, {
    attr: 'periodStart',
    name: 'Period Start',
    format: 'date',
    display: false,
    canEdit: true
  }, {
    attr: 'periodEnd',
    name: 'Period End',
    format: 'date',
    display: false,
    canEdit: true
  }, {
    attr: 'paymentMethod',
    name: 'Payment Method',
    display: false,
    canEdit: true
  }, {
    attr: 'tax',
    display: false,
    canEdit: true
  }, {
    attr: 'language',
    display: false,
    canEdit: true
  }, {
    attr: 'id',
    display: false,
    canEdit: true
  }, {
    attr: 'dateOfShipment',
    name: 'Shipment Date',
    component: 'billing-shipment-date'
  }, {
    attr: 'dateOfPayment',
    name: 'Payment Date',
    component: 'billing-payment-date'
  }, {
    attr: 'dateOfCancellation',
    name: 'Cancel',
    component: 'billing-cancellation-date',
    display: false
  }]);

  _exports.default = _default;
});