define("backend/pods/distributor/clients/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AgDmEgeH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"hm-models/create\",null,[[\"route\",\"label\"],[\"clients.new\",\"Create Client\"]]],false],[0,\"\\n\"],[1,[28,\"hm-models/list/table-clickable\",null,[[\"ownerType\",\"data\",\"isLoading\",\"rowComponent\",\"page\",\"size\",\"tableColumns\",\"defaultClientSort\"],[\"client\",[24,[\"model\"]],[24,[\"model\",\"isLoading\"]],[28,\"component\",[\"hm-models/list/row-w-departments\"],[[\"filterBy\"],[\"status.isClientStatus\"]]],[24,[\"page\"]],[24,[\"size\"]],[24,[\"tableColumns\"]],[24,[\"clientSort\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backend/pods/distributor/clients/template.hbs"
    }
  });

  _exports.default = _default;
});