define("backend/pods/kpi-dashboard/company/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var SALES_DASHBOARD = [{
    label: 'Total Clients',
    url: 'clients/number/total'
  }, {
    label: 'Total Sessions',
    suffix: 'happy patients',
    url: 'sessions/number/total'
  }, {
    label: 'New Clients',
    suffix: 'current month',
    url: 'clients/number/month'
  }, {
    label: 'New Sessions',
    suffix: 'happy patients (current month)',
    url: 'sessions/number/month'
  }, {
    label: 'Monthly Recurring Revenue',
    url: 'contracts/revenue/month',
    format: 'currency'
  }];
  var LIQUIDITY_CHART = 'liquidity';
  var REVENUE_LIST = 'contracts/revenue/list';

  var _default = Ember.Route.extend({
    model: function model() {
      var adapter = this.get('store').adapterFor('dashboard');
      var tiles = SALES_DASHBOARD.map(function (_ref, idx) {
        var suffix = _ref.suffix,
            label = _ref.label,
            url = _ref.url,
            format = _ref.format;
        return {
          id: idx,
          label: label,
          suffix: suffix,
          format: format,
          query: adapter.ajax(adapter.buildUrl(url), 'GET')
        };
      });
      return {
        tiles: tiles,
        liquidity: adapter.ajax(adapter.buildUrl(LIQUIDITY_CHART), 'GET').then(function (response) {
          return {
            series: [{
              name: 'Monthly Revenue €',
              data: response.map(function (_ref2) {
                var _ref3 = _slicedToArray(_ref2, 3),
                    date = _ref3[0],
                    amount = _ref3[1],
                    tooltip = _ref3[2];

                var dateLocal = new Date(date);
                var dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), dateLocal.getUTCHours(), dateLocal.getUTCMinutes(), dateLocal.getUTCSeconds());
                return [dateUTC, amount, tooltip];
              }).sort(function (a, b) {
                return a[0] - b[0];
              }),
              color: '#1e8cc8'
            }]
          };
        }),
        revenueList: adapter.ajax(adapter.buildUrl(REVENUE_LIST), 'GET')
      };
    }
  });

  _exports.default = _default;
});