define("backend/models/mlnames-model", ["exports", "backend/models/buffered-model"], function (_exports, _bufferedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bufferedModel.default.extend({
    displayName: Ember.computed('names.@each.name', {
      get: function get() {
        var engName = this.get('names').filterBy('code', 'eng');

        if (engName.get('length')) {
          return engName.objectAt(0).get('name');
        }
      },
      set: function set() {}
    }),
    createDependencies: function createDependencies() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this._super();

        _this._createEmptyNames(resolve);
      });
    },
    _createEmptyNames: function _createEmptyNames(resolve) {
      var _this2 = this;

      this._retrieveLanguages().then(function (languages) {
        var newNames = [];

        var selNames = _this2.get('names');

        Ember.run(function () {
          languages.forEach(function (language) {
            if (!selNames.isAny('code', language.get('id'))) {
              newNames.pushObject(_this2._createEmptyName(language));
            }
          });

          _this2.get('names').pushObjects(newNames);

          resolve();
        });
      });
    },
    _createEmptyName: function _createEmptyName(language) {
      var localizedName = {
        code: language.get('id')
      };
      var nameRecordType = this.get('nameRecordType');
      return this.get('store').createRecord(nameRecordType, localizedName);
    },
    _retrieveLanguages: function _retrieveLanguages() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var storedLanguages = _this3.get('store').peekAll('language');

        if (storedLanguages && storedLanguages.get('length') > 0) {
          resolve(storedLanguages);
        } else {
          resolve(_this3.get('store').findAll('language'));
        }
      });
    }
  });

  _exports.default = _default;
});