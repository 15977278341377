define("backend/models/device-type", ["exports", "ember-data", "backend/mixins/localize-mixin"], function (_exports, _emberData, _localizeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_localizeMixin.default, {
    label: _emberData.default.attr('string')
  });

  _exports.default = _default;
});