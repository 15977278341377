define("backend/utils/ajax-array-buffer", ["exports", "backend/utils/array-buffer-to-string"], function (_exports, _arrayBufferToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = Object.keys;

  function ajaxArrayBuffer(url, headers, mimeType) {
    return new Ember.RSVP.Promise(function (resolve) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';
      keys(headers).forEach(function (headerName) {
        request.setRequestHeader(headerName, headers[headerName]);
      });

      request.onload = function () {
        var arrayBuffer = this.response;
        var raw = (0, _arrayBufferToString.default)(arrayBuffer);
        var base64Str = "data:".concat(mimeType, ";base64,").concat(btoa(raw));
        resolve(base64Str);
      };

      request.send();
    });
  }

  var _default = ajaxArrayBuffer;
  _exports.default = _default;
});