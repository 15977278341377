define("backend/components/lazy-load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIRTUAL_BELONGS_TO = ['owner', 'sponsor'];

  var _default = Ember.Component.extend({
    storeCache: Ember.inject.service(),
    shouldLoad: false,
    loadNow: Ember.observer('shouldLoad', function () {
      this.lazyLoad();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.lazyLoad();
    },
    lazyLoad: function lazyLoad() {
      var _this = this;

      if (!this.get('shouldLoad')) {
        return false;
      }

      var _this$getProperties = this.getProperties('model', 'modelName'),
          model = _this$getProperties.model,
          modelName = _this$getProperties.modelName;

      var id, loadModelName;

      if (VIRTUAL_BELONGS_TO.includes(modelName)) {
        var virtualRelationship = model.get('asyncIds')[modelName];
        loadModelName = virtualRelationship.loadModelName;
        id = virtualRelationship.id;
      } else {
        id = model.belongsTo(modelName).id();
        loadModelName = modelName;
      }

      if (loadModelName && id) {
        this.get('storeCache').lazyLoad(loadModelName, id).then(function (record) {
          if (record && !_this.get('isDestroyed')) {
            _this.set('value', record.get(_this.get('attribute')));
          }
        });
      }
    }
  });

  _exports.default = _default;
});